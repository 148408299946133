import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Input} from "reactstrap";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../generic/loader";
import {t} from "i18next";

const PriceCurrency = ({form, state, setState, isRequired = true, disabled=false}) => {
    const {control, errors} = form;
    const [currencies, setCurrencies] = useState([]);
    const currenciesEndPoints = useDefaultEndpoints('currencies');

    useEffect(() => {
        currenciesEndPoints.getEntries({}).then(res => setCurrencies(() => res))
    }, [])
    return !currencies.length
        ? <Loader/>
        : (
            <Controller
                control={control}
                name="price_currency"
                defaultValue={state.price_currency}
                rules={isRequired ? {required: t('inputs.validation.required')} : {}}
                render={(
                    {value, onBlur, name, ref, onChange},
                    {invalid}
                ) =>
                    <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={isRequired}
                                         label={t('common.forms.priceCurrency.currency')}
                    >
                        <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                               disabled={disabled}
                               style={{border: invalid ? '1px solid #dc3545' : '0'}}
                               value={value}
                               onChange={(e) => {
                                   setState(e);
                                   onChange(e.target.value)
                               }}>
                            <option value={''} disabled>
                                {t('common.forms.priceCurrency.pickCurrency')}
                            </option>
                            {currencies.map(currency => <option key={currency.sign}
                                                                value={currency.key}>{currency.sign}</option>)}
                        </Input>
                    </FieldWithValidation>
                }
            />
        );
}

PriceCurrency.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};
export default PriceCurrency;