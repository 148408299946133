import { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import LinksHistory from "../LinksHistory";
import {t} from "i18next";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import { CopyLink } from '../../../generic/buttons';
import { toast } from 'react-toastify';
import { useLinksApi } from '../../../../lib/api/links';
import AsyncEventsPaginateComponent from './AsyncPaginate';

const EventsLinksGenerator = () => {
  const [events, onChangeEvents] = useState([]);
  const [reload, setReload] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  const linksEndpoints = useLinksApi();

  const permissions = useRoleSystem(ENTITIES.LINK_GEN);

  const generateLink = async () => {
    if (!events?.length) {
      toast.error(t('management.linksGenerator.enterField'))
      return;
    }

    const url = await linksEndpoints.generateLinkForEvents(events.map(base => base.value));

    setGeneratedLink(url);
    setReload(!reload);
  }

  return (
    <>
      <form>
        <Row>
          <Col lg={4} className="mb-3">
            <FormGroup>
              <Label>{t('management.linksGenerator.events')}<span style={{color: 'red'}}>*</span></Label>
              <AsyncEventsPaginateComponent events={events} onChangeEvents={onChangeEvents} />
            </FormGroup>
          </Col>
          <Col lg={4} className="mb-3">
            <Label>{t('management.linksGenerator.link')}</Label>
            <Input name="link" value={generatedLink} disabled
                   placeholder="https://booking.silavetra.com/events?base=berezki&event=train"/>
            <div className="mt-2">
              <CopyLink href={generatedLink} text={t('management.linksGenerator.followLink')}
                        className={!generatedLink ? 'disabledCopyButton' : ''}
                        buttonClassName={!generatedLink ? 'disabledCopyButton' : ''}/>
            </div>
          </Col>
          <Col xs={12} className="d-flex align-items-center">
            <ElementWithPermissions hidden={false} disabled={!permissions.edit}>
              <Button className="btn-rounded" size="sm" color="primary" onClick={generateLink}>{t('management.linksGenerator.generate')}</Button>
            </ElementWithPermissions>
          </Col>
        </Row>
      </form>
      <LinksHistory reload={reload}/>
    </>
  )
}

export default EventsLinksGenerator;