import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {PhoneRHF} from "../../../../common/reactHookFormsFields";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import PrivateFileComponent from '../../../../common/formElements/PrivateFile';
import { Constants } from 'sv-common';

const ClientData = ({state, dispatch, control, errors}) => {
    const [shirts, setShirts] = useState([]);
    const getShirts = useDefaultEndpoints('shirt-sizes').getEntries
    useEffect(() => {
        getShirts({}).then(res => setShirts(() => res));
    }, [])

    const setClientData = e => dispatch({type: 'setClientData', payload: e});
    const setAdditionalField = e => dispatch({type: 'setAdwqaditionalField', payload: e});
    return <Form>
        <Row>
            <Col xs={12} md={6} lg={4}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="first_name"
                        defaultValue={state.first_name || ''}
                        rules={{required: t('management.users.requiredField')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('management.users.name')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder={t('management.users.namePlaceholder')}
                                                 onChange={e => {
                                                     setClientData(e)
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={4}>
                <FormGroup>
                    <Label for="last_name">{t('management.users.surname')}</Label>
                    <Input type="text" name="last_name" id="last_name" placeholder={t('management.users.surnamePlaceholder')}
                           value={state.last_name || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <Col xs={12} md={6} lg={4}>
                <FormGroup>
                    <Label for="surname">{t('management.users.patronymic')}</Label>
                    <Input type="text" name="surname" id="surname" placeholder={t('management.users.patronymicPlaceholder')}
                           value={state.surname || ''} onChange={setClientData}/>
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <FormGroup>
                    <Label for="first_name_en">{t('management.users.nameEng')}</Label>
                    <Input type="text" name="first_name_en" id="first_name_en" placeholder="Ivan"
                           value={state.first_name_en || ''} onChange={setClientData}/>
                </FormGroup>
              </Col>
              <Col xs={12} md={6} lg={4}>
                  <FormGroup>
                      <Label for="last_name_en">{t('management.users.surnameEng')}</Label>
                      <Input type="text" name="last_name_en" id="last_name_en" placeholder="Ivanov"
                             value={state.last_name_en || ''} onChange={setClientData}/>
                  </FormGroup>
              </Col>
            </ElementWithAccessInCountries>
            <Col xs={12} md={3} lg={2}>
                <FormGroup>
                    <Label for="birthdate">{t('management.users.birthDate')}</Label>
                    <Input type="date" id="start" name="birthdate"
                           value={state.birthdate || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={3} lg={2}>
                <FormGroup>
                    <Label for="shirt_size">{t('management.users.shirtSize')}</Label>
                    <Input type="select" id="shirt_size" name="shirt_size"
                           value={state.shirt_size || ''} onChange={setClientData}
                    >
                        <option value={''} disabled>{t('management.users.size')}</option>
                        {shirts.map(shirt => <option value={shirt.tag} key={shirt.tag}>{shirt.tag}</option>)}
                    </Input>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="passport_num_ru">{t('management.users.passportNumRu')}</Label>
                    <Input type="text" id="passport_num_ru" name="passport_num_ru"
                           value={state.passport_num_ru || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="passport_date_ru">{t('management.users.passportDateRu')}</Label>
                    <Input type="text" id="passport_date_ru" name="passport_date_ru"
                           value={state.passport_date_ru || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="passport_authorities_ru">{t('management.users.passportAuthoritiesRu')}</Label>
                    <Input type="text" id="passport_authorities_ru" name="passport_authorities_ru"
                           value={state.passport_authorities_ru || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="passport_num_foreign">{t('management.users.passportNumForeign')}</Label>
                    <Input type="text" id="passport_num_foreign" name="passport_num_foreign"
                           value={state.passport_num_foreign || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="passport_date_foreign">{t('management.users.passportDateForeign')}</Label>
                    <Input type="text" id="passport_date_foreign" name="passport_date_foreign"
                           value={state.passport_date_foreign || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <FormGroup>
                    <Label for="citizenship">{t('management.users.citizenship')}</Label>
                    <Input type="text" id="citizenship" name="citizenship"
                           value={state.citizenship || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>

            <Col xs={12} md={4} lg={2} xl={1}>
                <FormGroup>
                    <Label for="gender">{t('management.users.gender')}</Label>
                    <Input type="select" id="gender" name="gender"
                           value={state.gender || ''} onChange={setClientData}
                    >
                        <option value={''} disabled>{t('management.users.gender')}</option>
                        <option value={'m'}>{t('management.users.male')}</option>
                        <option value={'f'}>{t('management.users.female')}</option>
                        =
                    </Input>
                </FormGroup>
            </Col>
            <Col xs={12} md={4} lg={3}>
                <FormGroup>
                   <PhoneRHF form={{control, errors}} setState={setClientData} state={state} isRequired
                             name={'phone'}
                   />
                </FormGroup>
            </Col>

            <Col xs={12} md={4} lg={3}>
                <FormGroup>
                    <Label for="sailing_card_id">{t('management.users.sailingCardId')}</Label>
                    <Input type="text" id="sailing_card_id" name="sailing_card_id"
                           value={state.sailing_card_id || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>

            <Col xs={12} md={4} lg={2} xl={1}>
                <FormGroup>
                    <Label for="sailing_experience">{t('management.users.experience')}</Label>
                    <Input type="select" id="sailing_experience" name="sailing_experience"
                           value={state.sailing_experience || ''} onChange={setClientData}
                    >
                        <option value={''} disabled>{t('management.users.experience')}</option>
                        <option value={'0/7'}>{t('management.users.sailingExperience.0/7')}</option>
                        <option value={'1/7'}>{t('management.users.sailingExperience.1/7')}</option>
                        <option value={'2/7'}>{t('management.users.sailingExperience.2/7')}</option>
                        <ElementWithAccessInCountries hideInCountries={['EN']}>
                          <>
                            <option value={'3/7'}>{t('management.users.sailingExperience.3/7')}</option>
                            <option value={'4/7'}>{t('management.users.sailingExperience.4/7')}</option>
                            <option value={'5/7'}>{t('management.users.sailingExperience.5/7')}</option>
                            <option value={'6/7'}>{t('management.users.sailingExperience.6/7')}</option>
                            <option value={'7/7'}>{t('management.users.sailingExperience.7/7')}</option>
                          </>
                        </ElementWithAccessInCountries>
                    </Input>
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <Label for="sailing_experience">{t('management.users.characteristic')}</Label>
                    <Input type="textarea" id="characteristic" name="characteristic"
                           value={state.characteristic || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row className={'mt-5'}>
            <Col xs={12} md={4}>
                <FormGroup>
                    <Label for="telegram">Telegram</Label>
                    <Input type={'text'} name="telegram" id="telegram" placeholder={'@silavetrasila'}
                           value={state?.telegram || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>

            <Col xs={12} md={4}>
                <FormGroup>
                    <Label for="country">{t('management.users.country')}</Label>
                    <Input type="text" name="country" id="country" placeholder={t('management.users.countryPlaceholder')}
                           value={state?.country || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <Label for="city">{t('management.users.city')}</Label>
                    <Input type="text" name="city" id="city" placeholder={t('management.users.cityPlaceholder')}
                           value={state?.city || ''} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <Label for="street">{t('management.users.additionalInfo')}</Label>
                    <Input type={'text'} name="extra_address_info" id="extra_address_info" placeholder={t('management.users.additionalInfoPlaceholder')}
                           value={state?.extra_address_info || ''} onChange={setClientData}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                  <CustomInput type="checkbox" className={'custom-switch'} id="unsubscribed_news" name="unsubscribed_news"
                               label={t('management.users.unsubscribed_news')}
                               checked={state.unsubscribed_news || false} onChange={setClientData}
                  />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                  <CustomInput type="checkbox" className={'custom-switch'} id="is_corp" name="is_corp"
                               label={t('management.users.is_corp')}
                               checked={state.is_corp || false} onChange={setClientData}
                  />
                </FormGroup>
            </Col>
        </Row>
        <Row className={'mt-5'}>
            {(Object.keys(state.additional_fields || {})).map(additionalField => <Col xs={12} md={4}
                                                                                      key={additionalField}>
                <FormGroup>
                    <Label for="postcode">{state.additional_fields[additionalField].label}</Label>
                    <Input type={'text'} name={additionalField} id={additionalField}
                           value={state.additional_fields[additionalField].value || ''} onChange={setAdditionalField}/>
                </FormGroup>
            </Col>)}
        </Row>
        <PrivateFileComponent
          extra={{ owner: state?.user_data?.id }}
          type={Constants.PRIVATE_FILE_TYPES.PASSPORT}
        />
    </Form>
}

export default ClientData;

ClientData.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}
