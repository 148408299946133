import React from 'react';
import PropTypes from 'prop-types';

const hex = (x) => {
    x = x.toString(16);
    return (x.length === 1) ? '0' + x : x;

}
const LoadBadge = ({value, colorBreakpoint = 80, startColor = 'E00000', middleColor = 'F9E000', endColor = '04C800', className = 'ml-3 badge badge-outline-success badge-pill'}) => {
    const ratio = value >= colorBreakpoint
        ? (value - colorBreakpoint) / (100 - colorBreakpoint)
        : value / colorBreakpoint;


    const r = value >= colorBreakpoint
        ?  Math.ceil(parseInt(middleColor.substring(0, 2), 16) * (1 - ratio) + parseInt(endColor.substring(0, 2), 16) * ratio)
        : Math.ceil(parseInt(startColor.substring(0, 2), 16) * (1 - ratio) + parseInt(middleColor.substring(0, 2), 16) * ratio);
    const g = value >= colorBreakpoint
        ? Math.ceil(parseInt(middleColor.substring(2, 4), 16) * (1 - ratio) + parseInt(endColor.substring(2, 4), 16) * ratio)
        : Math.ceil(parseInt(startColor.substring(2, 4), 16) * (1 - ratio) + parseInt(middleColor.substring(2, 4), 16) * ratio);
    const b = value >= colorBreakpoint
        ? Math.ceil(parseInt(middleColor.substring(4, 6), 16) * (1 - ratio) + parseInt(endColor.substring(4, 6), 16) * ratio)
        : Math.ceil(parseInt(startColor.substring(4, 6), 16) * (1 - ratio) + parseInt(middleColor.substring(4, 6), 16) * ratio);

    const color = '#' + hex(r) + hex(g) + hex(b);
    return <div className={className}
                style={{color: 'black', borderColor: color}}>
        {`${value || 0}`}%
    </div>

}

export default LoadBadge;

LoadBadge.propTypes = {
    value: PropTypes.number.isRequired,
    colorBreakpoint: PropTypes.number,
    startColor: PropTypes.string,
    endColor: PropTypes.string,
    middleColor: PropTypes.string,
}