import {EVENT_TYPES_ALLOWED_COUNTRY_ADAPTER} from "sv-common/constants/events";
import { TG_OPTIONS } from 'sv-common/constants/statuses';

export const COUNTRY = process.env.REACT_APP_COUNTRY || 'RU';
export const LANGUAGE = process.env.REACT_APP_LANGUAGE || 'RU';
export const OTHER_LANGUAGES = process.env.REACT_APP_OTHER_LANGUAGES;
export const IS_RUSSIA = COUNTRY === 'RU';
export const FILE_MAX_SIZE = process.env.REACT_APP_FILE_MAX_SIZE || 5000000;

export const LEVEL_TYPES = {
  beginner: 'beginner',
  experienced: 'experienced',
  advanced: 'advanced',
}

export const EVENT_TYPES_ALLOWED = EVENT_TYPES_ALLOWED_COUNTRY_ADAPTER[COUNTRY.toLowerCase()]

export const TG_OPTIONS_TRANSLATES = {
  [TG_OPTIONS.REQUIRED]: 'inputs.required',
  [TG_OPTIONS.OPTIONAL]: 'inputs.optional',
  [TG_OPTIONS.HIDDEN]: 'inputs.hidden',
}

export const COMMON_PRODUCT_DIRECTIONS = {
  ADVENTURES: 'adventures',
  GROUPS: 'groups',
  EVENTS: 'events',
}