import { generalHrefHoc } from './GeneralHrefComponent';
import ProductHrefAdmin from './ProductHrefAdmin';

const ArrivalHrefAdmin = ({ product, getParentEntry, ...rest }) => {
  const linkFun = async (preparedProductFunc) => {
    let preparedProduct = await preparedProductFunc();

    const adventure = await getParentEntry(product ? product.adventure_id : preparedProduct.adventure_id.id);

    if (preparedProduct.is_school) {
      return `/school/practices/view/${adventure.url}/${preparedProduct.url}`;
    }

    return `/adventures/view/${adventure.url}/${product?.url}`;
  }

  return <ProductHrefAdmin {...rest} linkFun={linkFun} product={product} />
}

const arrivalHrefHoc = (Component) => (props) => <Component
  {...props}
  name={props.linkName || 'заезд'}
  parentEndpoint='adventures'
  endpoint='arrivals'
/>

export default arrivalHrefHoc(generalHrefHoc(ArrivalHrefAdmin));