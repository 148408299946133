import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import ExportButton from "./ExportButton";
import _ from "lodash";

const ExportFunButton = ({exportFun, dataParameters}) => {

    const {sortField, sortDir, filters} = dataParameters;

    /**
     * @param {{start, limit, sortField, sortDir, filters}} dataParameters
     * @returns {function(*): Promise<any>}
     */
    const onExport = useCallback((mode) => {
        const limit = mode === ExportButton.Page ? dataParameters.limit : 10000000;
        const start = mode === ExportButton.Page ? dataParameters.start : 0;
        const params = _.pickBy({_start: start, _limit: limit, _sort: sortField + ':' + sortDir, productType: dataParameters.productType, ...filters}, (value) => !(value === undefined || value === null || value === ''))

        return exportFun(params);
    }, [exportFun, dataParameters]);

    return <ExportButton exportFun={onExport}/>
}

ExportFunButton.propTypes = {
    /**
     * function(mode: string): Promise<{id: string, status: string, filename: string}>
     */
    exportFun: PropTypes.func.isRequired,
    dataParameters: PropTypes.object.isRequired
};

export default ExportFunButton;