import React from 'react';
import PropTypes from 'prop-types';
import {AiFillStar, AiOutlineStar} from "react-icons/ai";

RateBar.propTypes = {
    rating: PropTypes.number.isRequired
};

function RateBar({rating}) {
    const exp_rate = [];
    for (let i = 0; i < 5; i++) {
        exp_rate.push(
            <div key={i} className={`d-inline`}>
                {i < rating ?<AiFillStar color={'gold'}/> : <AiOutlineStar/>}</div>)
    }
    return exp_rate.map(badge => badge)

}

export default RateBar;