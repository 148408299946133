// eslint-disable-next-line
import React from 'react';
import qs from 'qs';
import {useApi} from "./useApi";
import { JSONToMultipart } from 'sv-common/helpers/jsonToMultipart';

/**
 *
 * @param {string} entity
 * @param {boolean} isFormData
 * @param {boolean} isUploadOnlyFile
 * @return {{getEntries: (function(Object): Promise<*>), createEntry: (function(Object): Promise<*>), deleteEntry: (function(string): Promise<*>), countEntries: (function(Object): Promise<*>), findAndCountEntries: (function(Object): Promise<*>), getEntry: (function(string): Promise<*>), editEntry: (function(string, Object): Promise<*>)}}
 */
export const useDefaultEndpoints = (entity, isFormData = false, isUploadOnlyFile = false) => {
    const {request} = useApi();
    /**
     * Method to get specified 'entry'
     * @param {string} id
     * @return {Promise<*>}
     */
    const getEntry = async (id) => request('get', `/${entity}/${id}`);

    /**
     * Method to get specified entries, filtering allowed, follow
     * https://strapi.io/documentation/v3.x/content-api/parameters.html#available-operators
     * @param {object} params
     * @return {Promise<*>}
     */
    const getEntries = async (params) => {
        const query = qs.stringify(params);
        return request('get', `/${entity}?${query}`)
    };

    /**
     * Method to count specified entries, filtering allowed, follow
     * https://strapi.io/documentation/v3.x/content-api/parameters.html#available-operators
     * @param {object} params
     * @return {Promise<*>}
     */
    const countEntries = async (params) => {
        const query = qs.stringify(params);
        return request('get', `/${entity}/count?${query}`)
    };

    /**
     * Method to create a specified entry
     * @param {object} data
     * @return {Promise<*>}
     */
    const createEntry = async (data) => request('post', `/${entity}`,
        isFormData ? JSONToMultipart(data, isUploadOnlyFile) : data);

    /**
     * Method to edit a specified entry
     * @param {string} id
     * @param {object} data
     * @return {Promise<*>}
     */
    const editEntry = async (id, data) => {
        return request('put', `/${entity}/${id}`,
            isFormData ? JSONToMultipart(data) : data);
    }
    /**
     * Method to delete a specified entry
     * @param {string} id
     * @return {Promise<*>}
     */
    const deleteEntry = async (id) => request('delete', `/${entity}/${id}`);

    /**
     * may not exist, yet being created almost every time
     *
     * Method to find and count specified entries. Mostly used in pagination, filtering allowed, follow
     * https://strapi.io/documentation/v3.x/content-api/parameters.html#available-operators
     * @param {object} params
     * @return {Promise<*>}
     */
    const findAndCountEntries = async (params) => {
        const query = qs.stringify(params);
        return request('get', `/${entity}/findAndCount?${query}`)
    };

    /**
     * may not exist
     *
     * Exports all data by default (start and limit = 0)
     * @param {object} params
     * @returns {Promise<*>}
     */
    const exportEntries = async (params) => {
        const query = qs.stringify(params);
        return request('get', `/${entity}/export?${query}`)
    };
    /**
     * may not exist
     *
     * deletes many records
     * @param {array} ids
     * @returns {Promise<*>}
     */
    const deleteMultiple = async (ids) => {
        return request("post", `/${entity}/deleteMultiple`, {ids});
    }

    return {
        getEntry, getEntries, countEntries, createEntry, editEntry, deleteEntry,
        findAndCountEntries, exportEntries, deleteMultiple
    }
}