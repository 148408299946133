import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import { trimOnChange } from '../../../../lib/utils/helper'
import {t} from "i18next";

const Url = ({form, state, setState, isRequired = true, label, hint, isTrimOnChange, rules = {}}) => {
    const {control, errors} = form;
    const constRules = {validate: value => !value.includes('/') && (rules.validate ? rules.validate(value) : true)}
    
    return (
        <Controller
            control={control}
            name="url"
            defaultValue={state.url || ''}
            rules={isRequired ? {required: t('inputs.validation.required'),  ...rules, ...constRules} : { ...rules, ...constRules,}}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                     name={name} isRequired={isRequired}
                                     type="text" onBlur={onBlur} value={value} placeholder="strogino"
                                     hint={hint}
                                     onChange={e => {
                                        isTrimOnChange && trimOnChange(e)
                                        setState(e)
                                        onChange(e.target.value)
                                     }}
                />
            }
        />
    );
}

Url.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    hint: PropTypes.string,
    isTrimOnChange: PropTypes.bool,
};
export default Url;