import { TG_OPTIONS } from 'sv-common/constants/statuses';
import { t } from 'i18next';
import { TG_OPTIONS_TRANSLATES } from '../../../../lib/utils/constants';
import { Input } from 'reactstrap';

const TelegramOptions = ({ onChange, tg_options }) => {
  return <Input type="select" name="tg_options" id="tg_options"
                value={tg_options || TG_OPTIONS.HIDDEN}
                onChange={onChange}
  >
    {Object.values(TG_OPTIONS).map(i => (<option value={i} key={i}>{t(TG_OPTIONS_TRANSLATES[i])}</option>))}
  </Input>
}

export default TelegramOptions;