
const ruTitleMapper = (path) => {
    return urls[path];
}

const urls = {
    '': [{ru: "Главная"}, {ru: "Sila Vetra"}],
    '/': [{ru: "Главная"}, {ru: "Sila Vetra"}],

}

export default ruTitleMapper;