import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import MaskedTextInput from "react-text-mask";
import moment from "dayjs";

import customParseFormat from 'dayjs/plugin/customParseFormat';
moment.extend(customParseFormat)

function parseDate(str, format, locale) {
    if (!str || str === "") return undefined;
    const parsed = moment(str, format);
    if (parsed.isValid()) return parsed.toDate();
    return undefined;
}

function formatDate(date, format, locale) {
    if (!date) return "";
    return moment(date).format(format);
}

export default class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDay: this.props.selectedDay,
            dateFormat: this.props.dateFormat,
            maxDate: this.props.maxDate
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDay !== prevProps.selectedDay)
            this.setState({
                selectedDay: this.props.selectedDay
            });
    }

    handleDayClick(date, { disabled }) {
        if (disabled) return;
        this.setState({
            selectedDay: date
        });
        if (this.props.onDateChange) this.props.onDateChange(date);
    }

    render() {
        const {selectedDay, dateFormat} = this.state;   
        const mask = this.props.mask;

        return (
            <DayPickerInput
                style={{display: 'unset'}}
                value={selectedDay ? selectedDay : undefined }
                format={dateFormat ? dateFormat : "YYYY.MM.DD"}
                formatDate={formatDate} parseDate={parseDate}
                classNames={{
                    container: 'DayPickerInput ' + this.props.wrapperClassname,
                    overlayWrapper: 'DayPickerInput-OverlayWrapper',
                    overlay: 'DayPickerInput-Overlay z-2',
                }}
                inputProps={{
                    className: 'form-control ' + this.props.inputClassname + ' ' + this.props.classNames ,
                    id: this.props.id,
                    placeholder: this.props.placeholder,
                    disabled: this.props.disabled,
                }}
                dayPickerProps={{
                    showWeekNumbers: true,
                    selectedDates: selectedDay,
                    onDayClick: this.handleDayClick,
                    firstDayOfWeek: 1,
                    disabledDays: {
                        after: this.props.maxDate,
                    }
                }}
                {...(mask
                    ? {
                        component: (props) => <MaskedTextInput
                            {...props}
                            type="text"
                            mask={mask}
                        />
                    }
                    : {}
                )}
            />
        );
    }
}

DatePicker.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    selectedDay: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    disabled: PropTypes.bool,
    inputClassname: PropTypes.string,
    wrapperClassname: PropTypes.string
};