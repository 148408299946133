import {COUNTRY} from "../constants";

export const isShowElemInCountry = hideInCountries => !hideInCountries?.includes(COUNTRY);

export const localizeRoles = (roles, language) => {
    const localizedRoles = {
        'Administrator': 'Administrator',
        'Authenticated': 'Client',
        'Call-center': 'Call-center',
        'Captain': 'Captain',
        'Instructor': 'Instructor',
        'Manager': 'Manager',
        'Public': 'Not authorized',
        'Sport': 'Sport',
        'Teacher': 'Teacher',
   }

    return roles.map(role => {
        if (language === 'en')
            return {
                ...role,
                description: localizedRoles[role.name] || role.description
            }

        return role
    })
};

export const localizeRoleName = (roleName, language) => {
    const localizedRoleNames = {
        'Администратор': 'Administrator',
        'Клиент': 'Client',
        'Колл-центр': 'Call-center',
        'Спорт': 'Sport',
    }

    if (language === 'en')
        return localizedRoleNames[roleName] || roleName

    return roleName
};

export const localazeCalendar = (lang) => {
    if (lang === 'ru') {
        const MONTHS = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ];
          
        const WEEKDAYS_LONG = [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
          ];
          
        const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        return {
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
        }
    }
}