import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Col, CustomInput, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {TextControllerRHF} from "../../../../generic/fields";
import i18next from "i18next";
import {localizeRoles} from "../../../../../lib/utils/helpers/localizeFeatures";
import { ElementWithPermissions } from '../../../../common/formElements';
import { ENTITIES, useRoleSystem } from '../../../../../lib/utils/hooks/useRoleSystem';

const UserData = ({state, dispatch, control, errors}) => {
    const permissions = useRoleSystem(ENTITIES.USER_ROLE);

    const {getEntries} = useDefaultEndpoints('users-permissions/roles');
    const {getEntries: getFrontendEntries} = useDefaultEndpoints('frontend-roles');

    const [roles, setRoles] = useState([]);
    const [frontendRoles, setFrontendRoles] = useState([]);
    const setClientData = e => dispatch({type: 'setClientData', payload: e})
    const setUserData = e => dispatch({type: 'setUserData', payload: e})

    const {t, language} = i18next

    const prepareRoles = (roles) => {
        const filteredRoles = roles.filter(role => !['Captain', 'Instructor', 'Public', 'Teacher'].includes(role.name));

        return localizeRoles(filteredRoles, language)
    }

    useEffect(() => {
        getEntries({}).then(res => setRoles(prepareRoles(res.roles)));
        getFrontendEntries({}).then(setFrontendRoles);
    }, [])

    return <Form>
        <Row>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="email"
                        defaultValue={state.email || ''}
                        rules={{required: t('management.users.requiredField')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('management.users.mail')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="example@bask.ws"
                                                 onChange={e => {
                                                     setClientData(e)
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <ElementWithPermissions hidden={!permissions.edit} disabled={!permissions.edit}>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="role"
                    defaultValue={state.user_data?.role || ''}
                    rules={{required: t('management.users.requiredField')}}
                    render={(
                      {value, onBlur, name, ref, onChange},
                      {invalid}
                    ) =>
                      <FieldWithValidation invalid={invalid} errors={errors} label={t('management.users.role')}
                                           name={name} isRequired={true}
                      >
                        <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                               style={{border: invalid ? '1px solid #dc3545' : '0'}}
                               value={value}
                               onChange={(e) => {
                                 setUserData(e);
                                 onChange(e.target.value)
                               }}>
                          <option key={"user-role-none"} value='' disabled>{t('management.users.chooseRole')}</option>
                          {roles && roles.map(role => {
                            return <option key={"user-role-" + role.id} value={role.id} disabled={role.description === 'Инструктор'}>{role.description}</option>
                          })
                          }
                        </Input>
                      </FieldWithValidation>
                    }
                  />
                </FormGroup>
              </Col>
            </ElementWithPermissions>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="frontend_role"
                        defaultValue={state?.frontend_role?.id || ''}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                          <FieldWithValidation invalid={invalid} errors={errors} label={t('management.users.frontendRole')} name={name}
                          >
                            <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                   style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                   value={value}
                                   onChange={(e) => {
                                     setClientData(e);
                                     onChange(e.target.value)
                                   }}>
                              <option key={"user-role-none"} value='' disabled>{t('management.users.chooseRole')}</option>
                              {frontendRoles && frontendRoles.map(role => {
                                return <option key={"frontend-role-" + role.id} value={role.id}>{role.title}</option>
                              })
                              }
                            </Input>
                          </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <TextControllerRHF
                        type='password'
                        errors={errors}
                        control={control}
                        label={t('management.users.password')}
                        inputName={'password'}
                        setValue={setUserData}
                        defaultValue={state.user_data?.password || ''}
                        rules={{minLength: {value: 5, message: t('management.users.minLengthMessage')}}}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Label htmlFor="password">{t('management.users.authorizationService')}</Label>
                    <Input
                        type={'text'}
                        id="source"
                        className="form-control"
                        name="source"
                        value={state.user_data?.source || ''}
                        disabled
                    />
                </FormGroup>

            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="has_loyal_discount"
                                 name="has_loyal_discount" label={t('management.users.hasLoyalDiscount')}
                                 checked={state.has_loyal_discount || false} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_blocked" name="is_blocked"
                                 label={t('management.users.hasBlocked')}
                                 checked={state.is_blocked || false} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="confirmed" name="confirmed"
                                 label={t('management.users.confirmedMail')}
                                 checked={state.user_data?.confirmed || false} onChange={setUserData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_service" name="is_service"
                                 label={t('management.users.serviceRecord')}
                                 checked={state.user_data?.is_service || false} onChange={setUserData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={4}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="send_letters" name="send_letters"
                                 label={t('management.users.sendLetters')}
                                 checked={state.send_letters || false} onChange={setClientData}
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default UserData;

UserData.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}
