import React, {useEffect} from "react";
import { Dropzone } from '../../generic/fields';
import { Controller, useForm } from "react-hook-form";
import {Col, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {t} from "i18next";
import {API_URL} from "../../../lib/api/useApi";
import { PhoneRHF } from "../../common/reactHookFormsFields";
import { getCountryCode } from "../../../lib/utils/helper";
import { withBreadcrumbs } from "../../common/hoc/Breadcrumbs";


const EditModalChildren = withBreadcrumbs(({entity, setEntity, control, errors, handleInput}) => {
    const form = useForm();

    useEffect(() => {
        if (entity.img && Array.isArray(entity.img) && entity.img.length) {
            setEntity({...entity, img: {...entity.img.at(-1), preview: API_URL + entity.img.at(-1)?.url}})
        } 
    }, [entity.img])

    return (
        <Row>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.coordinators.name')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.coordinators.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                    <FormGroup>
                        <PhoneRHF form={form} state={{...entity}} name={'phone'} isRequired={false} country={getCountryCode()} setValue={(v) => setEntity({...entity, phone: v.target.value})}
                                  label={t('constructor.coordinators.phone')}
                        />
                    </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                    <FormGroup>
                        <PhoneRHF form={form} state={{...entity}} name={'corp_phone'} isRequired country={getCountryCode()} setValue={(v) => setEntity({...entity, corp_phone: v.target.value})}
                                  label={t('constructor.coordinators.corp_phone')}
                        />
                    </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="img"
                        defaultValue={entity.img || {}}
                        rules={{
                            validate: (value) => {
                                if (value instanceof File) return true
                                if (!value || !Array.isArray(value) || value.length === 0) return false
                
                                return true;
                            },
                            required: t('inputs.validation.required')
                        }}                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.imgSize')}
                                                 name={name} isRequired={true}
                                                 hint={t('adventures.edit.fileSize')}
                            >
                                <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                     className={invalid ? 'is-invalid' : ''}>
                                    <Dropzone name={'img'} extFile={entity.img} onBlur={onBlur} callback={img => {
                                        setEntity({...entity, files: img})
                                        onChange(img);
                                    }}/>
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
        </Row>)
})

export default EditModalChildren;
