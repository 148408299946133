import { Col, FormGroup, Label } from 'reactstrap';
import RangedSelectionDatePicker from '../../../generic/fields/RangedSelectionDatePicker';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const RangeSelectorDatePicker = ({ title, startKey, endKey, setEntity, entity, disabledDays }) => {
  const [daysFilter, setDaysFilter] = useState({
    from: dayjs(entity[startKey]).utc(true).toDate() || new Date(),
    to: dayjs(entity[endKey]).utc(true).toDate() || new Date()
  });

  useEffect(() => {
    setEntity(entity => {
      return ({...entity, [startKey]: daysFilter.from, [endKey]: daysFilter.to});
    });
  }, [daysFilter]);

  return (
    <Col xs={12} md={6}>
      <FormGroup>
        <Label>{title}</Label>
        <RangedSelectionDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"} numberOfMonths={2} disabledDays={disabledDays} />
      </FormGroup>
    </Col>
  )
}

export default RangeSelectorDatePicker;