import { useApi } from './useApi';
import { useDefaultEndpoints } from './useDefaultEndpoints';

export const useFrontendRules = () => {
  const {request} = useApi();

  const getUserConfig = async () => request('get', `/frontend-roles/config`);

  return {
    ...useDefaultEndpoints('frontend-roles'),
    getUserConfig,
  }
}