import { FrontendRulesState } from '../api/frontendRulesProvider';
import { useContext } from 'react';

export const withRuleAccess = (Component, accessKey) => props => {
  const rules = useContext(FrontendRulesState);

  if (rules[accessKey]) {
    return <Component {...props} />;
  }
}

export const RuleAccessProvider = ({ children, accessKey }) => {
  const rules = useContext(FrontendRulesState);

  if (rules[accessKey]) {
    return children;
  }

  return null;
}

export const useRuleAccess = () => {
    const rules = useContext(FrontendRulesState);

    const check = (key) => {
        return rules[key];
    }

    return { check };
}

export const isRuleAccess = (accessKey) => props => {
  const rules = useContext(FrontendRulesState);

  return (rules[accessKey]);
}