import React, {useEffect, useState} from 'react';
import {Col, Form, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";
import { Dropzone } from '../../generic/fields';
import { makePublicContactUrl } from './helpers';

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
  const [isLinkEmpty, setIsLinkEmpty] = useState(!entity.link);
  const [hasFiles, setHasFiles] = useState(!!entity.files && !!entity.files.preview);

  useEffect(() => {
    setIsLinkEmpty(!entity.link);
  }, [entity.link])

  const handleLinkChange = (e) => {
    const newLink = handleInput(e, setEntity).value;
    setIsLinkEmpty(!newLink);
    return newLink;
  };

  const handleFileChange = (fileInfo) => {
    setHasFiles(!!fileInfo && !!fileInfo.preview);
    return fileInfo;
  };

  return <Form>
    <Row>
      {!hasFiles && <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="link"
            defaultValue={entity.link || ''}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) =>
              <FieldWithValidation
                invalid={invalid}
                errors={errors}
                label={t('directories.publicContracts.link')}
                name={name}
                type="text"
                onBlur={onBlur}
                value={value}
                onChange={e => {onChange(handleLinkChange(e))}}
                isRequired={!!value}
                placeholder="legal_entity_2"
              />
            }
          />
        </FormGroup>
      </Col>}
    </Row>
    <Row>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="tag"
            defaultValue={entity.tag || ''}
            rules={{required: t('inputs.validation.required')}}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) =>
              <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                   label={t('directories.publicContracts.name')}
                                   type="text" onBlur={onBlur} value={value}
                                   placeholder={t('directories.publicContracts.namePlaceholder')}
                                   onChange={e => onChange(handleInput(e, setEntity).value)}
              />
            }
          />
        </FormGroup>
      </Col>
    </Row>
    {isLinkEmpty && <Row>
      <Col xs={12}>
        <FormGroup>
          <Controller
            control={control}
            name="files"
            defaultValue={{ preview: makePublicContactUrl(entity.link) }}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) => {
              return <FieldWithValidation
                invalid={invalid}
                errors={errors}
                name={name}
                label={t('constructor.files.file')}
              >
                <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                     className={invalid ? 'is-invalid' : ''}>
                  <Dropzone
                    name={name}
                    extFile={value}
                    onBlur={onBlur}
                    callback={value => {
                      handleInput({target: {value, name}}, setEntity);
                      onChange(handleFileChange(value));
                    }} />
                </div>
              </FieldWithValidation>
            }}/>
        </FormGroup>
      </Col>
    </Row>}
  </Form>
}

export default EditModalChildren;
