import React, {useEffect, useState} from 'react'
import {TableCard} from "../../generic/cards";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {FaPlus} from 'react-icons/fa'
import {BsPersonSquare} from 'react-icons/bs'
import EditModal from "./EditModal";
import {Button, Input} from "reactstrap";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import {RelationTextSearch} from "../../generic/fields";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {useHistory} from "react-router-dom";
import {ElementWithPermissions} from "../../common/formElements";
import {openInNewTab} from "../../../lib/utils/helper";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

export const mapInstructorStatus = (status) => {
    switch (status) {
        case 'active':
            return t('management.instructors.instructorList.active');
        case 'ill':
            return t('management.instructors.instructorList.ill');
        case 'inactive':
            return t('management.instructors.instructorList.inactive');
        default:
            return t('management.instructors.instructorList.active');
    }
}

const InstructorsList = withBreadcrumbs(({
                             baseFilter = undefined, boatFilter = undefined,
                             isInline = false, onClick, disabled
}) => {
    let history = useHistory();
    const {findAndCountEntries, editEntry} = useDefaultEndpoints('instructors');
    const {getEntries} = useDefaultEndpoints('bases');
    const boatsEndpoints = useDefaultEndpoints('boats-classes');
    const {edit} = useRoleSystem(ENTITIES.INSTRUCTORS);

    const [bases, setBases] = useState([]);
    const [boats, setBoats] = useState([]);

    const [isModalOpen, toggleModal] = useState(false)
    const [reload, setReload] = useState(false);

    const [selectedBaseFilter, setSelectedBaseFilter] = useState(baseFilter);
    const [selectedBoatFilter, setSelectedBoatFilter] = useState(boatFilter);

    const [clientsIds, setClientsIds] = useState([])

    //TODO решить вопрос дублирования кода
    const handleBaseFilterChange = (e, filterName) => {
        const {value} = handleInputChange(e)
        setSelectedBaseFilter(() => value || undefined)
    };
    const handleBoatFilterChange = e => {
        const {value} = handleInputChange(e)
        setSelectedBoatFilter(() => value || undefined)
    };

    const filters = {
        ...(selectedBaseFilter ? {base_id: selectedBaseFilter} : {}),
        ...(selectedBoatFilter ? {boats_classes_contains: selectedBoatFilter} : {}),
    };
    if (clientsIds.length)
        filters.client_id_in = clientsIds;

    const relatedFilters = (instructorDataFilter) => ({
        _where: {
            _or: [
                [{first_name_contains: instructorDataFilter}, {role_name: 'Инструктор'}],
                [{last_name_contains: instructorDataFilter}, {role_name: 'Инструктор'}],
            ],
        },
    })

    useEffect(() => {
        setSelectedBaseFilter(() => baseFilter)
    }, [baseFilter])

    useEffect(() => {
        setSelectedBoatFilter(() => boatFilter)
    }, [boatFilter])

    useEffect(() => {
        getEntries({is_archived_ne: true}).then(res => setBases(() => [...res]))
        boatsEndpoints.getEntries({}).then(res => setBoats(() => [...res]))
    }, []);

    const triggerModal = () => {
        setReload(reload => !reload)
        toggleModal(isModalOpen => {
            return !isModalOpen
        })
    };

    return <>
        {isModalOpen && <EditModal triggerModal={triggerModal} isOpened={isModalOpen} bases={bases}
                                   requiredFields={['base_id', 'status', 'client_id']}/>}
        <TableCard isInline={isInline}>
            <div className={"row justify-content-start mb-3"}>
                <div className={"col"}>
                    <Input type={'select'} id="base_id" bsSize={'sm'} className="form-control mr-3" name="base_id"
                           value={selectedBaseFilter} onChange={handleBaseFilterChange}>
                        <option value=''>{t('management.instructors.instructorList.allBases')}</option>
                        {bases && bases.map(base => <option key={"base_id-" + base.id}
                                                            value={base.id}>{base.name}</option>)}
                    </Input>
                </div>
                <div className={"col"}>
                    <Input type={'select'} id="boat_class" bsSize={'sm'} className="form-control mr-3" name="boat_class"
                           value={selectedBoatFilter} onChange={handleBoatFilterChange}>
                        <option value=''>{t('management.instructors.instructorList.allYachtClasses')}</option>
                        {boats && boats.map(boat => <option key={"boat_class-" + boat.id}
                                                            value={boat.class}>{boat.class}</option>)}
                    </Input>
                </div>
                <div className={"col"}>
                    <RelationTextSearch color="primary" bsSize={'sm'} type={'text'}
                                        onSearch={(val) => setClientsIds(() => val)}
                                        relatedEntity={'clients'} filteringFunction={relatedFilters}
                                        placeholder={t('management.instructors.instructorList.searchByInstructors')}/>
                </div>

                <div className={"col"}>
                    <ElementWithPermissions disabled={disabled !== undefined ? disabled : !edit}>
                        <Button color="primary" size={'sm'} className={'btn-rounded'} onClick={triggerModal}>
                            <FaPlus/>
                        </Button>
                    </ElementWithPermissions>
                </div>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.instructors.instructorList.instructor'), false, "client_id", res =>
                               <><BsPersonSquare/> {res.last_name || res.client_id.last_name} {res.first_name || res.client_id.first_name}</>),
                           field(t('management.instructors.instructorList.status'), true, "status", res =>
                               mapInstructorStatus(res.status)),
                           field(t('management.instructors.instructorList.yachtClasses'), true, "boats_classes", res =>
                               res.boats_classes.map(boat_class =>
                                   <div key={boat_class} className={'badge badge-outline-info badge-pill mr-1'}>
                                       {boat_class}
                                   </div>)),
                           field(t('management.instructors.instructorList.attachedToBase'), true, "base_id", res =>
                               <div className={'badge badge-outline-info badge-pill mr-1'}>{res.base_id?.name}</div>),
                           field(t('management.instructors.instructorList.comment'), false, "state")
                       ]}
                       sortField="created_at"
                       sortDir="desc"
                       name="instructors-list"
                       onClick={instructor => {
                           if (onClick) {
                               onClick(instructor, setReload);
                               if (baseFilter) {
                                   editEntry(instructor.id, {base_id: baseFilter}).then(res => {
                                       setReload(reload => !reload)
                                       setSelectedBaseFilter(() => baseFilter)
                                   })
                               }
                           }
                           else history.push('/administration/instructors/' + instructor.id)
                       }}
                       onMiddleClick={instructor => openInNewTab('/administration/instructors/' + instructor.id)}
                       reload={reload}
            />
        </TableCard>
    </>
});

export default InstructorsList;