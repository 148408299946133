import React, {useEffect, useState} from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {TableCard} from "../../generic/cards";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "../../generic/loader";
import {datetime} from "../../../lib/utils/format";
import {PRODUCT_TYPES, PRODUCT_TYPES_LANG_ADAPTER} from "sv-common/constants/certificates";
import {ConditionalField} from "../../generic/fields";
import ChooseClient from "../instructors/EditModal/tabs/ChooseClient";
import {useForm} from "react-hook-form";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {t} from "i18next";
import i18n from "../../../lib/utils/i18n";

const Violation = withBreadcrumbs(({}) => {
    const {id} = useParams();
    let history = useHistory();
    const {getEntry, createEntry} = useDefaultEndpoints('violations');
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({});
    const form = useForm({reValidateMode: 'onBlur',});
    const { language } = i18n;

    useEffect(() => {
        if (id)
            getEntry(id).then(res => {
                setState(() => ({...res}))
                setLoading(() => false);
            });
        else {
            setLoading(() => false);
        }
    }, [id]);

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setState(state => ({...state, [name]: value}))
    };

    const onCreate = () => createEntry({
        client_id: state.client_id,
        violation: state.violation,
        product_type: state.product_type
    }).then(res => history.push('/administration/violations/edit/' + res.id))


    return loading
        ? <Loader/>
        : <TableCard>
            <Form>
                <Row className={'mb-3 justify-content-between align-items-center'}>
                    <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                        <div className={'display-3'}>
                            {t('management.violations.blacklist')}
                        </div>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.push('/administration/violations')}>
                            {t('management.violations.backButton')}
                        </Button>
                    </Col>
                    <ConditionalField condition={!id}>
                        <Col className={'col-auto'}>
                            <Button className={'btn-rounded ml-1'} color={'success'} size={'sm'}
                                    onClick={onCreate}>
                                {t('management.violations.createButton')}
                            </Button>
                        </Col>
                    </ConditionalField>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label>{t('management.violations.description')}</Label>
                            <Input type={'textarea'} rows="20" style={{height: '100%'}} value={state.violation}
                                   name={'violation'} disabled={!!id} onChange={handleInput}/>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={8}>
                        <ConditionalField condition={!!id}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>{t('management.violations.name')}</Label>
                                        <Input type={'text'} rows="20" style={{height: '100%'}}
                                               value={state.client_id?.first_name} disabled
                                               onChange={handleInput}/>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>{t('management.violations.surname')}</Label>
                                        <Input type={'text'} rows="20" style={{height: '100%'}}
                                               value={state.client_id?.last_name} disabled
                                               onChange={handleInput}/>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>{t('management.violations.mail')}</Label>
                                        <Input type={'text'} rows="20" style={{height: '100%'}}
                                               value={state.client_id?.email} disabled
                                               onChange={handleInput}/>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>{t('management.violations.added')}</Label>
                                        <div>
                                            {datetime(state.created_at)}
                                        </div>
                                        <div>
                                            {state.admin_id?.email}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>{t('management.violations.product')}</Label>
                                        <div>
                                            {PRODUCT_TYPES_LANG_ADAPTER[language][state.product_type] || ''}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ConditionalField>
                        <ConditionalField condition={!id}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>{t('management.violations.productType')}</Label>
                                        <Input type={'select'} className="form-control" value={state.product_type || ''} bsSize={'sm'}
                                               name={'product_type'} onChange={handleInput}>
                                            <option value=''>{t('management.violations.chooseProduct')}</option>
                                            {Object.values(PRODUCT_TYPES).filter(v => !!PRODUCT_TYPES_LANG_ADAPTER[language][v])
                                                .map(type => <option key={type} value={type}>{PRODUCT_TYPES_LANG_ADAPTER[language][type]}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            {t('management.violations.selectedUser')}
                                        </Label>
                                        <div>
                                            <b> {state.client_id ? state.first_name + ' ' + state.last_name : t('management.violations.notSelected')} </b>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <ChooseClient state={state} setState={setState} form={form} role={'all'} canCreate={false} showSurname/>
                                </Col>
                            </Row>
                        </ConditionalField>
                    </Col>
                </Row>
            </Form>
        </TableCard>
});

Violation.propTypes = {}

export default Violation;