import React from "react";
import { Controller } from "react-hook-form";
import { Dropzone } from '../../generic/fields';
import {Col, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {t} from "i18next";
import {API_URL} from "../../../lib/api/useApi";
import { withBreadcrumbs } from "../../common/hoc/Breadcrumbs";
import CarouselComponent from "../../common/formElements/Carousel";
import {useFilesApi} from "../../../lib/api/files";


const EditModalChildren = withBreadcrumbs(({entity, setEntity, control, errors, handleInput}) => {
    const { upload } = useFilesApi();

    return (
        <Row>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.name')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="description"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.description')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.descriptionPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="city"
                        defaultValue={entity.city || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.city')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.cityPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="address"
                        defaultValue={entity.address || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.address')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.addressPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="building"
                        defaultValue={entity.building || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.building')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.buildingPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="getting_there_link"
                        defaultValue={entity.getting_there_link || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.baseAddresses.getting_there_link')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.baseAddresses.gettingThereLinkPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="img"
                        defaultValue={entity.img || {}}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.imgSize')}
                                                 name={name} isRequired={true}
                                                 hint={t('adventures.edit.fileSize')}
                            >
                                <div style={{border: '0'}}>
                                    <Dropzone
                                      callback={img => {
                                          if (img) {
                                              upload(img).then(res => {
                                                  setEntity(value => ({ ...value, img_urls: [...(value.img_urls || []), res.url] }))
                                              });
                                          }
                                    }}/>
                                    {<CarouselComponent
                                        items={(entity.img_urls || []).map(i => ({ src: API_URL+i }))}
                                        onDelete={(_, index) => {
                                            setEntity(value => ({
                                                ...value,
                                                img_urls: [
                                                    ...value.img_urls.slice(0, index), ...value.img_urls.slice(index + 1),
                                                ]
                                            }))
                                        }}
                                    />}
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
        </Row>)
})

export default EditModalChildren;
