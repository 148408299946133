import React, {useState, useEffect} from 'react';
import {FcEmptyTrash} from 'react-icons/fc'
import {AiFillMinusCircle, AiFillPlusCircle} from 'react-icons/ai'
import {EditModal, ModalConfirmation} from "../../../generic/modals";
import EditModalChildren from "./EditModalChildren";
import {FaPlus} from "react-icons/fa";
import {Col, Row} from 'reactstrap';
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";


const BoatsBlock = ({state, dispatch}) => {
    const [isModalOpen, toggleModal] = useState(false)
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false)
    const [ejectedBoat, setEjectedBoat] = useState('')
    const triggerModal = () => toggleModal(isModalOpen => !isModalOpen)
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen)
    const boatsEndpoints = useDefaultEndpoints('boats-classes');
    const [boats, setBoats] = useState([]);

    useEffect(() => {
        boatsEndpoints.getEntries({}).then(res => setBoats(() => [...res]))
    }, []);

    const permissions = useRoleSystem(ENTITIES.BASES);
    return <>
        {isModalOpen && <EditModal
            triggerModal={triggerModal}
            isOpened={isModalOpen}
            EditForm={EditModalChildren}
            requiredFields={['class', 'amount']}
            onSuccess={(entity) => {
                dispatch({type: 'addNewBoat', payload: {class: entity.class, amount: ~~entity.amount}})
                triggerModal()
            }}
            size={'sm'}
            edit={permissions.edit}
        />}
        {isModalConfirmationOpen && <ModalConfirmation
            onClose={triggerModalConfirmation}
            onConfirm={() => {
                dispatch({type: 'deleteBoat', payload: ejectedBoat});
                triggerModalConfirmation();
            }}
            isOpen={isModalConfirmationOpen}
            hasCancel
            title={t('bases.base.deleteClass')}
            text={t('bases.base.deleteClassText')}
            cancelButtonText={t('bases.base.no')}
            confirmButtonText={t('bases.base.yes')}
        />}
        <div className="table-responsive overflow-hidden">
            <Row>
                <Col xs={8}>
                    <h4 className={'display-4'}>{t('bases.base.attachedBoats')}</h4>

                </Col>
                <Col>
                     <span className={'btn btn-primary btn-rounded btn-sm'}
                           onClick={triggerModal}><FaPlus size={'1em'}/></span>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th><b>{t('bases.base.boatClass')}</b></th>
                            <th><b>{t('bases.base.availableToday')}</b></th>
                            <th><b>{t('bases.base.delete')}</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.boats.map((boat, index) => (
                            <tr key={index} className={!boats.find((b) => b.class === boat.class)?.is_active ? 'inactive_boat' : ''}>
                                <td>{boat.class}</td>
                                <td>
                                    <div className={'badge badge-pill badge-outline-primary'}>
                                <div className={'p-0 m-0 btn btn-rounded btn-sm btn-primary'}>
                                    <AiFillMinusCircle className={'boats-amount-icon'} size={20}
                                                       onClick={() => dispatch({
                                                           type: 'changeBoatsAmount',
                                                           payload: {index, value: -1}
                                                       })}
                                    />
                                </div>
                                        <span className='boats-amount'>{boat.amount}</span>

                                        <div className={'p-0 m-0 btn btn-rounded btn-sm btn-primary'}>
                                    <AiFillPlusCircle className={'boats-amount-icon'} size={20}
                                                      onClick={() => dispatch({
                                                          type: 'changeBoatsAmount',
                                                          payload: {index, value: 1}
                                                      })}
                                    />
                                </div>
                                    </div>
                                </td>
                                <td>
                                    <FcEmptyTrash size={20} onClick={() => {
                                        setEjectedBoat(() => boat.class)
                                        triggerModalConfirmation()
                                    }}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Col>
            </Row>

        </div>
    </>
};

export default BoatsBlock;