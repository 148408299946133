import React from 'react';
import {Col, FormGroup, Label, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {typeToLabel} from "../reducer";
import {CopyLink} from "../../../../generic/buttons";
import {BOOKING_URL} from "../../../../../lib/api/useApi";
import i18next, {t} from "i18next";

const MainInfo = ({state}) => {
    const EVENT_URL = `${state.base_id?.booking_url || BOOKING_URL}/sport/${state.url}`
    return <>
        <Col xs={12} md={6}>
            <Row className={'mb-3'}>
                <Col>
                    <div className={'display-4'}>
                        {t('bases.base.brieflyAboutEvent')}
                    </div>
                    <FormGroup>
                        <Label for={'name'} className={'text-secondary'}>/{state.url}</Label>
                        <div>
                            <CopyLink href={EVENT_URL}  text={t('bases.base.go')} />
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={5}>
                    {typeToLabel(state.type, i18next.language)}
                </Col>
                <Col xs={{offset: 1, size: 5}}>
                    [{state.available_boats_limit}] {state.boat_class}
                </Col>
                <Col xs={5}>
                    {state.duration}
                </Col>
                <Col xs={{offset: 1, size: 4}}>
                    {state.price_value} {state.price_currency}
                </Col>
            </Row>
        </Col>
    </>
}

export default MainInfo;

MainInfo.propTypes = {
    state: PropTypes.object.isRequired,
}