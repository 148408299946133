import React, {useEffect, useReducer} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {initialState, reducer} from "./reducer";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {TableCard} from "../../../generic/cards";
import {Button, Col, Row} from "reactstrap";
import EasyEdit from "react-easy-edit";
import {FaCheck, FaTimes} from "react-icons/fa";

import {Discounts, ExtraInfo, MainInfo, Promocodes, Settings} from "./NewEventBlocks";
import {Controller, useForm} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Loader} from "../../../generic/loader";
import AdditionalInfo from "./NewEventBlocks/AdditionalInfo";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import ManagerComments from '../../../common/formElements/ManagerComments';

const NewEvent = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory()
    let {url, event_url} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState(url));
    const {createEntry, editEntry, getEntries} = useDefaultEndpoints('events')
    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});
    const getBases = useDefaultEndpoints('bases').getEntries;
    const getCorpClients = useDefaultEndpoints('corp-clients').getEntries;

    const createOrUpdateEvent = () => {
        const {active_promocodes_ids, boats_classes, ...clearState} = state;
        if (!clearState.corp_client) clearState.corp_client = null;
        if (!clearState.check_id) clearState.check_id = null;
        return (!event_url
            ? createEntry(clearState).then((res) => history.push(`/bases/${url}/event/${res.url}`))
            : editEntry(history.location.state?.id || state.id, clearState).then((res) => history.push(`/bases/${url}/event/${res.url}`)))
            .catch(() => toast.error('Что-то пошло не так!'))
    }

    const onSubmit = handleSubmit(() => createOrUpdateEvent())
    const permissions = useRoleSystem(ENTITIES.EVENTS);

    useEffect(() => {
        if (event_url)
            getEntries({url: event_url}).then(res => {
                dispatch({type: 'setForm', payload: res[0]})
                const mapper = {
                    url: {ru: res[0].base_id.name || '', en: url},
                    event_url: {ru: res[0].name || '', en: event_url},
                }
                createTranslatedBreadcrumbsAndTitle
                && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })
        else {
            dispatch({type: 'refreshFields'})
            if (!state.id)
                dispatch({type: 'setLoading', payload: false})
        }

    }, [event_url, history.location.pathname, state.id])

    useEffect(() => {
        if (!state.base_id)
            getBases({url: state.baseUrl}).then(res => {
                dispatch({type: 'setForm', payload: {
                    hide_until_enabled: res[0]?.hide_until_enabled,
                    boats_classes: res[0]?.boats,
                    loading: event_url && !state.name,
                    base_id: res[0]
                }})
                const mapper = {
                    url: {ru: res[0].name || '', en: state.baseUrl},
                };
                createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })
        else {
            dispatch({type: 'setForm', payload: {
                boats_classes: state.base_id.boats,
            }})
        }
    }, [state.base_id])

    useEffect(() => {
      getCorpClients({}).then(res => {
        dispatch({ type: 'setForm', payload: {
            corpClients: res,
          }
        })
      })
    }, []);

    const setComments = (value) => dispatch({ type: 'setForm', payload: { comments_for_manager: value } });

    return (state.loading
            ? <Loader/>
            : <TableCard>
                <Row className={'mb-5 justify-content-between align-items-center'}>
                    <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                        <Controller
                            control={control}
                            name="name"
                            defaultValue={state.name}
                            rules={{required: t('inputs.validation.required')}}
                            render={(
                                {value, onBlur, name, ref, onChange},
                                {invalid}
                            ) =>
                                <FieldWithValidation invalid={invalid} errors={errors}
                                                     name={name} isRequired={true}
                                >
                                    <div className={'display-3' + (invalid ? ' is-invalid ' : '')}
                                    >
                                        <EasyEdit
                                            type="text"
                                            onSave={(val) => {
                                                onChange(val)
                                                dispatch({type: 'setForm', payload: {name: val}})
                                            }}
                                            value={value}
                                            onBlur={onBlur}
                                            placeholder={t('bases.events.newEvent')}
                                            saveButtonLabel={<FaCheck fill={'success'} stroke={'success'}/>}
                                            cancelButtonLabel={<FaTimes color={'secondary'}/>}
                                        />
                                    </div>
                                </FieldWithValidation>
                            }
                        />
                    </Col>
                    </Row>
                    <Row>
                        {state?.id && <Col xs={12} className='mb-4'>
                            <ManagerComments entity='events' id={state.id} data={state.comments_for_manager} setData={setComments} />
                            </Col>}
                    </Row>
                    <Row className='justify-content-end'>
                    <Col className={'col-auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                                {t('bases.base.save')}
                            </Button>
                        </ElementWithPermissions>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.push('/bases')}>
                            {t('bases.base.cancel')}
                        </Button>
                    </Col>
                </Row>

                <MainInfo state={state} dispatch={dispatch} control={control} errors={errors}/>

                <Row>
                    <Col xs={12} md={6}>
                        <Settings state={state} dispatch={dispatch} control={control} errors={errors}/>
                        <ExtraInfo state={state} dispatch={dispatch} control={control} errors={errors}/>
                    </Col>

                    <Col xs={12} md={6}>
                        <Discounts state={state} dispatch={dispatch}/>
                        {(!!state.id || !!state.base_id) && <Promocodes state={state} dispatch={dispatch} productType={'events'} disabled={!permissions.edit}/>}
                        <AdditionalInfo state={state} dispatch={dispatch} />
                    </Col>
                    <Col className={'col-auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                                {t('bases.base.save')}
                            </Button>
                        </ElementWithPermissions>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.push('/bases')}>
                            {t('bases.base.cancel')}
                        </Button>
                    </Col>
                </Row>
            </TableCard>
    )
}

export default NewEvent;