import React from "react";
import PropTypes from 'prop-types';
import SlotHrefAdmin from "./SlotHrefAdmin";
import ArrivalHrefAdmin from "./ArrivalHrefAdmin";
import GroupHrefGenerator from "./GroupHrefGenerator";

const getProductId = (product) => product.id ? product.id : product

const GenericHrefGenerator = ({order, fetchById, productUrl, linkName}) => {
    if (order.slot_id)
        return <SlotHrefAdmin product={fetchById ? null : order.slot_id}  linkName={order.slot_id?.name || linkName}
                              productUrl={productUrl} productId={fetchById && getProductId(order.slot_id)}/>
    if (order.group_id)
        return <GroupHrefGenerator product={fetchById ? null : order.group_id}  linkName={order.group_id?.name || linkName}
                              productUrl={productUrl} productId={fetchById && getProductId(order.group_id)}/>
    if (order.arrival_id)
        return <ArrivalHrefAdmin product={fetchById ? null : order.arrival_id} linkName={order.arrival_id?.name || linkName}
                                 productUrl={productUrl} productId={fetchById && getProductId(order.arrival_id)}/>
    if (order.subscription_id)
        return order.subscription_id?.name || 'абонемент'
    if (order.certificate_id)
        return 'сертификат' // TODO вернуть ссылку на сертификат
    return 'empty'
}

GenericHrefGenerator.propTypes = {
    order: PropTypes.object,
    fetchById: PropTypes.bool,
    productUrl: PropTypes.string,
    linkName: PropTypes.string
}

export default GenericHrefGenerator;