import React from 'react';
import {Col, CustomInput, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import './style.scss';
import BoatsBlock from "./BoatsBlock";
import {CityRHF, CountryListRHF, PhoneRHF} from "../../../common/reactHookFormsFields";
import {Dropzone, TextControllerRHF} from "../../../generic/fields";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import HideUntilField from "../../../common/formElements/hideUntilField";
import {t} from "i18next";
import {COUNTRY} from "../../../../lib/utils/constants";

const BaseInfo = ({state, dispatch, form}) => {
    const setBaseData = (e) => dispatch({type: 'setBaseData', payload: e})
    return (
        <Form>
            <Row>
                <Col lg={12} xl={5}>
                    <BoatsBlock state={state} dispatch={dispatch}/>
                </Col>
                <Col lg={12} xl={{size: 6, offset: 1}}>
                    <Row>
                        <Col lg={10}>
                            <FormGroup>
                                <PhoneRHF state={state} setState={setBaseData} form={form}
                                          country={COUNTRY === 'EN' ? 'ae' : 'ru'} />
                            </FormGroup>
                        </Col>
                        <Col lg={10}>
                            <FormGroup>
                                <TextControllerRHF inputName={'arrival_guide_url'} control={form.control}
                                                   setValue={setBaseData} label={t('bases.base.linkHowToGet')}
                                                   defaultValue={state.arrival_guide_url || ''} isRequired
                                                   rules={{required: t('inputs.validation.required')}} errors={form.errors}
                                                   placeholder={"https://silavetra.com/strogino"}/>
                            </FormGroup>
                        </Col>
                        <Col lg={10}>
                            <FormGroup>
                                <CustomInput type='checkbox' className={'custom-switch'} id='hide_until_enabled'
                                             name='hide_until_enabled'
                                             label={t('bases.base.hideToTime')}
                                             checked={state.hide_until_enabled || false} onChange={setBaseData}
                                />
                                <CustomInput type='checkbox' className={'custom-switch'} id='paid_locally'
                                             name='paid_locally'
                                             label={t('bases.base.paidLocally')}
                                             checked={state.paid_locally || false} onChange={setBaseData}
                                />
                            </FormGroup>
                        </Col>
                        {
                            state?.hide_until_enabled &&
                            <Col lg={10}>
                                <FormGroup>
                                    <HideUntilField errors={form.errors} defaultValue={state?.hide_until_time || 0}
                                                    control={form.control} setValue={setBaseData}/>
                                </FormGroup>
                            </Col>
                        }
                        <Col lg={10}>
                            <FormGroup>
                                <Controller
                                    control={form.control}
                                    name="img"
                                    defaultValue={state.files}
                                    rules={{required: t('inputs.validation.required')}}
                                    render={(
                                        {value, onBlur, name, ref, onChange},
                                        {invalid}
                                    ) =>
                                        <FieldWithValidation invalid={invalid} errors={form.errors}
                                                             label={t('bases.base.cover')}
                                                             name={name} isRequired={true}
                                                             hint={t('bases.base.fileSizeMax')}
                                        >
                                            <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                                 className={invalid ? 'is-invalid' : ''}>
                                                <Dropzone name={'img'} extFile={state.files} onBlur={onBlur}
                                                          callback={img => {
                                                              dispatch({type: 'setImg', payload: img});
                                                              onChange('test');
                                                          }}/>
                                            </div>
                                        </FieldWithValidation>
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col xs={12} md={3}>
                            <FormGroup>
                                <CountryListRHF form={form} setState={setBaseData} state={state}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={{offset: 1, size: 6}}>
                            <FormGroup>
                                <CityRHF form={form} setState={setBaseData} state={state}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <TextControllerRHF inputName={'street'} control={form.control} errors={form.errors}
                                                   setValue={setBaseData} label={t('bases.base.street')}
                                                   placeholder={t("bases.base.streetEx")}
                                                   defaultValue={state.street || ''} isRequired
                                                   rules={{required: t('inputs.validation.required')}}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={2}>
                            <FormGroup>
                                <TextControllerRHF inputName={'house'} control={form.control} errors={form.errors}
                                                   setValue={setBaseData} label={t('bases.base.house')} placeholder={"12"}
                                                   defaultValue={state.house || ''} isRequired
                                                   rules={{required: t('inputs.validation.required')}}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <TextControllerRHF inputName={'postcode'} control={form.control} errors={form.errors}
                                                   setValue={setBaseData} label={t('bases.base.index')} placeholder={"123321"}
                                                   defaultValue={state.postcode || ''} isRequired
                                                   rules={{required: t('inputs.validation.required')}}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <Label for="coordinates">{t('bases.base.coords')}</Label>
                                <Input type={'text'} name="coordinates" id="coordinates"
                                       placeholder={'55.74950237559708, 37.68128236559626'}
                                       value={state.coordinates || ''} onChange={setBaseData}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <Label for="extra_info">{t('bases.base.addInfo')}</Label>
                                <Input type={'text'} name="extra_info" id="extra_info"
                                       placeholder={t('bases.base.firstPier')}
                                       value={state.extra_info || ''} onChange={setBaseData}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>)
}

export default BaseInfo;