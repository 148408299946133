import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Input, Row} from "reactstrap";
import {useApi} from "../../../lib/api/useApi";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
    const {request} = useApi()
    const [tags, setTags] = useState([]);
    useEffect(() => {
        request('get', '/difficulty-levels/tags').then(res => setTags(() => res.map(tag => tag.tag)))
        // eslint-disable-next-line
    }, [])

    return <Form>
        <Row>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="title"
                        defaultValue={entity.title || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value}
                                                 label={t('directories.difficultyLevels.briefly')}
                                                 placeholder={t('directories.difficultyLevels.briefly')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="description"
                        defaultValue={entity.description || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.difficultyLevels.description')}
                                                 type="textarea" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.difficultyLevels.descriptionPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="tag"
                        defaultValue={entity.tag || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.difficultyLevels.briefly')}
                            >
                                <Input type={'select'} id={name} invalid={invalid}
                                       name={name} value={value} onBlur={onBlur}
                                       style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                       onChange={e => onChange(handleInput(e, setEntity).value)}>
                                    <option key={"tag-none"} value='' disabled>
                                        {t('directories.difficultyLevels.chooseTag')}
                                    </option>
                                    {tags.map(tag => {
                                        return <option key={"tag-" + tag} value={tag}>{tag}</option>
                                    })
                                    }
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={3}>
                <Controller
                    control={control}
                    name="level"
                    defaultValue={entity.level || ''}
                    rules={{required: t('inputs.validation.required')}}
                    render={(
                        {value, onBlur, name, ref, onChange},
                        {invalid}
                    ) =>
                        <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                             label={t('directories.difficultyLevels.difficultyLevel')}
                                             type="text" onBlur={onBlur} value={value} placeholder='2'
                                             onChange={e => onChange(handleInput(e, setEntity).value)}
                        />
                    }
                />
            </Col>
            <Col xs={12} md={3}>
                <Controller
                    control={control}
                    name="max_level"
                    defaultValue={entity.max_level || ''}
                    rules={{required: t('inputs.validation.required')}}
                    render={(
                        {value, onBlur, name, ref, onChange},
                        {invalid}
                    ) =>
                        <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.difficultyLevels.maxLevel')}
                                             name={name} isRequired={true}
                                             type="text" onBlur={onBlur} value={value}
                                             placeholder='6'
                                             onChange={e => onChange(handleInput(e, setEntity).value)}
                        />
                    }
                />
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
