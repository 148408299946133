import {EVENT_TYPES} from "sv-common/constants/events";

const ARE_PROMOCODES_ACTIVE = 'are_promocodes_active';
const PROMO_SUBSCRIPTION_AVAILABLE = 'promo_subscription_available';
const CHECK_AGE_REQUIRED = 'check_age_required';
const IS_PRIVATE = 'is_private';
const IS_FULL_BOOK_AVAILABLE = 'is_full_book_available';
const IS_FULL_BOOK_DISCOUNT_AVAILABLE = 'full_book_discount_size';

const resetObjectNames = {
    [ARE_PROMOCODES_ACTIVE]: false,
    [PROMO_SUBSCRIPTION_AVAILABLE]: false,
    [CHECK_AGE_REQUIRED]: false,
    [IS_PRIVATE]: false,
    [IS_FULL_BOOK_AVAILABLE]: false,
};

const group1 = {
    ...resetObjectNames,
    [ARE_PROMOCODES_ACTIVE]: true,
    [PROMO_SUBSCRIPTION_AVAILABLE]: true,
    [CHECK_AGE_REQUIRED]: true,
}

const group2 = {
    ...resetObjectNames,
    [ARE_PROMOCODES_ACTIVE]: true,
    [CHECK_AGE_REQUIRED]: true,
}

const group3 = {
    ...resetObjectNames,
    [ARE_PROMOCODES_ACTIVE]: true,
}

const group4 = {
    ...resetObjectNames,
    [PROMO_SUBSCRIPTION_AVAILABLE]: true,
    [CHECK_AGE_REQUIRED]: true,
    [IS_PRIVATE]: true,
}

const group5 = {
    ...resetObjectNames,
    [CHECK_AGE_REQUIRED]: true,
}

const group6 = {
    ...resetObjectNames,
    [IS_FULL_BOOK_AVAILABLE]: true,
    [CHECK_AGE_REQUIRED]: true,
    [IS_FULL_BOOK_DISCOUNT_AVAILABLE]: true,
    full_book_discount_type: 'percent',
    full_book_discount_size: '10',
}

export const defaultValueTypeHandler = {
    [EVENT_TYPES.TRAINING]: group1,
    [EVENT_TYPES.INDIVIDUAL_LUCH]: group1,
    [EVENT_TYPES.RACE]: group1,
    [EVENT_TYPES.SPECIAL]: group1,

    [EVENT_TYPES.GRAN_PRIX]: group2,
    [EVENT_TYPES.UNIQUE]: group2,
    [EVENT_TYPES.COURSES]: group2,

    [EVENT_TYPES.INDIVIDUAL_LASER]: group3,

    [EVENT_TYPES.RENT]: group4,

    [EVENT_TYPES.BASE_CHAMPIONSHIP]: group5,
    [EVENT_TYPES.MATCH_TRIP]: group5,

    [EVENT_TYPES.YACHTS]: group6,
}