import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Label,
    Input,
    UncontrolledPopover, PopoverBody
} from "reactstrap";
import {DataTable, field} from "../../../../generic/tables";
import dayjs from "dayjs";
import {daysAbbr} from "../../../../../lib/utils/format";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {FaCheck, FaInfoCircle} from "react-icons/fa";
import {ModalConfirmation} from "../../../../generic/modals";
import {RangedDatePicker} from "../../../../generic/fields";
import {t} from "i18next";
import './style.scss';
import { getConfirmationText } from '../helpers';

const MovePaymentModalSlot = ({destinationSlot, sourceSlotId, setDestinationSlot, isModalOpen, triggerModal, moveOrder, clientId, presetFilters = {}}) => {
    const {findAndCountEntries} = useDefaultEndpoints('slots');
    const getBases = useDefaultEndpoints('bases').getEntries;

    const [bases, setBases] = useState([]);
    const [selectedBase, setSelectedBase] = useState(presetFilters.base_id)
    const [selectedEvent, setSelectedEvent] = useState(presetFilters.event_id)
    const [daysFilter, setDaysFilter] = useState({
        from: dayjs().startOf('day').toDate(),
        to: dayjs().startOf('day').add(1, 'M').toDate()
    })

    useEffect(() => {
        getBases({_sort:'base_order:asc', is_archived: false}).then(res => setBases(() => res))
    }, [])

    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);

    const openMoveOrderModal = (res) => {
        setDestinationSlot(() => res);

        triggerConfirmationModal()
    }

    const triggerConfirmationModal = () => {
        toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen)
    }

    const filters = {
        name_ncontains: 'delete',
        'event_id.base_id': selectedBase,
        event_id: selectedEvent,
        start_date_gte: daysFilter.from.toISOString(),
        end_date_lte: daysFilter.to.toISOString()
    };

    return (
        <Modal isOpen={isModalOpen} toggle={triggerModal} size={'lg'}>
            <ModalConfirmation onClose={triggerConfirmationModal} isOpen={isModalConfirmationOpen}
                               onConfirm={moveOrder}
                               confirmButtonText={t('common.forms.movePaymentModal.yes')}
                               text={destinationSlot && getConfirmationText(clientId, destinationSlot)}
                               title={t('common.forms.movePaymentModal.clientMoving')}
                               timer={3}
            />
            <ModalHeader>{t('common.forms.movePaymentModal.move')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('common.forms.movePaymentModal.pickBase')}
                            </Label>
                            <Input type={'select'} name={'base_id'} value={selectedBase}
                                   onChange={(e) => setSelectedBase(() => e.target.value)}>
                                {bases.map(base => <option key={base.id} value={base.id}>{base.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('common.forms.movePaymentModal.pickEvent')}
                            </Label>
                            <Input type={'select'} name={'event_id'} value={selectedEvent}
                                   onChange={(e) => setSelectedEvent(() => e.target.value)}>
                                {bases.find(({id}) => id === +selectedBase)
                                    ?.events_ids
                                    ?.filter((event_id) => !event_id.name.includes('_deleted_'))
                                    ?.sort((a, b) => a.name.localeCompare(b.name))
                                    .map(event => <option key={event.id} value={event.id}>{event.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('common.forms.movePaymentModal.pickDates')}
                            </Label>
                            <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            findAndCount={findAndCountEntries}
                            filters={filters}
                            fields={[
                                field(t('common.forms.movePaymentModal.name'), true, "name", res => <>
                                    <h5>{res.name} </h5> <br/>
                                    {!!res.comment && <><h6>{res.comment} </h6> <br/></>}
                                    <div style={{color: 'green'}}>{res.url}</div>
                                </>),
                                field(t('common.forms.movePaymentModal.date'), true, "start_datetime", res => <>
                                    {dayjs(res.start_date).format('MM/DD ')} {daysAbbr[dayjs(res.start_date).isoWeekday()]}</>),
                                field(t('common.forms.movePaymentModal.time'), false, "time", res =>
                                    res.start_time? dayjs(res.start_time, 'HH:mm:ss').format('HH:mm') + ' - ' +
                                        dayjs(res.end_time, 'HH:mm:ss').format('HH:mm'): ''),
                                field(<>
                                        {t('common.forms.movePaymentModal.reservedSits')}&nbsp;
                                        <FaInfoCircle id={"sender_email"}/>
                                        <UncontrolledPopover placement="right" target={"sender_email"} trigger={'hover'}
                                                             className="reservedSits_popover">
                                            <PopoverBody>{t('common.forms.movePaymentModal.paymentMade')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </>,
                                    false, "booked_to_total",
                                    res => <>{(res.booked_sits) + ' / ' + res.total_sits}</>
                                ),
                                field(t('common.forms.movePaymentModal.bookedToTotal'), false, "available_reserved_sits", res => <>
                                    {((res.reserved_sits) || 0 )+ ' / ' + res.total_sits}
                                </>),
                                field(t('common.forms.movePaymentModal.pick'), false, "id", res =>
                                    <Button color={sourceSlotId !== res.id ? 'success' : 'secondary'}
                                            onClick={() => openMoveOrderModal(res)} disabled={sourceSlotId === res.id}>
                                        <FaCheck/>
                                    </Button>
                                ),
                            ]}
                            sortField="start_datetime"
                            sortDir="asc"
                            name="slots-list"
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

MovePaymentModalSlot.propTypes = {
    sourceSlotId: PropTypes.number.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    clientId: PropTypes.number,
    presetFilters: PropTypes.object
};

export {
    MovePaymentModalSlot
};