import React, {useEffect, useState} from 'react';
import {TableCard} from "../../../generic/cards";
import {DataTable, field} from "../../../generic/tables";
import {BsPersonSquare} from 'react-icons/bs';
import {Button, Col, Row} from "reactstrap";
import {FaPlus} from "react-icons/fa";
import ViewInstructorsModal from "./ViewInstructorsModal";
import RemoveInstructorModal from "./RemoveInstructorModal";  // Импортируем модалку
import {useInstructorsApi} from "../../../../lib/api/instructors";
import {useHistory} from "react-router-dom";
import {ISO_DATE} from "../../../../lib/utils/format";
import {openInNewTab} from "../../../../lib/utils/helper";
import dayjs from "dayjs";
import {ElementWithPermissions} from "../../../common/formElements";
import {t} from "i18next";
import _ from 'lodash';


export const mapInstructorStatus = (status) => {
    switch (status) {
        case 'active':
            return 'bases.base.active';
        case 'ill':
            return 'bases.base.sick';
        case 'busy':
            return 'bases.base.busy';
        case 'inactive':
            return 'bases.base.inactive';
        default:
            return 'bases.base.active';
    }
}

const SlotInstructors = ({state, dispatch, disabled}) => {
    let history = useHistory();
    const {findAndCountEntries, attachInstructor, detachInstructor} = useInstructorsApi();
    const [isModalOpen, toggleModal] = useState(false);
    const [isModalRemoveInstructorOpen, setIsRemoveInstructorOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [openedInstructorId, setOpenedInstructorId] = useState(0);
    const [freeInstructors, setFreeInstructors] = useState([]);
    const {getFreeInstructorsForSlot} = useInstructorsApi();

    const addInstructor = ({id}) => attachInstructor(state, id)
        .then(res => {
            dispatch({type: 'setSlotData', payload: res});
            triggerModal();
        });

    useEffect(() => {
        if (state?.id)
            getFreeInstructorsForSlot(state.id).then(res => setFreeInstructors(res));
    }, [state.id]);

    const triggerModal = () => {
        setReload(reload => !reload);
        toggleModal(isModalOpen => !isModalOpen);
    };

    const triggerRemoveInstructorModal = (order) => {
        setReload(reload => !reload);
        setOpenedInstructorId(order?.id || 0);
        setIsRemoveInstructorOpen(isModalRemoveInstructorOpen => !isModalRemoveInstructorOpen);
    };

    const generateScheduleUrl = instructor => '/administration/instructors/'
        + instructor.id + '?t=schedule&date='
        + dayjs(state.start_date).format(ISO_DATE);

    return (
        <>
            {isModalOpen && (
                <ViewInstructorsModal
                    state={state}
                    triggerModal={triggerModal}
                    onClick={addInstructor}
                    isOpened={isModalOpen}
                />
            )}

            {isModalRemoveInstructorOpen && (<RemoveInstructorModal
                isModalRemoveInstructorOpen={isModalRemoveInstructorOpen}
                triggerRemoveInstructorModal={triggerRemoveInstructorModal}
                state={state}
                openedInstructorId={openedInstructorId}
                freeInstructors={freeInstructors}
                onChangeFreeInstructors={setFreeInstructors}
                detachInstructor={detachInstructor}
                dispatch={dispatch}
            />)}

            <TableCard isInline>
                <div className="d-flex justify-content-end mb-5">
                    <Col xs={'auto'}>
                        <ElementWithPermissions disabled={disabled}>
                            <Button color="primary" size={'sm'} className="btn-rounded" onClick={triggerModal}>
                                <FaPlus /> {t('bases.base.add')}
                            </Button>
                        </ElementWithPermissions>
                    </Col>
                </div>
                <Row>
                    <Col>
                        <DataTable
                            findAndCount={findAndCountEntries}
                            filters={{
                                ...(state.instructors_ids && !!state.instructors_ids.length
                                    ? {id_in: state.instructors_ids.map(instructor => instructor.id)}
                                    : {id_null: true}),
                            }}
                            fields={[
                                field(t('bases.base.instructor'), false, 'client_id', res => (
                                    <>
                                        <BsPersonSquare /> {res.client_id?.last_name} {res.client_id?.first_name}
                                    </>
                                )),
                                field(t('bases.base.status'), true, 'status', res => t(mapInstructorStatus(res.status))),
                                field(t('bases.base.yachtsClasses'), true, 'boats_classes', res =>
                                    res.boats_classes.map(boat_class => (
                                        <div key={boat_class} className="badge badge-outline-info badge-pill mr-1">
                                            {boat_class}
                                        </div>
                                    ))
                                ),
                                field(t('bases.base.comment'), false, 'state'),
                            ]}
                            sortField="created_at"
                            sortDir="desc"
                            name="slots_instructors-list"
                            onClick={instructor => history.push(generateScheduleUrl(instructor))}
                            onMiddleClick={instructor => openInNewTab(generateScheduleUrl(instructor))}
                            onDelete={disabled ? null : triggerRemoveInstructorModal}
                            reload={reload}
                        />
                    </Col>
                </Row>
            </TableCard>
        </>
    );
};

export default SlotInstructors;
