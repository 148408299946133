import React, {useEffect, useMemo, useState} from 'react';
import {Card} from "../../generic/cards";
import {useApi} from "../../../lib/api/useApi";
import {routes} from "../../../lib/routes";
import {generateLinks} from "../Dashboard";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../generic/loader";
import {BsGear} from "react-icons/bs";
import {t} from "i18next";

BasesCard.propTypes = {

};

function BasesCard() {
    const {userRole} = useApi();
    const {getEntries} = useDefaultEndpoints('bases');
    const [baseRoutes, setBasesRoutes] = useState([]);
    const [loading, setLoading] = useState(true);
    const bases = useMemo(() => routes.find(r => t(r.title) === t('routes.bases')), [])

    useEffect(() => {
        getEntries({is_archived_ne: true, _sort: 'base_order:ASC', _or: [{ hide_in_menu_ne: true }, { hide_in_menu_null: true }]}).then(bases => {
            const loadedBases = bases.filter(b => !b.is_kids).map(base => ({
                title: base.name,
                to: '/bases/' + base.url + '/events',
                access: ['Super-admin', 'Administrator', 'Sport', 'Call-center'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'} title={'Редактирование'}>
                            <BsGear/></div>,
                        to: '/bases/' + base.url,
                    }
                ],
                accessKey: `SIDEBAR-BASES-ENTITY-ID-${base.id}`
            }))
            setBasesRoutes(() => loadedBases);
        }).then(() => setLoading(() => false))
    }, [])

    return loading
        ? <Loader/>
        :(<Card title={t('routes.bases')} className={'col-xs-3 col-md-6 col-lg-6 col-xl-3 grid-margin stretch-card'}>
            {generateLinks({...bases, children: baseRoutes}, userRole)}
        </Card>);
}

export default BasesCard;