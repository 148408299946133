import React from 'react';
import {DatePicker} from "../../../generic/fields";
import {CLIENT_FORMAT_DATE, ISO_DATE} from "../../../../lib/utils/format";
import dayjs from "dayjs";
import PropTypes from 'prop-types';

const IsoDatePicker = ({name, value, onDateChange, dateFormat = CLIENT_FORMAT_DATE}) => {
    const convertFormattedDateToIso = v => dayjs(v).format(ISO_DATE);
    const convertIsoDateToFormattedDate = v => dayjs(v, ISO_DATE).format(dateFormat)
    return (
        <>
            <DatePicker name={name}
                        selectedDay={convertIsoDateToFormattedDate(value)}
                        onDateChange={val => onDateChange(convertFormattedDateToIso(val))}
                        dateFormat={dateFormat}
            />
        </>
    );
};

IsoDatePicker.propTypes = {
    /** name of picker */
    name: PropTypes.string.isRequired,
    /** date in ISO (YYYY-MM-DD) */
    value: PropTypes.string.isRequired,
    /** cb that accepts date in ISO format */
    onDateChange: PropTypes.func.isRequired,
    /** format of date to show in field */
    dateFormat: PropTypes.string,
};

export default IsoDatePicker;