import PropTypes from 'prop-types';
import React from 'react';
import {isShowElemInCountry} from "../helpers/localizeFeatures";

const ElementWithAccessInCountries = ({children, hideInCountries, ...props}) =>
    isShowElemInCountry(hideInCountries) && React.cloneElement(<>{children}</>, props)

export default ElementWithAccessInCountries

ElementWithAccessInCountries.propTypes = {
    children: PropTypes.element.isRequired,
    hideInCountries: PropTypes.array.isRequired,
}