import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import CopyLink from '../../generic/buttons/CopyLink';
import { makePublicContactUrl } from './helpers';

const PublicContracts = (props) => <DirectoryTemplate
  fields={[
    field(t('directories.publicContracts.name'), true, "tag", res => <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{res.tag}</div>, '', 'w-45'),
    field(t('directories.publicContracts.link'), true, "link", res => <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{res.link}</div>, '', 'w-45'),
  ]}
  entityName={'public-contracts'}
  EditModalChildren={EditModalChildren}
  editable
  deletable
  permissions={getPermissions(ENTITIES.PUBLIC_CONTRACTS)}
  isFormData
  initialEntity={{ entity: 'public_contracts' }}
  additionalButtons={res => <div style={{ display: 'flex', margin: '0 10px' }}><CopyLink style={{ height: '100%' }} href={makePublicContactUrl(res.link)} showLink={false} /></div>}
  tableStyle={{ tableLayout: 'fixed' }}
  {...props}
/>


export default PublicContracts;