import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Label} from 'reactstrap';
import {useInstructorsApi} from "../../../../lib/api/instructors";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

const CustomFormControls = ({productType, state, setState}) => {
    const {getFreeInstructorsForSlot} = useInstructorsApi();
    const [freeInstructors, setFreeInstructors] = useState([])

    useEffect(() => {
        if (state.slot_id?.id)
            getFreeInstructorsForSlot(state.slot_id.id, state?.instructor_id?.id)
              .then(res => setFreeInstructors(res.filter((instructor, idx) => res[idx].id === state?.instructor_id?.id || instructor.available_sits >= state.paid_for_ids.length)))
          }, [state.id])

    if (productType === 'slots')
        return <Col xs={12}>
          <ElementWithAccessInCountries hideInCountries={['EN']}>
            <FormGroup>
              <Label>
                {t('management.orders.customFormControls.instructor')}
              </Label>
              <Input type={'select'} name={'instructor_id'} value={ state.instructor_id?.id || state.instructor_id || ''} onChange={setState}>
                <option value={''}>{t('management.orders.customFormControls.anyoneCool')}</option>
                {
                  freeInstructors.map((instructor, i) =>
                    <option
                      value={instructor.id}>
                      {
                        instructor.first_name
                      } {
                      instructor.last_name
                    } | {t('management.orders.customFormControls.places')}: {
                      instructor.available_sits + ' / ' + instructor.total_sits
                    }
                    </option>)
                }
              </Input>
            </FormGroup>
          </ElementWithAccessInCountries>
        </Col>
    else return <></>
}

export default CustomFormControls;

