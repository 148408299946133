import React, {Fragment, useState} from 'react';
import {Button, Col, CustomInput, FormGroup, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../generic/tables";
import {EditModal, ModalConfirmation} from "../../../generic/modals";
import EditArrivalModalChildren from "./EditArrivalModalChildren"
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useHistory} from 'react-router-dom';
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {useArrivalsApi} from "../../../../lib/api/arrivals";
import './style.scss'
import {singleArrivalFields} from "./arrivalTableRows/singleArrivalFields";
import {lowerLinkedFields} from "./arrivalTableRows/lowerLinkedFields";
import {upperLinkedFields} from "./arrivalTableRows/upperLinkedFields";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, getPermissions} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import { onNewPage } from '../helpers';
import {FaInfoCircle} from "react-icons/fa";
import LegendIndicator from "../../../generic/statusIndicator/LegendIndicator";

dayjs.extend(customParseFormat)

const ArrivalsTable = ({state, typeForRule}) => {
    const [reload, setReload] = useState(false);
    const [ejectedArrival, setEjectedArrival] = useState('');
    const [isModalOpen, toggleModal] = useState(false);
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const [modalConfirmationText, setModalConfirmationText] = useState('');
    const [showCurrent, setShowCurrent] = useState(true);
    let history = useHistory();
    const {findAndCountEntries, archiveArrival, createEntry, syncData} = useArrivalsApi();

    const preventRandomClicks = e => {
        e.preventDefault();
        const className = e.target.className;
        return !(!className.includes || className.includes('btn') || className.includes('fa') || className.includes('popover'));
    }

    const handleMouseUp = (e, res) => {
        const isMiddleClick = e.button === 1 || ((e.ctrlKey || e.metaKey) && e.button === 0)
        if (!isMiddleClick) return
        if (!preventRandomClicks(e)) return;

        onNewPage(`${state.url}/${res.url}`, res.is_school, state.is_camp, state.is_charter, false, history);
    }

    const onClick = (e, res) => {
        if (e.metaKey || e.ctrlKey) return;
        if (!preventRandomClicks(e)) return;

        onNewPage(`${state.url}/${res.url}`, res.is_school, state.is_camp, state.is_charter, true, history);
    }

    const triggerModal = () => toggleModal(isModalOpen => !isModalOpen);
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);

    const deleteArrival = async (arrival) => {
        setEjectedArrival(() => arrival);
        const actualArrivalData = await syncData(arrival.id);
        if (actualArrivalData.booked_sits > 0) {
            setModalConfirmationText(t('adventures.arrivalsTable.thereIsPayed'))
            setReload(reload => !reload);
        } else {
            setModalConfirmationText(t('adventures.arrivalsTable.areYouSure'))
        }
        triggerModalConfirmation()
    }

    const confirmArchiveArrival = () => archiveArrival(ejectedArrival.id).then(
        () => {
            setReload(reload => !reload);
            triggerModalConfirmation()
        })

    const getArrivalUrl = arrival => {
      let copyLink;
      if (state.is_school) {
        copyLink = `${BOOKING_URL}/school/practice/${state.url}/${arrival.url}`;
      } else if (state.is_charter) {
        copyLink = `${BOOKING_URL}/charter/${state.url}/${arrival.url}`;
      } else {
        copyLink = `${BOOKING_URL}/travel/${state.url}/${arrival.url}/`;
      }

      return copyLink;
    }

    const filters = {
        adventure_id_in: state.id,
        is_parental: true,
        is_school: state.is_school,
        is_archived: false,
        ...(showCurrent ? {start_date_gte: dayjs().startOf('day').format('YYYY-MM-DD')} : {}),
    }

    const customRowRenderer = (res, id) => {
        return res.linked_arrival_id
            ? <Fragment key={`row-arrivals-list-cell-${res.id}-${id}`}>
                <tr className={id % 2 === 0 ? 'arrivals-row custom-white has-linked' : 'arrivals-row custom-blue has-linked'}
                    onClick={e => onClick(e, res)} onMouseUp={e => handleMouseUp(e, res)}>{
                    upperLinkedFields(getArrivalUrl, deleteArrival, typeForRule)
                        .map((field, id) => <td key={`row-${id}-cell-${field.key}`}>{field.render(res)}</td>)
                }
                </tr>
                <tr className={id % 2 === 0 ? 'arrivals-row custom-white linked' : 'arrivals-row custom-blue linked'}
                    onClick={e => onClick(e, res.linked_arrival_id)}
                    onMouseUp={e => handleMouseUp(e, res.linked_arrival_id)}>{
                    lowerLinkedFields(getArrivalUrl, deleteArrival, typeForRule)
                        .map((field, id) => <td key={`row-${id}-cell-${field.key}`}>{field.render(res)}</td>)
                }
                </tr>
            </Fragment>
            : <tr className={id % 2 === 0 ? 'arrivals-row custom-white' : 'arrivals-row custom-blue'}
                  key={`row-arrivals-list-cell-${res.id}-${id}`} onClick={e => onClick(e, res)}
                  onMouseUp={e => handleMouseUp(e, res)}>
                {singleArrivalFields(getArrivalUrl, deleteArrival, typeForRule)
                    .map((field, id) => <td key={id}>{field.render(res, id)}</td>)}
            </tr>
    }

    return <Row>
        {isModalOpen &&
        <EditModal entityName={'arrivals'} triggerModal={triggerModal} isOpened={isModalOpen}
                   entry={{
                       ...state, adventure_id: state.id, total_sits: state.total_sits, payment_description: state.payment_description,
                       is_parental: true, name: state.name, price_value: state.price_value,
                       url: state.url, is_school: state.is_school, comment: '', client_comment: state.client_comment
                   }}
                   EditForm={EditArrivalModalChildren} requiredFields={[]}
                   onSuccess={(entry) => createEntry(entry).then(() => {
                       setReload(reload => !reload)
                       triggerModal();
                   })}
                   size={'sm'}
                   edit={
            getPermissions(state.type === 'world' ? ENTITIES.ADVENTURES_WORLD : ENTITIES.ADVENTURES_RUSSIA).edit
            || (state.is_school && getPermissions(ENTITIES.GROUPS_PRACTICE).edit)
        }/>
        }
        {isModalConfirmationOpen &&
        <ModalConfirmation onClose={triggerModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('adventures.arrivalsTable.arrivalDeleting')} text={modalConfirmationText}
                           cancelButtonText={t('adventures.arrivalsTable.no')}
                           confirmButtonText={t('adventures.arrivalsTable.yes')} onConfirm={confirmArchiveArrival}
        />}
        <Col xs={12}>
            <Row className={'mb-3'}>
                <Col md={3}>
                    <div className={'display-4'}>
                        {t('adventures.arrivalsTable.arrivals')}
                    </div>
                </Col>
                <Col>
                    <ElementWithPermissions disabled={!getPermissions(state.type === 'world' ? ENTITIES.ADVENTURES_WORLD : ENTITIES.ADVENTURES_RUSSIA).edit}>
                        <Button color={'dark'} className={'btn-rounded'} onClick={triggerModal}> {t('adventures.arrivalsTable.add')} </Button>
                    </ElementWithPermissions>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="showCurrent" name="showCurrent"
                                     label={t('adventures.arrivalsTable.hideExpired')} checked={showCurrent || false}
                                     onChange={() => setShowCurrent(cur => !cur)}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={12}>
                    <DataTable
                        filters={filters}
                        findAndCount={findAndCountEntries}
                        fields={[
                            field(t('adventures.arrivalsTable.arrival'), true, "start_date"),
                            field(t('adventures.arrivalsTable.name'), true, "name"),
                            field(t('adventures.arrivalsTable.price'), true, "price_value"),
                            field(t('adventures.arrivalsTable.bookedToTotal'), true, "booked_to_total"),
                            field(t('adventures.arrivalsTable.link'), false, "url"),
                            field("", false, ""),
                            field(
                                <>
                                    {t('adventures.arrivalsTable.status')}
                                    <FaInfoCircle className='ml-1' id={"status-legend_i"}/>
                                    <UncontrolledPopover placement="left" target={"status-legend_i"} trigger={'hover'}>
                                        <PopoverBody>
                                            <LegendIndicator/>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </>,
                                false,
                                "status"),
                        ]}
                        sortField="start_date"
                        sortDir="ASC"
                        name="arrivals-list"
                        customRowRender={(res, id) => customRowRenderer(res, id)}
                        reload={reload}
                        onClick={() => {}}
                        additionalButtons={() => {}}
                    />
                </Col>
            </Row>
        </Col>
    </Row>
}

ArrivalsTable.propTypes = {
    state: PropTypes.object.isRequired,
}

export default ArrivalsTable;

