import React from 'react';
import "./Loader.css";

const Loader = (props) =>
    <div className="loader-spinner">
        <div className={"flip-square-loader mx-auto" + (props.loading === false ? "d-none" : "")}/>
    </div>

export default Loader;

