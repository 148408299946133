import { handleInputChange } from '../../../../lib/utils/handleInputChange';
import dayJs from 'dayjs';
import { delta } from './helpers';
import { showDangerToast, showSuccessToast } from '../../../generic/modals/EditModal';
import { t } from 'i18next';
import { useDefaultEndpoints } from '../../../../lib/api/useDefaultEndpoints';
import { useState } from 'react';

const useGlobalSalesModel = ({ entityName, state }) => {
  const [stateEntity, setStateEntity] = useState(state.global_event_sale || {});
  const [formState, setFormState] = useState(state.global_event_sale || {});

  const [isModalOpen, toggleModal] = useState(false);

  const {editEntry} = useDefaultEndpoints(entityName);

  const triggerModal = () => toggleModal(isModalOpen => !isModalOpen);

  const handleInput = (e) => {
    const {name, value} = handleInputChange(e);

    if ((name === 'start_time') || (name === 'end_time')) {
      setFormState(entity => ({...entity, [name]: dayJs(value, 'HH:mm').format('HH:mm:ss.SSS')}));
    } else if (name === 'count') {
      setFormState(entity => ({ ...entity, count: value, limit: entity.limit + delta(value, entity.count) }));
    } else {
      setFormState(entity => ({...entity, [name]: value}));
    }

    return {name, value};
  }

  const createOrUpdate = () => {
    const {
      adventure_id, active_promocodes_ids, orders_ids,  booked_to_total, booked_sits, available_sits,
      start_date, end_date, reserved_sits, available_reserved_sits, options_included_ids,
      options_not_included_ids, ...rest
    } = state;

    if (state.id) {
      editEntry(state.id, { ...rest, global_event_sale: formState })
        .then((res) => {
          setStateEntity(res.global_event_sale);
          setFormState(res.global_event_sale);
          showSuccessToast(t('common.success'));
          triggerModal();
        })
        .catch(showDangerToast);
    }
  };

  const onCreateSale = () => {
    setFormState({});
    triggerModal();
  }

  const onEditSale = () => {
    setFormState(stateEntity);
    triggerModal();
  }

  const isPast = (stateEntity.status === 'finished') || dayJs(stateEntity.end_dateonly).isBefore(dayJs());
  const isCreateAvailable = isPast || !stateEntity.id;

  return {
    handleInput,
    triggerModal,
    onCreateSale,
    onEditSale,
    createOrUpdate,
    setFormState,

    isPast,
    isCreateAvailable,
    isModalOpen,
    stateEntity,
    formState,
  }
}

export default useGlobalSalesModel;