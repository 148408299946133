import React, {useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {Switch} from "../../../generic/buttons";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {useHistory} from 'react-router-dom';
import {ModalConfirmation} from "../../../generic/modals";
import {Promocodes} from "../../events/event/NewEventBlocks";
import {LegalEntitiesRHF, PriceCurrencyRHF, TimeZonePickerRHF, UrlRHF} from "../../../common/reactHookFormsFields";
import {TextControllerRHF} from "../../../generic/fields";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {t} from "i18next";
import PublicContracts from "../../../common/reactHookFormsFields/PublicContracts";

const BaseGeneral = ({state, dispatch, form}) => {
    let history = useHistory();
    const {editEntry} = useDefaultEndpoints('bases');
    const setBaseData = (e) => dispatch({type: 'setBaseData', payload: e})
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false)
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);
    const {edit} = useRoleSystem(ENTITIES.BASES);
    return (
        <>
            <ModalConfirmation onClose={triggerModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                               confirmButtonText={t('bases.base.yes')} cancelButtonText={t('bases.base.no')}
                               text={t('bases.basedeleteBaseInfo')} title={t('bases.base.deleteBase')}
                               onConfirm={() => editEntry(state.id, {is_archived: true}).then(() => history.push('/bases'))}
            />
            <Row>
                <Col xs={12} md={8}>
                    <h4 className={'display-4'}>{t('bases.base.main')}</h4>
                </Col>
            </Row>
            <Row className={'pb-5'}>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label for="name">{t('bases.base.name')}</Label>
                        <Input type="text" name="name" id="name" placeholder="namePlaceholder" disabled
                               value={state.name || ''}/>
                        <Label for={'name'} className={'text-secondary'}>{t('bases.base.nameLabel')}</Label>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <PriceCurrencyRHF setState={setBaseData} state={state} form={form}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <UrlRHF state={state} setState={setBaseData}
                                form={form} label={t('bases.base.baseUrl')} hint={t('bases.base.baseUrlPlaceholder')}
                                rules={{validate: value => !value.includes('_') || t("bases.base.invalidSymbol")}}
                                isTrimOnChange={true}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <TextControllerRHF inputName={'base_order'} control={form.control} setValue={setBaseData}
                                           placeholder={'1'} errors={form.errors} rules={{required: t('inputs.validation.required')}}
                                           defaultValue={state.base_order || ''} label={t('bases.base.baseOrder')} isRequired={true}
                                           hint={t('bases.base.baseOrderPlaceholder')}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <TextControllerRHF inputName={'booking_url'} control={form.control} setValue={setBaseData}
                                           placeholder={BOOKING_URL} errors={form.errors} rules={{}}
                                           defaultValue={state.booking_url || ''} label={t('bases.base.bookAddress')}
                                           hint={t('bases.base.bookAddressHint') + BOOKING_URL}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <TimeZonePickerRHF form={form} setState={setBaseData} state={state} isRequired/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <FormGroup>
                        <LegalEntitiesRHF form={form} setState={setBaseData} state={state} params={{}} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={8}>
                    <ElementWithPermissions disabled={!edit}>
                        <Promocodes productType={'bases'} state={state} dispatch={dispatch}/>
                    </ElementWithPermissions>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={8}>
                    <h4 className={'display-4'}>{t('bases.base.baseWork')}</h4>
                </Col>
            </Row>
            <Row className={'pb-5'}>
                <Col xs={8} md={3}>
                    {t('bases.base.openedSeason')}
                </Col>
                <Col xs={1}>
                    <Switch value={state.is_open} onChange={() => dispatch({type: 'triggerOpen'})}/>
                </Col>
            </Row>

            <ElementWithPermissions disabled={!edit} hidden={!edit}>
                {state.id ? <>
                    <Row>
                        <Col xs={12} md={8}>
                            <h4 className={'display-4'}>{t('bases.base.deleteBaseButton')}</h4>
                        </Col>
                    </Row>
                    <Row className={'pb-2'}>
                        <Col>
                            <Button size={'lg'} color={'danger btn-rounded'} onClick={triggerModalConfirmation} outline>
                                {t('bases.base.deleteBaseText')}
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label className={'text-secondary'}>{t('bases.base.archiveBase')}</Label>
                                <br/>
                                <Label className={'text-secondary'}>{t('bases.base.cantCancel')}</Label>
                            </FormGroup>
                        </Col>
                    </Row></> : <></>
                }
            </ElementWithPermissions>

        </>
    )
}

export default BaseGeneral;