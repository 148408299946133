import React, {Fragment, useEffect, useState} from 'react';
import "../loader/Loader.css";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import {Col, Row} from "reactstrap";
import {t} from "i18next";

const Pager = ({start, limit, onLimitChange, total, canChangeLimit, ...rest}) => {
    const [state, setState] = useState({start: start, limit: Number(limit)})

    useEffect(() => {
        if (state.limit !== limit) {
            setState(state => ({
                ...state,
                limit: limit
            }));
        }
        if (state.start !== start) {
            setState((state) => ({...state, start: start}))
        }
    }, [limit, state.limit, start, state.start])

    const handleChange = (e) => {
        let limit = e.target.value;
        onLimitChange(limit);
    }

    const onPageChange = (page) => {
        const newStart = (page - 1) * limit;
        setState(state => ({...state, start: newStart}));
        rest.onPageChange(newStart);
    }

    const limitList = [10, 20, 100, 1000];
    const minimalLimit = Math.min(limitList[0], limit);
    const page = 1 + Math.floor(state.start / limit);
    const totalPages = Math.ceil(total / limit);
    if (total > minimalLimit)
        return <Fragment>
            <Row className={"mr-2"}>
                <Col sm={12} md={5}>
                    <Pagination current={page} total={totalPages} onPageChange={onPageChange}/>
                </Col>
                {canChangeLimit && <Col sm={12} md={4}>
                    <div className="form-group form-inline">
                        <label className={"text-right  text-muted mt-2 mr-2"}>{t('generic.pager.entriesPerPage')}:</label>
                        <select
                            name="order-listing_length" aria-controls="order-listing"
                            value={state.limit}
                            onChange={handleChange} className="form-control">
                            {limitList.map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                    </div>
                </Col>}
                <Col sm={12} md={3} className={"text-right text-muted"}>
                    <div className={"mt-2"}>
                        {state.start} - {Math.min(state.start + Number(limit), total)} {t('generic.pager.of')} {total}
                    </div>
                </Col>
            </Row>

        </Fragment>;
    else
        return "";
}

Pager.propTypes = {
    start: PropTypes.number.isRequired,
    total: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    limit: PropTypes.number,
    canChangeLimit: PropTypes.bool
};

Pager.defaultProps = {
    start: 0,
    limit: 10,
    canChangeLimit: true
};

export default Pager;

