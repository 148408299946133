import React from "react";

const ruBreadcrumbsMapper = (path) => {
    return urls[path];
}

const theory = 'school.breadcrumbsAndTitle.theory';
const lecture = 'school.breadcrumbsAndTitle.lecture';
const practice = 'school.breadcrumbsAndTitle.practice';
const editing = 'school.breadcrumbsAndTitle.editing';

const urls = {
    '/school/theory/group/edit/:url': [
        {ru: theory, link: '/school/theory'},
        {ru: ':url', link: '/school/theory/group/view/:url'},
        {ru: editing, link: ''}
    ],
    '/school/theory/group/view/:url': [
        {ru: theory, link: '/school/theory'},
        {ru: ':url', link: ''},
    ],
    '/school/theory': [
        {ru: theory, link: ''},
    ],

    '/school/lectures/group/edit/:url': [
        {ru: lecture, link: '/school/lectures'},
        {ru: ':url', link: '/school/lectures/group/view/:url'},
        {ru: editing, link: ''}
    ],
    '/school/lectures/group/view/:url': [
        {ru: lecture, link: '/school/lectures'},
        {ru: ':url', link: ''},
    ],
    '/school/lectures/:url': [
        {ru: lecture, link: '/school/lectures'},
        {ru: ':url', link: ''},
    ],
    '/school/lectures': [
        {ru: lecture, link: '/school/lectures'},
    ],

    '/school/practices/view/:url/:arrival_url': [
        {ru: practice, link: '/school/practices'},
        {ru: ':url', link: '/school/practices/view/:url'},
        {ru: ':arrival_url', link: ''},
    ],
    '/school/practices/view/:url': [
        {ru: practice, link: '/school/practices/'},
        {ru: ':url', link: ''},

    ],
    '/school/practices/edit/:url': [
        {ru: practice, link: '/school/practices/'},
        {ru: ':url', link: '/school/practices/view/:url'},
        {ru: editing, link: '/school/practices/view/:url'},
    ],
    '/school/practices/edit/': [
        {ru: practice, link: '/school/practices/'},
        {ru: 'school.breadcrumbsAndTitle.creating', link: ''},
    ],
    '/school/practices/': [
        {ru: practice, link: ''},
    ],
}

export default ruBreadcrumbsMapper;