import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody} from 'reactstrap';
import {ProductOrdersList} from '../../../common/formElements';
import {t} from 'i18next';
import _ from 'lodash';

const RemoveInstructorModal = ({
    isModalRemoveInstructorOpen,
    triggerRemoveInstructorModal,
    state,
    openedInstructorId,
    freeInstructors,
    onChangeFreeInstructors,
    detachInstructor,
    dispatch
}) => {
    const [ordersToUpdate, setOrdersToUpdate] = useState(null);
    const [selectedInstructors, setSelectedInstructors] = useState({});

    const onGetOrders = (orders) => {
        setOrdersToUpdate(orders.data);
    };

    const onChangeInstructors = (value, instructorId, paidForLength) => {
        onChangeFreeInstructors(prev => {
            const newPrev = _.cloneDeep(prev);

            const newIndex = newPrev.findIndex(el => el?.id == value);

            if (selectedInstructors[instructorId]) {
                const lastIndex = newPrev.findIndex(el => el.id == selectedInstructors[instructorId]);

                if (value === '') {
                    newPrev[lastIndex].available_sits += paidForLength;

                    setSelectedInstructors(prevSelected => ({
                        ...prevSelected,
                        [instructorId]: null,
                    }));
                    return newPrev;
                } else {
                    if (lastIndex === newIndex) return newPrev;

                    newPrev[newIndex].available_sits -= paidForLength;
                    newPrev[lastIndex].available_sits += paidForLength;
                }
            } else {
                newPrev[newIndex].available_sits -= paidForLength;
            }

            setSelectedInstructors(prevSelected => ({
                ...prevSelected,
                [instructorId]: value || null,
            }));

            return newPrev;
        });
    };

    const removeInstructor = () => {
        let updatedOrders = [];

        const instructorsToUpdate = ordersToUpdate?.reduce((acc, order) => ({...acc, [order.id]: null}), {}) || {};
        const ordersAndInstructors = {...instructorsToUpdate, ...selectedInstructors};

        if (Object.keys(ordersAndInstructors).length) {
            updatedOrders = Object.keys(ordersAndInstructors).map((orderId) => ({
                orderId,
                newInstructorId: ordersAndInstructors[orderId],
            }));
        }

        detachInstructor(state, openedInstructorId, updatedOrders)
            .then(res => {
                dispatch({type: 'setSlotData', payload: {instructors_ids: state.instructors_ids.filter((el) => el.id !== openedInstructorId)}});
                triggerRemoveInstructorModal();
            });
    };

    return (
        <Modal isOpen={isModalRemoveInstructorOpen} toggle={triggerRemoveInstructorModal} size={ordersToUpdate && ordersToUpdate.length ? 'lg' : 'md'}>
            <ModalBody>
                {!!ordersToUpdate?.length ? (
                    <div className="d-flex justify-content-start mb-4">{t('bases.base.instructorIsBooked')}</div>
                ) : (
                    <div className="d-flex justify-content-start mb-4">{t('bases.base.deleteInstructor')}</div>
                )}

                {(_.isNull(ordersToUpdate) || !!ordersToUpdate?.length) && (
                    <ProductOrdersList
                        state={{...state}}
                        disabled
                        customFilters={{instructor_id: openedInstructorId, slot_id: state.id}}
                        additionalForProductFields={{
                            instructorsArray: freeInstructors,
                            onGetOrders,
                            onChangeInstructors,
                        }}
                    />
                )}
                <div className="d-flex justify-content-end mt-4">
                    <Button color="primary" size="sm" className="btn-rounded mr-2" onClick={removeInstructor}>
                        {t('bases.base.confirm')}
                    </Button>
                    <Button color="secondary" size="sm" className="btn-rounded" onClick={triggerRemoveInstructorModal}>
                        {t('bases.base.cancel')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default RemoveInstructorModal;
