import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, CustomInput, FormGroup, Row} from "reactstrap";
import {useOrdersApi} from "../../../../../../lib/api/orders";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {datetime} from "../../../../../../lib/utils/format";
import CertificateStatus from "./CertificateStatus";
import {useCertificatesApi} from "../../../../../../lib/api/certificates";
import {CopyLink} from "../../../../../generic/buttons";
import {AiOutlineMail} from "react-icons/ai";
import {downloadFileFromBuffer} from 'sv-common/utils/file';
import AsyncButton from "../../../../../generic/buttons/AsyncButton";
import {ElementWithPermissions} from "../../../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const PaymentInfo = ({state, dispatch, form}) => {
    let hasBeenSavedAssigneeEmail = useRef(state.assignee_email)
    if (form.formState.isSubmitSuccessful) hasBeenSavedAssigneeEmail.current = state.assignee_email

    const {generatePayment, setPaid, sendPayment} = useOrdersApi('orders');
    const {sendCertificateToRecipient, sendCertificateToSender, downloadCertificate} = useCertificatesApi();
    const permissions = useRoleSystem(ENTITIES.CERTIFICATES);
    const setFormValue = e => dispatch({payload: e, type: 'setFormData'})
    const changePaymentCheckbox = e => {
        dispatch({payload: e, type: 'setFormData'})
        if (e.target.value)
            dispatch({payload: {'createInvoice': false}, type: 'setForm'})
        if (e.target.value)
            dispatch({payload: {'sendEmail': false}, type: 'setForm'})
    }

    const changePaymentStatus = async () => setPaid(state.order.id, !state.order.has_paid).then(res => {
        toast.success(t('management.certificates.tabsPaymentInfo.statusChanged'));
        dispatch({type: 'setForm', payload: {order: {...state.order, has_paid: !state.order.has_paid}}})
    })

    const certificateToRecipientHandler = async () => {
        await sendCertificateToRecipient(state.id)
        toast.success(t('management.certificates.tabsPaymentInfo.certificateAddedToQueue'));
    }

    const certificateToSenderHandler = async () => {
        await sendCertificateToSender(state.id)
        toast.success(t('management.certificates.tabsPaymentInfo.certificateAddedToQueue'));
    }

    const downloadHandler = async () => {
        const response = await downloadCertificate(state.id);
        return downloadFileFromBuffer(response.file.name, response.file.content.data);
    }

    const generateCertificatePayment = async () => generatePayment('certificates', state.id, state.order.id,
                                                                    state.payment_type)
        .then(res => {
            toast.success(t('management.certificates.tabsPaymentInfo.invoiceHasBeenGenerated'));
            const updatedPayments = [...state.order.payments];
            updatedPayments[0].invoice_link = res.url;
            updatedPayments[0].due_date = res.dueDate;
            dispatch({type: 'setForm', payload: {order: {...state.order, payments: [...updatedPayments]}}})
        })

    const sendInvoice = async () => {
        await sendPayment(state.order.id)
        toast.success(t('management.certificates.tabsPaymentInfo.invoiceAddedToQueue'));
    }

    const isInvoiceExpired = state.order && state.order.payments?.[0]?.due_date ? dayjs().isAfter(dayjs(state.order.payments?.[0]?.due_date)) : false;
    const isInvoiceSendable =  state.order ? state.order.payments?.[0]?.invoice_link && !state.order.has_paid  && !isInvoiceExpired: false;
    return state.id
        ? <Row className={'align-items-end'}>
            <Col xs={12} md={10}>
                <FormGroup>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button block color={state.order.has_paid ? 'danger' : 'primary'} onClick={changePaymentStatus}
                                className={'btn-rounded'}>
                            {!!state.order?.has_paid ? t('management.certificates.tabsPaymentInfo.cancelPayment') : t('management.certificates.tabsPaymentInfo.toPay')}
                        </Button>
                    </ElementWithPermissions>
                </FormGroup>
            </Col>
            <Col xs={12} md={10}>
                <FormGroup>
                    <ElementWithPermissions disabled={
                        state.order.has_paid || (state.order.payments?.[0]?.invoice_link && !isInvoiceExpired)
                        || !permissions.edit}>
                        <Button block color={'outline-primary'} onClick={generateCertificatePayment}
                                className={'btn-rounded'}>
                            {!state.order.has_paid ?
                                t('management.certificates.tabsPaymentInfo.generateAnInvoice')
                                : t('management.certificates.tabsPaymentInfo.orderHasAlreadyBeenPaid')}
                        </Button>
                    </ElementWithPermissions>
                </FormGroup>
            </Col>
            {isInvoiceSendable
            && <Col xs={12} md={10} className={'mb-2 pb-2'}>
                <Row className={'justify-content-center'}>
                    <Col xs={'auto'}>
                        <CopyLink href={state.order.payments?.[0]?.invoice_link} className={'text-small'} text={t('management.certificates.tabsPaymentInfo.paymentLink')}/>
                    </Col>
                </Row>
                <Row className={'justify-content-center mb-1'}>
                    <Col xs={'auto'}>
                        <div className={'text-small'}>{t('management.certificates.tabsPaymentInfo.validUntil')}: {state.order.payments?.[0]?.due_date && datetime(dayjs(state.order.payments?.[0]?.due_date))}</div>
                    </Col>
                </Row>
                <Row className={'justify-content-center'}>
                    <Col xs={'auto'}>
                       <Button className={'btn-rounded'} color={'outline-primary'} size={'sm'} onClick={sendInvoice}>
                           <div className={'text-small'}>{t('management.certificates.tabsPaymentInfo.send')}&nbsp;<AiOutlineMail/></div>
                       </Button>
                    </Col>
                </Row>
            </Col>}
            <Col xs={12} md={10} >
                <FormGroup>
                    <Button block color={'outline-primary'} onClick={certificateToRecipientHandler} className={'btn-rounded'}
                            disabled={(!state.order.has_paid || !hasBeenSavedAssigneeEmail.current)}>
                        {t('management.certificates.tabsPaymentInfo.sendToRecipient')}
                    </Button>
                </FormGroup>
            </Col>
            <Col xs={12} md={10} >
                <FormGroup>
                    <Button block color={'outline-primary'} onClick={certificateToSenderHandler} className={'btn-rounded'}
                            disabled={!state.order.has_paid}>
                        {t('management.certificates.tabsPaymentInfo.sendToBuyer')}
                    </Button>
                </FormGroup>
            </Col>
            <Col xs={12} md={10} >
                <FormGroup>
                    <AsyncButton block color={'outline-primary'} onClick={downloadHandler} className={'btn-rounded'}
                            disabled={!state.order.has_paid}>
                        {t('management.certificates.tabsPaymentInfo.downloadFile')}
                    </AsyncButton>
                </FormGroup>
            </Col>
            <Col xs={12} md={10}>
                <FormGroup>
                   <CertificateStatus state={state} form={form} dispatch={dispatch}/>
                </FormGroup>
            </Col>

        </Row>
        : <Row className={'align-items-end'}>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='hasPaid'
                                 name='hasPaid' label={t('management.certificates.tabsPaymentInfo.hasPaid')} checked={state.hasPaid || false}
                                 onChange={changePaymentCheckbox}
                    />

                </FormGroup>
            </Col>

            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='createInvoice'
                                 name='createInvoice' disabled={state.hasPaid} onChange={setFormValue}
                                 label={t('management.certificates.tabsPaymentInfo.generateAnInvoice')} checked={state.createInvoice || false}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='sendEmail'
                                 name='sendEmail' disabled={!state.hasPaid} onChange={setFormValue}
                                 label={t('management.certificates.tabsPaymentInfo.sendCertificateToRecipient')} checked={state.sendEmail || false}/>
                </FormGroup>
            </Col>
        </Row>
};

PaymentInfo.propTypes = {
    state: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default PaymentInfo;