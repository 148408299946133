import { FaInfoCircle } from 'react-icons/fa';
import { PopoverBody, UncontrolledPopover} from 'reactstrap';

const InfoTooltip = ({ text }) => {
  return (
    <>
      <FaInfoCircle id={"export_button_info"} className="ml-1"/>
      <UncontrolledPopover placement="right" target={"export_button_info"} trigger={'hover'}>
        <PopoverBody>
          {text}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}

export default InfoTooltip;