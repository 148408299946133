import React from 'react';
import {Controller} from "react-hook-form";
import FieldWithValidation from "./FieldWithValidation";
import PropTypes from 'prop-types';
import {DatePicker} from "./index";
import {t} from "i18next";

const DatePickerRHF = ({
                           inputName,
                           control,
                           defaultValue = '',
                           rules,
                           errors,
                           label,
                           setValue,
                           placeholder,
                           isRequired,
                           ...rest
                       }) =>
    <Controller
        control={control}
        name={inputName}
        defaultValue={defaultValue}
        rules={rules}
        render={(
            {value, onBlur, name, ref, onChange},
            {invalid}
        ) =>
            <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                 name={name} isRequired={isRequired}
                                 type={'text'} onBlur={onBlur} value={value} placeholder={placeholder}
                                 onChange={e => {
                                     onChange(e.target.value);
                                     setValue && setValue(e);
                                 }}
                                 {...rest}
            >
                <DatePicker placeholder={placeholder || t('generic.datePicker.date')} selectedDay={value}
                            inputClassname={invalid ? 'is-invalid' : ''}
                            wrapperClassname={invalid ? 'is-invalid' : ''}
                            onDateChange={date => {
                                onChange(date);
                                setValue && setValue(date);
                            }}/>
            </FieldWithValidation>
        }
    />

DatePickerRHF.propTypes = {
    inputName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any.isRequired,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    label: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
}

export default DatePickerRHF;
