const ruBreadcrumbsMapper = (path) => {
    return urls[path];
};

const directories = 'directories.breadcrumbs.directories'

const urls = {
    '/directories/difficulties': [{ru: directories, link: ''},
        {ru: 'directories.breadcrumbs.difficultyLevels', link: ''}],
    '/directories/user_fields': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.userFields', link: ''}],
    '/directories/user_fields_templates': [{ru: directories, link: ''},
        {ru: 'directories.breadcrumbs.userFieldsTemplates', link: ''}],
    '/directories/options': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.travelOptions', link: ''}],
    '/directories/currencies': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.currencies', link: ''}],
    '/directories/boats_classes': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.boatsClasses', link: ''}],
    '/directories/shirt_sizes': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.shirtSizes', link: ''}],
    '/directories/public_contracts': [{ru: directories, link: ''},
        {ru: 'directories.breadcrumbs.publicContracts', link: ''}],
    '/directories/extra_materials': [{ru: directories, link: ''},
        {ru: 'directories.breadcrumbs.extraMaterials', link: ''}],
    '/directories/legal_entities': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.legalEntities', link: ''}],
    '/directories/subscriptions': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.subscriptions', link: ''}],
    '/directories/checks': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.checks', link: ''}],
    '/directories/coordinators': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.coordinators', link: ''}],
    '/directories/corp-clients': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.corpClients', link: ''}],
    '/directories/transaction-status': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.transactionStatus', link: ''}],
    '/directories/base_addresses': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.baseAddresses', link: ''}],
};

export default ruBreadcrumbsMapper;