import React from 'react';
import { Col, CustomInput, FormGroup, Row } from 'reactstrap';
import {t} from "i18next";
import TextAreaJsonBlock from '../../../../common/formElements/TextAreaJsonBlock';

const IncludedBlock = ({state, dispatch, control, errors}) => {
    return (
        <Row className={'pb-3'}>
            <Col xs={12} md={8}>
                <h4 className={'display-4'}>{t('adventures.edit.arrivalInfo')}</h4>
            </Col>
            <Col xs={12} md={6}>
              <TextAreaJsonBlock
                control={control}
                dispatch={dispatch}
                defaultValue={state.options_included_ids}
                required
                name={'options_included_ids'}
                label={t('adventures.edit.whatInclude')}
                placeholder={t('adventures.edit.whatIncludePlaceholder')}
                errors={errors}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextAreaJsonBlock
                control={control}
                dispatch={dispatch}
                defaultValue={state.options_not_included_ids}
                required
                name={'options_not_included_ids'}
                label={t('adventures.edit.whatNotInclude')}
                placeholder={t('adventures.edit.whatNotIncludePlaceholder')}
                errors={errors}
              />
            </Col>
            <Col xs={12} md={6}>
                <CustomInput type="checkbox" className={'custom-switch mb-1'} id="show_restrictions" name="show_restrictions"
                             label={t('adventures.edit.showRestrictions')} checked={state.show_restrictions || false}
                             onChange={e => dispatch({type: 'setFormData', payload: e})}/>
              <TextAreaJsonBlock
                control={control}
                dispatch={dispatch}
                defaultValue={state.restrictions}
                name={'restrictions'}
                label={t('adventures.edit.restrictions')}
                placeholder={t('adventures.edit.restrictionsPlaceholder')}
                errors={errors}
              />
            </Col>
        </Row>
    )
}

export default IncludedBlock;