import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import React, {useState} from 'react';
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {ModalConfirmation} from "../../generic/modals";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {FaPlus} from "react-icons/fa";

const Templates = withBreadcrumbs(() => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('templates');

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [ejectedTemplate, setEjectedTemplate] = useState();
    const [reload, setReload] = useState(false);

    let history = useHistory();

    const toggleModalConfirmation = () =>
        setIsModalConfirmationOpen(!isModalConfirmationOpen);

    const onConfirmModal = () => deleteEntry(ejectedTemplate.id).then(toggleModalConfirmation);

    const onDeleteTemplate = (template) => {
        setEjectedTemplate(() => template);
        toggleModalConfirmation();
    };

    const onTemplateClick = template => history.push('/constructor/templates/edit/' + template.id);

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('constructor.templates.deleting')} text={t('constructor.templates.areYouSure')}
                           cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
                           onConfirm={() => { onConfirmModal().then(() => setReload(!reload)) }}
        />

        <TableCard>
            <div className="justify-content-between mb-4">
                <button className={'btn btn-sm btn-primary btn-rounded'}
                        onClick={() => history.push('/constructor/templates/edit/')}>
                    <FaPlus/>
                    &nbsp; {t('common.create')}
                </button>
            </div>
            <DataTable
                findAndCount={findAndCountEntries}
                fields={[
                    field(t('constructor.label'), true, 'label'),
                    field(t('constructor.key'), true, 'key'),
                    field(t('constructor.createdAt'), true, 'created_at',
                        res => dayjs(res.created_at).format('DD.MM.YYYY HH:MM')),
                    field(t('constructor.updatedAt'), true, 'updated_at',
                        res => dayjs(res.updated_at).format('DD.MM.YYYY HH:mm')),
                ]}
                sortField='created_at'
                sortDir='desc'
                name='templates-list'
                reload={reload}
                onDelete={onDeleteTemplate}
                onClick={onTemplateClick}
            />
        </TableCard>
    </>
})

export default Templates