import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {
    AdventureCard,
    IncludedBlock,
    InsideInfoBlock,
    MainInfoBlock,
    SwitchBlock
} from "../../../adventure/edit/AdventureBlocks";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {RangedDatePicker} from "../../../../generic/fields";
import dayjs from "dayjs";
import {t} from "i18next";

const About = ({state, dispatch, errors, control, setValue}) => {
    const getCurrencies = useDefaultEndpoints('currencies').getEntries;
    const [currencies, setCurrencies] = useState([])
    const [daysFilter, setDaysFilter] = useState({
        from: dayjs(state.start_date).toDate(),
        to: dayjs(state.end_date).toDate()
    })

    useEffect(() => {
        if (state.start_date !== dayjs(daysFilter.from).format('YYYY-MM-DD')) {
            dispatch({type: 'setForm', payload: {start_date: daysFilter.from}});
            setDaysFilter(prevState => ({...prevState, to: dayjs(prevState.from).add(7, "d").toDate()}))
        }
    }, [daysFilter.from])

    useEffect(() => {
        dispatch({type: 'setForm', payload: {end_date: daysFilter.to}});
    }, [daysFilter.to])

    useEffect(() => {
        getCurrencies({}).then(res => setCurrencies(() => res));
    }, [])

    return <>
        <Row className={'pb-3'}>
            <Col xs={12} >
                <h4 className={'display-4'}>{t('adventures.arrival.about')}</h4>
            </Col>
            <Col xs={12} md={11}>
                    <FormGroup>
                        <Label>{t('adventures.arrival.dates')}</Label>
                        <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"}/>
                    </FormGroup>
            </Col>
            <Col xs={12} md={10}>
                <MainInfoBlock state={state} dispatch={dispatch} currencies={currencies} control={control}
                                errors={errors} isArrival setValue={setValue}/>
            </Col>
            <Col xs={12} md={2}>
                <SwitchBlock state={state} dispatch={dispatch} currencies={currencies} control={control}
                                errors={errors}/>
            </Col>
        </Row>

        <Row className={'pb-3'}>
            <Col xs={12} md={7}>
                <AdventureCard state={state} dispatch={dispatch} control={control} errors={errors} isArrival/>
            </Col>
        </Row>

        <IncludedBlock state={state} dispatch={dispatch} control={control} errors={errors}/>

        <Row>
            <Col xs={12}>
                <InsideInfoBlock state={state} dispatch={dispatch} control={control} errors={errors} isArrival entityName='arrivals'/>
            </Col>
        </Row>
    </>
}

export default About;