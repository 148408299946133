import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route as DefaultRoute } from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Layout, Login} from './layout';
import {Route, WIP} from './generic';
import {GlobalStateProvider} from '../lib/api/globalState';
import { QueryParamProvider } from "use-query-params";
import {
    CaptainsList,
    ClientsList,
    InstructorsList,
    Instructor,
    Order,
    OrdersList,
    Promocode,
    PromocodePackage,
    PromocodePackagesList,
    PromocodesList,
    User,
    Exports,
    Certificate,
    CertificatesList,
    FeedbackList,
    Feedback,
    ViolationsList,
    Violation,
    LinksGenerator,
    breadcrumbParser as administrationBcParser,
    titleParser as administrationTitleParser,
} from "./administration";
import {
    AvailableOptions,
    BoatsClasses,
    Currencies,
    DifficultyLevels,
    ExtraMaterials,
    LegalEntities,
    PublicContracts,
    ShirtSizes,
    UserFields,
    UserFieldsTemplates,
    Subscriptions,
    breadcrumbParser as directoryBcParser,
    titleParser as directoryTitleParser,
    Checks,
    TransactionStatus,
} from "./directories";
import {BaseInterface, BaseList, ruBreadcrumbsMapper as sportBcParser, ruTitleMapper as sportTitleParse} from "./bases";
import {Event, EventsList, NewEvent} from './bases/events'
import Slot from "./bases/slot";
import {AdventureEdit, AdventuresList, AdventureView, Arrival, breadcrumbParser as adventureBcParser, titleParser as adventureTitleParser} from "./adventures";
import {
    Projects,
    Templates,
    Pages,
    Files,
    breadcrumbParser as constructorBcParser,
    ruTitleMapper as constructorTitleParser,
    Template,
    Page,
    Coordinators,
    Areas,
    Formats,
    CorpClients,
    BaseAddresses,
} from "./constructor";
import {GroupEdit, GroupsList, GroupView, LecturesList, Practices, breadcrumbParser as schoolBcParser,
    titleParser as schoolTitleParser} from './school';
import {Dashboard, breadcrumbParser as dashboardBcParser, titleParser as dashboardTitleParser} from './dashboard';
import {useServiceWorker} from "./ServiceWorkerWrapper";

import '../lib/utils/i18n'
import { adventureComponentTypes } from './adventures/constants';
import FrontendRoles from './administration/frontendRoles';
import FrontendRulesProvider from '../lib/api/frontendRulesProvider';
import FrontendRole from './administration/frontendRoles/Role';
import Directory from "./constructor/Directory";
import EditDirectory from "./constructor/Directory/Edit";
import Separate from "./settings/separate";
import settingsTitleParser from "./settings/breadcrumbParser";
import settingsBcParser from "./settings/breadcrumbParser";

const toastStyle = {
    font: 'inherit',
};

const App = () => {
    const {isUpdateAvailable, updateAssets} = useServiceWorker();
    useEffect(() => {
        if (isUpdateAvailable)
            updateAssets()
    }, [isUpdateAvailable])

    return (
        <GlobalStateProvider>
          <FrontendRulesProvider>
            <Router>
                <Layout>
                    <QueryParamProvider ReactRouterRoute={DefaultRoute}>
                    <Switch>
                        <Route isPublic path={'/login'} component={Login}/>

                        <Route path={'/administration/orders/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Order}/>
                        <Route path={'/administration/orders/edit/'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Order}/>
                        <Route path={'/administration/orders'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={OrdersList}/>

                        <Route path={'/administration/feedback/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Feedback}/>
                        <Route path={'/administration/feedback'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={FeedbackList}/>

                        <Route path={'/administration/violations/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Violation}/>
                        <Route path={'/administration/violations/edit/'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Violation}/>
                        <Route path={'/administration/violations'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={ViolationsList}/>

                        <Route path={'/administration/captains'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={CaptainsList} hideInCountries={['EN', 'RU']}/>
                        <Route exact path={'/administration/users'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={props => <ClientsList showSurname={true} {...props} />}/>
                        <Route path={'/administration/users/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={User}/>
                        <Route path={'/administration/users/edit/'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={User}/>
                        <Route path={'/administration/instructors/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Instructor} hideInCountries={['EN']}/>
                        <Route path={'/administration/instructors'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={InstructorsList} hideInCountries={['EN']}/>
                        <Route path={'/administration/promocodes-packages/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={PromocodePackage}/>
                        <Route path={'/administration/promocodes-packages/edit'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={PromocodePackage}/>
                        <Route path={'/administration/promocodes-packages'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={PromocodePackagesList}/>
                        <Route path={'/administration/promocodes/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Promocode}/>
                        <Route path={'/administration/promocodes/edit'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Promocode}/>
                        <Route path={'/administration/promocodes'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={PromocodesList}/>
                        <Route path={'/administration/links-generator'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={LinksGenerator}/>
                        <Route path={'/administration/certificates/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Certificate}/>
                        <Route path={'/administration/certificates/edit'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Certificate}/>
                        <Route path={'/administration/certificates'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={CertificatesList}/>
                        <Route path={'/administration/exports'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={Exports}/>
                        <Route path={'/administration/materials'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={props => <Files {...props} entity='materials' />}/>
                        <Route path={'/administration/frontend-roles/edit/:id'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={FrontendRole} />
                        <Route path={'/administration/frontend-roles/edit'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={FrontendRole} />
                        <Route path={'/administration/frontend-roles'} ruBreadcrumbsMapper={administrationBcParser} ruTitleMapper={administrationTitleParser} component={FrontendRoles} />

                        <Route path={'/constructor/projects'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={Projects}/>
                        <Route path={'/constructor/templates/edit/:id'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={Template}/>
                        <Route path={'/constructor/templates/edit/'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={Template}/>
                        <Route path={'/constructor/templates'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={Templates}/>
                        <Route path={'/constructor/pages/edit/:id'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Page {...props} />}/>
                        <Route path={'/constructor/pages/edit/'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Page {...props} />}/>
                        <Route path={'/constructor/pages'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Pages {...props} cutstomFilters={{is_letter: false}} entity='corp' />}/>
                        <Route path={'/constructor/letters/edit/:id'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Page {...props} entity='letters' />}/>
                        <Route path={'/constructor/letters/edit/'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Page {...props} entity='letters' />}/>
                        <Route path={'/constructor/letters'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Pages {...props} cutstomFilters={{is_letter: true}} entity='letters' />}/>
                        <Route path={'/constructor/files'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={props => <Files {...props} entity='corp' />}/>
                        <Route path={'/constructor/directory/edit/:id'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={EditDirectory}/>
                        <Route path={'/constructor/directory/edit/'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={EditDirectory}/>
                        <Route path={'/constructor/directory'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorTitleParser} component={Directory}/>
                        <Route path={'/constructor/coordinators'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={Coordinators}/>
                        <Route path={'/constructor/base_addresses'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={BaseAddresses}/>
                        <Route path={'/constructor/corp-clients'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={CorpClients} />
                        <Route exact path={'/constructor/users'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={props => <ClientsList showSurname={true} customFilters={{is_corp: true}} {...props} />}/>
                        <Route path={'/constructor/users/edit/:id'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={User}/>
                        <Route path={'/constructor/users/edit/'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={User}/>
                        <Route path={'/constructor/areas'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={Areas} />
                        <Route path={'/constructor/formats'} ruBreadcrumbsMapper={constructorBcParser} ruTitleMapper={constructorBcParser} component={Formats} />

                        <Route path={'/directories/difficulties'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={DifficultyLevels}/>
                        <Route path={'/directories/user_fields'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={UserFields}/>
                        <Route path={'/directories/user_fields_templates'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={UserFieldsTemplates}/>
                        <Route path={'/directories/options'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={AvailableOptions}/>
                        <Route path={'/directories/currencies'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={Currencies} hideInCountries={['EN', 'RU']}/>
                        <Route path={'/directories/boats_classes'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={BoatsClasses}/>
                        <Route path={'/directories/shirt_sizes'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={ShirtSizes}/>
                        <Route path={'/directories/public_contracts'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={PublicContracts}/>
                        <Route path={'/directories/extra_materials'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={ExtraMaterials}/>
                        <Route path={'/directories/legal_entities'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={LegalEntities}/>
                        <Route path={'/directories/subscriptions'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={Subscriptions}/>
                        <Route path={'/directories/transaction-status'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={TransactionStatus}/>
                        <Route path={'/directories/checks'} ruBreadcrumbsMapper={directoryBcParser} ruTitleMapper={directoryTitleParser} component={Checks}/>

                        <Route path={'/global-settings/separate'} ruBreadcrumbsMapper={settingsBcParser} ruTitleMapper={settingsTitleParser} component={Separate}/>

                        <Route exact path={'/bases'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={BaseList} />
                        <Route exact path={'/bases/new'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={BaseInterface}/>
                        <Route exact path={'/bases/calendar'} component={WIP}/>
                        <Route exact path={'/bases/:url/events'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={EventsList} />
                        <Route exact path={'/bases/:url/event/:event_url/edit/'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={NewEvent}/>
                        <Route exact path={'/bases/:url/event/:event_url/slot/:slot_url'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={Slot}/>
                        <Route exact path={'/bases/:url/event'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={NewEvent}/>
                        <Route path={'/bases/:url/event/:event_url'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={Event}/>
                        <Route path={'/bases/:url'} ruBreadcrumbsMapper={sportBcParser} ruTitleMapper={sportTitleParse} component={BaseInterface}/>

                        <Route exact path={'/adventures/view/:url/:arrival_url'} ruBreadcrumbsMapper={adventureBcParser} ruTitleMapper={adventureTitleParser} component={Arrival}/>
                        <Route exact path={'/adventures/view/:url'} ruBreadcrumbsMapper={adventureBcParser} ruTitleMapper={adventureTitleParser} component={AdventureView}/>
                        <Route path={'/adventures/:type/edit/:url'} ruBreadcrumbsMapper={adventureBcParser} ruTitleMapper={adventureTitleParser} component={AdventureEdit}/>
                        <Route path={'/adventures/:type/edit/'} ruBreadcrumbsMapper={adventureBcParser} ruTitleMapper={adventureTitleParser} component={AdventureEdit}/>
                        <Route path={'/adventures/:type'} ruBreadcrumbsMapper={adventureBcParser} ruTitleMapper={adventureTitleParser} component={AdventuresList}/>

                        <Route exact path={'/camps/view/:url/:arrival_url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.camps)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.camps)} component={props => <Arrival is_camp {...props} />} />
                        <Route exact path={'/camps/view/:url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.camps)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.camps)} component={props => <AdventureView is_camp {...props} />} />
                        <Route path={'/camps/:type/edit/:url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.camps)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.camps)} component={props => <AdventureEdit is_camp {...props} />} />
                        <Route path={'/camps/:type/edit/'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.camps)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.camps)} component={props => <AdventureEdit is_camp {...props} />} />
                        <Route path={'/camps/:type'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.camps)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.camps)} component={props => <AdventuresList is_camp {...props} />} />

                        <Route exact path={'/charters/view/:url/:arrival_url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.charters)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.charters)} component={props => <Arrival {...props} />} />
                        <Route exact path={'/charters/view/:url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.charters)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.charters)} component={props => <AdventureView {...props} />} />
                        <Route path={'/charters/:type/edit/:url'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.charters)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.charters)} component={props => <AdventureEdit is_charter {...props} />} />
                        <Route path={'/charters/:type/edit/'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.charters)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.charters)} component={props => <AdventureEdit is_charter {...props} />} />
                        <Route path={'/charters/:type'} ruBreadcrumbsMapper={path => adventureBcParser(path, adventureComponentTypes.charters)} ruTitleMapper={path => adventureTitleParser(path, adventureComponentTypes.charters)} component={props => <AdventuresList is_charter {...props} />} />

                        <Route path={'/school/theory/group/edit/:url'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={GroupEdit}/>
                        <Route path={'/school/theory/group/view/:url'}  ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={GroupView}/>
                        <Route path={'/school/theory'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={GroupsList}/>

                        <Route path={'/school/lectures/group/edit/:url'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={GroupEdit}/>
                        <Route path={'/school/lectures/group/view/:url'}  ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={GroupView}/>
                        <Route path={'/school/lectures'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={LecturesList}/>

                        <Route exact path={'/school/practices/view/:url/:arrival_url'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={Arrival}/>
                        <Route exact path={'/school/practices/view/:url'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={AdventureView}/>
                        <Route path={'/school/practices/edit/:url'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={AdventureEdit}/>
                        <Route path={'/school/practices/edit/'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={AdventureEdit}/>
                        <Route path={'/school/practices/'} ruBreadcrumbsMapper={schoolBcParser} ruTitleMapper={schoolTitleParser} component={Practices}/>

                        <Route path={'/'} ruBreadcrumbsMapper={dashboardBcParser} ruTitleMapper={dashboardTitleParser} component={Dashboard}/>
                    </Switch>
                    </QueryParamProvider>
                </Layout>
            </Router>
            <ToastContainer hideProgressBar style={toastStyle}/>
          </FrontendRulesProvider>
        </GlobalStateProvider>
    );
}

export default App;
