import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../../directories/DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";



const Areas = (props) => {
    return <DirectoryTemplate
        fields={[
            field(t('constructor.areas.name'), true, "name"),
            field(t('constructor.areas.description'), true, "description"),
        ]}
        editable
        deletable
        entityName={'areas'}
        EditModalChildren={EditModalChildren}
        permissions={getPermissions(ENTITIES.AREAS)}
        isNameFieldSearch
        searchPlaceholder={t('constructor.directoryTemplate.search.areas')}
        {...props}
    />
}

export default Areas