import React from 'react';
import {CardText, Col, Label, Row} from "reactstrap";
import PropTypes from 'prop-types';
import AdventureCard from "../../AdventureCard";
import {CopyLink} from "../../../generic/buttons";
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {t} from "i18next";


const MainInfo = ({state}) => {
    let copyLink;

    if (state.is_school) {
      copyLink = `${BOOKING_URL}/school/practice/${state.url}/`;
    } else if (state.is_charter) {
      copyLink = `${BOOKING_URL}/charter/${state.url}/`;
    } else {
      copyLink = `${BOOKING_URL}/travel/${state.url}/`;
    }

    return <Row>
        <Col xs={12}>
            <Row className={'mb-3'}>
                <Col>
                    <div className={'display-4'}>
                        {t('adventures.mainInfo.brieflyAbout')} {state.is_school
                            ? t('adventures.mainInfo.practice')
                            : t('adventures.mainInfo.travel')}
                    </div>
                    <Label for={'name'} className={'text-secondary'}>/{state.url}</Label>
                    <CopyLink href={copyLink} showLink={false}/>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={{size: 6, offset: 2}}>
                    <AdventureCard adventure={state} height={'380px'}>
                        {/*//TODO добавить в постмвп*/}
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <h5>Длительность:</h5>*/}
                        {/*    </Col>*/}
                        {/*    <Col>7 дней</Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col>
                                <h5>{t('adventures.mainInfo.basePrice')}</h5>
                            </Col>
                            <Col>{state.price_value} {state.price_currency}</Col>
                        </Row>
                        <CardText className={'mt-3'}>
                            {state.comment}
                        </CardText>
                    </AdventureCard>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default MainInfo;

MainInfo.propTypes = {
    state: PropTypes.object.isRequired,
}