import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "reactstrap";

const AsyncButton = ({children, onClick, disabled, ...props}) => {

    const [isLoading, setLoading] = useState(false);

    async function onClickHandler() {
        if(isLoading) return;
        setLoading(true);
        try {
            await onClick();
        } finally {
            setLoading(false);
        }
    }

    return <Button onClick={onClickHandler} disabled={disabled || isLoading} {...props}>
        {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/>}
        {children}
    </Button>
}

AsyncButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default AsyncButton;