import React from 'react';
import {Col, Label, Row} from "reactstrap";
import {DatePicker} from "./index";
import PropTypes from "prop-types";

const RangedDatePicker = ({daysFilter, setDaysFilter, size='sm', classNames='', dateFormat='YYYY.MM.DD', disableFrom=false, disableTo=false}) => {
    const _setDaysFilter = (type, value) => type === 'from'
        ? setDaysFilter(filter => ({...filter, from: value}))
        : setDaysFilter(filter => ({...filter, to: value}))
    return (
        <Row form>
            <Col xs={6} sm={5}>
                <DatePicker onDateChange={(val) => _setDaysFilter('from', val)} placeholder={'2021.04.01'}
                            selectedDay={daysFilter.from || ''} classNames={`form-control-${size} ${classNames}`}
                            dateFormat={dateFormat}
                            disabled={disableFrom}
                />
            </Col>
            <Label size={'sm'} className={'col-2 pb-2 pr-2 pl-2 d-none d-sm-flex justify-content-center'}> — </Label>
            <Col xs={6}  sm={5}>
                <DatePicker
                    onDateChange={(val) => _setDaysFilter('to', val)} placeholder={'2022.04.01'}
                    selectedDay={daysFilter.to || ''} classNames={`form-control-${size} ${classNames}`}
                    dateFormat={dateFormat}
                    disabled={disableTo}
                />
            </Col>
        </Row>
    )
}

RangedDatePicker.propTypes = {
    daysFilter: PropTypes.object.isRequired,
    setDaysFilter: PropTypes.func.isRequired
}

export default RangedDatePicker;