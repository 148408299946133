import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Col, FormGroup, Label} from "reactstrap";
import {daysAbbr} from "../../../../../lib/utils/format";
import {t} from "i18next";

const Schedule = ({onCheckboxBtnClick, cSelected, entity}) => {
    return (
        <Col xs={12} md={8}>
            <FormGroup>
                <Label>
                    {t('school.theory.editModalChildren.schedule')}
                </Label>
                <div>
                    <ButtonGroup>
                        {[1,2,3,4,5].map(day =>
                            <Button color="primary" key={day} onClick={() => onCheckboxBtnClick(day)}
                                    active={cSelected.includes(day) && !entity.is_intensive}  size={'sm'}
                                    outline className={'btn-rounded'} disabled={entity.is_intensive}>
                                {daysAbbr[day]}
                            </Button>)}
                        {[6,7].map(day =>
                            <Button color="primary" onClick={() => onCheckboxBtnClick(day)} key={day}
                                    active={cSelected.includes(day) || entity.is_intensive} size={'sm'}
                                    outline className={'btn-rounded'} >
                                {daysAbbr[day]}
                            </Button>)}
                    </ButtonGroup>
                </div>
            </FormGroup>
        </Col>
    );
};

Schedule.propTypes = {
    onCheckboxBtnClick: PropTypes.func.isRequired,
    cSelected: PropTypes.array.isRequired,
    entity: PropTypes.object.isRequired
};

export default Schedule;