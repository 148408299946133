import { useInstructorsApi } from '../../../../lib/api/instructors';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useEffect, useState } from 'react';

const AsyncInstructorSelect = ({ slot }) => {
  const [instructorsState, setInstructorsState] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const { getAvailableInstructorsForSlot, attachInstructor, detachInstructor } = useInstructorsApi();

  useEffect(() => {
    setInstructorsState(slot.instructors_ids.map(instructorSelectOptions));
  }, [slot.instructors_ids.length]);

  const instructorSelectOptions = (instructor) => {
    const value = instructor.value || instructor.id;
    const label = instructor.label || (instructor.first_name + " " + instructor.last_name);
    return ({ value, label });
  }

  const onLoadInstructors = async (search) => {
    return getAvailableInstructorsForSlot(slot.start_time, slot.end_time, slot.start_date, slot.event_id.id, search).then(res => {
      return {
        options: res?.map(instructorSelectOptions),
        hasMore: false,
      }
    })
  }

  const onChange = (value, opts) => {
    setIsDisabled(true);

    try {
      if (opts.action === 'remove-value') {
        const id = opts.removedValue.value;

        const index = instructorsState.findIndex(i => i.value === id);

        if (index >= 0) {
          detachInstructor({ ...slot }, opts.removedValue.value).then(() => {
            slot.instructors_ids = [
              ...slot.instructors_ids.slice(0, index),
              ...slot.instructors_ids.slice(index + 1),
            ];

            setInstructorsState([
              ...instructorsState.slice(0, index),
              ...instructorsState.slice(index + 1),
            ]);

            setIsDisabled(false);
          });
        }
      }

      if (opts.action === 'select-option') {
        attachInstructor({ ...slot }, opts.option.value).then(() => {
          slot.instructors_ids = [...slot.instructors_ids, { id: opts.option.value, label: opts.option.label }];
          setInstructorsState([...instructorsState, opts.option]);

          setIsDisabled(false);
        });
      }
    } catch (e) {
      setIsDisabled(false);
    }
  }

  return (
    <AsyncPaginate
      value={instructorsState}
      loadOptions={onLoadInstructors}
      isMulti
      closeMenuOnSelect={false}
      onChange={onChange}
      isClearable={false}
      isDisabled={isDisabled}
    />
  )
}

export default AsyncInstructorSelect;