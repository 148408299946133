import dayjs from "dayjs";
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {t} from "i18next";
import ruLocale from 'dayjs/locale/ru';
import enLocale from 'dayjs/locale/en';

dayjs.extend(customParseFormat)

dayjs.locale('en');

export const ISO_DATE = 'YYYY-MM-DD';
export const CLIENT_FORMAT_DATE = 'DD.MM.YYYY';

export const isoDateToPickerDate = d => d ? dayjs(d, 'YYYY-MM-DD').format("DD.MM.YYYY"): ''

export const datetimeFull = d => d ? dayjs(d).format("YYYY.MM.DD HH:mm:ss") : "";

export const datetime = d => d ? dayjs(d).format("YYYY.MM.DD HH:mm") : "";

export const dateFull = d => d ? dayjs(d).format("DD/MM/YY") : "";

export const date = d => d ? dayjs(d).format("MMM DD") : "";

export const shortDate = d => d ? dayjs(d).format("DD/MM") : "";

export const year = d => d ? dayjs(d).format("MMM YYYY") : "";

export const time = d => d ? dayjs(d).format("HH:mm") : "";

export const fullTimeToShort = d => d ? dayjs(d, 'HH:mm:ss').format("HH:mm") : "";

export const number = (str) => {
    if(!str || str === "") return undefined;
    const parsed = parseInt(str);
    if(isNaN(parsed)) return undefined;
    return parsed;
}

export const daysAbbr = ['', t('utils.monday'), t('utils.tuesday'), t('utils.wednesday'), t('utils.thursday'),
    t('utils.friday'), <span style={{color: 'red'}}>{t('utils.saturday')}</span>,
    <span style={{color: 'red'}}>{t('utils.sunday')}</span>]

const monthLocale = {
    en: enLocale,
    ru: ruLocale
}

export const getStartDayAndMonth = (startDate, language) =>
    dayjs(startDate).locale(monthLocale[language]).format('DD MMMM');
export const getStartWeekDay = (startDate) => daysAbbr[dayjs(startDate).isoWeekday()];