import { Alert } from 'reactstrap';
import { t } from 'i18next';

const CreationAlert = () => {
  return (
    <Alert color="danger" className='ml-2'>
      {t('globalSales.creationAlert')}
    </Alert>
  )
}

export default CreationAlert;