import DirectoryTemplate from '../DirectoryTemplate';
import { field } from '../../generic/tables';
import { t } from 'i18next';
import Modal from './Modal';
import { ENTITIES, getPermissions } from '../../../lib/utils/hooks/useRoleSystem';

const TransactionStatus = (props) => <DirectoryTemplate
  fields={[
    field(t('directories.transactionStatus.title'), true, "title"),
    field(t('directories.transactionStatus.type'), true, "type"),
  ]}
  entityName='transaction-statuses'
  EditModalChildren={Modal}
  editable
  permissions={getPermissions(ENTITIES.TRANSACTION_STATUS)}
  {...props}
/>

export default TransactionStatus;