import { useState } from 'react';
import EditModalChildren from './EditModalChildren';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import utc from 'dayjs/plugin/utc';
import dayJs from 'dayjs';
import { delta } from './helpers';
import CreationAlert from './CreationAlert';
import useGlobalSalesModel from './model';
dayJs.extend(utc);

const GlobalSales = ({ entityName, state }) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur'});

  const {
    handleInput,
    triggerModal,
    onCreateSale,
    onEditSale,
    createOrUpdate,
    setFormState,

    isPast,
    isCreateAvailable,
    isModalOpen,
    stateEntity,
    formState,
  } = useGlobalSalesModel({ entityName, state });

  const onSubmit = handleSubmit(createOrUpdate);

  return <Row>
    <Modal isOpen={isModalOpen} toggle={triggerModal} className={'edit-modal'} size='sm'>
      <ModalHeader>{(stateEntity.id ? t('generic.editModal.edit') : t('generic.editModal.create'))}</ModalHeader>
      <ModalBody>
        {EditModalChildren({ entity: formState, handleInput, control, errors, setEntity: setFormState, state })}
      </ModalBody>
      <ModalFooter>
        <div>
          <Row>
            <Col>
              <Button color="primary" onClick={onSubmit}>
                {t('generic.okButton')}
              </Button>
            </Col>
            <Col>
              <Button color="secondary" outline onClick={triggerModal}>
                {t('generic.cancelButton')}
              </Button>
            </Col>
          </Row>
        </div>
      </ModalFooter>
    </Modal>
    <Row className={'mb-3 p-1'}>
      <Col xs="12" className='mb-3'>
        <Button color={'dark'} className={'btn-rounded'} onClick={() => isCreateAvailable ? onCreateSale() : setAlertVisible(!alertVisible)}> {t('common.create')} </Button>
      </Col>
      {alertVisible && <CreationAlert />}
      {!!stateEntity.id && <>
        <Col xs="12" className='mb-3'>
          <Button color={'dark'} className={'btn-rounded'} onClick={onEditSale}> {isPast ? t('common.resume') : t('common.edit')} </Button>
        </Col>
        <Col xs="12">
          <p>{t('globalSales.form.discountedPrice')}: {stateEntity.discounted_price}</p>
          <p>{t('globalSales.form.title')}: {stateEntity.title}</p>
          <p>{t('globalSales.form.startDate')}: {dayJs(`${stateEntity.start_dateonly} ${stateEntity.start_time}`).format('DD.MM.YYYY HH:mm')}</p>
          <p>{t('globalSales.form.endDate')}: {dayJs(`${stateEntity.end_dateonly} ${stateEntity.end_time}`).format('DD.MM.YYYY HH:mm')}</p>
          <p>{t('globalSales.form.count')}: {stateEntity.count}</p>
          <p>{t('globalSales.form.limit')}: {stateEntity.limit || stateEntity.count}</p>
          <p>{t('globalSales.form.sold')}: {delta(stateEntity.limit || stateEntity.count, stateEntity.count)}</p>
        </Col>
      </>}
    </Row>
  </Row>
}

export default GlobalSales;