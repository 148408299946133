const ruBreadcrumbsMapper = (path) => {
    return urls[path];
};

const management = 'management.breadcrumbsAndTitle.management'

const blacklist = 'management.breadcrumbsAndTitle.blacklist'
const create = 'management.breadcrumbsAndTitle.create'
const view = 'management.breadcrumbsAndTitle.view'
const edit = 'management.breadcrumbsAndTitle.edit'
const review = 'management.breadcrumbsAndTitle.review'
const purchases = 'management.breadcrumbsAndTitle.purchases'
const users = 'management.breadcrumbsAndTitle.users'
const instructors = 'management.breadcrumbsAndTitle.instructors'
const promoCodePackages = 'management.breadcrumbsAndTitle.promoCodePackages'
const promoCodes = 'management.breadcrumbsAndTitle.promoCodes'
const certificates = 'management.breadcrumbsAndTitle.certificates'
const frontendRoles = 'management.breadcrumbsAndTitle.frontendRoles'

const urls = {
    '/administration/violations': [
        {ru: management, link: ''},
        {ru: blacklist, link: ''}
    ],
    '/administration/violations/edit/': [
        {ru: management, link: ''},
        {ru: blacklist, link: '/administration/violations/'},
        {ru: create, link: ''}
    ],
    '/administration/violations/edit/:id': [
        {ru: management, link: ''},
        {ru: blacklist, link: '/administration/violations/'},
        {ru: view, link: ''}
    ],

    '/administration/feedback': [
        {ru: management, link: ''},
        {ru: review, link: ''}
    ],
    '/administration/feedback/edit/:id': [
        {ru: management, link: ''},
        {ru: review, link: '/administration/feedback/'},
        {ru: view, link: ''}
    ],

    '/administration/orders/edit/:id': [
        {ru: management, link: ''},
        {ru: purchases, link: '/administration/orders'},
        {ru: edit, link: ''}
    ],
    '/administration/orders/edit/': [
        {ru: management, link: ''},
        {ru: purchases, link: '/administration/orders'},
        {ru: create, link: ''}
    ],
    '/administration/orders': [
        {ru: management, link: ''},
        {ru: purchases, link: ''}
    ],

    '/administration/captains': [
        {ru: management, link: ''},
        {ru: 'management.breadcrumbsAndTitle.captains', link: ''}
    ],

    '/administration/users': [
        {ru: management, link: ''},
        {ru: users, link: ''}
    ],
    '/administration/users/edit/:id': [
        {ru: management, link: ''},
        {ru: users, link: '/administration/users'},
        {ru: edit, link: ''}
    ],
    '/administration/users/edit/': [
        {ru: management, link: ''},
        {ru: users, link: '/administration/users'},
        {ru: create, link: ''}
    ],

    '/administration/instructors/:id': [
        {ru: management, link: ''},
        {ru: instructors, link: '/administration/instructors'},
        {ru: 'management.breadcrumbsAndTitle.editInstructor', link: ''}
    ],
    '/administration/instructors': [
        {ru: management, link: ''},
        {ru: instructors, link: ''}
    ],

    '/administration/promocodes-packages/edit/:id': [
        {ru: management, link: ''},
        {ru: promoCodePackages, link: '/administration/promocodes-packages'},
        {ru: edit, link: ''}
    ],
    '/administration/promocodes-packages/edit': [
        {ru: management, link: ''},
        {ru: promoCodePackages, link: '/administration/promocodes-packages'},
        {ru: create, link: ''}
    ],
    '/administration/promocodes-packages': [
        {ru: management, link: ''},
        {ru: promoCodePackages, link: '/administration/promocodes-packages'}
    ],

    '/administration/promocodes/edit/:id': [
        {ru: management, link: ''},
        {ru: promoCodes, link: '/administration/promocodes'},
        {ru: edit, link: ''}
    ],
    '/administration/promocodes/edit': [
        {ru: management, link: ''},
        {ru: promoCodes, link: '/administration/promocodes'},
        {ru: create, link: ''}
    ],
    '/administration/promocodes': [
        {ru: management, link: ''},
        {ru: promoCodes, link: ''}
    ],

    '/administration/certificates/edit/:id': [
        {ru: management, link: ''},
        {ru: certificates, link: '/administration/certificates'},
        {ru: edit, link: ''}
    ],
    '/administration/certificates/edit': [
        {ru: management, link: ''},
        {ru: certificates, link: '/administration/certificates'},
        {ru: create, link: ''}
    ],
    '/administration/certificates': [
        {ru: management, link: ''},
        {ru: certificates, link: ''}
    ],

    '/administration/exports': [
        {ru: management, link: ''},
        {ru: 'management.breadcrumbsAndTitle.export', link: ''}
    ],
    '/administration/links-generator': [
        {ru: management, link: ''},
        {ru: 'management.breadcrumbsAndTitle.linksGenerator', link: ''}
    ],
    '/administration/materials': [
        {ru: management, link: ''},
        {ru: 'management.breadcrumbsAndTitle.materials', link: ''}
    ],
    '/administration/frontend-roles': [
        {ru: management, link: ''},
        {ru: 'management.breadcrumbsAndTitle.frontendRoles', link: ''}
    ],
    '/administration/frontend-roles/edit/:id': [
        {ru: management, link: ''},
        {ru: frontendRoles, link: '/administration/frontend-roles'},
        {ru: edit, link: ''}
    ],
    '/administration/frontend-roles/edit': [
        {ru: management, link: ''},
        {ru: frontendRoles, link: '/administration/frontend-roles'},
        {ru: create, link: ''}
    ],
};

export default ruBreadcrumbsMapper;