import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types'
import {TableCard} from "../../generic/cards";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {Col, FormGroup, Input, Label} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {RangedDatePicker, TextSearch} from "../../generic/fields";
import {datetime} from "../../../lib/utils/format";
import {openInNewTab} from "../../../lib/utils/helper";
import {FEEDBACK_PRODUCT_LIST_LANG_ADAPTER} from "sv-common/constants/feedback";
import dayjs from "dayjs";
import GenericHrefGenerator from "../../common/hrefGenerators/GenericHrefGenerator";
import {ExportFunButton} from "../../generic/buttons";
import RateBar from "./RateBar";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {t} from "i18next";
import i18n from "../../../lib/utils/i18n";

const FeedbackList = withBreadcrumbs(({}) => {
    const {findAndCountEntries, exportEntries} = useDefaultEndpoints('feedbacks');
    const now = dayjs();
    const [productFilter, setProductFilter] = useState('');
    const [rateFrom, setRateFrom] = useState(undefined);
    const [rateTo, setRateTo] = useState(undefined);
    const [daysFilter, setDaysFilter] = useState({
        from: now.toDate(),
        to: now.add(2, 'years').toDate()
    });
    let history = useHistory();
    const { language } = i18n;

    const filters = {
        rating_gte: rateFrom, rating_lte: rateTo,  product_type: productFilter,
        created_at_gte: dayjs(daysFilter.from).startOf('day').toDate(),
        created_at_lte: dayjs(daysFilter.to).startOf('day').toDate(),
    }

    const onMiddleClick = (res) => openInNewTab('/administration/feedback/edit/' + res.id)
    const exportButton = useCallback((dataParameters) => {
        return <ExportFunButton dataParameters={{...dataParameters}} exportFun={exportEntries}/>
    }, [])
    return <>
        <TableCard>
            <div className={'row justify-content-between mb-0 pr-0 mr-0 align-items-end'}>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.feedback.evaluationFrom')}</Label>
                        <TextSearch placeholder={'4'} value={rateFrom || ''}
                                    onSearch={v => setRateFrom(() => v)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.feedback.evaluationTo')}</Label>
                        <TextSearch placeholder={'2'} value={rateTo || ''}
                                    onSearch={v => setRateTo(() => v)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label>{t('management.feedback.date')}</Label>
                        <FormGroup>
                            <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} size={'md'}/>
                        </FormGroup>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.feedback.productType')}</Label>
                        <Input type={'select'} className="form-control" value={productFilter || ''} bsSize={'sm'}
                               onChange={(e) => setProductFilter(() => e.target.value)}>
                            <option key={"user-role-none"} value=''>{t('management.feedback.chooseProduct')}</option>
                            {FEEDBACK_PRODUCT_LIST_LANG_ADAPTER[language].map(p => <option key={p.key} value={p.key}>{p.value}</option>)}
                        </Input>
                    </FormGroup>
                </Col>

            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.feedback.evaluation'), true, "rating", RateBar),
                           field(t('management.feedback.firstLastName'), false, "client_id.first_name",
                                   res => <small>{res.client_id?.first_name || ''} {res.client_id?.last_name || ''}</small>),
                           field(t('management.feedback.mail'), true, "client_id.email"),
                           field(t('management.feedback.phone'), true, "client_id.phone"),
                           field(t('management.feedback.product'), false, "product_name",
                               res => <GenericHrefGenerator order={res.order_id} linkName={res.product_name}
                                                            productUrl={res.product_url}/>),
                           field(t('management.feedback.comment'), false, "comment", res => res.comment?.slice(0, 55) + '...'),
                           field(t('management.feedback.added'), true, "created_at", res => datetime(res.created_at)),
                       ]}
                       sortField="created_at"
                       sortDir="desc"
                       name="feedback-list"
                       onClick={feedback =>  history.push('/administration/feedback/edit/' + feedback.id)}
                       onMiddleClick={feedback => onMiddleClick(feedback)}
                       tableButtons={exportButton}
            />
        </TableCard>
    </>
})

FeedbackList.propTypes = {
    onClick: PropTypes.func,
    role: PropTypes.string
}

export default FeedbackList;

