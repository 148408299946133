import React from 'react';
import {useApi} from "../../../lib/api/useApi";
import {Link} from "react-router-dom";
import HeaderUser from "./HeaderUser";
import {FaAlignJustify} from "react-icons/all";
import svLogo from '../../../styles/assets/images/sv/sv_logo-black.svg'
import wrLogo from '../../../styles/assets/images/sv/WRLogoBlack.svg'
import {COUNTRY} from "../../../lib/utils/constants";

const Header = ({toggleSidebar}) => {
    const {userLoggedIn} = useApi();
    const logo = COUNTRY === 'RU' ? svLogo : wrLogo

    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <Link to={'/'} className="navbar-brand brand-logo">
                    <img src={logo}
                         style={{fill: '#0000a0'}}
                         alt="sila_vetra"/></Link>
                <Link to={'/'} className="navbar-brand brand-logo-mini">
                    <img src={logo} alt="sila_vetra"/>
                </Link>
            </div>
            <div className={'navbar-menu-wrapper d-flex align-items-center justify-content-end justify-content-lg-between'}>
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={toggleSidebar}>
                    <FaAlignJustify/>
                </button>
                {
                    userLoggedIn &&
                    <HeaderUser toggleSmNavbar={toggleSidebar}/>
                }
            </div>
        </nav>
    );
}

export default Header;