import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import PropTypes from "prop-types";
import {AiOutlineProfile} from 'react-icons/ai'
import {useHistory} from 'react-router-dom';
import {t} from "i18next";

const EditInstructor = ({state}) => {
    let history = useHistory();

    const [client, setClient] = useState({});

    useEffect(() => {
        state.client_id && setClient(() => state.client_id)
    }, [state.client_id?.id])


    return (
        <Form>
            {state.id && <Row>
                <Col md={11}>
                    <FormGroup>
                        <Label>
                            {t('management.instructors.instructor.instructor')}
                        </Label>
                        <Input type="text" id={'client_id'} name={'client_id'} disabled={true}
                               value={client.first_name + ' ' + client.last_name + ' - ' + client.role_name}/>
                    </FormGroup>
                </Col>
                <Col>
                    <AiOutlineProfile size={'100%'} title={t('management.instructors.instructor.goToClientRecord')}
                                      onClick={() => history.push('/administration/users/edit/' + state.client_id.id)}/>
                </Col>
            </Row>}
        </Form>

    )
}

EditInstructor.propTypes = {
    state: PropTypes.object.isRequired,
}

export default EditInstructor;
