import React, {useEffect, useReducer} from 'react';
import {TableCard} from "../../../../generic/cards";
import {Col, Row, Button} from "reactstrap";
import {useParams, useHistory} from 'react-router-dom';
import {initialState, reducer} from "./reducer";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../../generic/loader";
import {Controller, useForm} from "react-hook-form";
import {FieldWithValidation} from "../../../../generic/fields";
import EasyEdit from "react-easy-edit";
import {FaCheck, FaTimes} from "react-icons/fa";
import TechInfo from "./GroupBlocks/TechInfo";
import AddressBlock from "./GroupBlocks/AddressBlock";
import SwitchBlock from "./GroupBlocks/SwitchBlock";
import {Promocodes} from "../../../../bases/events/event/NewEventBlocks";
import AdditionalInfo from "./GroupBlocks/AdditionalInfo";
import {ElementWithPermissions} from "../../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const Group = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory();
    const {url} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState)
    const {getEntries, editEntry} = useDefaultEndpoints('groups')
    const permissions = useRoleSystem(state.type === 'theory' ? ENTITIES.GROUPS_THEORY: ENTITIES.GROUPS_LECTURES)
    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});

    useEffect(() => {
        if (url) {
            getEntries({url}).then(res => {
                dispatch({type: 'setForm', payload: {...res[0]}})
                const mapper = {
                    url: {ru: res[0].name, en: res[0].url }
                }
                createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })
        } else {
            dispatch({type: 'refreshFields'})
            dispatch({type: 'setLoading', payload: false})
        }

    }, [url])

    const onSubmit = handleSubmit(() => {
        if (state.check_id) {
          state.check_id = parseInt(state.check_id, 10);
        }
        const editData = {...state};
        delete editData.orders_ids;

        editData.id && editEntry(editData.id, {...editData})
            .then(() => history.goBack())
    })

    return state.loading
        ? <Loader/>
        : <TableCard>
            <Row className={'mb-5 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={state.name}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors}
                                                 name={name} isRequired
                            >
                                <div className={'display-3' + (invalid ? ' is-invalid ' : '')}
                                >
                                    <EasyEdit
                                        type="text"
                                        onSave={(val) => {
                                            onChange(val)
                                            dispatch({type: 'setForm', payload: {name: val}})
                                        }}
                                        value={value}
                                        onBlur={onBlur}
                                        placeholder={t('school.theory.edit.travelName')}
                                        saveButtonLabel={<FaCheck fill={'success'} stroke={'success'}/>}
                                        cancelButtonLabel={<FaTimes color={'secondary'}/>}
                                    />
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </Col>
                <Col className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('school.theory.edit.save')}
                        </Button>
                    </ElementWithPermissions>

                </Col>
                <Col className={'col-auto'}>
                    <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                            onClick={() => history.goBack()}>
                        {t('school.theory.edit.cancel')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4 className={'display-4'}>{t('school.theory.edit.groupMain')}</h4>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <TechInfo state={state} dispatch={dispatch} control={control} errors={errors}/>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <AddressBlock state={state} dispatch={dispatch} control={control} errors={errors}/>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <SwitchBlock state={state} dispatch={dispatch}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <AdditionalInfo state={state} dispatch={dispatch}/>
                </Col>
                <Col xs={12} md={{size:6, offset: 2}}>
                    <Promocodes dispatch={dispatch} state={state} productType={'groups'} disabled={!permissions.edit}/>
                </Col>
            </Row>
        </TableCard>
}

export default Group;