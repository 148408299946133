import {requiredRule as required} from "../../components/common/reactHookFormsFields/rules";
import {COUNTRY} from "./constants";
export const trimOnChange = e => e.target.value = e.target.value.trim()
export const exportStatuses = {
    Processing: "processing",
    Ready: "ready",
    Error: "error",
    Deleted: "deleted"
}

export const openInNewTab = url => {
    const absoluteUrl = new URL(url, window.location.href)
    const newWindow = window.open(absoluteUrl.href, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const requiredRule = required;

export const regexes = {
    phone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/,
};

export const getCountryCode = () => {
    if (COUNTRY === 'EN') {
        return 'ae'
    } else {
        return 'ru'
    }
}