import Select from 'react-select';
import React, { useState } from 'react';
import ViewModal from '../theory/ViewTeacherModal';
import { Button, Col, FormGroup, Input } from 'reactstrap';
import { t } from 'i18next';

const TeachersInput = ({ setEntity, entity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTeachersSelect, setIsTeachersSelect] = useState(false);

  const remove = (value) => {
    const teacherIndex = entity?.teacher_ids?.findIndex(i => i.id === value);

    let newEntity;

    if (teacherIndex >= 0) {
      newEntity = ({
        ...entity,
        teacher_ids: [
          ...entity.teacher_ids.slice(0, teacherIndex),
          ...entity.teacher_ids.slice(teacherIndex + 1),
        ]
      });
    } else {
      newEntity = entity;
    }

    setEntity(newEntity);
  }

  const toggle = (isTeachersOpen) => {
    setIsTeachersSelect(isTeachersOpen);
    setIsOpen(isOpen => !isOpen);
  }

  const chooseTeacher = (res) => {
    if (isTeachersSelect) {
      setEntity({...entity, teacher_ids: [...(entity.teacher_ids || []), res.client ]});
    } else {
      setEntity({...entity, teacher_id: res.client});
    }
    toggle();
  };

  return (
    <>
      {isOpen && <ViewModal triggerModal={toggle} name={'select_teacher'} id={'select_teacher'} isOpened={isOpen}
                            onClick={chooseTeacher}/>}
      <Col xs={12}>
        <FormGroup>
          <Button color='primary' className={'btn-rounded pb-1 pt-1 mb-1'} outline onClick={() => toggle(false)}>
            {t('school.theory.addressBlock.pickTeacher')}
          </Button>
          <Input type={'text'} value={`${entity.teacher_id?.first_name || ''} ${entity.teacher_id?.last_name || ''}`}
                 disabled/>
        </FormGroup>
      </Col>
      <Col xs={12}>
        <FormGroup>
          <Button color='primary' size={'sm'} className={'btn-rounded pb-1 pt-1 mb-1'} outline
                  onClick={() => toggle(true)}>
            {t('school.theory.editModalChildren.pickTeachers')}
          </Button>
          <Select
            isDisabled={false}
            name={'teacher_ids'}
            isMulti
            menuPlacement={'bottom'}
            value={entity?.teacher_ids?.map(teacher => ({ value: teacher.id, label: `${teacher.first_name || ''} ${teacher.last_name || ''}` }))}
            placeholder={false}
            onChange={(e, a) => {
              a.action === 'remove-value' && remove(a.removedValue.value);
            }}
            isClearable={false}
            menuIsOpen={false}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            isSearchable={false}
          />
        </FormGroup>
      </Col>
    </>
  )
}

export default TeachersInput;