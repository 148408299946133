// eslint-disable-next-line
import React, {useContext} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import {emptyGlobalState, GlobalState} from "./globalState";
import {t} from "i18next";

const http = rateLimit(axios.create(), {maxRequests: 6, perMilliseconds: 1000});
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:1337";
export const BOOKING_API_URL = process.env.REACT_APP_BOOKING_URL ? `${process.env.REACT_APP_BOOKING_URL}/api` : "http://localhost:1337";
export const BOOKING_URL = process.env.REACT_APP_BOOKING_URL || "http://localhost:3001";
export const DOWNLOAD_LINK = data => `${API_URL}/exports/download/${data.id}/${data.filename}`;

export const generateDownloadLink = (entity, data) => `${API_URL}/${entity}/download/${data.id}/${data.filename}`;

const translateError = err => {
    switch (err){
        case 'Not Found':
            return t('lib.notFound')
        case 'Method Not Allowed':
            return t('methodNotAllowed')
        case 'ValidationError':
            return t('lib.validationError')
        case 'Duplicate Entry':
            return t('lib.duplicateEntry')
        case 'Duplicate entry':
            return t('lib.duplicateEntry')
        default:
            return err;
    }

}

export const useApi = () => {
    const [state, setState] = useContext(GlobalState);

    function updateAccessToken() {
        return http.post(
            `${API_URL}/auth/refreshToken`,
            {token: state.userRefreshToken},
            {},
        )
            .then((response) => {
                const newState = {
                    ...state,
                    userAccessToken: response.data.jwt,
                    userRefreshToken: response.data.refresh,
                };

                setState(newState);

                return newState;
            })
            .catch((error) => {
                setState(emptyGlobalState);
                toast('Произошла ошибка при авторизации! Попробуйте перезайти.', { type: 'error' });
                throw error;
            });
    }

    function authorize(identifier, password) {
        return http.post(
            `${API_URL}/auth/local`,
            {identifier, password}
        )
            .then((response) => {
                const newState = {
                    ...state,
                    userAccessToken: response.data.jwt,
                    userRefreshToken: response.data.refresh,
                    userEmail: response.data.user.email,
                    userName: response.data.user.username,
                    userId: response.data.user.id,
                    userRole: response.data.user.role.name
                };
                setState(newState);
                return newState;
            })
            .catch((error) => {
                setState(emptyGlobalState);
                toast('Что-то не так с запросом. Попробуйте еще раз', {type: 'error'})
                throw error;
            });
    }

    // URL should start with '/'
    // Does NOT check user identity
    function rawRequest(method, url, data = {}) {
        const methodLower = method.toLowerCase();
        return http({
            method: method.toLowerCase(),
            url: prepareUrl(url),
            params: methodLower === 'get' ? data : {},
            data: methodLower === 'get' ? {} : data,
        })
            .then((response) => response.data)
            .catch((error) => {
                // TODO обработка ошибок generic, может в рамках другой задачи
                throw error
            });
    }

    /**
     * Generated absolute url from relative path
     * @param path
     * @returns {string}
     */
    function prepareUrl(path) {
        return `${API_URL}${path}`;
    }

    // URL should start with '/'
    // DOES check user identity
    function request(method, url, data = {}, stateToUse = null, isRetry = false) {
        const theState = stateToUse || state;
        const methodLower = method.toLowerCase();
        const headers = {};
        headers.Authorization = `Bearer ${theState.userAccessToken}`;

        return http({
            method: methodLower,
            url: prepareUrl(url),
            params: methodLower === 'get' ? data : {},
            data: methodLower === 'get' ? {} : data,
            headers,
        })
            .then((response) => response.data)
            .catch((error) => {
                if (error.response) {
                    // Internal server error
                    if (error.response.status === 500) {
                        toast('Что-то пошло не так. Попробуйте поменять данные или вернуться позднее', {type: 'error'})
                        throw error;
                    }

                    if (error.response.status === 401) {
                        if (!isRetry) {
                            return updateAccessToken()
                                .then((newState) =>
                                    request(method, url, data, {...newState}, true)
                                );
                        } else {
                            logout();
                        }
                    }

                    // Forbidden, Not Found.
                    if (error.response.data) {
                        const msg = error.response.data.message || error.response.data
                        toast(translateError(msg), {type: 'error'})
                        throw error;
                    }

                    // Usual errors
                    const {data: {errors}} = error.response.data
                    for (const e in errors) {
                        errors[e].forEach(msg => toast(msg, {type: 'error'}))
                    }
                    throw error;
                }
                toast('Что-то не так с запросом. Попробуйте позднее', {type: 'error'})
                throw error;
            });
    }

    function logout() {
        setState(emptyGlobalState);
    }

    function updateGlobalState(data) {
        setState({
            ...state,
            ...data,
        });
    }

    return {
        logout,

        request,
        authorize,
        rawRequest,
        updateGlobalState,
        prepareUrl,

        userId: state.userId,
        userEmail: state.userEmail,
        userName: state.userName,
        userLoggedIn: !!state.userId,
        userRole: state.userRole
    };
};
