import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import {useInstructorsApi} from "../../../../lib/api/instructors";
import Select from "react-select";
import RangedSelectionDatePicker from "../../../generic/fields/RangedSelectionDatePicker";
import {features} from "../../../../lib/api/globalState";
import {TimePickerRHF} from "../../../common/reactHookFormsFields";
import dayjs from "dayjs";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

const EditModalChildren = ({entity, setEntity, control, errors}) => {
    const [daysFilter, setDaysFilter] = useState({
        from: new Date(),
        to: new Date()
    })
    const [selectedInstructors, setSelectedInstructors] = useState([]);
    const [instructorsOptions, setInstructorsOptions] = useState([]);

    const {getAvailableInstructorsForSlot} = useInstructorsApi();

    useEffect(() => {
        setEntity(entity => ({...entity, start_date: daysFilter.from, end_date: daysFilter.to}))
    }, [daysFilter])

    useEffect(() => {
        if (!(entity.start_time && entity.end_time && entity.start_date)) return;

        getAvailableInstructorsForSlot(entity.start_time, entity.end_time, entity.start_date, entity.event_id)
            .then((res) => {
                const options = res ? res.map(instructor => ({
                    label: instructor.first_name + " " + instructor.last_name,
                    value: instructor.id,
                })) : [];
                setInstructorsOptions(options)
                setSelectedInstructors(options)
            })
    }, [entity.start_time, entity.end_time, entity.start_date])

    useEffect(() => {
        if (!selectedInstructors) {
            entity.instructors_ids = [];
            return;
        }
        entity.instructors_ids = selectedInstructors.map(el => el.value);
    }, [selectedInstructors])

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: value}))
    }
    const handleTimeChange = (e) => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: dayjs(value, 'HH:mm').format('HH:mm:ss.SSS')}))
        return value;
    }
    return <Form>
        <Row>
            <Col xs={12} md={6}>
                <FormGroup>
                    <Label for="class">{t('bases.base.repetitionRate')}</Label>
                    <RangedSelectionDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"} numberOfMonths={2} />
                </FormGroup>
            </Col>
            <Col xs={3}>
                {features['SV-2094']
                    ? <TimePickerRHF setEntity={setEntity} control={control} errors={errors} label={t('bases.base.startDate')}
                                     isRequired
                                     defaultValue={entity.start_time || ''} name="start_time"/>
                    : <FormGroup>
                        <Controller
                            control={control}
                            name="start_time"
                            defaultValue={entity.start_time || ''}
                            rules={{required: t('inputs.validation.required')}}
                            render={(
                                {value, onBlur, name, ref, onChange},
                                {invalid}
                            ) =>
                                <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.startDate')}
                                                     name={name} isRequired={true}
                                                     type="time" onBlur={onBlur} value={value}
                                                     onChange={e => {
                                                         onChange(handleTimeChange(e));
                                                     }}
                                />
                            }
                        />
                    </FormGroup>
                }
            </Col>
            <Col xs={3}>
                <TimePickerRHF setEntity={setEntity} control={control} errors={errors} label={t('bases.base.endDate')}
                               isRequired defaultValue={entity.end_time || ''} name="end_time"/>
            </Col>
            <Col xs={12} md={4}>
                <Label for="repeat_type">{t('bases.base.repetitionRate')}</Label>
                <Input type="select" name="repeat_type" id="repeat_type"
                       value={entity.repeat_type || ''} onChange={handleInput}>
                    <option value={''}>{t('bases.base.repetitionRate')}</option>
                    <option value={'once'}>{t('bases.base.once')}</option>
                    <option value={'daily'}>{t('bases.base.daily')}</option>
                    <option value={'weekly'}>{t('bases.base.weekly')}</option>
                    <option value={'monthly'}>{t('bases.base.monthly')}</option>
                    <option value={'yearly'}>{t('bases.base.yearly')}</option>
                </Input>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <Col xs={12} md={4}>
                <Label>{t('bases.base.instructors')}</Label>
                <Select
                  isDisabled={!instructorsOptions.length}
                  name="instructors_ids" isMulti options={instructorsOptions}
                  onChange={setSelectedInstructors} menuPlacement={'top'}
                  value={selectedInstructors}
                  placeholder={t("bases.base.pickInstructors")}
                />
              </Col>
            </ElementWithAccessInCountries>
            <Col xs={12} md={4}>
                <Label>{t('bases.base.price')}</Label>
                <Input name={'price_value'} onChange={handleInput}/>
            </Col>

        </Row>
    </Form>
}

export default EditModalChildren;
