import React from 'react'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {handleInputChange} from "../../../lib/utils/handleInputChange";

const EditModalChildren = ({entity, setEntity}) => {
    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: value}))
    }
    return <Form>
        <Row>
            <Col lg={6}>
                <FormGroup>
                    <Label for="value">Опция</Label>
                    <Input type="text" name="value" id="value"
                           placeholder="Бесплатный перелет" value={entity.value || ''} onChange={handleInput}/>
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <Label for="tags">Шаблон</Label>
                    <Input type="text" name="tags" id="tags" placeholder="Камчатка, Питер"
                           value={entity.tags || ''} onChange={handleInput}/>
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
