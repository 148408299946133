import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const MenuLink = ({name, to, icon, location={}}) => {
    const isActive = location.pathname === to;
    return (
        <li className={`nav-item ${isActive ? "active" : ""}`}>
            <Link
                className={`nav-link ${isActive ? "active" : ""}`}
                to={to}>
                <i className={'menu-icon'}>{icon}</i>
                <span className="menu-title">{name}</span>
            </Link>
        </li>
    );
}

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

export default MenuLink;