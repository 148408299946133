import {useDefaultEndpoints} from "./useDefaultEndpoints";
import {useApi} from "./useApi";

export const useLinksApi = () => {
    const {request} = useApi();

    const generateLinkForSport = async (bases, events, boats) => request("post", "/links/generate", {bases, events, productType: "sport", boats});
    const generateLinkForTheory = async (format, cities, types, category) => request("post", "/links/generate", {format, cities, productType: "theory", types, category});
    const generateLinkForEvents = async (events) => request("post", "/links/generate", {productType: "sport-events", events});
    return {
        ...useDefaultEndpoints('links'),
        generateLinkForSport,
        generateLinkForTheory,
        generateLinkForEvents,
    }
};