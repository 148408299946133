import { withBreadcrumbs } from '../../common/hoc/Breadcrumbs';
import { DataTable, field } from '../../generic/tables';
import { useDefaultEndpoints } from '../../../lib/api/useDefaultEndpoints';
import { useState } from 'react';
import { openInNewTab } from '../../../lib/utils/helper';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { TableCard } from '../../generic/cards';
import { ModalConfirmation } from '../../generic/modals';
import { Col } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import RolesSelectField from './RolesSelectField';

const FrontendRoles = withBreadcrumbs(() => {
  const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('frontend-roles');

  const [item, setItem] = useState({});
  const [list, setList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const history = useHistory();

  const toggleModal = () => setModalOpen(isOpen => !isOpen);

  const onMiddleClick = (i) => openInNewTab('/administration/frontend-roles/edit/' + i.id);
  const onClick = (i) => history.push('/administration/frontend-roles/edit/' + i.id);
  const onDeleteConfirm = () => {
    deleteEntry(item.id).then(() => {
      setReload(!reload);
      toggleModal();
    })
  }

  const onDelete = (i) => {
    setItem(i);
    toggleModal();
  }

  const onFindAndCount = async (params) => findAndCountEntries(params).then(i => {
    setList(i.data);
    return i;
  });

  return (
    <TableCard>
      <ModalConfirmation
        onClose={toggleModal}
        onConfirm={onDeleteConfirm}
        isOpen={isModalOpen}
        confirmButtonText={t('common.confirm')}
        text={t('common.deleteAnswer')}
        title={t('common.deleting')}
      />
      <div className={'row justify-content-end mb-0 pr-0 mr-0'}>
        <Col xs={12} md={'auto'}>
          <button className={'btn btn-sm btn-primary btn-rounded btn-block'}
                  onClick={() => history.push('/administration/frontend-roles/edit')}>
            <FaPlus/>
            &nbsp; {t('common.create')}
          </button>
        </Col>
      </div>
      <DataTable
        findAndCount={onFindAndCount}
        onDelete={onDelete}
        reload={reload}
        onMiddleClick={onMiddleClick}
        onClick={onClick}
        name='frontend-roles'
        fields={[
          field(t('common.title'), false, 'title'),
          field(t('management.frontRoles.roles'), false, 'roles', res => {
            return <RolesSelectField key={`${res.id}_${(res.sub_roles || []).length}`} id={res.id} roles={res.sub_roles} allRoles={list} />
          }),
        ]}
      />
    </TableCard>
  )
})

export default FrontendRoles;