import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const UserFieldsTemplates = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.userFieldsTemplates.templateName'), true, "name"),
    ]}
    entityName={'user-fields-templates'}
    EditModalChildren={EditModalChildren}
    editable
    deletable
    permissions={getPermissions(ENTITIES.TEMPLATES)}
    {...props}
/>


export default UserFieldsTemplates;