import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useGlobalConfigApi = () => {
    const {request} = useApi();

    const getGlobalSettings = async () => request('get', '/global-settings/get-config');

    const saveGlobalSettings = async (data) => request('post', '/global-settings/save', {...data});

    return {
        ...useDefaultEndpoints('global-settings'), getGlobalSettings, saveGlobalSettings
    }
};