import React, {useContext, useEffect} from "react";
import PropTypes from 'prop-types';
import {GlobalState} from "../../lib/api/globalState";
import {FaChevronRight} from "react-icons/fa";
import {Link} from "react-router-dom";
import capitalize from 'lodash/capitalize'
import {t} from "i18next";


const Title = (props) => {
    const {icon} = props;
    const [state] = useContext(GlobalState);

    useEffect(() => {
        document.title = state.domTitle
            ? state.domTitle.map(item => item.ru).join(" | ")
            : "Sila Vetra"
    }, [state.domTitle])

    return (
        <div className="row">
            <div className="col-md-12 mb-3">
                <div className="d-flex align-items-baseline mt-2 mt-sm-0">
                    {
                        props.pathname === '/' &&
                        <h2 className="mr-4 mb-0">
                            {t('generic.title')}
                        </h2>
                    }
                    <i className={`fas fa-${icon} mr-1 text-muted`}> {icon} </i>
                    <ol className={'breadcrumb mb-1 pb-1'}>
                        {state.breadcrumbs?.map((item, i) => (
                            <li key={"breadcrumb-" + i}
                                className={`breadcrumb-item`}>
                                <FaChevronRight className="fas fa-chevron-right fa-xs mr-1 text-muted" size={'1rem'}/>
                                {
                                    (i === state.breadcrumbs.length - 1 || !item.link)
                                        ? capitalize(item.ru)
                                        : <Link to={item.link} style={{color: 'black'}}>{capitalize(item.ru)}</Link>
                                }
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );

}

Title.propTypes = {
    title: PropTypes.string,
    to: PropTypes.string,
    icon: PropTypes.object
};

export default Title;