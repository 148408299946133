import React from 'react'
import {Col, Form, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {

    return <Form>
        <Row>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="size"
                        defaultValue={entity.size || ''}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.shirtSizes.sizeNumbers')}
                                                 name={name} isRequired={false}
                                                 type="text" onBlur={onBlur} value={value} placeholder="46"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="tag"
                        defaultValue={entity.tag || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.shirtSizes.sizeLetters')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="xs"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
