import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {FaCheck, FaTimes} from "react-icons/fa";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const UserFields = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.userFields.fieldName'), true, "value"),
        field(t('directories.userFields.required'), true, "is_required",
                res => res.is_required ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>),
        field(t('directories.userFields.optionalToShow'), true, "is_optional",
                res => res.is_optional ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>)
    ]}
    entityName={'user-fields'}
    EditModalChildren={EditModalChildren}
    editable
    deletable
    permissions={getPermissions(ENTITIES.USER_FIELDS)}
    {...props}
/>


export default UserFields;