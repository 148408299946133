import React, {useMemo} from 'react';
import {Card} from "../../generic/cards";
import {routes} from "../../../lib/routes";
import {useApi} from "../../../lib/api/useApi";
import {generateLinks} from "../Dashboard";
import {t} from "i18next";

TravelCard.propTypes = {};

function TravelCard() {
    const {userRole} = useApi();
    const travels = useMemo(() => routes.find(r => t(r.title) === t('routes.adventures')), [])
    return (
       <Card title={t('routes.adventures')}>
           {generateLinks(travels, userRole)}
       </Card>
    );
}

export default TravelCard;