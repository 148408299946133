import React from "react";
import SportLinksGenerator from "./SportLinksGenerator";
import TheoryLinksGenerator from "./TheoryLinksGenerator";
import EventsLinksGenerator from './EventsLinksGenerator';

export const tabList = [
    {
        name: 'management.linksGenerator.sport',
        child: <SportLinksGenerator />,
        url: "sport"
    },
    {
        name: 'management.linksGenerator.events',
        child: <EventsLinksGenerator />,
        url: "sport-events"
    },
    {
        name: 'management.linksGenerator.theory',
        child: <TheoryLinksGenerator />,
        url: "theory"
    }
]