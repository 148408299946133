import React, {useMemo} from 'react';
import {Card} from "../../generic/cards";
import {routes} from "../../../lib/routes";
import {useApi} from "../../../lib/api/useApi";
import {generateLinks} from "../Dashboard";
import {t} from "i18next";

SchoolCard.propTypes = {};

function SchoolCard() {
    const {userRole} = useApi();
    const schools = useMemo(() => routes.find(r => t(r.title) === t('routes.school')), [])
    return (
       <Card title={t('routes.school')}>
           {generateLinks(schools, userRole)}
       </Card>
    );
}

export default SchoolCard;