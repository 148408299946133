import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {Loader} from "../../../generic/loader";
import {t} from "i18next";


const EditModalChildren = ({entity, setEntity}) => {
    const [boatsClasses, setBoatsClasses] = useState([]);
    const {getEntries} = useDefaultEndpoints('boats-classes')
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        getEntries().then(res => {
            setBoatsClasses(() => res);
            setLoading(() => false);
        }).catch(toast.error)
        // eslint-disable-next-line
    }, [])


    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: value}))
    }
    return isLoading ? <Loader/> :
        <Form>
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label for="class">{t('bases.base.boatClass')}</Label>
                        <Input type="select" name="class" id="class"
                               placeholder={t("bases.base.boatClassPlaceholder")} value={entity.class || ''} onChange={handleInput}>
                            <option value={''}>{t('bases.base.pickClass')}</option>
                            {boatsClasses.filter(boatClass => boatClass.is_active).map(boatClass => <option key={boatClass.id}
                                                                   value={boatClass.class}>{boatClass.class}</option>)}
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Label for="amount">{t('bases.base.amount')}</Label>
                        <Input type="text" name="amount" id="amount"
                               placeholder="100500" value={entity.amount || ''} onChange={handleInput}/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
}

export default EditModalChildren;
