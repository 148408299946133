import {COUNTRY} from "../../../../lib/utils/constants";

const initialState = {
    loading: true,
    price_currency: COUNTRY === 'EN' ? 'AED' : 'RUB',
    country: COUNTRY === 'RU' ? 'russia' : 'uae',
    product_type: 'slots',
    type: 'default',
    is_created_manually: true,
    hasPaid: false,
    createInvoice: false,
    sendEmail: false
};

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value.trim ? value.trim() : value};
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'General'
            return {
                ...state,
                activeTab: action.payload
            }

        // Data editing block
        case 'setForm':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload
            }
        case 'setFormData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }
        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};