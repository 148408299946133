import React, {useState} from 'react'
import {TableCard} from "../generic/cards";
import {useDefaultEndpoints} from "../../lib/api/useDefaultEndpoints";
import {DataTable} from "../generic/tables";
import {FaPlus} from 'react-icons/fa'
import { EditModal, ModalConfirmation } from '../generic/modals';
import {Row, Button} from "reactstrap";
import PropTypes from 'prop-types';
import {withBreadcrumbs} from "../common/hoc/Breadcrumbs";
import {t} from "i18next";
import {Col, FormGroup} from "reactstrap";
import {TextSearch} from "../generic/fields";

const DirectoryTemplate = withBreadcrumbs(({
                               entityName, EditModalChildren, ExtraButtons, fields, permissions,
                               deletable, editable, filters = {}, customRowClick, isInline, isFormData, initialEntity, rowClass, additionalButtons, tableStyle, buttonsProps, isNameFieldSearch, searchPlaceholder
                           }) => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints(entityName, isFormData);
    const [isModalOpen, toggleModal] = useState(false)
    const [reload, setReload] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState({})
    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [nameFilter, setNameFilter] = useState();

    const triggerModal = () => {
        setReload(reload => !reload)
        if (isModalOpen) {
          setSelectedConfig(() => {})
        } else {
          setSelectedConfig((prevState) => ({ ...initialEntity, ...prevState }));
        }
        toggleModal(isModalOpen => {
            return !isModalOpen
        })
    }

  const toggleModalConfirmation = () =>
    setIsModalConfirmationOpen(!isModalConfirmationOpen);

    const onDeleteFile = (value) => {
      setSelectedConfig(value);
      toggleModalConfirmation();
    };

    const onConfirmModal = () => deleteEntry(selectedConfig.id).then(toggleModalConfirmation);

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('common.deleting')} text={t('common.areYouSure')}
                           cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
                           onConfirm={() => { onConfirmModal().then(() => {
                             setReload(!reload);
                             setSelectedConfig({});
                           })}}
        />
        {isModalOpen && <EditModal
            triggerModal={triggerModal}
            isOpened={isModalOpen}
            EditForm={EditModalChildren}
            entityName={entityName}
            entry={selectedConfig}
            edit={permissions.edit}
            isFormData={isFormData}
            size={'lg'}
        />}
        <TableCard isInline={isInline}>
            <Row  className={"row mb-3 pr-0 mr-0 pl-1 mt-1 justify-content-end"}>
                {isNameFieldSearch && <div className={"row mb-3 pr-0 mr-0"}>
                    <Col>
                        <FormGroup>
                            <TextSearch onSearch={setNameFilter} placeholder={searchPlaceholder} />
                        </FormGroup>
                    </Col></div>
                }
                {ExtraButtons && <ExtraButtons/>}
                {editable && permissions?.edit && <div className={"row mb-3 pr-0 mr-0"}>
                    <Col className={"col-"}>
                        <Button color="primary" onClick={triggerModal}>
                            <FaPlus/>
                            &nbsp; {t('directories.directoryTemplate.create')}
                        </Button>
                    </Col>
                </div>}
            </Row>
            <DataTable findAndCount={findAndCountEntries}
                       fields={fields}
                       sortField="created_at"
                       sortDir="desc"
                       name={entityName}
                       onClick={
                           (editable && permissions?.edit)
                               ? (config) => {
                                   if (!customRowClick){
                                       setSelectedConfig(() => config)
                                       triggerModal()

                                   }
                                   else
                                       customRowClick(config);
                               }
                               : undefined
                       }
                       reload={reload}
                       onDelete={ (deletable && permissions?.edit)
                           ? onDeleteFile
                           : undefined
                       }
                       filters={{...filters, name_contains: nameFilter}}
                       rowClass={rowClass}
                       additionalButtons={additionalButtons}
                       tableStyle={tableStyle}
                       buttonsProps={buttonsProps}
            />
        </TableCard>
    </>
})


DirectoryTemplate.propTypes = {
    entityName: PropTypes.string.isRequired,
    EditModalChildren: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    ExtraButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    fields: PropTypes.array.isRequired,
    deletable: PropTypes.any,
    editable: PropTypes.any,
    customRowClick: PropTypes.func,
    permissions: PropTypes.object,
    additionalButtons: PropTypes.func,
    tableStyle: PropTypes.object,
    searchPlaceholder: PropTypes.string,
}
export default DirectoryTemplate;