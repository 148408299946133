import React from 'react'
import BaseInfo from './BaseInfo';
import BaseGeneral from "./BaseGeneral";
import {InstructorsList} from "../../administration/instructors";
import { IS_RUSSIA } from '../../../lib/utils/constants';
import {frk} from "sv-common/constants/frontRoles";

const tabList = ({state, dispatch, form = {}, rolePermissions, check}) => {
    return (
        [
            {
                name: "bases.base.about",
                child: <BaseInfo state={state} dispatch={dispatch} form={form}/>,
                isDisplayed: true,
                url: 'about'
            },
            {
                name: "bases.base.instructors",
                child: <InstructorsList baseFilter={state.id} isInline disabled={!rolePermissions?.edit}/>,
                isDisplayed: ((IS_RUSSIA && !!state.id) && check(frk["BASES-ITEM-INSTRUCTORS"])) || check(frk["BASES-ITEM-INSTRUCTORS"]),
                url: 'instructors'
            },
            {
                name: "bases.base.administration",
                child: <BaseGeneral state={state} dispatch={dispatch} form={form}/>,
                isDisplayed: true,
                url: 'general'
            },
        ]
    )
}

export default tabList;