import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router';
import {useApi} from '../../lib/api/useApi';
import PropTypes from 'prop-types';
import {t} from "i18next";
import withAccessInCountries from "../../lib/utils/hocs/withAccessInCountries";
import {useRuleAccess} from "../../lib/utils/frontRules";
import {FrontendRulesState} from "../../lib/api/frontendRulesProvider";


const useBreadcrumbAndTitleParsers = ({computedMatch, location}, ruBreadcrumbMapper, ruTitleMapper) => {
    const {path = ''} = computedMatch;
    const {updateGlobalState} = useApi();

    const { check } = useRuleAccess();
    const rules = useContext(FrontendRulesState);

    const [currentArgs, setCurrentArgs] = useState();

    const getRu = (args, mapperItem) => t(mapperItem.ru[0] === ':' ? args[mapperItem.ru.slice(1)]?.ru : mapperItem.ru)
    const getLink = (args, mapperItem) => {
        if (mapperItem.checkRuleKey && !check(mapperItem.checkRuleKey)) {
            return undefined;
        }

        let link = mapperItem.link;
        if (link.includes(':'))
            link = link.split('/').map(part => args[part.slice(1)]?.en || part).join('/')
        if (link[0] !== '/' && link !== '') link = '/' + link
        if (!link) link = undefined;
        return link;
    }

    const callback = (bcArgs, titleArgs) => {
        !currentArgs && setCurrentArgs({ bcArgs, titleArgs });
        const bcParams = ruBreadcrumbMapper && ruBreadcrumbMapper(path).map(bcEl => {
            const ru = getRu(bcArgs, bcEl);
            const link = getLink(bcArgs, bcEl);
            return {ru, link}
        })

        const titleParams = ruTitleMapper && ruTitleMapper(path).map(titleEl => {
            return {ru: getRu(titleArgs, titleEl)}
        })

        updateGlobalState({
            breadcrumbs: bcParams,
            domTitle: titleParams
        })
    }

    useEffect(() => {
        // Нужно для перендера хлебных крошек с ограничениями по роли с одного места в коде
        // Переосмыслить если будет рефакторинг
        Object.keys(rules).length && currentArgs && callback(currentArgs.bcArgs, currentArgs.titleArgs);
    }, [rules, currentArgs]);

    return callback;
}

const _Route = ({
                    isPublic,
                    path,
                    exact,
                    component: Component,
                    ruBreadcrumbsMapper,
                    ruTitleMapper,
                    ...rest
                }) => {
    const {userLoggedIn} = useApi();

    const createTranslatedBreadcrumbsAndTitle = useBreadcrumbAndTitleParsers(rest, ruBreadcrumbsMapper, ruTitleMapper)

    if (!isPublic && !userLoggedIn) {
        return <Redirect to="/login"/>;
    }

    if (isPublic && userLoggedIn) {
        return <Redirect to="/"/>;
    }

    return (
        <Route path={path} exact={exact}>
            <Component {...rest} createTranslatedBreadcrumbsAndTitle={createTranslatedBreadcrumbsAndTitle}/>
        </Route>
    );
};

_Route.propTypes = {
    isPublic: PropTypes.bool,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    component: PropTypes.func.isRequired,
    ruBreadcrumbsMapper: PropTypes.func.isRequired,
    ruTitleMapper: PropTypes.func.isRequired,
}
export default withAccessInCountries(_Route);