import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import React, {useState} from 'react';
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {ModalConfirmation} from "../../generic/modals";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {FaCheck, FaPlus, FaTimes} from "react-icons/fa";
import {TextSearch} from "../../generic/fields";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {CopyLink} from "../../generic/buttons";
import usePages from "./usePages";

const Pages = withBreadcrumbs(({entity, cutstomFilters}) => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('pages');

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [ejectedPage, setEjectedPage] = useState();
    const [reload, setReload] = useState(false);
    const [nameFilter, setNameFilter] = useState();
    const [keyFilter, setKeyFilter] = useState();

    const {getPageFullURL} = usePages();

    const history = useHistory();

    const filters = {
        'project.label_contains': nameFilter || undefined,
        'project.key_contains': keyFilter || undefined,
    }

    const toggleModalConfirmation = () =>
        setIsModalConfirmationOpen(!isModalConfirmationOpen);

    const onConfirmModal = () => deleteEntry(ejectedPage.id).then(toggleModalConfirmation);

    const onDeletePage = (page) => {
        setEjectedPage(page);
        toggleModalConfirmation();
    };

    const onPageClick = page => history.push(`/constructor/${entity === 'letters' ? entity : 'pages'}/edit/` + page.id);

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('constructor.pages.deleting')} text={t('constructor.pages.areYouSure')}
                           cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
                           onConfirm={() => { onConfirmModal().then(() => setReload(!reload)) }}
        />

        <TableCard>
            <div className="justify-content-between mb-4">
                <button className={'btn btn-sm btn-primary btn-rounded'}
                        onClick={() => history.push(`/constructor/${entity === 'letters' ? entity : 'pages'}/edit/`)}>
                    <FaPlus/>
                    &nbsp; {t('common.create')}
                </button>
            </div>
            <Row>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>{t('constructor.pages.projectLabel')}</Label>
                        <TextSearch onSearch={setNameFilter} placeholder={t('constructor.pages.projectLabelPlaceholder')} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>{t('constructor.pages.projectKey')}</Label>
                        <TextSearch onSearch={setKeyFilter} placeholder={t('constructor.pages.projectKeyPlaceholder')} />
                    </FormGroup>
                </Col>
            </Row>
            <DataTable
                findAndCount={findAndCountEntries}
                fields={[
                    field(t('constructor.label'), true, 'label'),
                    field(t('constructor.key'), true, 'key', res => {
                        const pageURL = getPageFullURL(res.project, res.key);
                        return <>
                            {res.key}<br/>
                            <div className="mt-1"><small className={'font-weight-bold'}>{pageURL(true)}<CopyLink href={pageURL(true)} showLink={false} /> URL External</small></div>
                            <div className="mt-1"><small>{pageURL(false)}<CopyLink href={pageURL(false)} showLink={false} /> URL for testing</small></div>
                        </>
                    }),
                    field(t('constructor.public'), true, 'is_public',
                        res => res.is_public
                            ? <FaCheck color={'green'}/>
                            : <FaTimes color={'red'}/>
                    ),
                    field(t('constructor.createdAt'), true, 'created_at',
                        res => dayjs(res.created_at).format('DD.MM.YYYY HH:MM')),
                    field(t('constructor.updatedAt'), true, 'updated_at',
                        res => dayjs(res.updated_at).format('DD.MM.YYYY HH:mm')),
                ]}
                name='pages-list'
                reload={reload}
                onDelete={onDeletePage}
                onClick={onPageClick}
                filters={{...filters, ...cutstomFilters}}
            />
        </TableCard>
    </>
})

export default Pages