import React, {useState} from 'react';
import {Col, Label, Modal, ModalBody, Row} from "reactstrap";
import {Switch} from "../../../../generic/buttons";
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../../generic/tables";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {FaPlus} from "react-icons/fa";
import {Promocode, PromocodesList} from "../../../../administration";
import {dateFull} from "../../../../../lib/utils/format";
import {ElementWithPermissions} from "../../../../common/formElements";
import {t} from "i18next";
import dayJs from 'dayjs';

/**
 * @param {string} productType
 * @param {object} state
 * @return {{excluded: [{excluded_adventures_ncontains: *}, {excluded_arrivals_ncontains: *}], extra: [{adventures_ids_contains: *}], text: string, applicable_to: [string, string, string]}|{excluded: [{excluded_adventures_ncontains: *}, {excluded_arrivals_ncontains: *}], extra: [{adventures_ids_contains: *}], text: string, applicable_to: [string, string, string]}|{excluded: [{excluded_bases_ncontains: *}], extra: [], text: string, applicable_to: [string, string]}|{excluded: [{excluded_bases_ncontains: *}, {excluded_events_ncontains: *}], extra: [{bases_ids_contains: *}], text: string, applicable_to: [string, string, string]}|{excluded: [{excluded_bases_ncontains: number|*}, {excluded_events_ncontains: *}, {excluded_slots_ncontains: *}], extra: [{bases_ids_contains: number|*}, {events_ids_contains: *}], text: string, applicable_to: [string, string]}|{excluded: [{excluded_adventures_ncontains: *}], extra: [], text: string, applicable_to: [string, string, string]}|{excluded: [{excluded_adventures_ncontains: *}], extra: [], text: string, applicable_to: [string, string, string]}|{excluded: [{excluded_groups_ncontains: *}], extra: [], text: string, applicable_to: [string, string, string]}}
 */
const productToData = (productType, state) => {
    if (productType === 'bases')
        return {
            text: t('bases.events.newEventBlocks.promocodes.bases'),
            applicable_to: ['products', 'bases'],
            excluded: [{excluded_bases_ncontains: state.id}],
            extra: []
        }
    if (productType === 'events')
        return {
            text: t('bases.events.newEventBlocks.promocodes.events'),
            applicable_to: ['products', 'bases'],
            excluded: [{excluded_bases_ncontains: state.base_id.id}, {excluded_events_ncontains: state.id}],
            extra: [
                {
                    _or: [
                        {bases_ids_contains: [state.base_id.id + ",", state.base_id.id + "]"]},
                        {bases_ids_eq: state.base_id.id},
                    ],
                    applicable_to_contains: 'events_' + state.type
                },
                {
                    _or: [
                        {bases_ids_contains: [state.base_id.id + ",", state.base_id.id + "]"]},
                        {bases_ids_eq: state.base_id.id},
                    ],
                    applicable_to_eq: '[]'
                },
                {bases_ids_eq: '[]', applicable_to_contains: 'events_' + state.type},
            ]
        }
    if (productType === 'slots')
        return {
            text: t('bases.events.newEventBlocks.promocodes.slots'),
            applicable_to: ['products', 'bases', 'events_' + state.event_id.type],
            excluded: [{excluded_bases_ncontains: state.event_id.base_id}, {excluded_events_ncontains: state.event_id.id}, {excluded_slots_ncontains: state.id}],
            extra: [{bases_ids_contains: state.event_id.base_id}, {events_ids_contains: state.event_id.id}]
        }

    if (productType === 'adventures') {
        const result = {
          text: t('bases.events.newEventBlocks.promocodes.travels'),
          applicable_to: ['products'],
          excluded: [{excluded_adventures_ncontains: state.id}],
          extra: []
        };

        if (state.is_school) {
          result.text = t('bases.events.newEventBlocks.promocodes.practices');
          result.applicable_to.push('groups_all', 'groups_practice');
          return result;
        }

        if (state.is_camp) {
          result.text = t('bases.events.newEventBlocks.promocodes.camps');
          result.applicable_to.push('camps_all', `camps_${state.type}`);
          return result;
        }

        result.applicable_to.push('adventures_all', 'adventures_' + state.type);

        return result;
    }

    if (productType === 'arrivals') {
      const result = {
        text: t('bases.events.newEventBlocks.promocodes.arrival'),
        applicable_to: ['products'],
        excluded: [{excluded_adventures_ncontains: state.adventure_id.id}, {excluded_arrivals_ncontains: state.id}],
        extra: [{adventures_ids_contains: state.adventure_id.id}]
      }

      if (state.is_school) {
        result.text = t('bases.events.newEventBlocks.promocodes.practiceArrival');
        result.applicable_to.push('groups_all', 'groups_practice');
        return result;
      }

      if (state.adventure_id.is_camp) {
        result.text = t('bases.events.newEventBlocks.promocodes.camps');
        result.applicable_to.push('camps_all', `camps_${state.adventure_id.type}`);
        return result;
      }

      result.applicable_to.push('adventures_all', 'adventures_' + state.adventure_id.type);

      return result;
    }

    if (productType === 'groups')
        return {
            text: t('bases.events.newEventBlocks.promocodes.groups'),
            applicable_to: ['products', 'groups_all', 'groups_' + state.type],
            excluded: [{excluded_groups_ncontains: state.id}],
            extra: []
        }
}

const Promocodes = ({state, dispatch, productType, disabled}) => {
    const {text, applicable_to, excluded, extra} = productToData(productType, state)
    const {findAndCountEntries, editEntry} = useDefaultEndpoints('promocodes')
    const [reload, setReload] = useState(false);

    const [isListOpened, setListOpened] = useState(false);
    const toggleList = () => {
        setListOpened(isOpen => !isOpen);
        setReload(reload => !reload);
    }

    const [isCreateOpened, setCreateOpened] = useState(false);
    const toggleCreate = () => {
        setCreateOpened(isOpen => !isOpen);
        setReload(reload => !reload);
    }

    const notArchived = [{is_archived_ne: true}];

    const isAvailable = [
      { _or: [ { available_to_gte: dayJs().utc(true).toDate() }, { is_open_ended: true } ] },
      { is_extinguished_ne: true }
    ];

    const filters = {
        _where: {
            _or: [
                ...applicable_to.map(item => [{applicable_to_contains: item}].concat(excluded).concat(notArchived).concat(isAvailable)),
                [{[productType + '_ids_contains']: state.id}].concat(excluded).concat(notArchived).concat(isAvailable),
                ...extra.map(item => [item].concat(excluded).concat(notArchived).concat(isAvailable))
            ]
        },
    }

    return <>
        <Modal size={'lg'} isOpen={isListOpened} toggle={toggleList} unmountOnClose>
            <ModalBody>
                <PromocodesList isInline isView onClick={promocode => {
                    editEntry(promocode.id, {
                        [productType + '_ids']: [...promocode[productType + '_ids'].map(productId => productId), state.id],
                        ['excluded_' + productType]: promocode['excluded_' + productType]
                            ?.filter(productId => productId !== state.id)
                    })
                        .then(() => toggleList())
                }}/>
            </ModalBody>
        </Modal>
        <Modal size={'lg'} isOpen={isCreateOpened} toggle={toggleCreate} unmountOnClose>
            <ModalBody>
                <Promocode isInline productType={productType} productId={state.id} onSuccessCb={toggleCreate}/>
            </ModalBody>
        </Modal>
        <Row className={'mt-3 mb-3'}>
            <Col xs={'auto'}>
                <h5 className={'display-4'}>{t('bases.events.newEventBlocks.promocodes.title')}</h5>
            </Col>
            <Col xs={'auto'}>
                <ElementWithPermissions disabled={disabled}>
                    <button
                        className={'btn btn-primary btn-rounded btn-sm ml-2 ' + (state.are_promocodes_active ? '' : 'disabled')}
                        onClick={toggleCreate}>
                        <FaPlus size={'1em'}/> &nbsp; {t('bases.events.newEventBlocks.promocodes.new')}
                    </button>
                </ElementWithPermissions>

            </Col>
            <Col xs={'auto'}>
                <ElementWithPermissions disabled={disabled}>
                    <button
                        className={'btn btn-secondary btn-rounded btn-sm ml-2 ' + (state.are_promocodes_active ? '' : 'disabled')}
                        onClick={toggleList}>
                        <FaPlus size={'1em'}/> &nbsp; {t('bases.events.newEventBlocks.promocodes.existing')}
                    </button>
                </ElementWithPermissions>
            </Col>
        </Row>
        <Row>
            {dispatch && <>
                <Col xs={12} md={8}>
                    <Label>{t('bases.events.newEventBlocks.promocodes.promocodesFor')} {text} {t('bases.events.newEventBlocks.promocodes.active')}</Label>
                </Col>
                <Col xs={12} md={4}>
                    <Switch
                      value={state.are_promocodes_active}
                      name={'are_promocodes_active'}
                      onChange={e => dispatch({type: 'setFormData', payload: e})}
                      defaultValue={true}
                    />
                </Col>
            </>}
            {state.are_promocodes_active &&
            <Col xs={12} md={11}>
                <DataTable
                    findAndCount={findAndCountEntries}
                    filters={filters}
                    fields={[
                        field(t('bases.events.newEventBlocks.promocodes.name'), true, "name"),
                        field(t('bases.events.newEventBlocks.promocodes.size'), true, "discount_size", res =>
                            res.discount_size
                                ? `${res.discount_size} ${res.discount_type === 'summ' ? res.discount_currency : '%'}`
                                : t('bases.events.newEventBlocks.promocodes.notSpecified')
                        ),
                        field(t('bases.events.newEventBlocks.promocodes.expiresAt'), false, "expires_at",
                            res => res.available_to && !res.is_open_ended
                                ? t('bases.events.newEventBlocks.promocodes.before') + ' ' + dateFull(res.available_to)
                                : res.uses_max - (res.used_total || 0) + ` ${t('management.promoCodes.usages')} / ` + (res.uses_max || t('bases.events.newEventBlocks.promocodes.indefinitely'))),
                    ]}
                    sortField="created_at"
                    sortDir="desc"
                    name="promocodes"
                    reload={reload}
                    onDelete={disabled ? null : (promocode) => {
                        editEntry(promocode.id, {
                                ...promocode,
                                [productType + '_ids']: promocode[productType + '_ids'].filter(productId => productId !== state.id),
                                ['excluded_' + productType]: [...promocode['excluded_' + productType], state.id]
                            }
                        ).then(() => setReload(reload => !reload))
                    }}
                />
            </Col>
            }
        </Row>
    </>
};

export default Promocodes;

Promocodes.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    productType: PropTypes.string.isRequired,
}