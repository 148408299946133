import React, {useEffect, useState} from 'react';
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {TableCard} from "../../../generic/cards";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import {DatePicker, TextControllerRHF} from "../../../generic/fields";
import {Switch} from "../../../generic/buttons";
import dayjs from "dayjs";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import ApplicableSelect from "./ApplicableSelect";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Loader} from "../../../generic/loader";
import BasesSelect from "../../promocodePackages/promocodePackage/BasesSelect";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {PROMOCODE_PRODUCT_TYPE} from "sv-common/constants/promocodes";
import {useArrivalsApi} from "../../../../lib/api/arrivals";
import {useSlotsApi} from "../../../../lib/api/slots";
import {useEventsApi} from "../../../../lib/api/events";
import {useGroupsApi} from "../../../../lib/api/groups";
import {useAdventuresApi} from "../../../../lib/api/adventures";
import {useBasesApi} from "../../../../lib/api/bases";
import {ElementWithPermissions} from "../../../common/formElements";
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import ConditionsSelect from '../../promocodePackages/promocodePackage/CondtitionsSelect';
import InfoTooltip from "../../../generic/InfoTooltip";
import {isRuleAccess} from "../../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

const Promocode = withBreadcrumbs(({
    productType, productId, onSuccessCb, isInline,
    rhfConfig = undefined, setStateCb, extraState = {},
}) => {
    const {id} = useParams();
    let history = useHistory();
    const {createEntry, getEntry, editEntry} = useDefaultEndpoints('promocodes');
    const arrivalsAPI = useArrivalsApi();
    const slotsAPI = useSlotsApi();
    const eventsAPI = useEventsApi();
    const groupsAPI = useGroupsApi();
    const adventuresAPI = useAdventuresApi();
    const basesApi = useBasesApi();
    const [loading, setLoading] = useState(true);
    const [promocodeProducts, setPromocodeProducts] = useState({})
    const form = useForm({reValidateMode: 'onBlur'});
    const permissions = useRoleSystem(ENTITIES.PROMOCODES);
    const {handleSubmit, control, errors, reset} = rhfConfig ? rhfConfig : form;
    const [generationType, setGenerationType] = useState('manual');
    const getCurrencies = useDefaultEndpoints('currencies').getEntries;
    const [currencies, setCurrencies] = useState([]);
    const [state, setState] = useState({
        comment: '',
        name: '',
        category: 'simple',
        available_from: dayjs().startOf('day').toDate(),
        available_to: dayjs().add(1, 'M').startOf('day').toDate(),
        is_open_ended: false,
        used_total: 0,
        is_extinguished: false,
        is_archived: false,
        discount_type: 'summ',
        discount_currency: 'RUB',
        is_personal: false,
        applicable_to: [],
        bases_ids: [],
        special_conditions: [],
        ...extraState
    });


    const fillPromocodeProducts = async (promocode) => {
        const basesMap = {}

        const promocodeProductsAdapter = {
            [PROMOCODE_PRODUCT_TYPE.ADVENTURES]: {
                provider: adventuresAPI,
                getUrl: (adventure) => `/adventures/view/${adventure.url}`,
            },
            [PROMOCODE_PRODUCT_TYPE.ARRIVALS]: {
                provider: arrivalsAPI,
                getUrl: (arrival) => `/adventures/view/${arrival.adventure_id.url}/${arrival.url}`,
            },
            [PROMOCODE_PRODUCT_TYPE.EVENTS]: {
                provider: eventsAPI,
                getUrl: (event) => `/bases/${event.base_id.url}/event/${event.url}`,
            },
            [PROMOCODE_PRODUCT_TYPE.GROUPS]: {
                provider: groupsAPI,
                getUrl: (group) => `/school/theory/group/view/${group.url}`,
            },
            [PROMOCODE_PRODUCT_TYPE.SLOTS]: {
                provider: {
                    ...slotsAPI,
                    getEntries: async (params) => {
                        const result = await slotsAPI.getEntries(params)
                        const basesIds = []
                        result.forEach((slot) => {
                            basesIds.push(slot.event_id.base_id)
                        })
                        if (basesIds) {
                            const bases = await basesApi.getEntries({id_in: basesIds})

                            for (const base of bases) {
                                basesMap[base.id] = base;
                            }
                        }
                        return result;
                    }
                },
                getUrl: (slot) => `/bases/${basesMap[slot.event_id.base_id].url}/event/${slot.event_id.url}/slot/${slot.url}`,
            }
        }

        const promocodeProductsMapper = {
            [PROMOCODE_PRODUCT_TYPE.ADVENTURES]: {
                name: t('management.promoCodes.ofAdventures'),
                products: []
            },
            [PROMOCODE_PRODUCT_TYPE.ARRIVALS]: {
                name: t('management.promoCodes.ofArrivals'),
                products: []
            },
            [PROMOCODE_PRODUCT_TYPE.EVENTS]: {
                name: t('management.promoCodes.ofEvents'),
                products: []
            },
            [PROMOCODE_PRODUCT_TYPE.GROUPS]: {
                name: t('management.promoCodes.ofGroups'),
                products: []
            },
            [PROMOCODE_PRODUCT_TYPE.SLOTS]: {
                name: t('management.promoCodes.ofSlots'),
                products: []
            }
        }

        for (const promoProductType of Object.values(PROMOCODE_PRODUCT_TYPE)) {
            if (promocode[`${promoProductType}_ids`]?.length > 0) {
                const products = await promocodeProductsAdapter[promoProductType].provider.getEntries({id_in: promocode[`${promoProductType}_ids`]})
                products.forEach(product => {
                    promocodeProductsMapper[promoProductType].products.push({
                        name: product.name,
                        url: promocodeProductsAdapter[promoProductType].getUrl(product)
                    })
                })
            }
        }
        setPromocodeProducts(promocodeProductsMapper)
    }

    useEffect(() => {
        if (id && !extraState.id)
            getEntry(id).then(res => {
                const promocode = {
                    ...res,
                    available_from: dayjs(res.available_from).startOf('day').toDate(),
                    available_to: dayjs(res.available_to).startOf('day').toDate(),
                }
                setState(() => promocode)
                reset({name: res.name});
                setLoading(() => false);
                fillPromocodeProducts(promocode).then()
            });
        else {
            const promocode = {
                ...state,
                name: ''
            }
            setState((state) => promocode)
            setLoading(() => false);
            reset({name: ''});
            fillPromocodeProducts(promocode).then()
        }
        getCurrencies({}).then(res => setCurrencies(() => res));
    }, [id]);

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setState(state => ({...state, [name]: name === 'name' ? value.trim() : value}))
        if (setStateCb)
            setStateCb({[name]: value})
    };

    const createOrUpdate = () => {
        if (setStateCb) {
            return;
        }
        const updatedState = {...state};
        if (productType && productId && !id)
            updatedState[productType + '_ids'] = [productId]

        return id
            ? editEntry(id, state).then(res => toast.success(t('management.promoCodes.success')))
            : createEntry({
                ...updatedState,
                generationType
            }).then(res => onSuccessCb ? onSuccessCb() : history.push('/administration/promocodes/edit/' + res.id))
    }

    const submit = handleSubmit((data) => createOrUpdate())

    return loading
        ? <Loader/>
        : <TableCard isInline={isInline}>
            <Form>
                {rhfConfig ? '' : <div className={'row justify-content-end mb-0 pr-0 mr-0'}>
                    <div className={'col mb-3'}>
                        <h4>{id ? t('management.promoCodes.edit') : t('management.promoCodes.create')}{' '}{t('management.promoCodes.ofPromoCode')}</h4>
                        {Object.values(PROMOCODE_PRODUCT_TYPE).map(
                            promoProductType =>
                                (promocodeProducts[promoProductType] && promocodeProducts[promoProductType].products.length > 0 || productType === promoProductType)
                                && (
                                    <div>
                                        <small>
                                            {t('management.promoCodes.promoCodeProductTypeDescription')}{' '}{promocodeProducts[promoProductType].name}
                                            {promocodeProducts[promoProductType].products.map( product=> {
                                                return <>&nbsp;<a href={product.url}>{product.name}</a></>
                                            })}
                                        </small>

                                    </div>
                                )
                        )}
                    </div>
                    <div className={'col-auto'}>
                        <ElementWithPermissions disabled={!isRuleAccess(frk["PROMOCODES-ITEM-EDIT"])() || !permissions.edit}>
                            <button className={'btn btn-sm btn-primary btn-rounded'} onClick={submit}>
                                {id ? t('management.promoCodes.edit') : t('management.promoCodes.create')}
                            </button>
                        </ElementWithPermissions>

                    </div>
                </div>}
                <Row>
                    {!id && !rhfConfig && <Col xs={6} md={4}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.typeOfNameGeneration')}</Label>
                            <Input type={'select'} name={'generationType'} value={generationType}
                                   onChange={e => setGenerationType(e.target.value)}>
                                <option value={'manual'}>{t('management.promoCodes.manual')}</option>
                                <option value={'combined'}>{t('management.promoCodes.combined')}</option>
                                <option value={'automatic'}>{t('management.promoCodes.automatic')}</option>
                            </Input>
                        </FormGroup>
                    </Col>}
                    {(!(generationType === 'automatic') || !!id) && !rhfConfig && <Col xs={6} md={4}>
                        <FormGroup>
                            <TextControllerRHF control={control} inputName={'name'} setValue={handleInput}
                                               isRequired={true} placeholder={'sila-vetra'}
                                               defaultValue={state.name || ''} value={state.name}
                                               label={t('management.promoCodes.title')} disabled={!!id} errors={errors}
                                               rules={generationType !== 'automatic' ? {required: t('management.promoCodes.requiredField')} : {}}
                            />
                        </FormGroup>
                    </Col>}
                    {!!id && <Col xs={6} md={4}>
                      <FormGroup>
                        <Label>{t('management.promoCodes.creator')}</Label>
                        <Input type={'text'} value={state?.creator?.email} disabled={true} />
                      </FormGroup>
                    </Col>}
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <TextControllerRHF control={control} inputName={'comment'} setValue={handleInput}
                                               disabled={!!extraState.id}
                                               isRequired={true}
                                               defaultValue={state.comment || ''}
                                               label={t('management.promoCodes.comment')} errors={errors} rules={{required: t('management.promoCodes.requiredField')}}
                            />
                            <label className={'text-secondary'}>{t('management.promoCodes.commentDesc')}</label>
                        </FormGroup>
                    </Col>
                    <Col md={4} xs={8}>
                        <FormGroup>
                            <Label>
                                {t('management.promoCodes.startOfPromoCode')}
                            </Label>
                            <DatePicker name={'available_from'} onDateChange={val => {
                                setState(state => ({
                                    ...state,
                                    available_from: dayjs(val).startOf('day').toDate(),
                                    available_to: dayjs(val).startOf('day').add(1, 'y').toDate(),
                                }))
                                if (setStateCb)
                                    setStateCb({available_from: dayjs(val).startOf('day').toDate()})
                            }}
                                        selectedDay={dayjs(state.available_from).toDate()}
                                        disabled={!!extraState.id}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={4}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.openEnd')}</Label>
                            <div>
                                <Switch name={'is_open_ended'} value={state.is_open_ended}
                                        disabled={!!extraState.id}
                                        onChange={handleInput}/>
                            </div>
                        </FormGroup>
                    </Col>
                    {!state.is_open_ended && <Col xs={12} md={4}>
                                <FormGroup>
                                    <Label>
                                        {t('management.promoCodes.enoOfPromoCode')}
                                    </Label>
                                    <DatePicker mask={[/\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/]}
                                                disabled={!!extraState.id}
                                                onDateChange={val => {
                                                    setState(state => ({
                                                        ...state,
                                                        available_to: dayjs(val).startOf('day').toDate()
                                                    }))
                                                    if (setStateCb)
                                                            setStateCb({available_to: dayjs(val).startOf('day').toDate()})
                                                }}
                                                selectedDay={dayjs(state.available_to).toDate()}
                                                maxDate={dayjs().startOf('day').add(548, 'day').toDate()}
                                    />
                                </FormGroup>
                            </Col>}
                    <Col md={4} xs={12}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.usageType')}</Label>
                            <Input bsSize={'sm'} type={'select'} name={'category'} value={state.category}
                                   onChange={handleInput}
                            >
                                <option value={'simple'}>{t('management.promoCodes.normal')}</option>
                                <option value={'welcome'}>{t('management.promoCodes.welcome')}</option>
                                <option value={'disposable'}>{t('management.promoCodes.disposable')}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={6}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.usingMax')}</Label>
                            <Input type={'text'} name={'uses_max'} onChange={handleInput} disabled={!!extraState.id}
                                   value={state.uses_max || ''} placeholder={'100'}/>
                        </FormGroup>
                    </Col>
                    <Col md={1} xs={3}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.repaid')}</Label>
                            <div>
                                <Switch name={'is_extinguished'} value={state.is_extinguished}
                                        disabled={!!extraState.id} onChange={handleInput}/>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={3}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.archived')}</Label>
                            <div>
                                <Switch name={'is_archived'} value={state.is_archived} disabled={!!extraState.id}
                                        onChange={handleInput}/>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={{size: 6, offset: 0}}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.type')}</Label>
                            <Input bsSize={'sm'} type={'select'} disabled={!!extraState.id} name={'discount_type'}
                                   value={state.discount_type}
                                   onChange={handleInput}
                            >
                                <option value={'summ'}>{t('management.promoCodes.sum')}</option>
                                <option value={'percent'}>{t('management.promoCodes.percent')}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={1} xs={6}>
                        <FormGroup>
                            <TextControllerRHF control={control} inputName={'discount_size'} setValue={handleInput}
                                               disabled={!!extraState.id}
                                               isRequired={true} placeholder={'100'}
                                               defaultValue={state.discount_size || ''}
                                               label={t('management.promoCodes.sum')} errors={errors} rules={{required: t('management.promoCodes.requiredField')}}
                            />
                        </FormGroup>
                    </Col>
                    {state.discount_type === 'summ' && <Col md={1} xs={6}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="discount_currency"
                                defaultValue={state.discount_currency || ''}
                                rules={state.discount_type === 'summ' ? {required: t('management.promoCodes.requiredField')} : {}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid, isTouched, isDirty}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('management.promoCodes.currency')}
                                                         name={name} isRequired={true}
                                    >
                                        <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                               size={'sm'}
                                               disabled={!!extraState.id}
                                               style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                               value={value}
                                               onChange={(e) => {
                                                   handleInput(e);
                                                   onChange(e.target.value)
                                               }}>
                                            {currencies.map(currency => <option key={currency.sign}
                                                                                value={currency.key}>{currency.sign}</option>)}
                                        </Input>
                                    </FieldWithValidation>
                                }
                            />
                        </FormGroup>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={12} md={{size: 8, offset: 4}}>
                        <FormGroup>
                            <Label>
                                {t('management.promoCodes.whatIsAppliedTo')}
                            </Label>
                            <ApplicableSelect value={state.applicable_to} placeholder={t('management.promoCodes.chooseProduct')}
                                              disabled={!!extraState.id}
                                              onChange={val => {
                                                  setState(state => (
                                                      {...state, applicable_to: (val || []).map(val => val.value)}))
                                                  if (setStateCb)
                                                      setStateCb({applicable_to: (val || []).map(val => val.value)})
                                              }}
                            />
                        </FormGroup>

                    </Col>
                    <Col xs={12} md={{size: 8, offset: 4}}>
                        <Label>{t('management.promoCodes.andOr')}</Label>
                    </Col>
                    <Col xs={12} md={{size: 8, offset: 4}}>
                        <FormGroup>
                            <Label>{t('management.promoCodes.basesForAttachment')}</Label>
                            <BasesSelect value={state.bases_ids} placeholder={t('management.promoCodes.chooseBases')}
                                         disabled={!!extraState.id}
                                         onChange={val => {
                                             setState(state => (
                                                 {...state, bases_ids: (val || []).map(val => val.value)}))
                                             if (setStateCb)
                                                 setStateCb({bases_ids: (val || []).map(val => val.value)})
                                         }}
                            />
                        </FormGroup>
                    </Col>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <Col xs={12} md={{size: 8, offset: 4}}>
                            <Label>{t('management.promoCodes.specialConditions')}
                                <InfoTooltip text={t('management.promoCodes.specialConditionsInfo')} />
                            </Label>
                        </Col>
                        <Col xs={12} md={{size: 8, offset: 4}}>
                            <FormGroup>
                                <ConditionsSelect value={state.special_conditions} placeholder={t('management.promoCodes.chooseConditions')}
                                                disabled={!!extraState.id}
                                                onChange={val => {
                                                    setState(state => (
                                                        {...state, special_conditions: (val || []).map(val => val.value)}))
                                                    if (setStateCb)
                                                        setStateCb({special_conditions: (val || []).map(val => val.value)})
                                                }}
                                />
                            </FormGroup>
                        </Col>
                    </ElementWithAccessInCountries>
                </Row>
            </Form>
        </TableCard>
});

export default Promocode;