const initialState = {
    user_data: {},
};

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value};
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'General'
            return {
                ...state,
                activeTab: action.payload
            }

        // Data editing block
        case 'setClient':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload
            }
        case 'setClientData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }
        case 'setUserData':
            // value: SyntheticEvent
            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    ...handleInputChange(action.payload),
                    hasChanged: true
                }
            }

        case 'setAdditionalField':
            // value: SyntheticEvent
            const name = action.payload.target.name;
            const value = action.payload.target.value;
            return {
                ...state,
                additional_fields: {
                    ...state.additional_fields,
                    [name]: {
                        label: state.additional_fields[name].label,
                        value
                    }
                }
            }
        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};