import {Col, FormGroup, Row} from "reactstrap";
import { SelectControllerRHF, TextControllerRHF} from "../../../../generic/fields";
import {t} from "i18next";
import {PhoneRHF} from "../../../../common/reactHookFormsFields";
import {getCountryCode} from "../../../../../lib/utils/helper";
import React from "react";
import Dropzone from "../Dropzone";
import AsyncSelectController from "../AsyncSelectController";
import DateAndTimeSection from "./DateAndTimeSection";
import CardTemplateSection from "./CardTemplateSection";
import InformationBlocksSection from "./InformationBlockSection";
import DiscountsAndLinksSection from "./DiscountsAndLinksSection";
import PhotosSection from "./PhotosSection";

const PageFormFields = ({ control, errors, getBaseAddressesOptions, getCoordinatorsOptions, requiredRule, form, setValue, onLoadDirectory }) => (
    <>
        <Row className={'align-items-end'}>
                <Col lg={4} md={6} xs={12}>
                    <Dropzone form={form}/>
                </Col>
                <div className='pages-divider'/>

                <CardTemplateSection control={control} errors={errors} requiredRule={requiredRule} />
                <div className='pages-divider'/>

                <DateAndTimeSection control={control} errors={errors} requiredRule={requiredRule} setValue={setValue} />
                <Col md={6} xs={12}>
                    <FormGroup>
                        <AsyncSelectController
                            name="base_addresses"
                            form={form}
                            defaultValue={[]}
                            loadOptions={getBaseAddressesOptions}
                            isMulti
                            debounceTimeout={1000}
                            additional={{ offset: 0, limit: 10, query: "" }}
                            label={t('constructor.pages.base_address')}
                        />
                    </FormGroup>
                </Col>
                <div className='pages-divider'/>

                <InformationBlocksSection
                    form={form}
                    control={control}
                    errors={errors}
                    requiredRule={requiredRule}
                />
                <div className='pages-divider'/>

                <PhotosSection
                    form={form}
                    control={control}
                    errors={errors}
                    requiredRule={requiredRule}
                />
                <div className='pages-divider'/>

                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <SelectControllerRHF
                            inputName={"coordinator"} control={control} defaultValue='' options={getCoordinatorsOptions()}
                            label={t('constructor.pages.coordinator')} isRequired errors={errors} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>
                <div className='pages-divider'/>

                <DiscountsAndLinksSection
                    form={form}
                    control={control}
                    errors={errors}
                    requiredRule={requiredRule}
                />
                <div className='pages-divider'/>

                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'rules_link'} control={control} errors={errors} defaultValue=''
                            placeholder={t('constructor.pages.rulesLinkPlaceholder')}
                            isRequired={false}
                            label={t('constructor.templates.rules_link')} rules={requiredRule}
                        />
                    </FormGroup>
                </Col>

                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <PhoneRHF isRequired={false} form={form} state={{}} name={'phone'} country={getCountryCode()}
                                  label={t('constructor.templates.phone')}
                        />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <AsyncSelectController
                            name="what_bring"
                            form={form}
                            defaultValue={[]}
                            loadOptions={onLoadDirectory}
                            isMulti
                            mapOptions={(options) => options.map((opt) => ({ value: opt, label: opt }))}
                            debounceTimeout={1000}
                            additional={{ offset: 0, limit: 10, query: "" }}
                            label={t('constructor.templates.what_bring')}
                        />
                    </FormGroup>
                </Col>
            </Row>
    </>
);

export default PageFormFields;
