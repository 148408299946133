import {AiFillMinusCircle, AiFillPlusCircle} from "react-icons/ai";

const BoatsCounter = ({boatClass, onClickMinus, onClickPlus, boatsAmount, setValue}) => {
    return <div className={'badge badge-outline-dark badge-pill'}>
        <span className='boats-amount'>{boatClass}</span>

        <div className={'m-0 p-0 btn btn-light btn-rounded btn-sm '}>
            <AiFillMinusCircle
                    className={'boats-amount-icon'}
                    size={20}
                    onClick={onClickMinus}
            />
        </div>
        <input className='boats-amount fa'
               onKeyPress={(event) => {
                   if (!/[0-9]/.test(event.key)) {
                       event.preventDefault();
                   }
               }}
               onChange={(e) => setValue(+e.target.value)}
               value={boatsAmount} />
        <div className={'m-0 p-0 btn btn-light btn-rounded btn-sm '}>
            <AiFillPlusCircle
                    className={'boats-amount-icon'}
                    size={20}
                    onClick={onClickPlus}
            />
        </div>
    </div>
}

export default BoatsCounter;