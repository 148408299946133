import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import {FaPlus} from "react-icons/fa";
import ExtraMaterialsTable from "../../../../common/formElements/AdditionalExtraMaterials/ExtraMaterialsTable";
import ExtraMaterialsModal from "../../../../common/formElements/AdditionalExtraMaterials/ExtraMaterialsModal";
import {t} from "i18next";


const AdditionalInfo = ({state, dispatch}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={8}>
                        <h4 className={'display-4'}>{t('adventures.edit.additionalLinks')}</h4>
                    </Col>
                    <Col>
                         <span className={'btn btn-primary btn-rounded btn-sm'}
                               onClick={() => toggle()}><FaPlus size={'1em'}/></span>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {isOpen &&
                        <ExtraMaterialsModal state={state} toggle={toggle}
                                             setState={newState => dispatch({type: 'setForm', payload: newState})}/>
                        }
                        <ExtraMaterialsTable state={state}
                                             setState={newState => dispatch({type: 'setForm', payload: newState})} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AdditionalInfo;