import {Col, Form, FormGroup, Row} from "reactstrap";
import {TextControllerRHF} from "../../generic/fields";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, errors, control, handleInput}) => {
    return <Form>
        <Row>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={"label"} setValue={e => handleInput(e, setEntity)}
                        defaultValue={entity.label} label={t('constructor.label')}
                        control={control} errors={errors} isRequired
                        rules={{required: t('inputs.validation.required')}}
                        placeholder={t('constructor.projects.editModalChildren.labelPlaceholder')}
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={"key"} setValue={e => handleInput(e, setEntity)} isRequired
                        defaultValue={entity.key} label={t('constructor.key')} isDeleteSpaces
                        control={control} errors={errors} placeholder={'sber'}
                        rules={{required: t('inputs.validation.required')}}
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren