import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useInstructorSchedulesApi = () => {
    const defaultApi = useDefaultEndpoints('instructor-schedules');
    const {request} = useApi();

    const findAndCountByDay = async (query) =>
        request('get', `/instructor-schedules/findAndCountByDay`, query);

    return {...defaultApi, findAndCountByDay}
};