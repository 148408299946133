
const ruTitleMapper = (path) => {
    return urls[path];
}


const theory = 'school.breadcrumbsAndTitle.theory';
const lecture = 'school.breadcrumbsAndTitle.lecture';
const practice = 'school.breadcrumbsAndTitle.practice';
const editing = 'school.breadcrumbsAndTitle.editing';

const urls = {
    '/school/theory/group/edit/:url': [
        {ru: theory},
        {ru: ':url'},
        {ru: editing}
    ],
    '/school/theory/group/view/:url': [
        {ru: theory},
        {ru: ':url'},
    ],
    '/school/theory': [
        {ru: theory},
    ],

    '/school/lectures/group/edit/:url': [
        {ru: lecture},
        {ru: ':url'},
        {ru: editing}
    ],
    '/school/lectures/group/view/:url': [
        {ru: lecture},
        {ru: ':url'},
    ],
    '/school/lectures/:url': [
        {ru: lecture},
        {ru: ':url'},
    ],
    '/school/lectures': [
        {ru: lecture},
    ],

    '/school/practices/view/:url/:arrival_url': [
        {ru: practice},
        {ru: ':url'},
        {ru: ':arrival_url'},
    ],
    '/school/practices/view/:url': [
        {ru: practice},
        {ru: ':url'},

    ],
    '/school/practices/edit/:url': [
        {ru: practice},
        {ru: ':url'},
        {ru: editing},
    ],
    '/school/practices/edit/': [
        {ru: practice},
        {ru: 'school.breadcrumbsAndTitle.creating'},
    ],
    '/school/practices/': [
        {ru: practice},
    ],
}

export default ruTitleMapper;