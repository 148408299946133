import { Col, FormGroup, CustomInput } from "reactstrap"

const SwitchExtraButton = ({value, setValue, id, label}) => {
    return <Col>
        <FormGroup>
            <CustomInput type='checkbox' className={'custom-switch pt-2'} id={id}
                         name={id} checked={value}
                         onClick={() => setValue(!value)}
                         label={label}
            />
        </FormGroup>
    </Col>
}

export default SwitchExtraButton