import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const ShirtSizes = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.shirtSizes.sizeNumbers'), true, "size"),
        field(t('directories.shirtSizes.sizeLetters'), true, "tag")
    ]}
    entityName={'shirt-sizes'}
    EditModalChildren={EditModalChildren}
    editable
    deletable
    permissions={getPermissions(ENTITIES.SHIRT_SIZES)}
    {...props}
/>


export default ShirtSizes;