import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../generic/loader";

const BasesSelect = ({value, onChange, placeholder, disabled}) => {
    const {getEntries} = useDefaultEndpoints('bases');
    const [bases, setBases] = useState([]);

    useEffect(() => {
        getEntries({is_archived_ne: true}).then(res => setBases(() => [...res]))
        // eslint-disable-next-line
    }, []);

    const options = bases.map(base =>({label: base.name, value: base.id}));

    return !!bases.length
        ?
            <Select isDisabled={disabled} placeholder={placeholder} isMulti options={options} value={(value || [])
                .map(val => options.filter(option => option.value === val)[0])}
                    menuPlacement={'top'} onChange={onChange}/>
        : <Loader/>
};

BasesSelect.propTypes = {
    value:  PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default BasesSelect;