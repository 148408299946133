import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useSubscriptionChangesApi = () => {
    const defaultOrdersApi = useDefaultEndpoints('subscription-changes');
    const {request} = useApi();
    const changeSubscription = async (amount, subscriptionId, clientId, comment) =>
        request('put', `/subscription-changes/change`, {amount, subscriptionId, clientId, comment})

    return {...defaultOrdersApi, changeSubscription}
};