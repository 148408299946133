import _ from 'lodash';

const initialState = {
    name: '',
    phone: undefined,
    price_currency: 'RUB',
    city: '',
    coordinates: null,
    country: '',
    house: '',
    postcode: '',
    street: '',
    arrival_guide_url: '',
    url: '',
    boats: [],
    events_ids: [],
    files: undefined,
    is_open: true,
    loading: true,
    timezone: '+0300',
    reload: false
};
const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value};
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'General'
            return {
                ...state,
                activeTab: action.payload
            }
        case 'setLoading':
            // value: false'
            return {
                ...state,
                loading: action.payload,
            }

        case 'setFormData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        // Data editing block
        case 'setBase':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload,
                loading: false
            }

        case 'setBaseData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        // Data editing block
        case 'setBaseAddress':
            // value: SyntheticEvent
            return {
                ...state,
                address_id: {
                    ...state.address_id,
                    ...handleInputChange(action.payload)
                }
            }

        case 'changeBoatsAmount':
            // value: {index: 1, value: -1}
            const updatedBoats = _.cloneDeep(state.boats)
            updatedBoats[action.payload.index].amount += ~~action.payload.value
            return {
                ...state,
                boats: updatedBoats
            }

        case 'addNewBoat':
            // value: {class: "Луч", amount: 8}
            const newBoats = _.cloneDeep(state.boats)
            newBoats.push(action.payload)
            return {
                ...state,
                boats: newBoats
            }

        case 'setImg':
            // value: File
            return {
                ...state,
                files:
                action.payload
            }

        case 'deleteBoat':
            // value: "Луч"
            return {
                ...state,
                boats: state.boats.filter(boat => boat.class !== action.payload)
            }

        case 'triggerOpen':
            // value: "Луч"
            return {
                ...state,
                is_open: !state.is_open
            }

        case 'refreshFields':
            return {...initialState}
        case 'setForm':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export {reducer, initialState};