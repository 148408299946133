import {useRuleAccess} from "../../lib/utils/frontRules";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import {frk} from "sv-common/constants/frontRoles";

const AdventureRemoveKeyHandler = {
    [PRODUCT_TYPES.PRACTICES]: frk["PRACTISE-LIST-ITEM-REMOVE"],
    [PRODUCT_TYPES.ARRIVALS]: frk["ADVENTURES-LIST-ITEM-REMOVE"],
    [PRODUCT_TYPES.CHARTERS]: frk["CHARTERS-LIST-ITEM-REMOVE"],
}

const ArrivalsRemoveKeyHandler = {
    [PRODUCT_TYPES.PRACTICES]: frk["PRACTISE-ITEM-ARRIVALS-LIST-ROW-REMOVE"],
    [PRODUCT_TYPES.ARRIVALS]: frk["ADVENTURES-ITEM-ARRIVALS-LIST-ROW-REMOVE"],
    [PRODUCT_TYPES.CHARTERS]: frk["CHARTERS-ITEM-ARRIVALS-LIST-ROW-REMOVE"],
}

export const AdventureRemoveRuleProvider = ({ typeForRule, children, isArrivals }) => {
    const { check } = useRuleAccess();

    const handler = isArrivals ? ArrivalsRemoveKeyHandler : AdventureRemoveKeyHandler;

    const key = handler[typeForRule];

    if (key) {
        if (check(key)) {
            return children
        } else {
            return null;
        }
    }

    return children;
}