import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import {ModalConfirmation} from "../../generic/modals";
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {FaPlus} from "react-icons/fa";
import {DataTable, field} from "../../generic/tables";
import dayjs from "dayjs";

const Directory= withBreadcrumbs(() => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('constructor-directories');

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [item, setItem] = useState();
    const [reload, setReload] = useState(false);

    let history = useHistory();

    const toggleModalConfirmation = () =>
        setIsModalConfirmationOpen(!isModalConfirmationOpen);

    const onConfirmModal = () => deleteEntry(item.id).then(toggleModalConfirmation);

    const onDelete = (v) => {
        setItem(() => v);
        toggleModalConfirmation();
    };

    const onClick = v => history.push('/constructor/directory/edit/' + v.id);

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('common.deleting')} text={t('common.areYouSure')}
                           cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
                           onConfirm={() => { onConfirmModal().then(() => setReload(!reload)) }}
        />
        <TableCard>
            <div className="justify-content-between mb-4">
                <button className={'btn btn-sm btn-primary btn-rounded'}
                        onClick={() => history.push('/constructor/directory/edit/')}>
                    <FaPlus/>
                    &nbsp; {t('common.create')}
                </button>
            </div>
            <DataTable
                findAndCount={findAndCountEntries}
                fields={[
                    field(t('common.description'), true, 'description'),
                    field(t('constructor.createdAt'), true, 'created_at',
                        res => dayjs(res.created_at).format('DD.MM.YYYY HH:MM')),
                    field(t('constructor.updatedAt'), true, 'updated_at',
                        res => dayjs(res.updated_at).format('DD.MM.YYYY HH:mm')),
                ]}
                sortField='created_at'
                sortDir='desc'
                name='directory-list'
                reload={reload}
                onDelete={onDelete}
                onClick={onClick}
            />
        </TableCard>
    </>
});

export default Directory;