import React, {useEffect, useState} from "react";
import {ExpandableLink, MenuLink, SubMenuLink} from "../../generic/links";
import {routes as staticRoutes} from "../../../lib/routes";
import PropTypes from 'prop-types';
import {useApi} from "../../../lib/api/useApi";
import person from '../../../styles/assets/images/person/1.png'
import {useLocation} from "react-router-dom";
import _ from 'lodash'
import {Loader} from "../../generic/loader";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {FaPlus} from "react-icons/fa";
import {t} from "i18next";
import {COUNTRY} from "../../../lib/utils/constants";
import {useRuleAccess} from "../../../lib/utils/frontRules";

const Sidebar = (props) => {
    const {userRole, userEmail} = useApi();
    const {getEntries} = useDefaultEndpoints('bases');
    const [routes, setRoutes] = useState(staticRoutes);
    const [loading, setLoading] = useState(true);
    const { check } = useRuleAccess();
    let location = useLocation();

    const isRouteShowed = (r) => {
        const basicFilter = (!r.access || r.access.includes(userRole)) && !r.hideInCountries?.includes(COUNTRY);

        return basicFilter && (r.accessKey ? check(r.accessKey) : true);
    }

    useEffect(() => {
        // TODO подумать есть ли смысл положить базы в глобальный контекст и в интерфейсе баз не делать новый запрос
        // + это слишком специфичный код (мб вынести в хук целиком)
        // + не будет апдейтиться сайдбар при таком кейсе. А если делать через глобальный контекст и обновлять его
        // при создании - будет
        getEntries({is_archived_ne: true, _sort: 'base_order:ASC', _or: [{ hide_in_menu_ne: true }, { hide_in_menu_null: true }]}).then(bases => {
            const loadedBases = bases.map(base => ({
                is_kids: base.is_kids,
                title: base.name,
                to: '/bases/' + base.url + '/events',
                access: ['Super-admin', 'Administrator', 'Sport', 'Call-center'],
                extraLinks: [
                    {
                        link: <div className={'btn btn-light btn-rounded p-0 ml-2'} title={'Создать событие'}>
                            <FaPlus/></div>,
                        to: '/bases/' + base.url + '/event',
                    }
                ],
                accessKey: `SIDEBAR-BASES-ENTITY-ID-${base.id}`
            }))
            setRoutes((routes) => {
                const updatedRoutes = _.cloneDeep(routes);
                updatedRoutes[0].children = [...updatedRoutes[0].children, ...loadedBases.filter(base => !base.is_kids)];
                updatedRoutes[5].children = [...updatedRoutes[4].children, ...loadedBases.filter(base => base.is_kids)];
                return updatedRoutes;
            });
        }).then(() => setLoading(() => false));
    }, [])
    return (loading ? <Loader/> :
            <>
                <div className={"overlay " + (props.active ? 'active' : '')}>
                </div>
                <nav className={"sidebar sidebar-light sidebar-offcanvas " + (props.active ? "active" : "")}
                     id="sidebar">
                    <ul className="nav">
                        <li className="profile">
                            <div className="profile-wrapper">
                                <img src={person} alt="profile"/>
                                <div className="profile-details">
                                    <p className="name">{userEmail}</p>
                                    <small className="designation">{userRole}</small>
                                </div>
                            </div>
                        </li>

                        {routes.filter(isRouteShowed).map(route =>
                            route.children ?
                                <ExpandableLink key={route.to} to={route.to} name={t(route.title)} icon={route.icon}
                                                linkClickable={route.linkClickableAccessKey ? check(route.linkClickableAccessKey) : route.linkClickable} location={location} {...props}
                                                isOpenConstantly={route.isOpenConstantly}>
                                    {
                                        route.children.filter(isRouteShowed).map((subRoute, index) =>
                                            <SubMenuLink key={subRoute.to + index} location={location} exact
                                                           extraLinks={subRoute.extraLinks} to={subRoute.to}
                                                           {...props}>{t(subRoute.title)}</SubMenuLink>
                                        )
                                    }
                                </ExpandableLink> :
                                <MenuLink key={route.to} name={route.title} icon={route.icon} to={route.to}
                                          location={location} {...props}/>
                        )}
                    </ul>
                </nav>
            </>
    );
}

Sidebar.propTypes = {
    active: PropTypes.bool
};

export default Sidebar;
