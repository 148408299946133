import React, { useEffect } from 'react';
import {Row, Col, FormGroup, Input, Label} from "reactstrap";
import {SelectControllerRHF, TextControllerRHF} from "../../../../../generic/fields";
import {requiredRule} from "../../../../../common/reactHookFormsFields/rules";
import {t} from "i18next";
import {getCityOptions} from "../../../EditModalChildren";
import TeachersInput from '../../../../inputs/TeachersInput';

const AddressBlock = ({state, dispatch, control, errors}) => {
    const setFormData = (e) => dispatch({type: 'setFormData', payload: e})

    const selectOptions = getCityOptions();

    useEffect(() => {
      if (selectOptions.length === 1) {
        setFormData({ target: { name: 'place', value: selectOptions[0].props.value } });
      }
    }, [selectOptions.length]);

    return (
        <Row>
            <TeachersInput entity={state} setEntity={(value) => dispatch({type: 'setForm', payload: value})} />
            <Col xs={12}>
                <FormGroup>
                    <SelectControllerRHF inputName={"place"} control={control} setValue={setFormData} defaultValue={state.place}
                                       label={t('school.theory.addressBlock.place')} isRequired errors={errors} rules={requiredRule}
                                       placeholder={t('school.theory.addressBlock.moscow')} options={selectOptions}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={"address"} control={control} setValue={setFormData}
                                       defaultValue={(state.is_online ? t('school.theory.addressBlock.online') : state.address) || ''}
                                       label={t('school.theory.addressBlock.address')} isRequired errors={errors} rules={requiredRule}
                                       placeholder={t('school.theory.addressBlock.moscow')}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Label>{t('school.theory.addressBlock.coordinates')}</Label>
                    <Input name={'coordinates'} value={state.coordinates || ''} type={'text'}
                           placeholder={'11.111111 22.222222'} onChange={setFormData}/>
                </FormGroup>

            </Col>
        </Row>
    )
};

export default AddressBlock;