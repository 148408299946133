import './style.scss';
import { generalHrefHoc } from './GeneralHrefComponent';
import ProductHrefAdmin from './ProductHrefAdmin';

const GroupHrefGenerator = ({ getParentEntry, ...rest }) => {
  const linkFun = async (preparedProductFunc) => {
    let preparedProduct = await preparedProductFunc();
    return `/school/${preparedProduct.type === 'lecture' ? 'lectures' : preparedProduct.type}/group/view/${preparedProduct.url}`;
  }

  return <ProductHrefAdmin {...rest} linkFun={linkFun} />
}

const groupHrefHoc = (Component) => (props) => <Component
  {...props}
  name={props.linkName || 'группа'}
  endpoint='groups'
/>

export default groupHrefHoc(generalHrefHoc(GroupHrefGenerator));