import React from 'react';
import PropTypes from 'prop-types';
import {QUEUE_STATUSES_TRANSLATIONS_LANG_ADAPTER} from "sv-common/constants/certificates";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {datetime} from "../../../../../lib/utils/format";
import {t} from "i18next";
import i18n from "../../../../../lib/utils/i18n";

const actionToRu = (action, language) => QUEUE_STATUSES_TRANSLATIONS_LANG_ADAPTER[language][action]

const QueueStatus = ({state}) => {
    const { language } = i18n;

    return (
        <div>
            <Form>
                <Row>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label>
                                {t('management.certificates.queueStatus.status')}
                            </Label>
                            <Input disabled value={actionToRu(state.queue_status, language) || t('management.certificates.queueStatus.notDefined')}/>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label>
                                {t('management.certificates.queueStatus.dateOfLastAction')}
                            </Label>
                            <Input disabled
                                   value={state.queue_action_date ? datetime(state.queue_action_date) : t('management.certificates.queueStatus.notDefined')}/>
                        </FormGroup>
                    </Col>

                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label>
                                {t('management.certificates.queueStatus.lastRecipientEmail')}
                            </Label>
                            <Input disabled value={state.queue_action_email || t('management.certificates.queueStatus.notDefined')}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('management.certificates.queueStatus.error')}
                            </Label>
                            <Input type={'textarea'} style={{height: '100px'}} disabled value={state.queue_error_message || ''}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

QueueStatus.propTypes = {
    state: PropTypes.object.isRequired,
};

export default QueueStatus;