import React from "react";
import {FormText} from "reactstrap";
import {Controller} from "react-hook-form";
import {Col, Form, FormGroup, Row} from "reactstrap";
import {requiredRule} from "../../../lib/utils/helper";
import {SelectControllerRHF} from "../../generic/fields";
import {CHECK_TYPES_OPTIONS} from "../../../lib/utils/helpers/checks";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => (
    <Form>
        <Row>
            <Col xs={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.checks.name')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.checks.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <SelectControllerRHF inputName={'product_type'} control={control}
                                         setValue={e => handleInput(e, setEntity)}
                                         isRequired label={t('directories.checks.type')}
                                         rules={requiredRule} defaultValue={entity.product_type || ''}
                                         errors={errors} options={[
                        <option value={''} disabled>{t('directories.checks.chooseType')}</option>,
                        ...CHECK_TYPES_OPTIONS
                    ]}
                    />
                </FormGroup>
            </Col>
        </Row>
        <FormText>
            {t('directories.checks.varToUse')} <br/>
            <b>{'<%=base%>'}</b>{t('directories.checks.baseVarInfo')}<br/>
            <b>{'<%=yacht%>'}</b>{t('directories.checks.yachtVarInfo')}<br/><br/>

            {t('directories.checks.example')}<b>{'<%=yacht%>'}</b> <br/>
            {t('directories.checks.exampleRes')}<b>SV20</b>
        </FormText>
    </Form>
)

export default EditModalChildren;
