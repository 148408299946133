import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {UsersList} from '../../administration/users';
import {t} from "i18next";

const ViewModal = ({isOpened, triggerModal, onClick}) => {
    return (
        <Modal isOpen={isOpened} toggle={triggerModal} className={'edit-modal'} size={'lg'}>
            <ModalHeader>{t('school.theory.viewTeacherModal.pickTeacher')}</ModalHeader>
            <ModalBody>
               <UsersList onClick={onClick} isInline/>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Row>
                        <Col>
                            <Button block color="primary" outline onClick={triggerModal}>
                                {t('school.theory.viewTeacherModal.close')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ViewModal;

ViewModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
}
