import React from 'react';
import {Button, Col, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import {field} from "../../../../generic/tables";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {FaRegCommentAlt, FaTrash} from 'react-icons/fa'
import {CopyLink} from "../../../../generic/buttons";
import {ElementWithPermissions} from "../../../../common/formElements";
import {ENTITIES, getPermissions} from "../../../../../lib/utils/hooks/useRoleSystem";
import { AdventureRemoveRuleProvider } from "../../../RuleProvider";
import StatusIndicator from "../../../../generic/statusIndicator/StatusIndicator";
import {LoadBadge} from "../../../../generic/badges";

dayjs.extend(customParseFormat)

export const upperLinkedFields = (getArrivalUrl, deleteArrival, typeForRule) => [
    field("Заезд", true, "start_date", res =>
        <Row>
            <Col xs={12}>
                {
                    dayjs(res.start_date).format('DD.MM.YYYY - ')
                    + dayjs(res.end_date).format('DD.MM.YYYY')
                }
            </Col>

        </Row>),
    field("Имя", true, "name", res => <Row><Col>{res.name}</Col></Row>),
    field("Цена", true, "price_value", res => <Row><Col>{res.price_value} {res.price_currency}</Col></Row>),
    field("Записи", true, "booked_to_total", res =>
        <Row>
            <Col xs={12} className={`mt-0`}>
                {res.booked_sits} {` (${res.total_sits - res.booked_sits - res.available_reserved_sits}) `} / {res.total_sits} <LoadBadge value={res.booked_to_total}/>
            </Col>
        </Row>),
    field("Ссылка", false, "url", res => <CopyLink showLink={false} href={getArrivalUrl(res)}/>),
    field("", false, "comment", res =>
        <Row>
            <Col>
                {!!res.comment && <>
                    <FaRegCommentAlt id={"comment-" + res.id}/>
                    <UncontrolledPopover placement="left" target={"comment-" + res.id} trigger={'hover'}>
                        <PopoverBody>{res.comment}</PopoverBody>
                    </UncontrolledPopover>
                </>}
            </Col>
        </Row>),
    field("Статус", false, "status", res => <Row><Col> <StatusIndicator res={res}/></Col></Row>),
    field("", false, "comment", res =>
        <AdventureRemoveRuleProvider typeForRule={typeForRule} isArrivals>
            <Row>
                <Col>
                    <div className="btn-group">
                        <ElementWithPermissions disabled={!getPermissions(res.adventure_id.type === 'world' ? ENTITIES.ADVENTURES_WORLD : ENTITIES.ADVENTURES_RUSSIA).edit}>
                            <Button color="danger" size="xs" onClick={() => deleteArrival(res)}>
                                <FaTrash/>
                            </Button>
                        </ElementWithPermissions>
                    </div>
                </Col>
            </Row>
        </AdventureRemoveRuleProvider>),
]



