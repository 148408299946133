import { ENTITIES } from '../../../../../lib/utils/hooks/useRoleSystem';

export const getPermissionEntity = (adventure) => {
  let entity;

  if (adventure.is_school) {
    entity = ENTITIES.GROUPS_PRACTICE;
  } else {
    entity = adventure.type === 'world' ? ENTITIES.ADVENTURES_WORLD : ENTITIES.ADVENTURES_RUSSIA;
  }

  return entity;
}