import React, {useEffect, useReducer, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {initialState, reducer} from "./reducer";
import {toast} from "react-toastify";
import {Loader} from "../../../generic/loader";
import {TableCard} from "../../../generic/cards";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import qs from 'qs';

import tabList from "./tabList";
import {useForm} from "react-hook-form";
import {useApi} from "../../../../lib/api/useApi";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {useClientsApi} from "../../../../lib/api/clients";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import {t} from "i18next";

const User = withBreadcrumbs(({}) => {
    let history = useHistory()
    let {id} = useParams();
    const {request} = useApi();
    const {pathname, search} = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {getEntry, createEntry, editEntry, validatePhoneExists} = useClientsApi();
    const [isLoading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("personal");
    const permissions = useRoleSystem(ENTITIES.CLIENTS);

    const toggleTab = tab => {
        history.replace(`${pathname}?t=${tab.url}`)
        if (activeTab !== tab.url) setActiveTab(tab.url)
    };

    useEffect(() => {
        if (id)
            getEntry(id).then(res => {
                dispatch({type: 'setClient', payload: res});
                setLoading(() => false);
            })        // eslint-disable-next-line
        else
            setLoading(() => false);
        if (!search) history.replace(`${pathname}?t=${activeTab}`);
        let {t} = qs.parse(history.location?.search.slice(1))
        if (t)
            setActiveTab(t)
    }, [history.location.pathname, id])


    const {handleSubmit, control, errors, formState: { dirtyFields }} = useForm({reValidateMode: 'onBlur',});

    const createOrUpdateUser = async () => {
        const clientId = state.id;
        const exists = (dirtyFields.phone) ? await validatePhoneExists(state.phone, clientId) : false;
        if (exists) return toast.error(t('management.users.phoneAlreadyExist'));
        if (clientId) {
            if (!state.user_data || !state.user_data.id) {
                request('post', '/clients/user', {email: state.email, password: state.user_data.password, role: state.user_data.role, clientId: state.id})
                    .then(res => {
                        const {user_data, ...filteredState} = state
                        return editEntry(state.id, {filteredState})
                    })
                    .then(() => toast.success(t('management.users.success')))

            }
            else
                editEntry(clientId, state).then(() => toast.success(t('management.users.success')))

        }
        else {
            createEntry(state).then(res => history.push('/administration/users/edit/' + res.id))
        }

    }

    const onSubmit = handleSubmit(async (data) => {await createOrUpdateUser()},
        (error) => setActiveTab (error.first_name ?  'personal' : 'tech'))

    const filteredTabList = tabList({state, dispatch}).filter(tab => tab.isDisplayed !== false);

    return isLoading
        ? <Loader/>
        : <TableCard>
            <Row className={'mb-3 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <div className={'display-3'}>
                        {id ? t('management.users.editing') : t('management.users.creating')}{' '}{t('management.users.user')}
                    </div>
                </Col>
                <Col className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('management.users.save')}
                        </Button>
                    </ElementWithPermissions>
                </Col>
                <Col className={'col-auto'}>
                    <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                            onClick={() => history.push('/administration/users')}>
                        {t('management.users.cancel')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={`align-items-stretch no-gutters row-cols-${filteredTabList.length}`}>
                        {filteredTabList
                            .map(tab =>
                                <NavItem
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={"h-100 " + (activeTab === tab.url && "active" ? "active" : "")}
                                             onClick={() => toggleTab(tab)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {tabList({state, dispatch, control, errors}).filter(tab => tab.isDisplayed !== false).map(tab => (
                            <TabPane tabId={tab.url} key={tab.name}>
                                {tab.child}
                            </TabPane>
                        ))}
                    </TabContent>
                </Col>
            </Row>

        </TableCard>
})

export default User;
