import React from 'react';
import './style.scss';

const Switch = ({onChange, value, name='', disabled}) => {
    return (
        <label className="switch">
            <input disabled={disabled} type="checkbox" name={name} onChange={onChange} checked={value}/>
            <span className="slider round"/>
        </label>
    )
}

export default Switch;