import { adventureComponentTypes } from './constants';

const ruBreadcrumbsMapper = (path, type = adventureComponentTypes.adventures) => {
    return urls(type)[path];
}

const urls = (type) => ({
    [`/${type}/view/:url/:arrival_url`]: [
        {ru: ':type', link: `/${type}/:type`},
        {ru: ':url', link: `/${type}/view/:url`},
        {ru: ':arrival_url', link: ''}
    ],
    [`/${type}/view/:url`]: [
        {ru: ':type', link: `/${type}/:type`},
        {ru: ':url', link: ''},
    ],
    [`/${type}/:type/edit/:url`]: [
        {ru: ':type', link: `/${type}/:type`},
        {ru: ':url', link: `/${type}/view/:url`},
        {ru: 'school.breadcrumbsAndTitle.editing', link: ''}
    ],
    [`/${type}/:type/edit/`]: [
        {ru: ':type', link: `/${type}/:type`},
        {ru: 'school.breadcrumbsAndTitle.creating', link: ''}
    ],
    [`/${type}/:type`]: [
        {ru: ':type', link: ''},
    ]
});

export default ruBreadcrumbsMapper;