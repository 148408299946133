import React, {useMemo} from 'react';
import {Card} from "../../generic/cards";
import {routes} from "../../../lib/routes";
import {useApi} from "../../../lib/api/useApi";
import {generateLinks} from "../Dashboard";
import {t} from "i18next";

AdministrationCard.propTypes = {};

function AdministrationCard() {
    const {userRole} = useApi();
    const administration = useMemo(() => routes.find(r => t(r.title) === t('routes.administration')), [])
    return (
       <Card title={t('routes.administration')} className={'col-xs-3 col-md-6 col-lg-6 col-xl-3 grid-margin stretch-card'}>
           {generateLinks(administration, userRole)}
       </Card>
    );
}

export default AdministrationCard;