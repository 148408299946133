import React from 'react';
import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {FaExclamation} from "react-icons/fa";
import currency from "currency.js";


const PaidWithRubles = ({res}) => {
    const payments = res.payments.filter(it => it.invoice_closed)
    const total = payments.reduce((acc, it) => acc.add(currency(it.payment_value)), currency(0))
    return     <>
        <FaExclamation id={"is-ruble-" + res.id} size={'20px'} color={'green'}/>
        <UncontrolledPopover placement="left" target={"is-ruble-" + res.id}
                             trigger={'hover'}>
            <PopoverBody>
                {
                    payments.map((it, i) => {
                        return <div key={it.invoice_num}>{i+1} часть: {currency(it.payment_value, {fromCents: true, precision: 2}).value} ({it.payment_size}%)</div>
                    })
                }
                Всего: {currency(total.value, {fromCents: true, precision: 2}).value}
            </PopoverBody>
        </UncontrolledPopover>
        &nbsp;
    </>
}


export default PaidWithRubles;