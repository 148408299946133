import { COMMON_EVENT_STATUSES } from 'sv-common/constants/statuses';
import { t } from 'i18next';

export const getConfirmationText = (clientId, entity) => {
  const postfixKey = clientId ? 'Client' : 'Order';

  if (entity.status === COMMON_EVENT_STATUSES.FINISHED) {
    return t(`common.forms.movePaymentModal.areYouSurePast${postfixKey}`);
  } else if (entity.is_closed || (Object.hasOwn(entity, 'is_open') && !entity.is_open)) {
    return t(`common.forms.movePaymentModal.areYouSureClosed${postfixKey}`);
  }

  return t(`common.forms.movePaymentModal.areYouSure${postfixKey}`);
}