import React from 'react'
import ChooseClient from './tabs/ChooseClient';
import CreateNew from "./tabs/CreateNew";
import {t} from "i18next";

const tabList = ({state, dispatch, form = {}}) => {
    return (
        [
            {
                name: t('management.instructors.editModal.createNewInstructor'),
                child: <CreateNew state={state} setState={dispatch} form={form}/>,
                isDisplayed: !state.id,
                url: 'new'
            },
            {
                name: state.id ? t('management.instructors.editModal.instructorData') : t('management.instructors.editModal.chooseFromExisting'),
                child: <ChooseClient state={state} setState={dispatch} form={form}/>,
                isDisplayed: true,
                url: 'existing'
            },
        ]
    )
}

export default tabList;