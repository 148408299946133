import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";

const useFilesApi = () => {
    const {getEntries, countEntries} = useDefaultEndpoints('public-files');

    const findAndCount = (props) => {
        return Promise.all([
            getEntries(props),
            countEntries(props)
        ]).then(([data, total]) => ({data, total}));
    }

    return {findAndCount}
}

export default useFilesApi