const ruTitleMapper = (path) => {
    return urls[path];
};

const directories = 'directories.breadcrumbs.directories'

const urls = {
    '/directories/difficulties': [{ru: directories}, {ru: 'directories.breadcrumbs.difficultyLevels'}],
    '/directories/user_fields': [{ru: directories}, {ru:'directories.breadcrumbs.userFields'}],
    '/directories/user_fields_templates': [{ru: directories}, {ru: 'directories.breadcrumbs.userFieldsTemplates'}],
    '/directories/options': [{ru: directories}, {ru: 'directories.breadcrumbs.travelOptions'}],
    '/directories/currencies': [{ru: directories}, {ru: 'directories.breadcrumbs.currencies'}],
    '/directories/boats_classes': [{ru: directories}, {ru: 'directories.breadcrumbs.boatsClasses'}],
    '/directories/shirt_sizes': [{ru: directories}, {ru: 'directories.breadcrumbs.shirtSizes'}],
    '/directories/public_contracts': [{ru: directories}, {ru: 'directories.breadcrumbs.publicContacts'}],
    '/directories/extra_materials': [{ru: directories}, {ru: 'directories.breadcrumbs.extraMaterials'}],
    '/directories/legal_entities': [{ru: directories}, {ru: 'directories.breadcrumbs.legalEntities'}],
    '/directories/subscriptions': [{ru: directories}, {ru: 'directories.breadcrumbs.subscriptions'}],
    '/directories/checks': [{ru: directories}, {ru: 'directories.breadcrumbs.checks'}],
    '/directories/corp-clients': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.corpClients', link: ''}],
    '/directories/transaction-status': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.transactionStatus', link: ''}],
    '/directories/coordinators': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.coordinators', link: ''}],
    '/directories/base_addresses': [{ru: directories, link: ''}, {ru: 'directories.breadcrumbs.base_addresses', link: ''}],
};

export default ruTitleMapper;