import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import {Badge} from "reactstrap";
import DirectoryTemplate from "../DirectoryTemplate";

const AvailableOptions = (props) => <DirectoryTemplate
    fields={[
        field("Опция", true, "value"),
        field("Шаблон", true, "tags", res => <div>
                {(res.tags || '').split(',').map((group, id) =>
                    <Badge className={'mr-2'} key={id}
                           color={"success"}>{group}</Badge>)}
            </div>)
    ]}
    entityName={'available-options'}
    EditModalChildren={EditModalChildren}
    editable
    deletable
    {...props}
/>


export default AvailableOptions;