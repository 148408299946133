import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {FormGroup} from "reactstrap";
import React from "react";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import dayjs from "dayjs";

const TimePicker = ({name, label, setEntity, control, errors, defaultValue, isRequired}) => {
    const handleTimeChange = (e) => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: dayjs(value, 'HH:mm').format('HH:mm:ss.SSS')}))
        return value;
    }

    return <FormGroup>
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={isRequired ? {required: 'Поле обязательно'} : ''}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation
                    invalid={invalid} errors={errors} label={label} name={name} isRequired={isRequired} type="time"
                    onBlur={onBlur} value={value}
                    onChange={e => {
                        onChange(handleTimeChange(e));
                    }}
                />
            }
        />
    </FormGroup>
}

export default TimePicker