import {Col, Row} from "reactstrap";
import {t} from "i18next";
import {DataTable} from "../../../generic/tables";
import {useOrdersApi} from "../../../../lib/api/orders";
import {useHistory} from "react-router-dom";

const getFilters = (entity, entityOrderName) => {
    const cancelledFilters = {
        has_paid: false,
        has_expired: true,
        [entityOrderName]: entity.id,
        master_order_null: true
    }

    return ({
        _where: {
            _or: [
                {
                    order_status: 'approved',
                    ...cancelledFilters
                },
                {
                    order_status: 'closed',
                    ...cancelledFilters
                },
                {
                    order_status: 'partially_paid',
                    ...cancelledFilters
                },
                ...(entity.moved_orders_ids?.length > 0 ? [{
                    id_in: entity.moved_orders_ids,
                    is_moved: true
                }] : [])
            ]
        }
    })
}

const CancelledAndMovedOrdersList = ({name, fields, entity, entityOrderName, reload}) => {
    const {findAndCountEntries} = useOrdersApi('orders');
    const history = useHistory();
    const onClick = order => {
        order.master_order ? history.push('/administration/orders/edit/' + order.master_order.id)
            : history.push('/administration/orders/edit/' + order.id)
    }

    return (
        <Row className={'mt-5'}>
            <Col xs={10}>
                <div className={'display-4 mb-3'}>
                    {t('common.productOrderList.movedAndCancelledPurchases')}
                </div>
            </Col>
            <Col xs={12}>
                <DataTable
                    findAndCount={findAndCountEntries}
                    filters={getFilters(entity, entityOrderName)}
                    fields={fields}
                    sortField="created_at"
                    sortDir="desc"
                    name={name}
                    reload={reload}
                    onClick={onClick}
                    rowClass={res => {
                        if (res.has_expired) return 'opacity-50'
                    }}
                />
            </Col>
        </Row>
    )
}

export default CancelledAndMovedOrdersList