import React from 'react';
import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {FaTimes} from "react-icons/fa";
import {AiOutlineWarning} from "react-icons/ai";

const HasViolations = ({res}) =>
    <>
        <AiOutlineWarning id={"has_violations-" + res.id} size={'20px'} color={'red'}/>
        <UncontrolledPopover placement="top" target={"has_violations-" + res.id}
                             trigger={'hover'}>
            <PopoverBody>
                В блэклисте
            </PopoverBody>
        </UncontrolledPopover>
        &nbsp;
    </>

export default HasViolations;