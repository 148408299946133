import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../../generic/tables";
import {datetime} from "../../../../../lib/utils/format";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {useHistory} from "react-router-dom";
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";
import GenericHrefGenerator from "../../../../common/hrefGenerators/GenericHrefGenerator";
import {t} from "i18next";
import {Button, Col, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {TextControllerRHF} from "../../../../generic/fields";
import {GlobalState} from "../../../../../lib/api/globalState";
import { ObjectsDifferences } from "sv-common/helpers";
import {REASON_TYPES} from "sv-common/constants/certificates";
import changesObjectTranslates from './changesTranslates';

const reasonToLabel = ({change_reason}) => {
    switch (change_reason) {
        case REASON_TYPES.PURCHASE:
            return t('management.certificates.history.purchase');
        case REASON_TYPES.MANUAL:
            return t('management.certificates.history.manual');
        case REASON_TYPES.OPERATOR_DOWNLOAD:
            return t('management.certificates.history.operatorDownload');
        case REASON_TYPES.TYPE_CHANGE:
            return t('management.certificates.history.typeChange');
        case REASON_TYPES.COMMENT:
            return t('management.certificates.history.comment');
        case REASON_TYPES.UPDATE:
            return t('management.certificates.history.update');
        case REASON_TYPES.CREATE:
            return t('management.certificates.history.create');
        default:
            return change_reason
    }
}

const extraFormattedRow = (extra_info) => {
  let extraArray = [];

  try {
    const parsedExtra = JSON.parse(extra_info);
    const diffObj = new ObjectsDifferences(null, null, changesObjectTranslates);
    extraArray = diffObj.getFormattedArray(parsedExtra);

    return extraArray.map(i => <p>{i}</p>);
  } catch (e) {
    return extra_info;
  }
}

const History = ({state}) => {
    let history = useHistory()
    const {createEntry, findAndCountEntries} = useDefaultEndpoints('certificate-changes');
    const selectArrow = (change) => {
        if (change === 0) return ''
        if (change > 0) return <TiArrowSortedUp size={'20px'} color={'green'}/>
        if (change < 0) return <TiArrowSortedDown size={'20px'} color={'red'}/>
    }

    const [tableReload, setReload] = useState(false);
    const {handleSubmit, control, errors, reset} = useForm({reValidateMode: 'onBlur',});
    const [globalState] = useContext(GlobalState)

    const  addComment = async ({comment}) => {
        const data = {
            change_reason: 'comment',
            certificate_id: state.id,
            user_email: globalState.userEmail,
            order_id: state.order.id,
            comment
        }
        await createEntry(data)
        reset()
        setReload(!tableReload)
    }

    useEffect(() => {
        setReload(!tableReload)
    }, [state.size_left])
    return (
            <div>
                <form onSubmit={handleSubmit(addComment)} className="mb-4">
                    <Label for="comment">{t('management.certificates.history.comment')}</Label>
                    <Row>
                        <Col sm={9} md={6} lg={3}>
                            <TextControllerRHF inputName={'comment'} control={control} label={''} isRequired
                                               placeholder={t('management.certificates.history.commentPlaceholder')}
                                               errors={errors} rules={{required: t('inputs.validation.required')}} />
                        </Col>
                        <Col xs="auto" sm={3} md="auto" className="d-flex justify-content-end mt-3 mt-sm-0">
                            <Button type="submit" color="primary">
                                {t('management.certificates.history.add')}
                            </Button>
                        </Col>
                    </Row>
                </form>
                <DataTable findAndCount={findAndCountEntries}
                           reload={tableReload}
                           filters={{certificate_id: state.id}}
                           fields={[
                               field(t('management.certificates.history.change'), true, "size_change", res => {
                                 if (res?.extra_info) {
                                   return extraFormattedRow(res?.extra_info);
                                 }

                                 return <>
                                     {res.size_change || res.comment || t('management.certificates.history.noChange')} &nbsp; {selectArrow(res.size_change)}
                                 </>}
                               ),
                               field(t('management.certificates.history.changeReason'), true, "change_reason", reasonToLabel),
                               field(t('management.certificates.history.whoApplied'), true, "user_email"),
                               field(t('management.certificates.history.product'), true, "action", res =>
                                   res.order_id &&
                                   <GenericHrefGenerator linkName={res.product_name || ''} fetchById
                                                         order={res.order_id}/>),
                               field(t('management.certificates.history.time'), true, "created_at", res => datetime(res.created_at))
                           ]}
                           sortField="created_at"
                           sortDir="desc"
                           name="certificate_changes-list"
                           onClick={record => record.order_id?.id && history.push('/administration/orders/edit/' + record.order_id?.id)}
                />
            </div>
        );
    }
;

History.propTypes = {
    state: PropTypes.object.isRequired,
};

export default History;