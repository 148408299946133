import React, {useEffect} from 'react';
import i18n from "i18next";

export const withBreadcrumbs = Component => ({createTranslatedBreadcrumbsAndTitle, ...props }) => {
    const {language} = i18n;

    useEffect(() => {
        createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle({}, {})
    }, [language])

    return <Component {...props} />
}
