import React, {useState} from 'react'
import {TableCard} from "../generic/cards";
import {DataTable, field} from "../generic/tables";
import {BOOKING_URL} from "../../lib/api/useApi";
import {FaCheck, FaPlus, FaTimes} from 'react-icons/fa'
import {Button} from "reactstrap";
import {useHistory} from 'react-router-dom'
import {TextSearch} from "../generic/fields";
import {BsGear} from 'react-icons/bs'
import {openInNewTab} from "../../lib/utils/helper";
import {CopyLink} from "../generic/buttons";
import {withBreadcrumbs} from "../common/hoc/Breadcrumbs";
import {ElementWithPermissions} from "../common/formElements";
import {ENTITIES, useRoleSystem} from "../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import CommonToggle from '../common/formElements/Toggle';
import { showDangerToast, showSuccessToast } from '../generic/modals/EditModal';
import { useBasesApi } from '../../lib/api/bases';
import InfoTooltip from '../generic/InfoTooltip';
import {RuleAccessProvider} from "../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

const BaseList = withBreadcrumbs(({}) => {
    const {findAndCountEntries, setInMenuVisibility} = useBasesApi()
    const {edit, view} = useRoleSystem(ENTITIES.BASES);
    const [tableReload, setReload] = useState(false);
    const [filter, setFilter] = useState('');
    const filters = {
        _where: {
            _or: [
                [{'name_contains': filter}, {'is_archived_ne': true}],
                [{'country_contains': filter}, {'is_archived_ne': true}],
                [{'city_contains': filter}, {'is_archived_ne': true}],
            ],
        },
    };

    let history = useHistory();
    const onMiddleClick = (res) => openInNewTab('/bases/' + res.url + '/events')

    const getBaseURL = base => `${base.booking_url || BOOKING_URL}/sport/?bases=${base.url}`

    const changeVisibility = (val) => {
      setInMenuVisibility(val.id, !val.hide_in_menu)
        .then(() => {
          showSuccessToast(t('bases.base.statusReloaded'));
          setReload(r => !r);
        })
        .catch(e => showDangerToast(e.message))
    }

    return <>
        <TableCard>

            <div className={"row justify-content-end mb-3 pl-0 ml-0"}>
                <div className={"col-3 ml-10"}>
                    <TextSearch value={filter} onSearch={setFilter}
                                style={{height: '100%'}}
                                placeholder={t('bases.base.baseSearch')}
                                bsSize={'sm'}/>
                </div>
                <div className={"col-auto ml-3"}>
                    <ElementWithPermissions hidden={!view} disabled={!edit}>
                        <Button color={'primary'} className={'btn-rounded'} size={'sm'}
                                onClick={() => history.push('/bases/new')}>
                            <FaPlus/>
                            &nbsp; {t('bases.base.create')}
                        </Button>
                    </ElementWithPermissions>
                </div>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       reload={tableReload}
                       filters={filters}
                       fields={[
                           field(t("bases.base.name"), true, "name", res =>
                               <>
                                   <span className="title-underlined">{res.name}</span>
                                   <RuleAccessProvider accessKey={frk["BASES-LIST-ROW-GEAR"]}>
                                       <span className={'btn btn-light btn-rounded btn-sm p-1 ml-1'}
                                             title={'Настройки базы'}
                                             onClick={() => history.push(`/bases/${res.url}`)}> <BsGear/>
                                       </span>
                                   </RuleAccessProvider>
                               </>),
                           field(t("bases.base.phone"), true, "phone"),
                           field(t("bases.base.link"), true, "url", res =>
                               <CopyLink showLink={false} href={getBaseURL(res)}/>),
                           field(t("bases.base.country"), true, "country"),
                           field(t("bases.base.city"), true, "city"),
                           field(t("bases.base.order"), true, "base_order"),
                           field(t("bases.base.open"), true, "is_open", res =>
                               res.is_open ? <FaCheck size={'1.5em'} color={'green'}/> :
                                   <FaTimes size={'1.5em'} color={'red'}/>
                           ),
                           field(<>{t('inputs.hide')}<InfoTooltip text={t('bases.base.shot_in_admin_tooltip')} /></>, true, "hide_in_menu", res =>
                             <CommonToggle isActive={res.hide_in_menu} onChange={() => changeVisibility(res)} />
                           ),
                           field(t("bases.base.boats"), true, "boats", res =>
                               res.boats?.map(boat => boat.class).join(', ')),
                       ]}
                       sortField="base_order"
                       sortDir="asc"
                       name="bases-list"
                       onClick={(item) => history.push('/bases/' + item.url + '/events')}
                       onMiddleClick={onMiddleClick}
            />
        </TableCard>
    </>
})

export default BaseList;