import React from 'react'
import {Col, Form, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {

    return <Form>
        <Row>
            <Col lg={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="link"
                        defaultValue={entity.link || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.extraMaterials.link')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="www.example.com"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.extraMaterials.name')}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.extraMaterials.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
