import { adventureComponentTypes } from './constants';

const ruTitleMapper = (path, type = adventureComponentTypes.adventures) => {
    return urls(type)[path];
}

const urls = (type) => ({
    [`/${type}/view/:url/:arrival_url`]: [
        {ru: ':type'},
        {ru: ':url'},
        {ru: ':arrival_url'}
    ],
    [`/${type}/view/:url`]: [
        {ru: ':type'},
        {ru: ':url'},
    ],
    [`/${type}/:type/edit/:url`]: [
        {ru: ':type'},
        {ru: ':url'},
        {ru: 'school.breadcrumbsAndTitle.editing'}
    ],
    [`/${type}/:type/edit/`]: [
        {ru: ':type'},
        {ru: 'school.breadcrumbsAndTitle.creating'}
    ],
    [`/${type}/:type`]: [
        {ru: ':type'},
    ]
});

export default ruTitleMapper;