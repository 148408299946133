import React from 'react';
import {Col, Label, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {LoadBadge} from "../../../../../../generic/badges";
import {CopyLink} from "../../../../../../generic/buttons";
import {t} from "i18next";
import {BOOKING_URL} from "../../../../../../../lib/api/useApi";

const categoryToRu = (category) => {
    if (category === 'school') return t('school.theory.groupsList.captainSchool')
}

const MainInfo = ({state}) => {
    const groupBookingLink = `${BOOKING_URL}/school/${(state.type === "theory" ? "theory": "lectures")}/${state.url}`;

    return <Row>
        <Col xs={12}>
            <Row className={'mb-3'}>
                <Col>
                    <div className={'display-4'}>
                        {t('school.theory.mainInfo.brieflyAboutGroup')}
                    </div>
                    <Label for={'name'} className={'text-secondary'}>/{state.url}</Label>
                    <CopyLink href={groupBookingLink} showLink={false}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className={'badge badge-outline-secondary'} style={{color: "black", minWidth: '20em'}}>
                        {state.type === 'school' && <Row>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <Label>{categoryToRu(state.category)}</Label>
                            </Col>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <Label>{state.lessons_per_week} {t('school.theory.mainInfo.lessonsPerWeek')}</Label>
                            </Col>
                        </Row>}
                        <Row>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <Label>{state.place}</Label>
                            </Col>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <Label>{state.booked_sits || 0} {` (${state.reserved_sits - state.booked_sits}) `}/{state.total_sits}
                                  <LoadBadge value={state.booked_to_total || 0} className='ml-2 badge badge-outline-success badge-pill' />
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <p style={{ whiteSpace: 'break-spaces' }}>{`${state.teacher_id?.first_name || t('school.theory.groupsList.noPicked')} ${state.teacher_id?.last_name || ''}`}</p>
                            </Col>
                            <Col xs={6} className={'my-auto d-flex justify-content-start'}>
                                <Label>{state.price_value} {state.price_currency}</Label>
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col xs={8} className={'my-auto d-flex justify-content-start'}>
                                <Label>{state.comment}</Label>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default MainInfo;

MainInfo.propTypes = {
    state: PropTypes.object.isRequired,
}