import Select from 'react-select';

const sanitize = (v) => ({ value: v.id, label: v.title });

const RolesSelect = ({ allRoles, onChange, ref, value = [] }) => {
  const _onChange = (v) => {
    onChange(v?.map(i => allRoles.find(x => x.id === i.value)) || []);
  }

  return (
    <Select
      isMulti
      options={allRoles.map(sanitize)}
      onChange={_onChange}
      inputRef={ref}
      value={value?.map(sanitize)}
    />
  )
}

export default RolesSelect;