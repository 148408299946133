import dayjs from 'dayjs';
import React, { useState } from 'react';
import ExportButton from '../../../generic/buttons/ExportButton';
import { useOrdersApi } from '../../../../lib/api/orders';
import { RangedDatePicker } from '../../../generic/fields';
import { ElementWithPermissions } from '../index';
import { ENTITIES, useRoleSystem } from '../../../../lib/utils/hooks/useRoleSystem';
import { Col, Label } from 'reactstrap';
import { COMMON_PRODUCT_DIRECTIONS } from '../../../../lib/utils/constants';
import { t } from 'i18next';

const userMods = [
  { label: 'generic.exportButton.exportByAdventureDirection', type: COMMON_PRODUCT_DIRECTIONS.ADVENTURES },
  { label: 'generic.exportButton.exportByGroupDirection', type: COMMON_PRODUCT_DIRECTIONS.GROUPS },
  { label: 'generic.exportButton.exportByEventDirection', type: COMMON_PRODUCT_DIRECTIONS.EVENTS },
];

const ExportByDirectionButton = ({ direction, userId, directionId }) => {
  const { exportByDirection } = useOrdersApi();

  const permissions = useRoleSystem(ENTITIES.EXPORT_BY_DIRECTION);

  const [daysFilter, setDaysFilter] = useState({
    from: dayjs().toDate(),
    to: dayjs().add(1, 'y').toDate()
  })

  const onExport = (value) => exportByDirection(daysFilter.from, daysFilter.to, value || direction, userId, directionId);

  return (
    <ElementWithPermissions hidden={!permissions.view} disabled={!permissions.view}>
      <Col xs={4} className='mt-4 mb-4 p-0'>
        <Label>{t('generic.exportButton.exportOrdersByDirection')}</Label>
        <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
        <ExportButton
          exportFun={onExport}
          mods={!direction ? userMods : []}
          buttonTitle='generic.exportButton.export'
        />
      </Col>
    </ElementWithPermissions>
  );
}

export default ExportByDirectionButton;