import React, {useEffect, useState} from 'react';
import {TableCard} from "../../generic/cards";
import {Button, Col, FormGroup, Row} from "reactstrap";
import {RangedDatePicker} from "../../generic/fields";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";
import {DataTable, field} from "../../generic/tables";
import {BsGear} from "react-icons/bs";
import {shortDate} from "../../../lib/utils/format";
import {LoadBadge} from "../../generic/badges";
import {AdventuresList} from "../../adventures";
import {openInNewTab} from "../../../lib/utils/helper";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {t} from "i18next";

const Practices = withBreadcrumbs(() => {
    const findAndCountArrivals = useDefaultEndpoints('arrivals').findAndCountEntries;
    let history = useHistory();

    const [daysFilter, setDaysFilter] = useState({
        from: dayjs().toDate(),
        to: dayjs().add(1, 'y').toDate()
    });
    const [timeSelected, setTimeSelected] = useState('closest');
    const [gridType, setGridType] = useState('adventures');
    const [filters, setFilters] = useState({
        'start_date_gte': daysFilter.from,
        'end_date_lte': daysFilter.to
    });

    useEffect(() => {
        if (timeSelected === 'all')
            setFilters(() => ({}))

        if (timeSelected === 'closest')
            setFilters(() => ({
                'start_date_gte': daysFilter.from,
                'end_date_lte': daysFilter.to
            }))
    }, [timeSelected, daysFilter.to, daysFilter.from])


    const onMiddleClick = (res) => openInNewTab(`/school/practices/view/${res.adventure_id?.url}`)

    return <TableCard>
        <Row>
            <Col xs={'auto'}>
                <FormGroup>
                    {t('school.practices.gridType')}
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'adventures'}
                            onClick={() => setGridType(() => 'adventures')} active={gridType === 'adventures'}>
                        {t('school.practices.events')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'arrivals'}
                            onClick={() => setGridType(() => 'arrivals')} active={gridType === 'arrivals'}>
                        {t('school.practices.arrivals')}
                    </Button>
                </FormGroup>
            </Col>
        </Row>
        {gridType === 'arrivals' && <Row>
            <Col xs={'auto'}>
                <FormGroup>
                    {t('school.practices.classes')}
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'closest'}
                            onClick={() => setTimeSelected(() => 'closest')} active={timeSelected === 'closest'}>
                        {t('school.practices.closest')}
                    </Button>
                    <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'all'}
                            onClick={() => setTimeSelected(() => 'all')} active={timeSelected === 'all'}>
                        {t('school.practices.all')}
                    </Button>
                </FormGroup>
            </Col>
            {timeSelected === 'closest' && <Col xl={3} lg={5} md={8} xs={12} className={'float-right'}>
                <FormGroup>
                    <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"}/>
                </FormGroup>
            </Col>}
        </Row>}
        <Row>
            {gridType === 'adventures'
                ? <Col>
                    <AdventuresList is_school isInline/>
                </Col>
                : <Col>
                    <DataTable
                        findAndCount={findAndCountArrivals}
                        filters={{...filters, is_school: true}}
                        fields={[
                            field(t('school.practices.group'), true, "name", res => <>
                                <span className="title-underlined">{res.name}</span>
                                <span className={'btn btn-light btn-rounded btn-sm p-1 ml-1'}
                                      title={t('school.practices.arrivalSettings')}
                                      onClick={() => history.push(`/school/practices/view/${res.adventure_id?.url}/${res.url}`)}> <BsGear/>
                                   </span>
                            </>),
                            field(t('school.practices.country'), true, "country"),
                            field(t('school.practices.dates'), true, "start_date", res => `${shortDate(res.start_date)} - ${shortDate(res.end_date)}`),
                            field(t('school.practices.bookedToTotal'), true, "booked_to_total",
                                res => <>{res.booked_sits || 0}/{res.total_sits} <LoadBadge
                                    value={res.booked_to_total || 0}/></>
                            ),
                        ]}
                        sortField="start_date"
                        sortDir="desc"
                        name="groups-list"
                        onClick={(arrival) => history.push(`/school/practices/view/${arrival.adventure_id?.url}`)}
                        onMiddleClick={onMiddleClick}
                    />
                </Col>
            }
        </Row>
    </TableCard>
})

export default Practices;