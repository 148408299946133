import React, {useEffect, useState} from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {TableCard} from "../../generic/cards";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "../../generic/loader";
import RateBar from "./RateBar";
import {datetime} from "../../../lib/utils/format";
import GenericHrefGenerator from "../../common/hrefGenerators/GenericHrefGenerator";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {t} from "i18next";

const Feedback = withBreadcrumbs(({}) => {
    const {id} = useParams();
    let history = useHistory();
    const {getEntry} = useDefaultEndpoints('feedbacks');
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({});

    useEffect(() => {
        if (id)
            getEntry(id).then(res => {
                setState(() => ({...res}))
                setLoading(() => false);
            });

    }, [id]);

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setState(state => ({...state, [name]: value}))
    };

    return loading
        ? <Loader/>
        : <TableCard>
            <Form>
                <Row className={'mb-3 justify-content-between align-items-center'}>
                    <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                        <div className={'display-3'}>
                            {t('management.feedback.titleReview')}
                        </div>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.push('/administration/feedback')}>
                            {t('management.feedback.backButton')}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Label>{t('management.feedback.comment')}</Label>
                            <Input type={'textarea'} rows="20" style={{height:'100%'}} value={state.comment} disabled
                                   onChange={handleInput}/>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.name')}</Label>
                                    <Input type={'text'} rows="20" style={{height:'100%'}} value={state.client_id?.first_name} disabled
                                           onChange={handleInput}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.surname')}</Label>
                                    <Input type={'text'} rows="20" style={{height:'100%'}} value={state.client_id?.last_name} disabled
                                           onChange={handleInput}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.mail')}</Label>
                                    <Input type={'text'} rows="20" style={{height:'100%'}} value={state.client_id?.email} disabled
                                           onChange={handleInput}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.phone')}</Label>
                                    <Input type={'text'} rows="20" style={{height:'100%'}} value={state.client_id?.phone} disabled
                                           onChange={handleInput}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.evaluation')}</Label>
                                    <div>
                                        <RateBar rating={state.rating || 0}/>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>{t('management.feedback.added')}</Label>
                                    <div>
                                        {datetime(state.created_at)}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs={12} >
                                <FormGroup>
                                    <Label>{t('management.feedback.product')}</Label>
                                    <div>
                                        <GenericHrefGenerator productUrl={state.product_url}
                                                              linkName={state.product_name} order={state.order_id}/>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </TableCard>
});

Feedback.propTypes = {}

export default Feedback;