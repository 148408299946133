import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useSlotsApi = () => {
    const defaultSlotsApi = useDefaultEndpoints('slots');
    const {request} = useApi();

    const syncData = async (id) => request('get', '/slots/sync', {id});
    const findByUrl = async (url) => request('get', '/slots/findByUrl/' + url);
    const setAdminVisibility = async (slotId, value) => defaultSlotsApi.editEntry(slotId, {show_in_admin: value});

    return {...defaultSlotsApi, syncData, findByUrl, setAdminVisibility}
};