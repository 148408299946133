import {BOOKING_URL} from "../../../lib/api/useApi";

const usePages = () => {
    const getPageFullURL = (projectValue, pageKey) => (booking) => {
        const projectURL = projectValue?.key
            ? `/${projectValue.key}`
            : '';

        const pageURL = pageKey ? `/${pageKey}` : '';
        const host = booking ? BOOKING_URL : window.location.origin
        return host + '/corp' + projectURL + pageURL;
    }

    return {getPageFullURL}
}

export default usePages;