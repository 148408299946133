import {frk} from "sv-common/constants/frontRoles";

const ruBreadcrumbsMapper = (path) => {
    return urls[path];
}

const events = 'bases.breadcrumbsAndTitle.events';
const bases = 'bases.breadcrumbsAndTitle.bases';

const urls = {
    '/bases': [{ru: bases, link: ''}],
    '/bases/new': [{ru: 'bases.breadcrumbsAndTitle.newBase', link: ''}],
    '/bases/calendar': [
        {ru: bases, link: '/bases'},
        {ru: 'bases.breadcrumbsAndTitle.calendar', link: ''}
    ],
    '/bases/:url/events': [
        {ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]},
        {ru: events, link: ''}
    ],
    '/bases/:url/event/:event_url/edit/': [
        {ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]},
        {ru: events, link: '/bases/:url/events'},
        {ru: ':event_url', link: '/bases/:url/event/:event_url/'},
        {ru: 'bases.breadcrumbsAndTitle.editing', link: ''},
    ],
    '/bases/:url/event/:event_url/slot/:slot_url': [
        {ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]},
        {ru: events, link: '/bases/:url/events'},
        {ru: ':event_url', link: '/bases/:url/event/:event_url/'},
        {ru: ':slot_url', link: ''},
    ],
    '/bases/:url/event': [
        {ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]},
        {ru: events, link: '/bases/:url/events'},
        {ru: 'bases.breadcrumbsAndTitle.new', link: ''},
    ],
    '/bases/:url/event/:event_url': [
        {ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]},
        {ru: events, link: '/bases/:url/events'},
        {ru: ':event_url', link: ''},
    ],
    '/bases/:url': [{ru: ':url', link: '/bases/:url', checkRuleKey: frk["BREADCRUMBS-BASES"]}]
}
export default ruBreadcrumbsMapper;