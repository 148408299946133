import React from 'react';
import PropTypes from 'prop-types';
import {ModalConfirmation} from "../../../generic/modals";
import {Button, Col, Row} from 'reactstrap';
import {AiOutlineSearch} from "react-icons/ai";
import {useHistory} from "react-router-dom"
import {t} from "i18next";


const InstructorAttachModal = ({instructorState, isOpened, triggerModal, onConfirm}) => {
    let history = useHistory();

    const openSlot = () => {
        history.push(`/bases/anybase/event/${instructorState.event_id.url}/slot/${instructorState.url}`)
        triggerModal()
    }
    let modalText;
    if (instructorState?.id)
        modalText = <>
            <Row>
                <Col xs={10} className={'d-flex'}>
                    <div className={'align-self-center'}>{t('bases.base.instructorBusy')}</div>

                </Col>
                <Col xs={2}>
                    <Button color={'success'} size={'xs'} title={t('bases.base.goToSlot')} onClick={openSlot}><AiOutlineSearch/></Button>
                </Col>
            </Row>
        </>
     if (instructorState === 'inAnotherBases')
         modalText = t('bases.base.attachInstructor')
    if (instructorState === 'sameBase')
        modalText = t('bases.base.instructorBusyOtherSlot')
    return (
        <ModalConfirmation isOpen={isOpened} onClose={triggerModal} onConfirm={onConfirm} text={modalText}
                           hasCancel={!instructorState?.id} hasConfirm={!instructorState?.id}
                           title={t('bases.base.instructorStatus')} color={'success'}
                           confirmButtonText={t('bases.base.attach')}/>
    )
}

InstructorAttachModal.propTypes = {
    instructorState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    isOpened: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
}

export default InstructorAttachModal;

