import NewTabHref from './GeneralHrefComponent';
import { useEffect, useState } from 'react';

const ProductHrefAdmin = ({name, product, fetchById, getEntries, getEntry, linkFun, productUrl, productId}) => {
  const [link, setLink] = useState('');
  useEffect(async () => {
    let preparedProduct = async () => product;
    if (!product || fetchById) {
      if (productUrl && getEntries)
        preparedProduct = async () => await getEntries({url: productUrl})[0];
      if (productId && getEntry)
        preparedProduct = async () => await getEntry(productId);
    }

    const linkFunction = () => linkFun(preparedProduct);

    setLink(() => linkFunction);
  }, []);

  return <NewTabHref link={link} name={name} />
}

export default ProductHrefAdmin;