import React, {useState} from "react";
import PropTypes from "prop-types";
import {FaChevronRight} from "react-icons/fa";
import {Collapse} from 'reactstrap';
import './style_expandable.scss';
import {Link} from 'react-router-dom'

const ExpandableLink = ({name, icon, to, children, location, linkClickable = false, isOpenConstantly = false}) => {
    const isActive = location.pathname.startsWith(to);
    const [isOpen, setIsOpen] = useState(false);
    const id = "id" + to.replace(/[^a-zA-Z]/g, "_");
    const toggle = () => setIsOpen(!isOpen);
    const [isHovered, setHovered] = useState(false);
    return (
        <li className={`nav-item ${isActive ? "active" : ""} ${isHovered ? 'hover-open' : ''}`}
            onMouseEnter={() => setHovered(() => true)}
            onMouseLeave={() => setHovered(false)}
        >

            <div className={`nav-link ${isActive || isOpenConstantly ? "active" : ""}`}
                 onClick={toggle}
                 data-toggle="collapse"
                 tag={'a'}
                 aria-expanded={isOpen || isOpenConstantly}
                 aria-controls={id}>
                <i className={'menu-icon'}>{icon}</i>
                {linkClickable
                    ? <Link
                        className={`nav-link ${isActive ? "active" : ""} menu-title`}
                        to={to}>
                        {name}
                    </Link>
                    : <span className="nav-link menu-title">{name}</span>
                }
                {!isOpenConstantly &&
                <i className={`menu-arrow rotate ${isOpen ? 'down' : ''}`}><FaChevronRight size={'0.625rem'}/></i>}
            </div>

            <Collapse isOpen={isOpen || isOpenConstantly} className={'hide-for-minimal'} id={id}>
                <ul className="nav flex-column sub-menu">
                    {children}
                </ul>
            </Collapse>
        </li>
    );
}

ExpandableLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

export default ExpandableLink;