import {useRuleAccess} from "../../lib/utils/frontRules";
import {GROUP_TYPES_MAP} from "sv-common/constants/groups";
import {frk} from "sv-common/constants/frontRoles";

export const GroupRemoveKeyHandler = {
    [GROUP_TYPES_MAP.THEORY]: frk["THEORY-LIST-ROW-REMOVE"],
    [GROUP_TYPES_MAP.LECTURE]: frk["LECTURE-LIST-ROW-REMOVE"],
}

export const GroupRemoveRuleProvider = ({ typeForRule, children }) => {
    const { check } = useRuleAccess();

    const key = GroupRemoveKeyHandler[typeForRule];

    if (key) {
        if (check(key)) {
            return children
        } else {
            return null;
        }
    }

    return children;
}