import React from 'react';
import PropTypes from 'prop-types';

const ElementWithPermissions = ({children, disabled, hidden}) => {
    if (hidden) return <></>
    return React.cloneElement(children, {disabled, ...(disabled ? {onClick: null} : {})})
}

ElementWithPermissions.propTypes = {
    disabled: PropTypes.bool.isRequired,
    hidden: PropTypes.bool
}

export default ElementWithPermissions;