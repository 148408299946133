import React, {useEffect, useState} from 'react';
import {DataTable, field} from "../../../generic/tables";
import {useParams, useHistory} from 'react-router-dom';
import {TableCard} from "../../../generic/cards";
import {ModalConfirmation} from "../../../generic/modals";
import {openInNewTab} from "../../../../lib/utils/helper";
import {Loader} from "../../../generic/loader";
import {CopyLink} from "../../../generic/buttons";
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {useSlotsApi} from "../../../../lib/api/slots";
import {toast} from "react-toastify";
import {fullTimeToShort, getStartDayAndMonth, getStartWeekDay} from "../../../../lib/utils/format";
import {useBasesApi} from "../../../../lib/api/bases";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import i18next from 'i18next';
import SlotClosed from "./tableEdit/SlotClosed/index";
import BoatsLimitCounter from "./tableEdit/BoatsLimitCounter/index";
import AsyncInstructorSelect from './AsyncInstructorSelect';
import { IS_RUSSIA } from '../../../../lib/utils/constants';
import CommonToggle from '../../../common/formElements/Toggle';
import {frk} from "sv-common/constants/frontRoles";
import {useRuleAccess} from "../../../../lib/utils/frontRules";
const showDangerToast = e => toast(e, {type: 'error'});
const showSuccessToast = e => toast(e, {type: 'success'});


const SlotsTable = ({filters}) => {
    const getSlotUrl = slot => `${baseBookingUrl || BOOKING_URL}/sport/${slot.event_id.url}/${slot.url}`
    const [reload, setReload] = useState(false);
    const [baseBookingUrl, setBaseBookingUrl] = useState();
    const {findAndCountEntries, deleteEntry, setAdminVisibility} = useSlotsApi();
    const basesApi = useBasesApi();
    const { check } = useRuleAccess();

    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const {url} = useParams();
    let history = useHistory();
    const [ejectedEvent, setEjectedEvent] = useState('');

    useEffect(() => {
        setReload(r => !r);
        basesApi.getEntries({url: url}).then(bases => setBaseBookingUrl(bases[0]?.booking_url))
    }, [url])

    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);
    const changeVisibility = (val) => {
        setAdminVisibility(val.id, !val.show_in_admin)
            .then(() => {
                showSuccessToast(t('bases.base.statusReloaded'));
                setReload(r => !r);
            })
            .catch(e => showDangerToast(e.message))
    }

    const deleteEvent = (event) => {
        setEjectedEvent(() => event);
        triggerModalConfirmation()
    }

    const generateSlotLink = slot => '/bases/' + url + '/event/' + slot.event_id.url + '/slot/' + slot.url
    const onMiddleClick = (res) => openInNewTab(generateSlotLink(res))
    const {edit} = useRoleSystem(ENTITIES.EVENTS);

    const {t, language} = i18next;

    const getFields = () => {
      const fields = [
        field(t("bases.base.name"), true, "name"),
        field(
            t("bases.base.slotClosed"),
            false,
            'is_closed',
            (res) => <SlotClosed value={res.is_closed} id={res.id} />,
            undefined,
            undefined,
            { key: frk["BASE-SLOTS-LIST-ROW-CLOSE"], hide: true }
        ),
        field(t("bases.base.date"), true, "start_start_date", res => <>
          {getStartDayAndMonth(res.start_date, language)} | {getStartWeekDay(res.start_date, language)}
        </>),
        field(t("bases.base.time"), true, "start_time", res => <>{fullTimeToShort(res.start_time)} - {fullTimeToShort(res.end_time)}</>),
        field(
            `${t("bases.base.orders")} & ${t("bases.base.boatLimit")}`,
            false,
            'available_boats',
            (res) => {
              return <BoatsLimitCounter id={res.id}
                                        boatsAmount={res.available_boats}
                                        boatClass={res?.event_id?.boat_class || ''}
                                        totalSits={res.total_sits}
                                        availableSits={res.available_sits}
                                        bookedSits={res.booked_sits}
                                        bookedToTotal={res.booked_to_total}
                                        isAddedNewOrder={res.is_added_new_order}
                                        reservedSits={res.reserved_sits}
              />
            },
            undefined,
            undefined,
            { key: frk["BASE-SLOTS-LIST-ROW-BOATS"], hide: true }
        ),
        field("URL", true, "url", res => <CopyLink href={getSlotUrl(res)} showLink={false}/>),
        field(
            t('inputs.hide'),
            true,
            "show_in_admin",
                res =>
                  <ElementWithPermissions disabled={!edit}>
                    <CommonToggle isActive={!res.show_in_admin} onChange={() => changeVisibility(res)} />
                  </ElementWithPermissions>,
            undefined,
            undefined,
            { key: frk["BASE-SLOTS-LIST-ROW-HIDE"], hide: true }
        ),
      ];

      if (IS_RUSSIA) {
        fields.push(field(t('bases.base.instructors'), false, "instructors_ids", res => (
          <div style={{ width: 500 }} className='cancel-click'>
            <AsyncInstructorSelect slot={res} />
          </div>
        ), undefined, undefined, { key: frk["BASE-SLOTS-LIST-ROW-INSTRUCTORS"], hide: true }));
      }

      return fields;
    }

    return (!filters['event_id.base_id']
            ? <Loader/>
            : <>
                {isModalConfirmationOpen && <ModalConfirmation onClose={triggerModalConfirmation} onConfirm={() => {
                    deleteEntry(ejectedEvent.id).then(() => {
                        setReload(reload => !reload);
                        triggerModalConfirmation();
                    })

                }} isOpen={isModalConfirmationOpen} hasCancel title={t('bases.base.slotDeleting')}
                                                               text={t('bases.base.areYouSureSlot')}
                                                               cancelButtonText={t('bases.base.cancel')}
                                                               confirmButtonText={t('bases.base.yes')}
                />}

                <TableCard title={t('bases.base.slots')} isInline>
                    <DataTable findAndCount={findAndCountEntries}
                               filters={filters}
                               fields={getFields()}
                               sortField="start_datetime"
                               sortDir="asc"
                               name="slots-list"
                               onClick={res => history.push(generateSlotLink(res), res)}
                               onDelete={edit ? check(frk["BASE-SLOTS-LIST-ROW-REMOVE"]) && deleteEvent : null}
                               reload={reload}
                               onMiddleClick={onMiddleClick}
                    />
                </TableCard>
            </>
    )
};

export default SlotsTable;