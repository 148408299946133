import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Col, Row} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {datetime} from "../../../../lib/utils/format";
import {FaUserCircle} from "react-icons/fa";
import {AiOutlineSwap} from "react-icons/ai";
import DeleteButton from "./DeleteButton";
import {ElementWithPermissions, MovePaymentModal} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const OrderTable = ({data = [], isClient = false, isNew = true, setState, orderId, onAnnulationCb, productId, productType, presetFilters, order}) => {
    let history = useHistory();
    const [selectedClient, setSelectedClient] = useState(false)
    const permissions = useRoleSystem(ENTITIES.ORDERS);

    const [isMoveModalOpen, toggleMoveModal] = useState(false);
    const triggerMoveModal = () => {
        if (isMoveModalOpen) setSelectedClient(() => undefined)
        toggleMoveModal(isModalOpen => !isModalOpen)
    }
    const movePayment = (client) => {
        setSelectedClient(() => client);
        triggerMoveModal();
    }

    const clickCb = (res) => {
          setState(state => (
              {
                  ...state,
                  paid_for_ids: res.paid_for_ids
              }))
          onAnnulationCb && onAnnulationCb(res)
      }

    return <Row>
        {isMoveModalOpen &&
        <MovePaymentModal productType={productType} selectedProductId={productId} isModalOpen={isMoveModalOpen}
                          triggerModal={triggerMoveModal} orderId={orderId} clientId={selectedClient?.id}
                          clickCb={clickCb} presetFilters={presetFilters}/>}
        <Col xs={12}>
            <div className={'table-responsive'}>
                <table className={'table'}>
                    <tbody>
                    {(isClient ? data[0]?.first_name : data[0]?.client_id.first_name) && data.map(((item, index) => <tr
                        key={index}>
                        <td className='py-1'>
                            <img src='https://via.placeholder.com/36x36'/>
                        </td>
                        <td>
                            {isClient ? `${item.first_name} ${item.last_name}` : `${item.client_id.first_name} ${item.client_id.last_name}`}
                        </td>
                        <td>
                            {isClient ? `${item.phone || ''}` : `${item.client_id.phone || ''}`}
                        </td>
                        {
                            !isClient && (item.paid_to_total || item.paid_to_total === 0) &&
                            <>
                                <td>
                                    <div className='progress'>
                                        <div className='progress-bar bg-success' role='progressbar'
                                             style={{width: ((item.paid_value || 0) / (item.total_value || 1)) * 100 + '%'}}
                                             aria-valuenow={((item.paid_value || 0) / (item.total_value || 1)) * 100}
                                             aria-valuemin='0' aria-valuemax='100'/>
                                    </div>
                                </td>
                                <td>
                                    {item.paid_value || 0} {item.payment_currency}
                                </td>
                                <td>
                                    {datetime(item.created_at)}
                                </td>
                            </>
                        }

                        <td>
                            <ButtonGroup>
                                <Button title={t('management.orders.orderTable.goToUserProfile')} className={'p-1 m-0'} color={'success'}
                                        onClick={e =>
                                            history.push('/administration/users/edit/' + (isClient ? item.id : item.client_id.id))
                                        }
                                >
                                    <FaUserCircle/>
                                </Button>
                                {isClient && !isNew &&
                                    <ElementWithPermissions disabled={!permissions.edit}>
                                        <Button title={t('management.orders.orderTable.transferToAnotherProduct')} size="xs" className={'p-1 m-0'}
                                                color="info" onClick={() => movePayment({...item, index})}>
                                            <AiOutlineSwap/>
                                        </Button>
                                    </ElementWithPermissions>

                                }
                                <DeleteButton orderId={orderId} setState={setState} index={index} isNew={isNew} disabled={!permissions.edit}
                                              data={data} isClient={isClient} item={item} onAnnulationCb={onAnnulationCb}
                                />
                            </ButtonGroup>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </Col>
    </Row>

};

OrderTable.propTypes = {
    data: PropTypes.array.isRequired,
    isClient: PropTypes.bool,
    isNew: PropTypes.bool,
    setState: PropTypes.func,
    onAnnulationCb: PropTypes.func,
    productId: PropTypes.number
};

export default OrderTable;

