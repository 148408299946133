import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";
import {t} from "i18next";
import TimoutButton from '../../common/formElements/TimoutButton';

const ModalConfirmation = (props) => {
    const {onConfirm, onClose, isOpen, timer} = props;

    const confirmButton = <Button color={props.color} onClick={onConfirm}>{props.confirmButtonText}</Button>;

    return (
        <Modal isOpen={isOpen} toggle={onClose} className={props.className} unmountOnClose>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
                {props.text}
            </ModalBody>
            <ModalFooter>
                {props.hasConfirm && timer ? <TimoutButton
                  seconds={timer}
                  button={confirmButton}
                />: confirmButton}{' '}
                {props.hasCancel && <Button color="secondary" onClick={onClose}>{t('generic.modals.ModalConfirmation.cancel')}</Button>}
            </ModalFooter>
        </Modal>
    );
}
ModalConfirmation.propTypes = {
    text: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeModalConfirmation: PropTypes.func,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    hasCancel: PropTypes.bool,
    hasConfirm: PropTypes.bool
};

ModalConfirmation.defaultProps = {
    confirmButtonText: "Удалить",
    cancelButtonText: 'Отменить',
    color: "danger",
    isOpen: false,
    title: "Удаление",
    hasCancel: true,
    hasConfirm: true
};

export default ModalConfirmation;