import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {APPLICABLE_PRODUCTS} from "sv-common/constants/promocodes";
import {getEventTypes} from "../../../bases/events/event/reducer";
import i18next, {t} from "i18next";
import getCountryValue from '../../../../lib/utils/getCountryValue';
import {EVENT_TYPES} from "sv-common/constants/events";

const filterEventTypes = (v) => {
    return v.value !== EVENT_TYPES.RENT;
}

const ApplicableSelect = ({value, onChange, placeholder, disabled}) => {
    const {language} = i18next;
    const [options, setOptions] = useState([
      {label: t('management.promoCodes.allBases'), value: APPLICABLE_PRODUCTS.BASES},
      ...getEventTypes(language).filter(filterEventTypes).map(eType => ({label: t('management.promoCodes.events') + eType.label, value: 'events_' + APPLICABLE_PRODUCTS[(eType.value).toUpperCase()]})),
      {label: t('management.promoCodes.adventures'), value: APPLICABLE_PRODUCTS.ADVENTURES},
      {label: t('management.promoCodes.captainsSchool'), value: APPLICABLE_PRODUCTS.GROUPS_ALL},
      {label: t('management.promoCodes.captainsLecture'), value: APPLICABLE_PRODUCTS.GROUPS_LECTURES},
      {label: t('management.promoCodes.subscriptions'), value: APPLICABLE_PRODUCTS.SUBSCRIPTIONS},
    ]);

    useEffect(() => {
      const extraOpts = getCountryValue({
        ru: [
          {label: t('management.promoCodes.adventuresRus'), value: APPLICABLE_PRODUCTS.ADVENTURES_RUSSIA},
          {label: t('management.promoCodes.adventuresWorld'), value: APPLICABLE_PRODUCTS.ADVENTURES_WORLD},
          {label: t('management.promoCodes.eventsHike'), value: APPLICABLE_PRODUCTS.EVENTS_TRIP},
          {label: t('management.promoCodes.courseTheory'), value: APPLICABLE_PRODUCTS.GROUPS_THEORY},
          {label: t('management.promoCodes.coursePractice'), value: APPLICABLE_PRODUCTS.GROUPS_PRACTICE},
          {label: t('management.promoCodes.camps'), value: APPLICABLE_PRODUCTS.CAMPS},
          {label: t('management.promoCodes.campsRus'), value: APPLICABLE_PRODUCTS.CAMPS_RUSSIA},
          {label: t('management.promoCodes.campsWorld'), value: APPLICABLE_PRODUCTS.CAMPS_WORLD},
        ],
      });

      extraOpts && setOptions([ ...options, ...extraOpts ]);
    }, []);

    return (
        <>
            <Select isDisabled={disabled} placeholder={placeholder} isMulti options={options} onChange={onChange}
                    menuPlacement={'top'}
                    value={(value || []).map(val => options.filter(option => option.value === val)[0])}/>
        </>
    );
};

ApplicableSelect.propTypes = {
    value:  PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default ApplicableSelect;