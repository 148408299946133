import React from "react";
import {Col, CustomInput, FormGroup} from "reactstrap";
import {t} from "i18next";
import ControllerRHF from "../../../../generic/fields/ControllerRHF";
import {TextControllerRHF} from "../../../../generic/fields";

const PhotosSection = ({form, control, errors, requiredRule}) => (
    <>
        <Col lg={4} md={6} xs={12} className={'mb-1'}>
            <FormGroup>
                <ControllerRHF
                    name={'show_photo'}
                    form={form}
                    render={({value, name, onChange}) => (
                        <CustomInput
                            type="checkbox"
                            className={'custom-switch'}
                            id={name}
                            name={name}
                            label={t("constructor.templates.show_photo")}
                            checked={value || false}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    )}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup>
                <TextControllerRHF
                    inputName={'photos_link'}
                    control={control}
                    errors={errors}
                    isRequired
                    defaultValue=''
                    placeholder={t('constructor.pages.photosLinkPlaceholder')}
                    label={t('constructor.templates.photos_link')}
                    rules={requiredRule}
                />
            </FormGroup>
        </Col>
    </>
);

export default PhotosSection;
