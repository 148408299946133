import React from 'react'
import {field} from "../../generic/tables";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const Currencies = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.currencies.name'), true, "key"),
        field(t('directories.currencies.symbol'), true, "sign")
    ]}
    entityName={'currencies'}
    EditModalChildren={{}}
    permissions={getPermissions(ENTITIES.CURRENCIES)}
    {...props}
/>


export default Currencies;