import React from 'react';
import {Col, FormGroup, Input, Row} from "reactstrap";
import {TextControllerRHF} from "../../../../generic/fields";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {t} from "i18next";

const agentSign = [
    {
        name: "adventures.edit.another",
        key: "another",
    }
]


const ReceiptInfoBlock = ({state, dispatch, control, errors}) => {
    const setAdventureData = (e) => dispatch({type: 'setFormData', payload: e})

    return (
        <Row className={'pb-3'}>
            <Col xs={12} >
                <h4 className={'display-4'}>{t('adventures.edit.checkInfo')}</h4>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="agent_sign"
                        defaultValue={state.agent_sign}
                        rules={{}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.agentSign')}
                                                 name={name} isRequired={false}
                            >
                                <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                        style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                        value={value}
                                        onChange={e => {
                                            setAdventureData(e)
                                            onChange(e.target.value)
                                        }}>
                                    <option value={''}>{t('adventures.edit.pickAgentSign')}</option>
                                    {agentSign.map(agentSign =>
                                        <option value={agentSign.key} key={agentSign.key}>
                                            {t(agentSign.name)}
                                        </option>)
                                    }
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'supplier_name'}  setValue={setAdventureData}
                                       label={t('adventures.edit.supplierName')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.supplier_name || ''}
                                       placeholder={''}
                                       hint={''}
                                       rules={{}}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'supplier_phone'}  setValue={setAdventureData}
                                       label={t('adventures.edit.supplierPhone')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.supplier_phone || ''}
                                       placeholder={'+70001112233'}
                                       hint={t('adventures.edit.supplierPhoneHint')}
                                       rules={{maxLength: 20}}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'supplier_inn'}  setValue={setAdventureData}
                                       label={t('adventures.edit.supplierINN')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.supplier_inn || ''}
                                       placeholder={''}
                                       hint={t('adventures.edit.supplierINNHint')}
                                       rules={{maxLength: 30}}/>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default ReceiptInfoBlock;