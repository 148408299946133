import { Col, Form, FormGroup, Row } from 'reactstrap';
import { Dropzone } from '../../generic/fields';
import {t} from "i18next";
import {Controller} from "react-hook-form";
import { requiredRule } from '../../common/reactHookFormsFields/rules';
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import React from 'react';

const EditModalChildren = ({entity, setEntity, errors, control, handleInput}) => {
    return <Form>
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="files"
                        defaultValue=""
                        rules={requiredRule}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired
                                                 label={t('constructor.files.file')}
                            >
                                <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                     className={invalid ? 'is-invalid' : ''}>
                                    <Dropzone name={name} extFile={value} onBlur={onBlur} callback={value => {
                                        handleInput({target: {value, name}}, setEntity);
                                        onChange(value);
                                    }} />
                                </div>
                            </FieldWithValidation>
                        }/>
                </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="comment"
                  defaultValue={entity.provider_metadata?.comment || ''}
                  rules={{ maxLength: { value: 50, message: t('common.forms.maxLength', {count: 50}) } }}
                  render={(
                    {value, onBlur, name, ref, onChange},
                    {invalid}
                  ) =>
                    <FieldWithValidation
                      invalid={invalid}
                      errors={errors}
                      label={t('common.comment')}
                      name={name}
                      type="text" onBlur={onBlur} value={value}
                      onChange={e => onChange(handleInput(e, setEntity).value)}
                    />
                  }
                />
              </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;