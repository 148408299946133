import {Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {t} from "i18next";
import {certificatesFrontRulesArray} from "sv-common/constants/frontRoles/certificates";
import {sidebarFrontRulesArray} from "sv-common/constants/frontRoles/sidebar";
import {breadcrumbsFrontRulesArray} from "sv-common/constants/frontRoles/breadcrubms";
import {basesFrontRulesArray} from "sv-common/constants/frontRoles/bases";
import {baseFrontRulesArray} from "sv-common/constants/frontRoles/base";
import {eventFrontRulesArray} from "sv-common/constants/frontRoles/event";
import {slotFrontRulesArray} from "sv-common/constants/frontRoles/slot";
import {practiseFrontRulesArray} from "sv-common/constants/frontRoles/practise";
import {adventuresFrontRulesArray} from "sv-common/constants/frontRoles/adventures";
import {theoryFrontRulesArray} from "sv-common/constants/frontRoles/theory";
import {lecturesFrontRulesArray} from "sv-common/constants/frontRoles/lectures";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {promocodesFrontRulesArray} from "sv-common/constants/frontRoles/promocodes";
import {useEffect, useState} from "react";
import {chartersFrontRulesArray} from "sv-common/constants/frontRoles/charters";

const basesObject = { label: 'frontRoles.bases.group', data: basesFrontRulesArray };
const baseObject = { label: 'frontRoles.base.group', data: baseFrontRulesArray };
const eventObject = { label: 'frontRoles.event.group', data: eventFrontRulesArray };
const slotObject = { label: 'frontRoles.slot.group', data: slotFrontRulesArray };

const adventureObject = { label: 'frontRoles.adventures.group', data: adventuresFrontRulesArray };
const charterObject = { label: 'frontRoles.charter.group', data: chartersFrontRulesArray };
const practiseObject = { label: 'frontRoles.practise.group', data: practiseFrontRulesArray };
const theoryObject = { label: 'frontRoles.theory.group', data: theoryFrontRulesArray };
const lecturesObject = { label: 'frontRoles.lecture.group', data: lecturesFrontRulesArray };

const certificatesObject = { label: 'frontRoles.certificates.group', data: certificatesFrontRulesArray };
const breadcrumbsObject = { label: 'frontRoles.breadcrumbs.group', data: breadcrumbsFrontRulesArray };
const sidebarObject = { label: 'frontRoles.sidebar.group', data: sidebarFrontRulesArray };
const promocodesObject = { label: 'frontRoles.promocodes.group', data: promocodesFrontRulesArray };

const sportGroups = {
    label: 'common.sport',
    children: [basesObject, baseObject, eventObject, slotObject],
}

const adventureGroups = {
    label: 'common.adventures',
    children: [adventureObject],
}

const schoolGroups = {
    label: 'common.school',
    children: [practiseObject, theoryObject, lecturesObject],
}

const charterGroups = {
    label: 'common.charter',
    children: [charterObject]
}

const managementGroups = {
    label: 'common.management',
    children: [breadcrumbsObject, certificatesObject, promocodesObject, sidebarObject],
}

const groups = [
    sportGroups,
    adventureGroups,
    schoolGroups,
    charterGroups,
    managementGroups,
];

export const RuleFields = ({ register }) => {
    const {getEntries} = useDefaultEndpoints('bases');
    const [_groups, setGroups] = useState(groups);

    useEffect(() => {
        getEntries({is_archived_ne: true, _sort: 'base_order:ASC'}).then(i => {
            const basesObject = {
                label: 'frontRoles.bases.group',
                data: i.map(base => ({ key: `SIDEBAR-BASES-ENTITY-ID-${base.id}`, label: base.name }))
            };

            setGroups([
                sportGroups,
                adventureGroups,
                schoolGroups,
                charterGroups,
                { ...managementGroups, children: [...managementGroups.children, basesObject] },
            ]);
        });
    }, []);

    return _groups.map((group, gIndex) => (
        <Card body className='mb-2' key={gIndex}>
            <h1>{t(group.label)}</h1>
            <CardBody>
                {group.children.map((child, cIndex) => (
                    <Row key={cIndex} className='mb-2'>
                        <Card body>
                            <CardTitle>
                                {t(child.label)}
                            </CardTitle>
                            <CardBody>
                                <Row>
                                    {child.data.map((value, vIndex) => {
                                        const id = `${value.key}_${vIndex}`;

                                        return (
                                            <Col key={id} xs='3'>
                                                <FormGroup style={{ marginTop: 0 }}>
                                                    <Input
                                                        id={id}
                                                        name={`rules.${value.key}`}
                                                        type="checkbox"
                                                        innerRef={register()}
                                                    />
                                                    <Label
                                                        check
                                                        for={id}
                                                        style={{ marginLeft: 0 }}
                                                    >
                                                        {t(value.label)}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                ))}
            </CardBody>
        </Card>
    ))
}