import React, {useState, useEffect} from 'react';
import {Col, FormGroup, Input, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {getEventTypes} from "../reducer";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {Loader} from "../../../../generic/loader";
import i18next, {t} from "i18next";
import {IS_RUSSIA} from "../../../../../lib/utils/constants";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";

const MainInfo = ({state, dispatch, control, errors}) => {
    const {language} = i18next;

    const boatsClasses = state?.boats_classes;
    const corpClients = state?.corpClients;
    const hours = IS_RUSSIA ? 'ч' : 'h'
    const minutes = IS_RUSSIA ? 'м' : 'm'
    const boatsEndpoints = useDefaultEndpoints('boats-classes');
    const [boats, setBoats] = useState([]);
    const [defaultActiveBoat, setDefaultActiveBoat] = useState(null);

    useEffect(() => {
        boatsEndpoints.getEntries({}).then(res => setBoats(() => [...res]))
        setDefaultActiveBoat(state.boat_class)
    }, []);

    return !boatsClasses?.length ? <Loader/> : <>
        <Row className={'mb-3'}>
            <Col xs={12} md={8}>
                <h5 className={'display-4'}>{t('bases.base.general')}</h5>
            </Col>
        </Row>

        <Row className={'mb-3'}>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12} md={10}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="type"
                                defaultValue={state.type}
                                rules={{required: t('inputs.validation.required')}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.eventType')}
                                                         name={name} isRequired={true}
                                    >
                                        <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                                style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                                value={value}
                                                onChange={e => {
                                                   dispatch({type: 'setFormData', payload: e});
                                                   onChange(e.target.value)
                                               }}>
                                            <option value={''} disabled>
                                                {t('bases.events.newEventBlocks.pickEventType')}
                                            </option>
                                            {getEventTypes(language).map(eventType =>
                                                <option key={eventType.value} value={eventType.value}>
                                                    {eventType.label}
                                                </option>
                                            )}
                                        </Input>
                                    </FieldWithValidation>
                                }
                            />

                        </FormGroup>
                    </Col>
                </Row>
            </Col>

            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12} md={4}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="boat_class"
                                defaultValue={state.boat_class}
                                rules={{required: t('inputs.validation.required')}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.boatClass')}
                                                         name={name} isRequired={true} 
                                    >
                                        <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                                style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                                value={value}
                                                onChange={e => {
                                                    dispatch({type: 'setFormData', payload: e});
                                                    onChange(e.target.value)
                                                }}>
                                            <option value={''} disabled>{t('bases.base.pickClass')}</option>
                                            {boats.find((b) => b.class === defaultActiveBoat && !b.is_active) && <option value={defaultActiveBoat}>{defaultActiveBoat}</option>}
                                            {boatsClasses.filter(boatClass => boats.find((b) => b.class === boatClass.class)?.is_active).map(boatClass =>
                                                <option key={boatClass.class} disabled={state.type === 'individual_luch' && boatClass.class !== t('bases.base.luch')}
                                                        value={boatClass.class}>{boatClass.class}
                                            </option>)}
                                        </Input>
                                    </FieldWithValidation>
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Controller
                          control={control}
                          name="corp_client"
                          defaultValue={state?.corp_client?.id}
                          render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                          ) =>
                            <FieldWithValidation
                              invalid={invalid}
                              errors={errors}
                              label={t('bases.events.corpClient')}
                              name={name}
                            >
                              <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                      style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                      value={value}
                                      onChange={e => {
                                        dispatch({type: 'setFormData', payload: e});
                                        onChange(e.target.value)
                                      }}>
                                <option value={''}>{t('bases.base.no')}</option>
                                {corpClients.map(corpClient => <option key={corpClient.id} value={corpClient.id}>{corpClient.title}</option>)}
                              </Input>
                            </FieldWithValidation>
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={3}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="available_boats_limit"
                                defaultValue={state.available_boats_limit || ''}
                                rules={{required: t('inputs.validation.required')}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.boatLimit')}
                                                         name={name} isRequired={true}
                                                         type="text" onBlur={onBlur} value={value} placeholder="10"
                                                         onChange={e => {
                                                             dispatch({type: 'setFormData', payload: e});
                                                             onChange(e.target.value)
                                                         }}
                                    />
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} md={2}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="duration"
                                defaultValue={state.duration || ''}
                                rules={{required: t('inputs.validation.required'),
                                        pattern: new RegExp(`^(\\d{1,2}[${hours}${minutes}]{1})(\\s\\d{1,2}[${minutes}])?$`)}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.duration')}
                                                         name={name} isRequired={true}
                                                         type="text" onBlur={onBlur} value={value} placeholder={t('bases.base.durationPlaceholder')}
                                                         onChange={e => {
                                                             dispatch({type: 'setFormData', payload: e});
                                                             onChange(e.target.value)
                                                         }}
                                    />
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} md={2}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="price_value"
                                defaultValue={state.price_value || state.price_value === 0 ? state.price_value : ''}
                                rules={{required: t('inputs.validation.required')}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.price')}
                                                         name={name} isRequired={true}
                                                         type="text" onBlur={onBlur} value={value} placeholder="2500"
                                                         onChange={e => {
                                                             dispatch({type: 'setFormData', payload: e});
                                                             onChange(e.target.value)
                                                         }}
                                    />
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Col>

        </Row>
    </>
}

export default MainInfo;

MainInfo.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}
