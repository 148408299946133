import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {Input} from "reactstrap";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";

const WAIT_INTERVAL = 2000;
const ENTER_KEY = 13;
let timer = null;

/**
 * Function to get filtered ID-list of relative entries in order to filter some parental entry
 * @param relatedEntity
 * @param filters
 * @param className
 * @param bsSize
 * @param style
 * @param value
 * @param onSearch
 * @param placeholder
 * @return {JSX.Element}
 * @constructor
 */
const RelationTextSearch = ({relatedEntity, filteringFunction, className, bsSize, style, value, onSearch, placeholder}) => {
    const [state, setState] = useState(value || "")
    const {getEntries} = useDefaultEndpoints(relatedEntity)


    useEffect(() => setState(() => value), [value])


    const handleChange = (e) => {
        clearTimeout(timer);
        setState(() => (e.target.value));
        timer = setTimeout(() => triggerChange(e.target.value), WAIT_INTERVAL);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) triggerChange(state);
    }

    const triggerChange = (value) => {
        clearTimeout(timer);
        getEntries(filteringFunction(value)).then(entries => {

            if (!entries.length)
                // Workaround to no matches issue
                onSearch([-1])
            else
                onSearch(entries.map(entry => entry.id))
        })
    }

    return (
        <Input
            className={className}
            bsSize={bsSize}
            value={state || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            style={style}
        />
    );
}

export default RelationTextSearch;

RelationTextSearch.propTypes = {
    bsSize: PropTypes.string,
    style: PropTypes.object,
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    relatedEntity: PropTypes.string.isRequired,
    filteringFunction: PropTypes.func.isRequired
};