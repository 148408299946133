import React, {useEffect, useState} from "react";
import {DataTable, field} from "../../generic/tables/";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/utils/format";
import {TableCard} from "../../generic/cards";
import {exportStatuses} from "../../../lib/utils/helper";
import {toast} from "react-toastify";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DOWNLOAD_LINK} from "../../../lib/api/useApi";
import {LoadBadge} from "../../generic/badges";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const statuses = exportStatuses;
const translateStatus = res => {
    const status = res.status;
    if (status === 'ready')
        return t('management.export.statusReady')
    if (status === 'processing')
        return t('management.export.statusInProgress')
    if (status === 'error')
        return t('management.export.statusError')
}
const filters = {status_in: [statuses.Ready, statuses.Processing, statuses.Error]};
const showDangerToast = e => toast(e, {type: 'error'});

const Table = withBreadcrumbs(({reload, isInline}) => {
    const [tableReload, setReload] = useState(false);
    const {findAndCountEntries, deleteEntry, deleteMultiple} = useDefaultEndpoints('exports');
    const {edit} = useRoleSystem(ENTITIES.EXPORT);
    useEffect(() => setReload(!reload), [reload]);

    return <TableCard title={t('management.export.title')} isInline={isInline}>
        <DataTable
            filters={filters}
            onDelete={edit ? (res) => deleteEntry(res.id).then(() => setReload(!tableReload)) : null}
            findAndCount={findAndCountEntries}
            reload={tableReload}
            fields={[
                field(t('management.export.entity'), true, "entity"),
                field(t('management.export.progress'), true, "progress", res =>
                    <div className={'d-flex justify-content-between align-items-center'}><div>{res.currentRows} / {res.rows} {t('management.export.entity')}
                    </div> &nbsp; <LoadBadge value={Math.floor(res.currentRows / res.rows) * 100}/></div>
                ),
                field(t('management.export.status'), true, "status", translateStatus),
                field(t('management.export.file'), true, "filename", res =>
                    <a href={DOWNLOAD_LINK(res)} rel="noopener noreferrer" target="_blank" download>{res.filename}</a>
                ),
                field(t('management.export.description'), true, "description", res =>
                    <small>{res.description}</small>
                ),
                field(t('management.export.user'), true, "user.username"),
                field(t('management.export.date'), true, "created_at", res => datetime(res.created_at))
            ]}
            onMultipleDelete={edit ? ids => deleteMultiple(ids).then(() => setReload((rel) => !rel)).catch(showDangerToast) : null}
            name={"export"}>
        </DataTable>
    </TableCard>;
})

Table.propTypes = {
    onChange: PropTypes.func,
    reload: PropTypes.any
};

export default Table;