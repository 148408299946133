import React from 'react';
import {Row, Col, FormGroup, CustomInput, Label, Input} from "reactstrap";
import {t} from "i18next";
import TelegramOptions from '../../../../../common/formElements/TelegramOptions';
import ElementWithAccessInCountries from "../../../../../../lib/utils/hocs/ElementWithAccessInCountries";

const SwitchBlock = ({state, dispatch}) => {
    const setFormData = (e) => dispatch({type: 'setFormData', payload: e})
    return (
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='is_open'
                                 name='is_open' label={t('school.theory.switchBlock.isOpen')}
                                 checked={state.is_open || false} onChange={setFormData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='is_private'
                                 name='is_private' label={t('school.theory.switchBlock.isPrivate')}
                                 checked={state.is_private || false} onChange={setFormData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='check_age_required'
                                 name='check_age_required' label={t('school.theory.switchBlock.ageRequired')}
                                 checked={state.check_age_required} onChange={setFormData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type='checkbox' className={'custom-switch'} id='paid_locally'
                                 name='paid_locally' label={t('school.theory.editModalChildren.paidLocally')}
                                 checked={state.paid_locally || false} onChange={setFormData}
                    />
                </FormGroup>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Col xs={12}>
                    <FormGroup>
                        <CustomInput type='checkbox' className={'custom-switch'} id='visible_installment'
                                     name='visible_installment' label={t('school.theory.switchBlock.isInstallment')}
                                     checked={state.visible_installment || false} onChange={setFormData}
                        />
                    </FormGroup>
                </Col>
            </ElementWithAccessInCountries>
            <Col xs={12}>
                <FormGroup>
                  <Label>{t('school.theory.editModalChildren.tg_options')}</Label>
                  <TelegramOptions tg_options={state.tg_options} onChange={setFormData} />
                </FormGroup>
            </Col>
            {state.check_age_required && <>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="check_age_value">{t('school.theory.switchBlock.age')}</Label>
                        <Input type="text" name="check_age_value" id="check_age_value" placeholder="16"
                               value={state.check_age_value || ''} onChange={setFormData}
                        />
                    </FormGroup>
                </Col>
            </>}
        </Row>
    )
};

export default SwitchBlock;