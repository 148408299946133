import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import { t } from 'i18next';

const options = [{label: t('management.orders.orderList.tinkoff'), value: 'tinkoff'}];

const ConditionsSelect = ({value, onChange, placeholder, disabled}) => (
    <Select isDisabled={disabled} placeholder={placeholder} isMulti options={options} value={(value || [])
                .map(val => options.filter(option => option.value === val)[0])}
                    menuPlacement={'top'} onChange={onChange}/>
);

ConditionsSelect.propTypes = {
    value:  PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

export default ConditionsSelect;