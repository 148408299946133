import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../../directories/DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";



const Coordinators = (props) => {
    return <DirectoryTemplate
        fields={[
            field(t('constructor.coordinators.name'), true, "name"),
            field(t('constructor.coordinators.phone'), true, "phone"),
            field(t('constructor.coordinators.corp_phone'), true, "corp_phone"),
        ]}
        editable
        deletable
        entityName={'coordinators'}
        EditModalChildren={EditModalChildren}
        permissions={getPermissions(ENTITIES.COORDINATORS)}
        isNameFieldSearch
        searchPlaceholder={t('constructor.directoryTemplate.search.coordinator')}
        isFormData
        {...props}
    />
}

export default Coordinators