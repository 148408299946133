import React from 'react';
import {Card} from "../../generic/cards";

DataLensCard.propTypes = {};

function DataLensCard() {
    return (
        <Card title={'DataLens'} className={'col-md-12 grid-margin stretch-card'}>
            <div style={{textAlign: 'center'}}>
                <a href={'https://datalens.yandex.ru/pcwwysyf2536j'} target="_blank" rel="noopener noreferrer"
                         className={'display-4'}>Перейти в DataLens</a>
            </div>
        </Card>
    );
}

export default DataLensCard;