import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import {Badge} from "reactstrap";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const DifficultyLevels = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.difficultyLevels.level'), true, "level", res => `${res.level} / ${res.max_level}`),
        field(t('directories.difficultyLevels.briefly'), true, "title"),
        field(t('directories.difficultyLevels.description'), true, "description"),
        field(t('directories.difficultyLevels.group'), true, "tag", res =>
            <Badge color={"success"}>{res.tag}</Badge>
        ),
    ]}
    entityName={'difficulty-levels'}
    EditModalChildren={EditModalChildren}
    deletable
    editable
    permissions={getPermissions(ENTITIES.DIFFICULTIES)}
    {...props}
/>


export default DifficultyLevels;