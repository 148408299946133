import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {FormGroup, Input} from "reactstrap";
import FieldWithValidation from "./FieldWithValidation";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import { t } from 'i18next';

const CheckSelector = ({control, defaultValue, dispatch, productName, errors, deps = []}) => {
    const {getEntries} = useDefaultEndpoints('checks');

    const [checks, setChecks] = useState([]);

    useEffect(() => {
      getEntries({ productName }).then(res => setChecks(res))
    }, deps);

    return (
        <FormGroup>
            <Controller
                control={control}
                name="check_id"
                defaultValue={defaultValue}
                rules={{required: t('inputs.validation.required')}}
                render={(
                    {value, onBlur, name, ref, onChange},
                    {invalid}
                ) =>
                    <FieldWithValidation isRequired invalid={invalid} label={t('adventures.edit.check')} name={name} errors={errors}>
                        <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                               value={value}
                               onChange={e => {
                                 dispatch && dispatch({type: 'setFormData', payload: e});
                                 onChange(e.target.value)
                               }}>
                            <option value={''}>{t('adventures.edit.pickCheck')}</option>
                            {checks.map(check =>
                                <option value={check.id} key={check.id}>{check.name}</option>
                            )}
                        </Input>
                    </FieldWithValidation>
                }
            />
        </FormGroup>
    )
}

export default CheckSelector
