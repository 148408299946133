import React from "react";
import {tabList} from "./tabList";
import {TableCard} from "../../generic/cards";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {StringParam, useQueryParam, withDefault} from "use-query-params";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {t} from "i18next";

const LinksGenerator = withBreadcrumbs(() => {
    const [activeTab, setActiveTab] = useQueryParam("activeTab", withDefault(StringParam, "sport"));

    return <TableCard>
        <Nav tabs fill className="align-items-end no-gutters">
            {tabList.map(tab =>
                <NavItem className={`col-sm-12 col-md-${Math.ceil(12 / tabList.length)}`} key={tab.url}>
                    <NavLink className={activeTab === tab.url ? "active" : ""} onClick={() => setActiveTab(tab.url)}>
                        {t(tab.name)}
                    </NavLink>
                </NavItem>
            )}
        </Nav>

        <TabContent activeTab={activeTab}>
            {tabList.map(tab =>
                <TabPane tabId={tab.url} key={tab.name}>
                    {tab.child}
                </TabPane>
            )}
        </TabContent>
    </TableCard>
})

export default LinksGenerator