import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalState } from './globalState';
import { useFrontendRules } from './frontendRules';

export const FrontendRulesState = createContext({});

const FrontendRulesProvider = ({ children }) => {
  const { getUserConfig } = useFrontendRules();
  const [globalState] = useContext(GlobalState);
  const [rules, setRules] = useState({});

  useEffect(() => {
    globalState.userId && getUserConfig().then(setRules);
  }, [globalState.userId]);

  return (
    <FrontendRulesState.Provider value={rules}>
        {children}
    </FrontendRulesState.Provider>
  )
}

export default FrontendRulesProvider;