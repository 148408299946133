import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Input} from "reactstrap";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../generic/loader";
import {t} from "i18next";

const LegalEntityItem = ({ control, state, isRequired, setState, legalEntities, errors, withAlter, info }) => {
    const prefix = withAlter ? 'alter_' : '';

    return (
        <Controller
            control={control}
            name={`${prefix}legal_entity_id`}
            defaultValue={state[`${prefix}legal_entity_id`]?.id || state[`${prefix}legal_entity_id`] || ''}
            rules={isRequired ? {required: t('inputs.validation.required')} : {}}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation info={info} invalid={invalid} errors={errors} label={t(`common.forms.${prefix}legalEntity.label`)}
                                     name={name} isRequired={isRequired}
                >
                    <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                           style={{border: invalid ? '1px solid #dc3545' : '0'}}
                           value={value}
                           onChange={(e) => {
                               setState(e);
                               onChange(e.target.value)
                           }}>
                        <option value={''}>{t(`common.forms.${prefix}legalEntity.pickLegalEntity`)}</option>
                        {legalEntities.map(legalEntity => <option key={legalEntity.id}
                                                                  value={legalEntity.id}>{legalEntity.name}</option>)}
                    </Input>
                </FieldWithValidation>
            }
        />
    )
}

const LegalEntity = ({form, state, setState, isRequired = true, params = {}, withAlter}) => {
    const {control, errors} = form;
    const getLegalEntities = useDefaultEndpoints('legal-entities').getEntries;
    const [legalEntities, setLegalEntites] = useState([])
    useEffect(() => {
        getLegalEntities(params).then(res => setLegalEntites(() => res));
    }, [JSON.stringify(params)])

    const props = { control, state, isRequired, legalEntities, errors, setState };

    return !legalEntities.length
        ? <Loader/>
        : (
            <>
                <LegalEntityItem {...props} />
                {withAlter && <div className='mt-4'>
                    <LegalEntityItem withAlter{...props} info={t('common.forms.alter_legalEntity.tooltip')} />
                </div>}
            </>
        );
}

LegalEntity.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    withAlter: PropTypes.bool,
};
export default LegalEntity;