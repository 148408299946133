import React, {useEffect, useState} from "react";
import {LoaderOverlay} from "../loader";
import {Pager} from "../pager";
import PropTypes from "prop-types";
import {getCookie, setCookie} from "../../../lib/utils/cookies";
import {useHistory} from 'react-router';
import usePrevious from "../../../lib/utils/hooks/usePrevious";
import _ from 'lodash';
import {number} from "../../../lib/utils/format";
import {toast} from "react-toastify";

const showDangerToast = e => toast(e, {type: 'error'});

const DataList = (props) => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const query = (props) => ({
        start: number(params.get(props.name + '_start')),
        limit: number(params.get(props.name + '_limit')) || getCookie(props.name + "-limit") || 20
    })
    const prevProps = usePrevious(props)

    const [state, setState] = useState({
        sortField: props.sortField,
        sortDir: props.sortDir,
        start: query(props).start,
        limit: query(props).limit,
        loading: true
    })

    useEffect(() => {
        if (!_.isEqual(prevProps, props)) {
            let isSubscribed = true;
            loadData()
                .then(({data, total}) => setState((state) => ({...state, data, total, loading: false})))
                .catch(e => {
                    setState((state) => ({...state, loading: false}));
                    showDangerToast(e);
                });
            // eslint-disable-next-line
            return () => isSubscribed = false
        }
    }, [props.filters])

    const dataParameters = () => {
        const {start, limit, sortField, sortDir} = state;
        const filters = Object.assign({}, props.filters);
        const sort = !sortField || !sortDir ? '' : `${sortField}:${sortDir.toUpperCase()}`
        return {sort, start, limit, filters}
    }

    const loadData = async () => {
        const {start, limit, sort, filters} = dataParameters();
        const params = _.pickBy({_start: start, _limit: limit, _sort: sort, ...filters}, (value) => !(value === undefined || value === null || value === ''))
        if (props.preloadedData) {
            // no sorting of filtering supported
            const {_start, _limit} = start !== undefined ? {_start: start, _limit: start + 10} : {_start: 0, _limit: 10}
            return Promise
                .resolve({data: props.preloadedData.slice(_start, _limit), total: props.preloadedData.length})
        }
        return props.findAndCount(params)
    }

    const {data} = state;
    const {emptyContent, tableButtons, wrapperClassname = ''} = props;

    return <LoaderOverlay isVisible={state.loading} className="data-table-content">
        <div className={wrapperClassname}>
            {((!data || data.length === 0) && emptyContent) ? emptyContent :
                (data && data.map(res => props.renderer(res)))
            }
        </div>
        <div className={"mt-2"}>
            <Pager
                start={state.start}
                total={state.total}
                limit={state.limit}
                onPageChange={start => {
                    params.set(`${props.name}_start`, start)
                    history.push(history.location.pathname + '?' + params.toString())
                    setState((state) => ({...state, start}));
                }}
                onLimitChange={limit => {
                    setCookie(`${props.name}-limit`, limit);
                    params.set(`${props.name}_limit`, limit)
                    history.push(history.location.pathname + '?' + params.toString())
                    setState((state) => ({...state, limit}));
                }}
                canChangeLimit={!!props.name}
            />
            {tableButtons && tableButtons(dataParameters)}
        </div>
    </LoaderOverlay>;
}

DataList.propTypes = {
    filters: PropTypes.object,
    findAndCount: PropTypes.func.isRequired,
    wrapperClassname: PropTypes.string,
    /**
     * function(res: object): JSX
     */
    renderer: PropTypes.func.isRequired,
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    name: PropTypes.string,
    reload: PropTypes.any,
    emptyContent: PropTypes.node,
    /**
     * function(parameters: {sortField, sortDir, start, limit, filters}): JSX
     */
    tableButtons: PropTypes.func,
    preloadedData: PropTypes.array
};

DataList.defaultProps = {
    filters: {},
    name: "",
    limit: 10
};

export default DataList;