import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import {TableCard} from "../../../generic/cards";
import {useHistory, useParams} from "react-router-dom";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import EditorPane from "../../../generic/EditorPane";
import {useForm} from "react-hook-form";
import {TextControllerRHF} from "../../../generic/fields";
import {requiredRule} from "../../../common/reactHookFormsFields/rules";
import {CORP_PAGE_PARAMETERS} from "sv-common/constants/corp"
const Template = withBreadcrumbs(() => {
    let history = useHistory();
    const {id} = useParams();
    const {getEntry, createEntry, editEntry} = useDefaultEndpoints('templates');
    const form = useForm();
    const {control, errors, handleSubmit, reset} = form;

    const [templateData, setTemplateData] = useState();

    const onCreateOrUpdate = (data) => id
        ? editEntry(id, {...data, ...templateData}).then( () => toast.success(t('common.success')) )
        : createEntry({...data, ...templateData}).then( () => history.push('/constructor/templates/') );

    const onSubmit = handleSubmit(onCreateOrUpdate)

    useEffect(() => {
        if (id) {
            getEntry(id).then(res => {
                setTemplateData({
                    htmlTemplate: res.htmlTemplate,
                    cssTemplate: res.cssTemplate,
                    jsTemplate: res.jsTemplate,
                })

                Object.keys(res).forEach(key => {
                    if (res[key] === null) {
                        delete res[key];
                    }
                });

                reset(res);
            })
        }
    }, [id]);

    return <TableCard>
        <div className={"row justify-content-end mb-3"}>
            <div className={"col-"}>
                <Button color="success" size={'sm'} className={'btn-rounded'} onClick={onSubmit}>
                    {t('management.orders.order.save')}
                </Button>
            </div>
        </div>
        <Form>
            <Row>
                <Col md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'label'} control={control} errors={errors} label={t('constructor.label')}
                            isRequired defaultValue='' rules={requiredRule} placeholder={t('constructor.templates.labelPlaceholder')}
                        />
                    </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'key'} control={control} errors={errors} placeholder={t('constructor.keyPlaceholder')}
                            label={t('constructor.key')} isRequired defaultValue='' rules={requiredRule} isDeleteSpaces
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={9}>
                    <EditorPane
                        html={templateData?.htmlTemplate} css={templateData?.cssTemplate} js={templateData?.jsTemplate}
                        onChange={(mode, value) => {
                            const templateObj = {...templateData};
                            templateObj[mode+"Template"] = value;
                            setTemplateData(templateObj);
                        }}
                    />
                </Col>
                <Col xs={12} xl={3} className={"jumbotron"}>
                    <h5 className="mb-3">{t('constructor.templates.availableParams')}</h5>
                    <ul>
                        {Object.keys(CORP_PAGE_PARAMETERS)
                            .map(it =>  <li className={'mb-2'} key={it}><b>{'{{'}{it}{'}}'}</b><br/>{t(`constructor.templates.${it.toLowerCase()}`)}</li>)}
                    </ul>
                </Col>
            </Row>
        </Form>
    </TableCard>
})

export default Template