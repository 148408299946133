import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import dayjs from "dayjs";
import RangedSelectionDatePicker from "../../../generic/fields/RangedSelectionDatePicker";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity}) => {
    const [daysFilter, setDaysFilter] = useState({
        from: new Date(),
        to: dayjs().add(7, 'd').toDate()
    })

    useEffect(() => {
        setEntity(entity => ({...entity, start_date: daysFilter.from}))
        setDaysFilter(prevState => ({...prevState, to: dayjs(prevState.from).add(7, "d").toDate()}))
    }, [daysFilter.from])

    useEffect(() => {
        setEntity(entity => ({...entity, end_date: daysFilter.to}))
    }, [daysFilter.to])

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setEntity(entity => ({...entity, [name]: value}))
    }

    return <Form>
            <Row>
                <Col xs={12} md={12}>
                    <FormGroup>
                        <Label for="name">{t('adventures.editArrival.name')}</Label>
                        <Input type={'text'} value={entity.name} onChange={handleInput} name={'name'}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={12}>
                    <FormGroup>
                        <Label for="class">{t('adventures.editArrival.date')}</Label>
                        <RangedSelectionDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter} dateFormat={"DD.MM.YYYY"} numberOfMonths={2} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={12}>
                    <Label for="repeat_type">{t('adventures.editArrival.repeat')}</Label>
                    <Input type="select" name="repeat_type" id="repeat_type"
                           value={entity.repeat_type || ''} onChange={handleInput}>
                        <option value={''}>{t('adventures.editArrival.pickRepeat')}</option>
                        <option value={'once'}>{t('adventures.editArrival.once')}</option>
                        <option value={'daily'}>{t('adventures.editArrival.daily')}</option>
                        <option value={'weekly'}>{t('adventures.editArrival.weekly')}</option>
                        <option value={'monthly'}>{t('adventures.editArrival.monthly')}</option>
                        <option value={'yearly'}>{t('adventures.editArrival.yearly')}</option>
                    </Input>
                </Col>

            </Row>
        </Form>
}

export default EditModalChildren;
