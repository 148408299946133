import React, {useReducer, useEffect, useState} from 'react';
import {TableCard} from "../../../../generic/cards";
import {Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {initialState, reducer} from "../../../../bases/base/reducer";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Loader} from "../../../../generic/loader";
import {FaPencilAlt} from "react-icons/fa";
import tabList from "./tabList";
import qs from 'qs';
import {ENTITIES, useRoleSystem} from "../../../../../lib/utils/hooks/useRoleSystem";
import ManagerComments from '../../../../common/formElements/ManagerComments';

const Group = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory()
    const {pathname, search} = useLocation();
    let {url} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {getEntries} = useDefaultEndpoints('groups')
    const [activeTab, setActiveTab] = useState('about');
    const permissions = useRoleSystem(state.type === 'theory' ? ENTITIES.GROUPS_THEORY: ENTITIES.GROUPS_LECTURES)
    const toggleTab = tab => {
        history.replace(`${pathname}?t=${activeTab}`)
        if (activeTab !== tab.url) setActiveTab(tab.url)
    };

    useEffect(() => {
        if (url)
            getEntries({url: url}).then(res => {
                dispatch({type: 'setBase', payload: res[0]});
                const mapper = {
                    url: {ru: res[0].name, en: res[0].url},
                };
                createTranslatedBreadcrumbsAndTitle
                && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })        // eslint-disable-next-line
        else
            dispatch({type: 'setLoading', payload: false});
        if (!search) history.replace(`${pathname}?t=${activeTab}`);

        let {t} = qs.parse(history.location?.search.slice(1))
        if (t)
            setActiveTab(t)
        // eslint-disable-next-line
    }, [history.location.pathname, url])

    const setComments = (value) => dispatch({ type: 'setBase', payload: { comments_for_manager: value } });

    return state.loading
        ? <Loader/>
        : <TableCard>
            <Row>
              <Col>
                <ManagerComments entity='groups' id={state.id} data={state.comments_for_manager} setData={setComments} />
              </Col>
            </Row>
            <Row className={'mb-5 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <div className={'display-4'}>
                        {state.name} <FaPencilAlt style={{cursor: 'pointer'}}
                                                  onClick={() => history.push( `/school/${state.type === "theory" ? "theory" : "lectures"}/group/edit/${url}`)}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabList({state, dispatch, permissions})
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(6 / tabList({state, dispatch, permissions}).length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {tabList({
                            state,
                            dispatch,
                            permissions
                        }).filter(tab => tab.isDisplayed !== false).map(tab => (
                            <TabPane tabId={tab.url} key={tab.name}>
                                {tab.child}
                            </TabPane>
                        ))}
                    </TabContent>
                </Col>
            </Row>


        </TableCard>
}

export default Group;