import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {getPermissions, ENTITIES} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import {FaCheck, FaTimes} from "react-icons/fa";


const BoatsClasses = (props) => <DirectoryTemplate
    fields={[
        field(t('directories.boatsClasses.boatClass'), true, "class"),
        field(t('directories.boatsClasses.isActive'), true, "is_active" ,
            res => res.is_active ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>)
    ]}
    entityName={'boats-classes'}
    EditModalChildren={EditModalChildren}
    editable
    deletable
    permissions={getPermissions(ENTITIES.BOAT_CLASSES)}
    {...props}
/>


export default BoatsClasses;