import cookie from "js-cookie";

export const setCookie = (key, value, expires=null) => {
    cookie.set(key, value, {
        expires: expires || 28,
        path: "/"
    });
};

export const eraseCookie = (key, expires=null) => {
    cookie.remove(key, {
        expires: expires || 28
    });
};

export const getCookie = (key) => {
    return cookie.get(key);
};