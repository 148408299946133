import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useCallback, useState } from 'react';
import { t } from 'i18next';
import { FaCheck, FaExclamation } from 'react-icons/fa';
import makePassportsZip from './helpers';
import { useOrdersApi } from '../../../../../lib/api/orders';
import { LoaderOverlay } from '../../../../generic/loader';
import { DataTable, field } from '../../../../generic/tables';

const ProductPassportsComponent = ({ productId, type, fileName }) => {
  const { getProductPassports } = useOrdersApi();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passports, setPassports] = useState([]);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const onExport = async () => {
    setIsLoading(true);

    await makePassportsZip(fileName, passports);

    setIsLoading(false);
  }

  const tableButton = useCallback(() => !!passports.length && <Button color='primary' onClick={onExport}>{t('generic.exportButton.export')}</Button>, [onExport, passports.length]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} className={'edit-modal'} size={'lg'}>
        <ModalHeader>{t('management.orders.passportsModal.title')}</ModalHeader>
        <ModalBody>
          <LoaderOverlay isVisible={isLoading} className="data-table-content">
          <DataTable
            fields={[
              field(t('common.firstName'), false, 'owner.first_name'),
              field(t('common.lastName'), false, 'owner.last_name'),
              field(t('common.email'), false, 'owner.email'),
              field(t('management.orders.passportsModal.passExists'), false, 'id', res => <div key={res.id} className='text-center'>
                {res.id ? <FaCheck color='green' /> : <FaExclamation color='red' />}
              </div>),
            ]}
            findAndCount={() => getProductPassports(productId, type)}
            onMultipleSelect={(ids,data) => setPassports(data)}
            onMultipleAllSelect={(ids,data) => setPassports(data)}
            tableButtons={tableButton}
          />
          </LoaderOverlay>
        </ModalBody>
      </Modal>
      <Button className='mt-2' color='primary' onClick={toggle}>{t('management.orders.passportsModal.title')}</Button>
    </>
  )
}

export default ProductPassportsComponent;