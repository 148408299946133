import React, {useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {useApi} from "../../../lib/api/useApi";
import person from '../../../styles/assets/images/person/1.png'
import {Dropdown, DropdownToggle, NavbarToggler} from "reactstrap";
import {FaGripLines, FaSignOutAlt} from "react-icons/fa";
import LanguageSwitch from "./LanguageSwitch";
import {LANGUAGE} from "../../../lib/utils/constants";
import {t} from "i18next";

const HeaderUser = (props) => {
    const {logout} = useApi();
    const [isMenuOpened, setMenuOpened] = useState(false);

    return (
        <ul className="navbar-nav navbar-nav-right">
                <Dropdown className="nav-item nav-profile"
                          toggle={() => setMenuOpened((isMenuOpened)=> !isMenuOpened)}
                          isOpen={isMenuOpened}
                >
                    <DropdownToggle className="nav-link" tag={'a'}>
                        <img src={person} alt="profile"/>
                    </DropdownToggle>
                    <div className={`dropdown-menu dropdown-menu-right navbar-dropdown${isMenuOpened ? ' show' : ''}`}>
                        <Link to={"/login"} className="dropdown-item" onClick={logout}>
                            <FaSignOutAlt size="18" className={"dropdown-icon"} color={'#6e7aff'} />
                            {t('layout.exit')}
                        </Link>
                    </div>
                </Dropdown>
            {LANGUAGE !== 'RU' &&
                <div className="col-xs-1 m-1">
                    <LanguageSwitch />
                </div>
            }
            <NavbarToggler className="navbar-toggler-right d-lg-none align-self-center"
                           type="button"
                           onClick={() => props.toggleSmNavbar(isOpened => !isOpened)}
            >
                <FaGripLines className="fas fa-align-justify"/>
            </NavbarToggler>
        </ul>
    );
}

HeaderUser.propTypes = {
    toggleSmNavbar: PropTypes.func.isRequired
};

export default HeaderUser;