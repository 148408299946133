import { Card } from '../../generic/cards';
import { useApi } from '../../../lib/api/useApi';
import { routes } from '../../../lib/routes';
import { t } from 'i18next';
import { generateLinks } from '../Dashboard';
import { useMemo } from 'react';

const CampsCard = () => {
  const {userRole} = useApi();
  const travels = useMemo(() => routes.find(r => t(r.title) === t('routes.camps')), [])
  return (
    <Card title={t('routes.camps')}>
      {generateLinks(travels, userRole)}
    </Card>
  );
}

export default CampsCard;