import React, {useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../../../../generic/tables";
import {EditModal, ModalConfirmation} from "../../../../../../generic/modals";
import EditModalChildren from "./EditModalChildren"
import {useDefaultEndpoints} from "../../../../../../../lib/api/useDefaultEndpoints";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {daysAbbr, time} from "../../../../../../../lib/utils/format";
import {ENTITIES, useRoleSystem} from "../../../../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../../../../common/formElements";
import {t} from "i18next";

dayjs.extend(customParseFormat)

const LessonsTable = ({state}) => {
    const [reload, setReload] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(undefined);
    const [isModalOpen, toggleModal] = useState(false);
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const {findAndCountEntries, deleteEntry, createEntry, editEntry} = useDefaultEndpoints('lessons');
    const permissions = useRoleSystem(state.type === 'theory' ? ENTITIES.GROUPS_THEORY: ENTITIES.GROUPS_LECTURES)

    const triggerModal = () => toggleModal(isModalOpen => !isModalOpen);
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);

    const deleteArrival = () => permissions.edit
            ? (lesson) => {
             setSelectedLesson(() => lesson);
             triggerModalConfirmation()
            }
            : null;

    const onClick = (lesson) => {
        setSelectedLesson(() => lesson);
        triggerModal();
    }

    return <Row>
        {isModalOpen &&
        <EditModal entityName={'lessons'} triggerModal={triggerModal} isOpened={isModalOpen}
                   entry={{
                       ...selectedLesson,
                       group_id: state.id,
                   }}
                   EditForm={EditModalChildren}
                   onSuccess={(entry) => (entry.id
                       ? editEntry(entry.id, entry)
                       : createEntry(entry))
                       .then(() => {
                           setReload(reload => !reload)
                           triggerModal();
                           setSelectedLesson(() => undefined);
                       })
                   }
                   size={'sm'}
                   edit={permissions.edit}
        />
        }
        {isModalConfirmationOpen &&
        <ModalConfirmation isOpen={isModalConfirmationOpen} hasCancel title={t('school.theory.lessonsTable.deleteLesson')}
                           text={`${t('school.theory.lessonsTable.youDeleteLesson')} ${selectedLesson.theme}`}
                           cancelButtonText={t('school.theory.grouspList.noDeleteGroup')}
                           confirmButtonText={t('school.theory.groupsList.yesDeleteGroup')}
                           onClose={() => {
                               triggerModalConfirmation();
                               setSelectedLesson(() => undefined);
                           }}
                           onConfirm={() => deleteEntry(selectedLesson.id).then(
                               () => {
                                   setReload(reload => !reload);
                                   triggerModalConfirmation()
                                   setSelectedLesson(() => undefined)
                               })}
        />}
        <Col xs={12}>
            <Row className={'mb-3'}>
                <Col md={3}>
                    <div className={'display-4'}>
                        {t('school.theory.lessonsTable.lessons')}
                    </div>
                </Col>
                <Col>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button color={'dark'} className={'btn-rounded'}
                                onClick={() => onClick(undefined)}> {t('school.theory.lessonsTable.add')} </Button>
                    </ElementWithPermissions>

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <DataTable
                        filters={{group_id: state.id}}
                        findAndCount={findAndCountEntries}
                        fields={[
                            field(t('school.theory.lessonsTable.date'), false, "date", res => <>
                                {dayjs(res.start_date).format('DD/MM - ')} {daysAbbr[dayjs(res.start_date).isoWeekday()]}
                            </>),
                            field(t('school.theory.lessonsTable.start_time'), false, "start_time", res => time(dayjs(res.start_time, 'HH:mm:ss'))),
                            field(t('school.theory.lessonsTable.theme'), false, "theme", res => res.theme && `${res.lesson_number}. ${res.theme}`),
                        ]}
                        sortField={'start_date'}
                        sortDir="asc"
                        name="arrivals-list"
                        onClick={onClick}
                        onDelete={deleteArrival()}
                        reload={reload}
                    />
                </Col>
            </Row>
        </Col>
    </Row>
}

export default LessonsTable;

LessonsTable.propTypes = {
    state: PropTypes.object.isRequired,
}