import { Button, Table } from 'reactstrap';
import React, { useState } from 'react';
import ManagerCommentsForm from './Form';
import { t } from 'i18next';
import dayjs from 'dayjs';
import {FaTrash} from "react-icons/fa";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";

const Item = ({ text, fullName, email, index, date, remove }) => (
  <tr>
    <td>{text}</td>
    <td>{fullName}</td>
    <td>{email}</td>
    <td>{date ? dayjs(date).format('DD.MM.YYYY HH:mm') : ''}</td>
    <td><Button color="danger" size="xs" onClick={() => remove(index)}><FaTrash/></Button></td>
  </tr>
);

const ManagerComments = ({ data: comments, id, entity, setData }) => {
  const data = comments || [];
  const [isOpen, setIsOpen] = useState(false);

  const { editEntry } = useDefaultEndpoints(entity);

  const toggle = () => setIsOpen(!isOpen);

  const isNotSingle = data.length > 1;
  const firstData = data[0];
  const otherData = isNotSingle && data.slice(1) || [];

  const onAdd = (value) => {
    setData([value, ...data]);
  }

  const onRemove = (index) => {
      const newList = [...data.slice(0, index), ...data.slice(index + 1)];

      editEntry(id, { comments_for_manager: newList }).then(() => setData(newList));
  }

  return (
    <>
      <Table responsive>
        <thead>
        <tr>
          <th>{t('common.comment')}</th>
          <th>{t('common.fullName')}</th>
          <th>{t('common.email')}</th>
          <th>{t('common.date')}</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {firstData && <Item {...firstData} remove={onRemove} index={0} key={0} />}
        {isOpen && otherData.map((i, index) => (
          <Item {...i} remove={onRemove} index={index + 1} key={index + 1} />
        ))}
        </tbody>
      </Table>
      {isNotSingle && <>
        <Button className='mb-4' color="link" onClick={toggle}>
          {!isOpen ? t('common.showAll') : t('common.hide')}
        </Button>
      </>}
      <ManagerCommentsForm id={id} entity={entity} onAdd={onAdd} />
    </>
  );
}

export default ManagerComments;