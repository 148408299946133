import React, {useEffect} from 'react';
import {useQueryParam} from "use-query-params";

const UseQueryParamWithDefault =(name, paramType, defaultValue, updateType) => {
    const [param, setParam] = useQueryParam(name, paramType);

    useEffect(() => {
        defaultValue && !param && setParam(defaultValue, 'replaceIn')
    }, []);


    const setParamWithReplace = (newValue) => setParam(newValue, updateType || 'replaceIn')
    return [param, setParamWithReplace];

};

export default UseQueryParamWithDefault;