import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';

const TimoutButton = ({ seconds, callback, button }) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    setTime(seconds);
    setInterval(() => {
      if (time > 0) {
        setTime(time => time - 1);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (time < 0) {
      callback && callback();
    }
  }, [time]);

  if (button) {
    return React.cloneElement(button, {
      disabled: time > 0,
    });
  } else return <Button disabled={time > 0} onClick={() => callback && callback()}>{time > 0 ? time : t('generic.okButton')}</Button>;
}

export default TimoutButton;