import { withBreadcrumbs } from '../../common/hoc/Breadcrumbs';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDefaultEndpoints } from '../../../lib/api/useDefaultEndpoints';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { Loader } from '../../generic/loader';
import { TableCard } from '../../generic/cards';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import RolesSelect from './RolesSelect';
import { RuleFields } from './utils';
import { frontRulesDefaultObject } from 'sv-common/constants/frontRoles';
import InfoTooltip from "../../generic/InfoTooltip";

const FrontendRole = withBreadcrumbs(() => {
  const history = useHistory()
  const { id } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [allRoles, setAllRoles] = useState([]);

  const {getEntry, createEntry, editEntry, findAndCountEntries} = useDefaultEndpoints('frontend-roles');

  const form = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      rules: frontRulesDefaultObject,
    }
  });

  const { handleSubmit, reset, register, control } = form;

  const onSubmit = handleSubmit((data) => {
    const promise = async () => id ? editEntry(id, data) : createEntry(data);

    promise()
      .then(() => {
        toast.success(t('common.success'));
        history.push('/administration/frontend-roles')
      });
  });

  useEffect(() => {
    findAndCountEntries({ _limit: 1000, _start: 0 }).then(v => setAllRoles(v.data));
  }, []);

  useEffect(() => {
    if (id) {
      getEntry(id)
        .then(res => {
          reset(res);
          setLoading(() => false)
        });
    } else {
      setLoading(() => false);
    }
  }, [id]);

  return isLoading
    ? <Loader/>
    : <TableCard>
      <Row className={'mb-3 justify-content-between align-items-center'}>
        <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
          <div className={'display-3'}>
            {id ? t('common.editing') : t('common.creating')}{' '}
          </div>
        </Col>
        <Col className={'col-auto'}>
          <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
            {t('common.save')}
          </Button>
        </Col>
        <Col className={'col-auto'}>
          <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                  onClick={() => history.push('/administration/frontend-roles')}>
            {t('common.cancel')}
          </Button>
        </Col>
      </Row>
      <Form>
        <Row>
          <Col xs={12}>
            <Label className={'display-4'}>{t('management.frontRoles.item')}</Label>
            <FormGroup>
                <Input innerRef={register()} name='title' />
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col xs={12}>
            <Label className={'display-4'}>{t('management.frontRoles.roles')}<InfoTooltip text={t('management.frontRoles.rolesInfo')} /></Label>
            <Controller
              control={control}
              render={({ value, ref, onChange }) => (
                <RolesSelect
                  allRoles={allRoles}
                  ref={ref}
                  onChange={onChange}
                  value={value}
                />
              )}
             name='sub_roles'
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Label className={'display-4'}>{t('management.frontRoles.rules')}</Label>
          </Col>
          <Col xs={12}>
            <RuleFields register={register} />
          </Col>
        </Row>
      </Form>
    </TableCard>
});

export default FrontendRole;