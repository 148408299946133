import {isShowElemInCountry} from "../../../lib/utils/helpers/localizeFeatures";
import {field} from "../../generic/tables";
import TinkoffLogo from "./ProductOrdersList/fieldSets/d6400f9d-63bb-4076-b887-7aa967baf8a9.svg";
import {t} from "i18next";
import {showWithCopy} from "./ProductOrdersList/fieldSets/utils";

const TinkoffIdField = () => {
    return isShowElemInCountry(['EN'])
        ? [field(<><img src={TinkoffLogo} alt={t('common.productOrderList.bankId')} style={{width: '2rem', marginLeft: '0.2rem'}}/></>, false, "client_id.id", res => showWithCopy(res.client_id.id))]
        : [];
}

export default TinkoffIdField;