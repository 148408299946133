import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {TableCard} from "../../../../generic/cards";
import {DataTable, field} from "../../../../generic/tables";
import SlotHrefAdmin from "../../../../common/hrefGenerators/SlotHrefAdmin";
import {date, fullTimeToShort, ISO_DATE,} from "../../../../../lib/utils/format";
import dayjs from "dayjs";
import {Col, FormGroup, Label} from "reactstrap";
import {useInstructorSchedulesApi} from "../../../../../lib/api/instructor-schedules";
import ClientHrefAdmin from "../../../../common/hrefGenerators/ClientHrefAdmin";
import {useQueryParam} from "use-query-params";
import IsoDatePicker from "../../../../common/formElements/IsoDatePicker";
import {t} from "i18next";

const Schedule = props => {
    const {findAndCountByDay} = useInstructorSchedulesApi();
    const [startDate, setStartDate] = useQueryParam('date');

    const filters = {
        instructor_id: props.state.id,
        start_date: startDate || dayjs().format(ISO_DATE),
        is_active: true
    };

    useEffect(() => {
       if (!startDate) setStartDate((dayjs().format(ISO_DATE)));
    }, []);

    return (
        <TableCard isInline>
            <div className={'row mb-3 pr-0 mr-0 align-items-end'}>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>{t('management.instructors.instructor.date')}</Label>
                        <IsoDatePicker name={'start_date'} value={filters.start_date}
                                       onDateChange={v => setStartDate(v, 'replaceIn')}/>
                    </FormGroup>
                </Col>
            </div>

            <DataTable findAndCount={findAndCountByDay}
                       filters={filters}
                       fields={[
                           field(t('management.instructors.instructor.date'), true, "start_date", res => date(res.start_date)),
                           field(t('management.instructors.instructor.time'), true, "start_time", res => fullTimeToShort(res.start_time)),
                           field(t('management.instructors.instructor.boat'), true, "boat_class"),
                           field(t('management.instructors.instructor.whoBooked'), false, "is_active",
                                   res =>
                                       <>{res.clients?.map((c, id) =>
                                           <>
                                               {
                                                   <ClientHrefAdmin name={`${c.first_name} ${c.last_name}`} clientId={c.id}/>
                                               }
                                               {
                                                   id !== (res.clients.length - 1) ?<>,<br/><br/></> : ''
                                               }
                                           </>
                                       )}</>
                           ),
                           field(t('management.instructors.instructor.slot'), false, "slot_id", res =>
                               <SlotHrefAdmin fetchById productId={res.slot_id?.id} linkName={res.slot_id.name}/>),
                       ]}
                       sortField="start_time"
                       sortDir="ASC"
                       name="subscription-changes"
            />
        </TableCard>
    );
};

Schedule.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};

export default Schedule;