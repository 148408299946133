import dayjs from "dayjs";
import PropTypes from "prop-types";
import {FaCheck} from "react-icons/fa";
import React, {useState} from 'react';
import {DataTable, field} from "../../../generic/tables";
import {RangedDatePicker} from "../../../generic/fields";
import {ModalConfirmation} from "../../../generic/modals";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Label} from "reactstrap";
import {t} from "i18next";
import { getConfirmationText } from './helpers';

const MovePaymentModalGroups = ({destinationGroup, sourceGroupId, setDestinationArrival, isModalOpen, triggerModal, clientId, moveOrder, presetFilters}) => {

    const {findAndCountEntries} = useDefaultEndpoints('groups');

    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const [daysFilter, setDaysFilter] = useState({
        from: dayjs().startOf('day').toDate(),
        to: dayjs().startOf('day').add(1, 'M').toDate()
    });

    const openMoveOrderModal = (res) => {
      setDestinationArrival(() => res);
        triggerConfirmationModal();
    };

    const triggerConfirmationModal = () => {
        toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);
    };

    const filters = {
        name_ncontains: 'delete',
        end_date_lte: daysFilter.to.toISOString(),
        start_date_gte: daysFilter.from.toISOString(),
        ...presetFilters
    };

    return (
        <Modal isOpen={isModalOpen} toggle={triggerModal} size={'lg'}>
            <ModalConfirmation onClose={triggerConfirmationModal} isOpen={isModalConfirmationOpen} onConfirm={moveOrder}
                               confirmButtonText={t('common.forms.movePaymentModalGroups.yes')}
                               title={t('common.forms.movePaymentModalGroups.clientMoving')}
                               text={destinationGroup && getConfirmationText(clientId, destinationGroup)}
                               timer={3}
            />
            <ModalHeader>{t('common.forms.movePaymentModalGroups.moving')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t('common.forms.movePaymentModalGroups.pickDates')}</Label>
                            <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            sortDir="asc"
                            filters={filters}
                            name="groups-list"
                            sortField="start_date"
                            findAndCount={findAndCountEntries}
                            fields={[
                                field(t('common.forms.movePaymentModalGroups.name'), true, "name", res => <>
                                    <h5>{res.name} </h5> <br/>
                                    {!!res.comment && <><h6>{res.comment} </h6> <br/></>}
                                    <div style={{color: 'green'}}>{res.url}</div>
                                </>),
                                field(t('common.forms.movePaymentModalGroups.startDate'), true, "start_date", res => <>
                                    {dayjs(res.start_date).format('DD.MM.YYYY')}</>),
                                field(t('common.forms.movePaymentModalGroups.endDate'), true, "end_date", res => <>
                                    {dayjs(res.end_date).format('DD.MM.YYYY ')}</>),
                                field(t('common.forms.movePaymentModalGroups.bookedToTotal'), false, "booked_to_total", res => <>
                                    {(res.booked_sits) + ' / ' + res.total_sits}
                                </>),
                                field(t('common.forms.movePaymentModalGroups.pick'), false, "id", res =>
                                    <Button color={sourceGroupId !== res.id ? 'success' : 'secondary'}
                                            onClick={() => openMoveOrderModal(res)} disabled={sourceGroupId === res.id}>
                                        <FaCheck/>
                                    </Button>
                                ),
                            ]}
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

MovePaymentModalGroups.propTypes = {
    isLecture: PropTypes.bool,
    clientId: PropTypes.number,
    moveOrder: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    sourceGroupId: PropTypes.number.isRequired,
    setDestinationArrival: PropTypes.func.isRequired,
};

export {
    MovePaymentModalGroups
};