import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useOrdersApi = () => {
    const {request} = useApi();
    const defaultOrdersApi = useDefaultEndpoints('orders');

    const setPartialPayInFull = async (orderId) => request('put', '/orders/setPartialPayInFull', {orderId});
    const setPaid = async (orderId, value) => request('put', '/orders/setPaid', {value, orderId});
    const deleteMember = async (orderId, clientId) => request('put', '/orders/deleteMember', {orderId, clientId});
    const movePayment = async (orderId, sourceProductType, sourceProductId, destinationProductType, destinationProductId, clientId) =>
        request('put', '/orders/move', {orderId, sourceProductType, destinationProductType, destinationProductId, clientId, sourceProductId});
    const generatePayment = async (productType, productId, orderId, payment_type = null) =>
        request('post', '/orders/invoice', {productType, productId, orderId, payment_type});
    const sendPayment = async (orderId) =>
        request('post', '/orders/invoice/send', {orderId});
    const createRefund = async (orderId, reason) => request('post', '/orders/refund', {orderId, reason});
    const exportForBase = async (isMaster, baseId) => {
      const data = { baseId };

      if (isMaster) {
        data.isMaster = isMaster;
      }

      return request('get', '/orders/export-by-bases', data);
    };
    const getOrdersStatistic = async (userId, start = '', end = '', status = '') => {
      return request('get', `/orders/statistic/${userId}?start=${start}&end=${end}&status=${status}`);
    }

    const getProductPassports = async (id, type) => {
      return request('get', `/orders/product-passports/${id}/${type}`);
    }

    const exportByDirection = async (from, to, direction, userId, directionId) => {
      return request('get', `/orders/export-by-direction`, { from, to, direction, userId, directionId });
    }

    return {...defaultOrdersApi, setPaid, deleteMember, movePayment, generatePayment, sendPayment, createRefund, setPartialPayInFull, exportForBase, getOrdersStatistic, getProductPassports, exportByDirection}
};