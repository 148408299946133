import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import ControllerRHF from "../../../generic/fields/ControllerRHF";
import { Label } from "reactstrap";
import {FormGroup} from 'reactstrap';

const AsyncSelectController = ({ 
    name, 
    form, 
    loadOptions, 
    label, 
    defaultValue = [], 
    isMulti = true, 
    debounceTimeout = 1000, 
    closeMenuOnSelect = false, 
    additional = { offset: 0, limit: 10, query: "" } 
}) => (
    <FormGroup>
        <Label>{label}</Label>
        <ControllerRHF
            name={name}
            form={form}
            defaultValue={defaultValue}
            render={({ value, onChange }) => (
                <AsyncPaginate
                    value={value}
                    loadOptions={loadOptions}
                    isMulti={isMulti}
                    onChange={(values) => onChange(values || [])}
                    debounceTimeout={debounceTimeout}
                    closeMenuOnSelect={closeMenuOnSelect}
                    additional={additional}
                />
            )}
        />
    </FormGroup>
);

export default AsyncSelectController;
