import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

const TableCard = ({title, children, className, isInline, ...rest}) =>
    isInline
        ?  <>
            {title && <h3 className="card-title">{title}</h3>}
            {children}
        </>
        : <Card title={title} className={className + ' table-card '} {...rest}>
            {children}
        </Card>;

TableCard.propTypes = {
    title: PropTypes.string
};

export default TableCard;