const ruTitleMapper = (path) => {
    return urls[path];
};

const management = 'management.breadcrumbsAndTitle.management'

const blacklist = 'management.breadcrumbsAndTitle.blacklist'
const create = 'management.breadcrumbsAndTitle.create'
const view = 'management.breadcrumbsAndTitle.view'
const edit = 'management.breadcrumbsAndTitle.edit'
const review = 'management.breadcrumbsAndTitle.review'
const purchases = 'management.breadcrumbsAndTitle.purchases'
const users = 'management.breadcrumbsAndTitle.users'
const instructors = 'management.breadcrumbsAndTitle.instructors'
const promoCodePackages = 'management.breadcrumbsAndTitle.promoCodePackages'
const promoCodes = 'management.breadcrumbsAndTitle.promoCodes'
const certificates = 'management.breadcrumbsAndTitle.certificates'
const frontendRoles = 'management.breadcrumbsAndTitle.frontendRoles'

const urls = {
    '/administration/violations': [{ru: management}, {ru: blacklist}],
    '/administration/violations/edit/': [{ru: management}, {ru: blacklist}, {ru: create}],
    '/administration/violations/edit/:id': [{ru: management}, {ru: blacklist}, {ru: view}],

    '/administration/feedback': [{ru: management}, {ru: review}],
    '/administration/feedback/edit/:id': [{ru: management}, {ru: review}, {ru: view}],

    '/administration/orders/edit/:id': [{ru: management}, {ru: purchases}, {ru: edit}],
    '/administration/orders/edit/': [{ru: management}, {ru: purchases}, {ru: create}],
    '/administration/orders': [{ru: management}, {ru: purchases}],

    '/administration/captains': [{ru: management}, {ru: 'management.breadcrumbsAndTitle.captains'}],

    '/administration/users': [{ru: management}, {ru: users}],
    '/administration/users/edit/:id': [{ru: management}, {ru: users}, {ru: edit}],
    '/administration/users/edit/': [{ru: management}, {ru: users},{ru: create}],

    '/administration/instructors': [{ru: management}, {ru: instructors}],
    '/administration/instructors/:id': [{ru: management}, {ru: instructors}, {ru: 'management.breadcrumbsAndTitle.editInstructor'}],

    '/administration/promocodes-packages/edit/:id': [{ru: management}, {ru: promoCodePackages}, {ru: edit}],
    '/administration/promocodes-packages/edit': [{ru: management}, {ru: promoCodePackages}, {ru: create}],
    '/administration/promocodes-packages': [{ru: management}, {ru: promoCodePackages}],

    '/administration/promocodes/edit/:id': [{ru: management}, {ru: promoCodes}, {ru: edit}],
    '/administration/promocodes/edit': [{ru: management}, {ru: promoCodes}, {ru: create}],
    '/administration/promocodes': [{ru: management}, {ru: promoCodes}],

    '/administration/certificates/edit/:id': [{ru: management}, {ru: certificates}, {ru: edit}],
    '/administration/certificates/edit': [{ru: management}, {ru: certificates}, {ru: create}],
    '/administration/certificates': [{ru: management}, {ru: certificates}],

    '/administration/exports': [{ru: management}, {ru: 'management.breadcrumbsAndTitle.export'}],
    '/administration/links-generator': [{ru: management}, {ru: 'management.breadcrumbsAndTitle.linksGenerator'}],
    '/administration/materials': [{ru: management}, {ru: 'management.breadcrumbsAndTitle.materials'}],
    '/administration/frontend-roles': [{ru: management}, {ru: 'management.breadcrumbsAndTitle.frontendRoles', link: ''}],
    '/administration/frontend-roles/edit/:id': [{ru: management}, {ru: frontendRoles, link: '/administration/frontend-roles'}, {ru: edit, link: ''}],
    '/administration/frontend-roles/edit': [{ru: management}, {ru: frontendRoles, link: '/administration/frontend-roles'}, {ru: create, link: ''}],
};

export default ruTitleMapper;