import React from 'react';
import {Controller} from "react-hook-form";
import FieldWithValidation from "./FieldWithValidation";
import PropTypes from 'prop-types';
import {Input} from "reactstrap";

const SelectControllerRHF = ({
                               inputName,
                               control,
                               defaultValue = '',
                               rules,
                               errors,
                               label,
                               setValue,
                               placeholder,
                               isRequired,
                               options,
                               disabled,
                               ...rest
                           }) =>
    <Controller
        control={control}
        name={inputName}
        defaultValue={defaultValue}
        rules={rules}
        render={(
            {value, onBlur, name, ref, onChange},
            {invalid, isTouched, isDirty}
        ) =>
            <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                 name={name} isRequired={isRequired}
            >
                <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                       style={{border: invalid ? '1px solid #dc3545' : '0'}}
                       value={value}
                       onChange={(e) => {
                           setValue && setValue(e);
                           onChange(e.target.value);
                       }}
                       disabled={disabled}

                >
                    {options}
                </Input>
            </FieldWithValidation>
        }
    />

export default SelectControllerRHF;

SelectControllerRHF.propTypes = {
    inputName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any.isRequired,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    label: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    options: PropTypes.array.isRequired,

}