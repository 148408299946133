import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import {Col, FormGroup, Label, Row} from 'reactstrap'
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import qs from 'qs';
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import {SelectControllerRHF, TextControllerRHF} from "../../../../generic/fields";
import Select from "react-select";
import FileControllerRHF from "../../../../generic/fields/FileControllerRHF";
import './style.css'
import {t as tr} from "i18next";


const CommonData = ({entity, bases, form}) => {
    const [boatsClasses, setBoatsClasses] = useState([]);
    const {control, errors} = form;
    let history = useHistory();
    const {t} = qs.parse(history.location?.search.slice(1))
    const boatsClassesEndpoints = useDefaultEndpoints('boats-classes');

    useEffect(() => {
        boatsClassesEndpoints.getEntries({})
            .then(res => setBoatsClasses(() => [...res.map(boat => ({value: boat.class, label: boat.class}))]))
    }, [])


    return <Row>
        {(t !== 'existing' || (t === 'existing' && !entity.id)) && <>
            <Col md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'first_name'} control={control}
                                       label={tr('management.instructors.editModal.name')}
                                       isRequired={t === 'new'} errors={errors}
                                       defaultValue={entity.first_name || ''}
                                       placeholder={tr('management.instructors.editModal.namePlaceholder')} rules={t === 'new' ? {required: tr('management.instructors.editModal.requiredField')} : {}}/>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <TextControllerRHF inputName={'last_name'} control={control}
                                       label={tr('management.instructors.editModal.surname')}
                                       isRequired={t === 'new'} errors={errors}
                                       defaultValue={entity.last_name || ''}
                                       placeholder={tr('management.instructors.editModal.surnamePlaceholder')}
                                       rules={t === 'new' ? {required: tr('management.instructors.editModal.requiredField')} : {}}/>
                </FormGroup>
            </Col>
        </>}
                <Col md={6}>
                    <FormGroup>
                        <SelectControllerRHF inputName={'status'} control={control}
                                             isRequired={true} rules={{required: tr('management.instructors.editModal.requiredField')}}
                                             errors={errors} label={tr('management.instructors.editModal.status')}
                                             defaultValue={entity.status || ''}
                                             options={[
                                                 <option value='' disabled>{tr('management.instructors.editModal.chooseStatus')}</option>,
                                                 <option value={'active'}>{tr('management.instructors.editModal.active')}</option>,
                                                 <option value={'inactive'}>{tr('management.instructors.editModal.inactive')}</option>,
                                                 <option value={'ill'}>{tr('management.instructors.editModal.ill')}</option>
                                             ]}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <SelectControllerRHF inputName={'base_id'} control={control}
                                             isRequired={true} rules={{required: tr('management.instructors.editModal.requiredField')}}
                                             errors={errors} label={tr('management.instructors.editModal.attachToBase')}
                                             defaultValue={entity.base_id?.id || ''}
                                             options={[
                                                 <option value='' disabled>{tr('management.instructors.editModal.chooseBase')}</option>,
                                                 ...(bases?.map(base => (<option key={"base_id-" + base.id}
                                                                                 value={base.id}>{base.name}</option>)) || [])]}/>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <TextControllerRHF inputName={'state'} control={control}
                                           label={tr('management.instructors.editModal.comment')}
                                           errors={errors}
                                           defaultValue={entity.state || ''}
                                           placeholder={tr('management.instructors.editModal.wellDone')}/>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label for="state">{tr('management.instructors.editModal.boatClass')}</Label>
                        <Controller
                            control={control}
                            name="boats_classes"
                            defaultValue={entity.boats_classes}
                            rules={{required: tr('management.instructors.editModal.requiredField')}}
                            render={(
                                {onChange},
                                {}
                            ) =>
                                <Select
                                    isMulti
                                    name="boats_classes"
                                    options={boatsClasses}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={entity.boats_classes.map(boat_class => ({
                                        value: boat_class,
                                        label: boat_class
                                    }))}
                                    onChange={(res) => onChange((res || []).map(record => record.value))}
                                    placeholder={tr('management.instructors.editModal.chooseBoatClasses')}
                                />
                            }
                        />
                    </FormGroup>

                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FileControllerRHF
                            form={form}
                            name={"files"}
                            fileName={"avatar"}
                            defaultValue={entity.files}
                            label={tr('management.instructors.editModal.avatar')}
                            hint={tr('management.instructors.editModal.fileHint')}
                        />
                    </FormGroup>
                </Col>
            </Row>


}

CommonData.propTypes = {
    entity: PropTypes.object.isRequired,
}

export default CommonData;

