import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useCertificatesApi = () => {
    const defaultCertificatesApi = useDefaultEndpoints('certificates');
    const {request} = useApi();
    const changeCertificate = async (size, price_currency, size_left, certificateId) =>
        request('put', `/certificates/change`, {size, price_currency, size_left, certificateId})

    const sendCertificateToRecipient = async (certificateId) =>
        request('post', `/certificates/send/recipient`, {certificateId})

    const sendCertificateToSender = async (certificateId) =>
        request('post', `/certificates/send/sender`, {certificateId})

    const downloadCertificate = async (certificateId) =>
        request('get', `/certificates/${certificateId}/download`);


    return {...defaultCertificatesApi, changeCertificate, sendCertificateToRecipient, sendCertificateToSender, downloadCertificate};
}