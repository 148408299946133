import React from 'react';
import {TableCard} from "../../../../generic/cards";
import {Loader} from '../../../../generic/loader';
import {Promocodes} from "../../../../bases/events/event/NewEventBlocks";
import {ENTITIES, useRoleSystem} from "../../../../../lib/utils/hooks/useRoleSystem";

const ArrivalPromocodes = ({state, dispatch}) => {
    const permissions = useRoleSystem(state.adventure_id?.type === 'world' ? ENTITIES.ADVENTURES_WORLD : ENTITIES.ADVENTURES_RUSSIA)
    return !state.id ?
        <Loader/>
        : (
            <>
                <TableCard isInline>
                    <Promocodes state={state} productType={'arrivals'} dispatch={dispatch} disabled={!permissions.edit}/>
                </TableCard>
            </>
        )
}

export default ArrivalPromocodes;