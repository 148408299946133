import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {useHistory, useParams} from "react-router-dom";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import {TextControllerRHF} from "../../generic/fields";
import {requiredRule} from "../../common/reactHookFormsFields/rules";

const EditDirectory = withBreadcrumbs(() => {
    let history = useHistory();
    const {id} = useParams();
    const {getEntry, createEntry, editEntry} = useDefaultEndpoints('constructor-directories');
    const form = useForm();
    const {control, errors, handleSubmit, reset} = form;

    const onCreateOrUpdate = (data) => id
        ? editEntry(id, data).then( () => toast.success(t('common.success')) )
        : createEntry(data).then( () => history.push('/constructor/directory/') );

    const onSubmit = handleSubmit(onCreateOrUpdate);

    useEffect(() => {
        id && getEntry(id).then(reset);
    }, [id]);

    return <TableCard>
        <div className={"row justify-content-end mb-3"}>
            <div className={"col-"}>
                <Button color="success" size={'sm'} className={'btn-rounded'} onClick={onSubmit}>
                    {t('management.orders.order.save')}
                </Button>
            </div>
        </div>
        <Form>
            <Row>
                <Col md={6} xs={12}>
                    <FormGroup>
                        <TextControllerRHF
                            inputName={'description'} control={control} errors={errors} label={t('common.description')}
                            isRequired defaultValue='' rules={requiredRule} placeholder={t('common.description')}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    </TableCard>
});

export default EditDirectory;