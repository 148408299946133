import React, { useState, createContext } from 'react';
import { getCookie, setCookie } from '../utils/cookies';
export const GlobalState = createContext({});

const parseFeatureFlags = () => {
    let features = [];
    if (process.env.REACT_APP_DEV_FRONTEND_FEATURES)
        features = (process.env.REACT_APP_DEV_FRONTEND_FEATURES || '').split(',')
    if (process.env.REACT_APP_PROD_FRONTEND_FEATURES)
        features = (process.env.REACT_APP_PROD_FRONTEND_FEATURES || '').split(',')
    return features.reduce((acc, curVal) => {
        if (curVal)
            acc[curVal] = true;
        return acc;
    }, {})
}

export const features = parseFeatureFlags()

export const emptyGlobalState = {
    userId: 0,
    userEmail: '',
    userName: '',
    userAccessToken: '',
    userRefreshToken: '',
    userRole: 'Public',
    language: '',
    authProvider: '',
};

let initialGlobalState = emptyGlobalState;

if (typeof window !== 'undefined') {
    initialGlobalState = {
        userId: parseInt(getCookie('sv_admin_uid'), 10),
        userEmail: getCookie('sv_admin_email'),
        userName: getCookie('sv_admin_name'),
        userAccessToken: getCookie('userAccessToken'),
        userRefreshToken: getCookie('sv_admin_refresh_token'),
        userRole: getCookie('sv_admin_role'),
        authProvider: getCookie('sv_admin_auth_provider')
    };
}

export const GlobalStateProvider = ({ children }) => {
    const [globalStateValue, _setGlobalStateValue] = useState(initialGlobalState);

    const setGlobalStateValue = (state) => {
        setCookie('sv_admin_uid', state.userId);
        setCookie('sv_admin_email', state.userEmail);
        setCookie('sv_admin_name', state.userName);
        setCookie('userAccessToken', state.userAccessToken);
        setCookie('sv_admin_refresh_token', state.userRefreshToken, 58);
        setCookie('sv_admin_role', state.userRole);
        setCookie('sv_admin_auth_provider', state.authProvider);


        _setGlobalStateValue(state);
    };

    return (
        <GlobalState.Provider value={[globalStateValue, setGlobalStateValue]}>
            {children}
        </GlobalState.Provider>
    );
};
