import React, {useEffect, useState} from "react";
import SortableColumn from "./SortableColumn";
import PropTypes from "prop-types";

const SortableTable = (props) => {
    const [state, setState] = useState({
        sortField: props.sortField,
        sortDir: props.sortDir,
        multipleSelectAll: false
    })

    /**
     * Обновляет сортировку при клике по столбцу
     * @param sortField - ключ поля
     * * @param sortDir - направление соритровки asc или desc
     */
    const handleSort = (sortField, sortDir) => {
        setState(state => ({...state, sortField: sortField, sortDir: sortDir}));
        props.handleSort(sortField, sortDir);
    }

    const onMultipleSelectAll = (checked) => {
        setState(state => ({...state, multipleSelectAll: checked}));
        props.onMultipleSelectAll(checked);
    }


    /**
     * Обновляет сортировку, если поля изменяются снаружи
     * @param prevProps
     */
    useEffect(() => {
        setState(state => ({...state, sortField: props.sortField, sortDir: props.sortDir}))
    }, [props.sortField, props.sortDir])

    return <table
      className={
        props.className ||
        ("table table-striped table-bordered" + (props.hover ? " table-hover" : ""))
      }
      style={props.style}
      >
        <thead>
        <tr>
            {props.hasMultipleSelection && <th style={{width: '1px'}}>
                <input
                    onChange={event => onMultipleSelectAll(event.target.checked)}
                    checked={state.multipleSelectAll ? 'checked' : ''}
                    className="checkbox"
                    type="checkbox"
                />
            </th>}
            {props.fields.map(field =>
                field.isSortable ?
                    <SortableColumn key={field.key}
                                    sort={state.sortField === field.key ? state.sortDir : null}
                                    handleSort={sortDir => handleSort(field.key, sortDir)} {...field.props}
                                    className={field.className}>
                        {field.label}
                    </SortableColumn> :
                    <th key={field.key} className={field.className}>{field.label}</th>
            )}
        </tr>
        </thead>
        <tbody>
        {props.children}
        </tbody>
    </table>;
}


export class HeaderConfig {
    constructor(label, isSortable, key, className) {
        this.label = label;
        this.isSortable = isSortable || false;
        this.key = key || label;
        this.className = className;
    }
}

export const headerConfig = (label, isSortable, key, className) => new HeaderConfig(label, isSortable, key, className)

SortableTable.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.instanceOf(HeaderConfig)),
    className: PropTypes.string,
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
    hover: PropTypes.bool,
    hasMultipleSelection: PropTypes.bool,
    /**
     * function(checked: bool)
     */
    onMultipleSelectAll: PropTypes.func,
    multipleSelectAll: PropTypes.bool
};

export default SortableTable;

