import React, {useEffect, useState} from 'react';
import {DataTable, field} from "../../../generic/tables";
import {useParams, useHistory} from 'react-router-dom';
import {TableCard} from "../../../generic/cards";
import { Button } from 'reactstrap';
import {ModalConfirmation} from "../../../generic/modals";
import {openInNewTab} from "../../../../lib/utils/helper";
import {CopyLink} from "../../../generic/buttons";
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {useEventsApi} from "../../../../lib/api/events";
import {toast} from "react-toastify";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import {t} from "i18next";
import CommonToggle from '../../../common/formElements/Toggle';
import {RuleAccessProvider} from "../../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

const showDangerToast = e => toast(e, {type: 'error'});
const showSuccessToast = e => toast(e, {type: 'success'});

const EventsTable = ({filters}) => {
    const getEventLink = ({base_id, url}) => `${base_id?.booking_url || BOOKING_URL}/sport/${url}`

    const [reload, setReload] = useState(false);
    const {deleteEntry, findAndCountWithSlotFiltration, setAdminVisibility} = useEventsApi();
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const {url} = useParams();
    let history = useHistory();
    const [ejectedEvent, setEjectedEvent] = useState('');

    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);
    const changeVisibility = (val) => {
        setAdminVisibility(val.id, !val.show_in_admin)
            .then(() => {
                showSuccessToast(t('bases.base.statusReloaded'));
                setReload(r => !r);
            })
            .catch(e => showDangerToast(e.message))
    }

    useEffect(() => {
        setReload(r => !r);
    }, [url])

    const deleteEvent = (event) => {
        setEjectedEvent(() => event);
        triggerModalConfirmation()
    }

    const {edit} = useRoleSystem(ENTITIES.EVENTS);

    const onMiddleClick = (res) => openInNewTab(`/bases/${url}/event/${res.url}`)

    return (
        <>
            {isModalConfirmationOpen && <ModalConfirmation onClose={triggerModalConfirmation} onConfirm={() => {
                deleteEntry(ejectedEvent.id).then(() => {
                    setReload(reload => !reload);
                    triggerModalConfirmation();
                })

            }} isOpen={isModalConfirmationOpen} hasCancel title={t('bases.base.eventDeleting')}
                                                           text={t('bases.base.areYouSureEvent')}
                                                           cancelButtonText={t('bases.base.cancel')}
                                                           confirmButtonText={t('bases.base.yes')}
            />}

            <TableCard title={t('bases.base.events')} isInline>
                <div className={'row justify-content-end mb-3 pr-0 mr-0'}>
                    <div className={'col-'}>
                        <RuleAccessProvider accessKey={frk["BASE-EVENTS-CREATION"]}>
                            <ElementWithPermissions disabled={!edit}>
                                <Button size={'sm'} color={'primary'} className={'btn-rounded'}
                                        onClick={() => history.push("/bases/" + url + "/event")}>
                                    {t('bases.base.createEvent')}
                                </Button>
                            </ElementWithPermissions>
                        </RuleAccessProvider>
                    </div>
                </div>
                <DataTable findAndCount={findAndCountWithSlotFiltration}
                           filters={filters}
                           fields={[
                               field(t("bases.base.name"), true, "name"),
                               field(t("bases.base.boatClass"), true, "boat_class"),
                               field(t("bases.base.comment"), true, "comment"),
                               field("URL", true, "url", res => <>
                                   {res.url}
                                   <span>
                                       <CopyLink href={getEventLink(res)} showLink={false}/>
                                   </span>
                               </>),
                               field(t("bases.base.duration"), true, "duration"),
                               field(t('inputs.hide'), true, "show_in_admin", res =>
                                   <ElementWithPermissions disabled={!edit}>
                                     <CommonToggle isActive={!res.show_in_admin} onChange={() => changeVisibility(res)} />
                                   </ElementWithPermissions>
                               ),
                           ]}
                           sortField="created_at"
                           sortDir="desc"
                           name="events-list"
                           onClick={(res) => history.push(`/bases/${url}/event/${res.url}`)}
                           onDelete={edit ? deleteEvent : null}
                           reload={reload}
                           onMiddleClick={onMiddleClick}
                />
            </TableCard>
        </>
    )
};

export default EventsTable;