import {React, useState, useEffect} from 'react';
import './statusIndicator.scss'

function StatusIndicator({res}) {
    const [status, setStatus] = useState('');
    useEffect(() => {
        if (res.available_sits && res.is_open && !res.is_private) {
            setStatus("indicator-green")
        } else if (res.available_sits && res.is_open && res.is_private) {
            setStatus("indicator-yellow")
        } else {
            setStatus("indicator-red")
        }
    }, [res.available_sits, res.is_open, res.is_private]);

    return (
        <div className={'wrapper-indicator'} id={"comment-" + res.id}>
            <span className={`indicator ${status}`}></span>
        </div>
    )
}

export default StatusIndicator;