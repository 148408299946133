import React from "react";
import PropTypes from 'prop-types';


const generateCard = ({children, title, className}) => (
    <div className={'card ' + className}>
        <div className="card-body">
            {title && <h3 className="card-title">{title}</h3>}
            {children}
        </div>
    </div>
);

const Card = ({children, title, className, ignoreWrapper, passClassName}) =>
    ignoreWrapper
        ? generateCard({children, title, className})
        : <div className={`${className ? ` ${className}` : ''}`}>
            {generateCard({children, title, className: passClassName ? className : ''})}
        </div>;

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    ignoreWrapper: PropTypes.bool,
    passClassName: PropTypes.bool
};

Card.defaultProps = {
    className: "",
    ignoreWrapper: false
};

export default Card;