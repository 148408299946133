import FieldWithValidation from "./FieldWithValidation";
import {Controller} from "react-hook-form";
import PropTypes from 'prop-types';


const ControllerRHF = ({
    form,
    name,
    label,
    defaultValue,
    rules,
    hint,
    isRequired = false,
    render,
}) => {
    const {control, errors} = form;

    return (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={(
            {value, onBlur, name, ref, onChange},
            {invalid, isTouched, isDirty}
        ) =>
            <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                 name={name} isRequired={isRequired}
                                 hint={hint}>
                {render({value, onBlur, name, ref, onChange}, {invalid, isTouched, isDirty})}
            </FieldWithValidation>
        }
    />
    )
}


ControllerRHF.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any.isRequired,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
}


export default ControllerRHF;