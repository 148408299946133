import React from 'react'
import EditInstructor from './tabs/EditInstructor';
import Schedule from "./tabs/Schedule";
import {t} from "i18next";

const tabList = ({state, dispatch, form = {}}) => {
    return (
        [
            {
                name: t('management.instructors.instructor.instructorData'),
                child: <EditInstructor state={state} setState={dispatch} form={form}/>,
                isDisplayed: true,
                url: 'existing'
            },
            {
                name: t('management.instructors.instructor.schedule'),
                child: <Schedule state={state} setState={dispatch} form={form}/>,
                isDisplayed: !!state.id,
                url: 'schedule'
            },

        ]
    )
}

export default tabList;