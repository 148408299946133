const ruTitleMapper = (path) => {
    return urls[path];
}

const events = 'bases.breadcrumbsAndTitle.events';
const bases = 'bases.breadcrumbsAndTitle.bases';

const urls = {
    '/bases': [{ru: bases}],
    '/bases/new': [{ru:'bases.breadcrumbsAndTitle.newBase'}],
    '/bases/calendar': [
        {ru:bases},
        {ru: 'bases.breadcrumbsAndTitle.calendar'}
    ],
    '/bases/:url/events': [
        {ru: ':url'},
        {ru: events}
    ],
    '/bases/:url/event/:event_url/edit/': [
        {ru: ':url'},
        {ru: events},
        {ru: ':event_url'},
        {ru: 'bases.breadcrumbsAndTitle.editing'},
    ],
    '/bases/:url/event/:event_url/slot/:slot_url': [
        {ru: ':url'},
        {ru: events},
        {ru: ':event_url'},
        {ru: ':slot_url'},
    ],
    '/bases/:url/event': [
        {ru: ':url'},
        {ru: events},
        {ru: 'bases.breadcrumbsAndTitle.new'},
    ],
    '/bases/:url/event/:event_url': [
        {ru: ':url'},
        {ru: events},
        {ru: ':event_url'},
    ],
    '/bases/:url': [{ru: ':url'},]
}
export default ruTitleMapper;