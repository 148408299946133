import React from 'react'
import {Col, CustomInput, Form, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {

    return <Form>
        <Row>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="key"
                        defaultValue={entity.key || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.userFields.nameEn')}
                                                 hint={t('directories.userFields.nameEnHint')} name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="example_name"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="value"
                        defaultValue={entity.value || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('directories.userFields.nameRu')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.userFields.nameRuPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_required" name="is_required"
                                 label={t('directories.userFields.requiredLabel')} checked={entity.is_required || false}
                                 onChange={e => handleInput(e, setEntity).value}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_optional" name="is_optional"
                                 label={t('directories.userFields.optionalToShowLabel')} checked={entity.is_optional || false}
                                 onChange={e => handleInput(e, setEntity).value}
                    />
                </FormGroup>
            </Col>

        </Row>
    </Form>
}

export default EditModalChildren;
