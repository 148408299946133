import React, {useMemo} from 'react';
import {Card} from "../../generic/cards";
import {routes} from "../../../lib/routes";
import {useApi} from "../../../lib/api/useApi";
import {generateLinks} from "../Dashboard";
import {t} from "i18next";

DirectoriesCard.propTypes = {};

function DirectoriesCard() {
    const {userRole} = useApi();
    const directories = useMemo(() => routes.filter(r => r.access.includes(userRole))
        .find(r => t(r.title) === t('routes.directories')), [])
    return (directories?.title ?
               <Card title={t('routes.directories')} className={'col-xs-3 col-md-6 col-lg-6 col-xl-3 grid-margin stretch-card'}>
                   {generateLinks(directories, userRole)}
               </Card>
            :   <></>
    );
}

export default DirectoriesCard;