import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../../lib/api/useDefaultEndpoints";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {TextControllerRHF} from "../../../../../generic/fields";
import {useSubscriptionChangesApi} from "../../../../../../lib/api/subscription-changes";
import {useForm} from "react-hook-form";
import {t} from "i18next";

const SubscriptionChangeModal = ({triggerModal, isOpened, state, dispatch}) => {
    const setClient = data => dispatch({type: 'setClient', payload: data});
    const {changeSubscription} = useSubscriptionChangesApi();
    const {getEntries} = useDefaultEndpoints('subscriptions')
    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});
    const [subscriptions, setSubscriptions] = useState([]);
    const [depositSum, setDepositSum] = useState(state.deposit_sum || 0);
    const [depositTypeId, setDepositTypeId] = useState(state.subscription_id?.id || '')
    const [comment, setComment] = useState('');

    useEffect(() => {
        getEntries({_sort: 'price_value:ASC'}).then(res => setSubscriptions(() => res))
    }, [])

    const onSubmit = handleSubmit(() =>
        changeSubscription(depositSum, depositTypeId, state.id, comment)
            .then(res => setClient(res))
            .then(triggerModal))
    return <>
        <Modal isOpen={isOpened} toggle={triggerModal} size={'md'} unmountOnClose>
            <ModalHeader>{t('management.users.subscriptionChangeTitle')}</ModalHeader>
            <ModalBody>
                <Form>
                    <Row className={'justify-content-around'}>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <TextControllerRHF inputName={'deposit_sum'} control={control} errors={errors}
                                                   defaultValue={depositSum}
                                                   setValue={e => setDepositSum(() => e.target.value)}
                                                   label={t('management.users.subscriptionSum')}
                                                   rules={{
                                                       pattern: {
                                                           value: /^[0-9]*$/,
                                                           message: t('management.users.subscriptionIntValidation')
                                                       }
                                                   }}/>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={5}>
                            <FormGroup>
                                <Label>{t('management.users.subscription')}</Label>
                                <Input type={'select'} value={depositTypeId}
                                       onChange={e => setDepositTypeId(() => e.target.value)}>
                                    <option value={''}>{t('management.users.notSelected')}</option>
                                    {subscriptions.map(sub => <option key={sub.id} value={sub.id}>{sub.name}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={11}>
                            <FormGroup>
                                <Label>{t('management.users.comment')}</Label>
                                <Input type={'text'} value={comment}
                                       onChange={e => setComment(() => e.target.value)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col>
                        <Button color="primary" onClick={onSubmit}>
                            {t('management.users.ok')}
                        </Button>
                    </Col>
                    <Col>
                        <Button color="secondary" outline onClick={triggerModal}>
                            {t('management.users.cancel')}
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>

    </>
}

SubscriptionChangeModal.propTypes = {
    state: PropTypes.object.isRequired,
    triggerModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired

}

export default SubscriptionChangeModal;


