import { Col, FormGroup, Row } from "reactstrap";
import { SelectControllerRHF, TextControllerRHF } from "../../../generic/fields";
import { t } from "i18next";
import Dropzone from "./Dropzone";
import AsyncSelectController from "./AsyncSelectController";

const LetterFormFields = ({ control, errors, getFormatsOptions, getBaseAddressesOptions, getAreasOptions, getCoordinatorsOptions, requiredRule, form }) => (
    <>
        <Row>
            <Col lg={4} md={6} xs={12}>
                <Dropzone form={form} isRequired={false} />
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={"rules_link"}
                        control={control}
                        errors={errors}
                        isRequired
                        defaultValue=""
                        placeholder={t("constructor.pages.rulesLinkPlaceholder")}
                        label={t("constructor.templates.commercial_offer")}
                        rules={requiredRule}
                    />
                </FormGroup>
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <AsyncSelectController
                    name="formats"
                    form={form}
                    loadOptions={getFormatsOptions}
                    label={t("constructor.pages.formats")}
                />
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <AsyncSelectController
                    name="base_addresses"
                    form={form}
                    loadOptions={getBaseAddressesOptions}
                    label={t("constructor.pages.base_address")}
                />
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <AsyncSelectController
                    name="areas"
                    form={form}
                    loadOptions={getAreasOptions}
                    label={t("constructor.pages.areas")}
                />
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={"photos_link"}
                        control={control}
                        errors={errors}
                        isRequired
                        defaultValue=""
                        placeholder={t("constructor.pages.photosLinkPlaceholder")}
                        label={t("constructor.templates.presentaion")}
                        rules={requiredRule}
                    />
                </FormGroup>
            </Col>
        </Row>
        <div className="pages-divider" />

        <Row>
            <Col xs={12}>
                <FormGroup>
                    <SelectControllerRHF
                        inputName={"coordinator"}
                        control={control}
                        defaultValue=""
                        options={getCoordinatorsOptions()}
                        label={t("constructor.pages.coordinator")}
                        isRequired
                        errors={errors}
                        rules={requiredRule}
                    />
                </FormGroup>
            </Col>
        </Row>
    </>
);

export default LetterFormFields;
