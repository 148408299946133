import "./index.scss";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import LinksHistory from "../LinksHistory";
import {CopyLink} from "../../../generic/buttons";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {EVENT_TYPES_LANG_ADAPTER} from "sv-common/constants/events";
import {useBoatsClassesApi} from "../../../../lib/api/boatsClasses";
import {useLinksApi} from "../../../../lib/api/links";
import {useBasesApi} from "../../../../lib/api/bases";
import {toast} from "react-toastify";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import i18n from "../../../../lib/utils/i18n";
import {EVENT_TYPES_ALLOWED} from "../../../../lib/utils/constants";

const SportLinksGenerator = () => {
    const [boats, setBoats] = useState([]);
    const [bases, setBases] = useState([]);
    const [selectedBases, setSelectedBases] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedBoats, setSelectedBoats] = useState([]);
    const [reload, setReload] = useState(false);
    const [generatedLink, setGeneratedLink] = useState("");

    const permissions = useRoleSystem(ENTITIES.LINK_GEN);
    const {findAndCountEntries} = useBasesApi();
    const boatsApi = useBoatsClassesApi();
    const linksEndpoints = useLinksApi();

    const { language } = i18n;
    const EVENT_TYPES = EVENT_TYPES_ALLOWED.map(eventType => ({value: eventType, label: EVENT_TYPES_LANG_ADAPTER[language][eventType]}));

    const generateLink = async () => {
        if (!selectedEvents?.length && !selectedBoats?.length && !selectedBases?.length) {
            toast.error(t('management.linksGenerator.enterField'))
            return;
        }
        const url = await linksEndpoints.generateLinkForSport(
            selectedBases?.length ? selectedBases.map(base => base.value) : [],
            selectedEvents?.length ? selectedEvents.map(event => event.value) : [],
            selectedBoats?.length ? selectedBoats.map(event => event.value) : []
        )
        setGeneratedLink(url);
        setReload(!reload);
    };

    useEffect(() => {
        findAndCountEntries({"is_archived_ne": true}).then(res => setBases(res.data));
        boatsApi.getEntries().then(res => setBoats(res))
    }, []);

    return <>
        <form>
            <Row>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.base')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'bases'} isMulti menuPlacement={'bottom'} value={selectedBases}
                                placeholder={t('management.linksGenerator.choose')} onChange={setSelectedBases}
                                options={bases.map(base => ({label: base.name, value: base.url}))}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.eventType')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'events'} isMulti menuPlacement={'bottom'} value={selectedEvents}
                                options={EVENT_TYPES.map(event => ({label: event.label, value: event.value}))}
                                onChange={setSelectedEvents} placeholder={t('management.linksGenerator.choose')}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.boardType')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'boats'} isMulti menuPlacement={'bottom'} value={selectedBoats}
                                options={boats.map(boat => ({label: boat.class, value: boat.class}))}
                                onChange={setSelectedBoats} placeholder={t('management.linksGenerator.choose')}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <Label>{t('management.linksGenerator.link')}</Label>
                    <Input name="link" value={generatedLink} disabled
                           placeholder="https://booking.silavetra.com/sport?base=berezki&event=train"/>
                    <div className="mt-2">
                        <CopyLink href={generatedLink} text={t('management.linksGenerator.followLink')}
                                  className={!generatedLink ? 'disabledCopyButton' : ''}
                                  buttonClassName={!generatedLink ? 'disabledCopyButton' : ''}/>
                    </div>
                </Col>
                <Col xs={12} className="d-flex align-items-center">
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className="btn-rounded" size="sm" color="primary" onClick={generateLink}>{t('management.linksGenerator.generate')}</Button>
                    </ElementWithPermissions>
                </Col>
            </Row>
        </form>
        <LinksHistory reload={reload}/>
    </>
}

export default SportLinksGenerator