import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {AiOutlinePercentage} from "react-icons/ai";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {t} from "i18next";

const showSuccessToast = e => toast(e, {type: 'success'});

const PayInstallmentPopover = ({orderId, duration, label, promocode}) => (
    <>
        <CopyToClipboard text={promocode} onCopy={() => showSuccessToast(t('common.popovers.installmentNameCopied'))}>
            <AiOutlinePercentage id={"installment-" + orderId} size={'20px'} color={'green'}/>
        </CopyToClipboard>

        <UncontrolledPopover placement="left" target={"installment-" + orderId} trigger={'hover'}>
            <PopoverBody>
                <b className={'popover-text'}>{`${label} `}</b>
                <div>
                    {t('common.popovers.installmentDuration')}: <b>{`${duration}`}</b>
                </div>
                <div>
                    {t('common.popovers.installmentPromocode')}: <b>{`${promocode}`}</b>
                </div>
            </PopoverBody>
        </UncontrolledPopover>
    </>
)

export default PayInstallmentPopover