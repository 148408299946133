import Header from "./Header";
import Sidebar from "./Sidebar";
import React, {useEffect, useState} from "react";
import {useApi} from "../../../lib/api/useApi";
import {getCurrentRoute} from "../../../lib/routes";
import {useLocation} from "react-router-dom";
import {Title} from '../../generic'
import {getCookie, setCookie} from "../../../lib/utils/cookies";


const AdminLayout = ({children}) => {
    let location = useLocation();
    const {userLoggedIn} = useApi();
    const [isSidebarOpen, setSidebarOpen] = useState(getCookie('sv_admin_sidebar') === 'open');

    const toggleSidebar = () => {
        setSidebarOpen((isOpen) => {
            setCookie('sv_admin_sidebar', isOpen ? 'closed' : 'open')
            return !isOpen
        })
    }
    const [route, setRoute] = useState(getCurrentRoute(location.pathname));
    useEffect(() => {
        setRoute(() => getCurrentRoute(location.pathname));
    }, [location.pathname])

    return (
        <div className={'container-scroller'}>
            {userLoggedIn && <Header toggleSidebar={toggleSidebar}/>}
            <div className={'container-fluid page-body-wrapper '  + (isSidebarOpen ? '' : 'sidebar-icon-only')}>
                {userLoggedIn && <Sidebar active={isSidebarOpen}/>}
                                                            {/*workaround for login form width*/}
                <div className={'main-panel'} style={!userLoggedIn ? {width:'100%'} : {fake: '0'}}>
                    <div className={userLoggedIn ? 'content-wrapper' : 'content-wrapper d-flex align-items-center'}>
                        {userLoggedIn && <Title {...route} pathname={location.pathname}/>}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminLayout