import React, {useState, useEffect} from 'react';
import {Col, Row, Input, FormGroup, Label} from "reactstrap";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {t} from "i18next";

const ClientFieldsTable = ({state, dispatch, fields, showAlways = false, headless = false}) => {

    return <table className="table">
        <thead>
        <tr>
            <th>{!headless && <b>{showAlways ? '(i) ' + t('adventures.edit.askAlways') : t('adventures.edit.field')}</b>}</th>
            <th>{!headless && <b>{t('adventures.edit.show')}</b>}</th>
        </tr>
        </thead>
        <tbody>
        {fields.map((field, index) => (
            <tr key={index}>
                <td>{field.value}</td>
                <td>
                    <Input type={'checkbox'} disabled={showAlways} name={field.key}
                           onChange={(e) => dispatch({type: 'setReqField', payload: [e, field]})}
                           checked={showAlways ? true : !!state.req_fields[field.key]?.label}/>
                </td>
            </tr>
        ))}
        </tbody>
    </table>
}

const ClientFieldsBlock = ({state, dispatch}) => {
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templates, setTemplates] = useState([]);
    const [userFields, setUserFields] = useState([]);

    const {getEntries} = useDefaultEndpoints('user-fields-templates');
    const getUserFields = useDefaultEndpoints('user-fields').getEntries;

    const setTemplateFields = e => {
        const template = templates.filter(template => template.id === ~~e.target.value)[0];
        setSelectedTemplate(() => template);

        const optionalFields = userFields.filter(field => template.fields.includes(field.key))
        const requiredFields = userFields.filter(({is_required, is_optional}) => is_required && !is_optional)
        dispatch({type: 'setReqFields', payload: [...optionalFields, ...requiredFields]});
    }

    useEffect(() => {
        getEntries({}).then(res => setTemplates(() => res));
        getUserFields({}).then(res => setUserFields(() => res));
    }, [])

    return (
        <Row>
            <Col xs={12}>
                <h4 className={'display-4'}>{t('adventures.edit.whatWeNeed')}</h4>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Label for="country">{t('adventures.edit.template')}</Label>
                    <Input type="select" name="selectedTemplate" id="selectedTemplate" placeholder="Россия"
                           value={selectedTemplate.id || ''}
                           onChange={setTemplateFields}
                    >
                        <option disabled value={''}>{t('adventures.edit.pickTemplate')}</option>
                        {templates.map(template => <option key={template.id} value={template.id}>{template.name}</option>)}
                    </Input>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <ClientFieldsTable state={state} dispatch={dispatch}
                                   fields={userFields
                                       .filter(({is_required, is_optional}) => is_required && is_optional)}/>
            </Col>
            <Col xs={12}>
                <ClientFieldsTable state={state} showAlways dispatch={dispatch}
                                   fields={userFields
                                       .filter(({is_required, is_optional}) => is_required && !is_optional)}/>
            </Col>
            <Col xs={12} md={5}>
                <ClientFieldsTable state={state} headless dispatch={dispatch}
                                   fields={userFields
                                       .filter(({is_required, is_optional}) => (!is_required) && is_optional)}/>
            </Col>
        </Row>
    )
}

export default ClientFieldsBlock;