import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {TableCard} from "../../generic/cards";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {useApi} from "../../../lib/api/useApi";
import {FaPlus} from 'react-icons/fa';
import {Input} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {TextSearch} from "../../generic/fields";
import {datetime} from "../../../lib/utils/format";
import {openInNewTab} from "../../../lib/utils/helper";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../common/formElements";
import {t} from "i18next";

const Users = ({role = undefined, onClick = undefined, isInline, createTranslatedBreadcrumbsAndTitle}) => {
    const {findAndCountEntries} = useDefaultEndpoints('users')
    const {getEntries} = useDefaultEndpoints('users-permissions/roles');
    const [roles, setRoles] = useState([]);

    const [nameFilter, setNameFilter] = useState(undefined)
    const [emailFilter, setEmailFilter] = useState(undefined)
    const [selectedRole, setSelectedRole] = useState(role);
    const {userRole} = useApi();
    let history = useHistory();
    const permissions = useRoleSystem(role === 'Капитан' ? ENTITIES.CAPTAINS : ENTITIES.CLIENTS);

    useEffect(() => {
        getEntries({}).then(res => setRoles(() => [...res.roles]))
        createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle({}, {})
        // eslint-disable-next-line
    }, [])


    const filters = {
        _where: {
            _or: [
                [{'client.first_name_contains': nameFilter}, {'role.description': selectedRole}, {'client.email_contains': emailFilter?.trim()}, { 'client_null': false }],
                [{'client.last_name_contains': nameFilter}, {'role.description': selectedRole}, {'client.email_contains': emailFilter?.trim()}, { 'client_null': false }],
            ],
        },
    }

    const onMiddleClick = (res) => openInNewTab('/administration/users/edit/' + res.client?.id)

    return <>
        <TableCard isInline={isInline}>
            <div className={"row justify-content-end mb-3 pr-0 mr-0"}>
                {
                    !role && <div className={'col-2'}>
                        <Input
                            type={'select'}
                            id="ChooseRole"
                            className="form-control"
                            name="role"
                            value={selectedRole || ''}
                            onChange={(e) => setSelectedRole(() => e.target.value)}
                            bsSize={'sm'}
                        >
                            <option key={"user-role-none"} value='' disabled>{t('management.users.chooseRole')}</option>
                            {roles && roles.map(role => {
                                return <option key={"user-role-" + role.id}
                                               value={role.description}>{role.description}</option>
                            })
                            }
                        </Input>
                    </div>
                }
                <div className={'col-3'}>
                    <TextSearch value={nameFilter || ''} onSearch={(res) => setNameFilter(() => res)}
                                placeholder={t('management.users.surnamePlaceholder')} bsSize={'sm'}/>
                </div>
                <div className={'col-3'}>
                    <TextSearch value={emailFilter || ''} onSearch={(res) => setEmailFilter(() => res)}
                                placeholder={'email@example.com'} bsSize={'sm'}/>
                </div>
                <div className={"col-"}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <button className={'btn btn-rounded btn-primary btn-sm'}
                                onClick={() => history.push('/administration/users/edit')}>
                            <FaPlus/>
                            &nbsp; {t('management.users.create')}
                        </button>
                    </ElementWithPermissions>
                </div>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.users.name'), true, "client.first_name"),
                           field(t('management.users.mail'), true, "email"),
                           field(t('management.users.role'), true, "role", res => res.role.description),
                           field(t('management.users.blocked'), true, "blocked", res =>
                               <small>{res.blocked ? t('management.users.yes') : t('management.users.no')}</small>
                           ),
                           field(t('management.users.registered'), true, "created_at", res => datetime(res.created_at)),
                       ]}
                       sortField="created_at"
                       sortDir="desc"
                       name="users-list"
                       onClick={user => onClick ? onClick(user) : history.push('/administration/users/edit/' + user.client?.id)}
                       onMiddleClick={user => !onClick && onMiddleClick(user)}
            />
        </TableCard>
    </>
}

export default Users;

Users.propTypes = {
    onClick: PropTypes.func,
    role: PropTypes.string
}