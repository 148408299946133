import React from 'react';
import PropTypes from 'prop-types';
import SelectControllerRHF from "../../../generic/fields/SelectControllerRHF";
import {INT_COUNTRIES_LIST} from "sv-common/constants/bases";
import {t} from "i18next";
import {COUNTRY} from "../../../../lib/utils/constants";

const getCountries = () => [
                    <option value={''}>{t('common.forms.country.pickCountry')}</option>,
                    ...INT_COUNTRIES_LIST[COUNTRY].map(c => <option key={c.key} value={c.key}>{c.value}</option>),
]
const Country = ({form, state, setState, isRequired = true}) => {
    const {control, errors} = form;
    return (
        <SelectControllerRHF control={control} isRequired={isRequired} inputName={'country'} options={getCountries()}
                             errors={errors} setValue={setState} defaultValue={state.country || ''}
                             label={t('common.forms.countryList.label')}
                             rules={isRequired ? {required: t('inputs.validation.required')} : {}}
        />
    );
}

Country.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    hint: PropTypes.string
};
export default Country;