import React from 'react'
import About from "./tabs/about";
import {ProductOrdersList} from "../../common/formElements";
import ArrivalPromocodes from "./tabs/promocodes";
import CancelledAndMovedOrdersList from "../../common/formElements/CancelledAndMovedOrdersList";
import {getArrivalSet, getPracticeSet} from "../../common/formElements/ProductOrdersList/fieldSets";
import GlobalSales from '../../common/formElements/GlobalSales';
import TinkoffIdField from "../../common/formElements/TinkoffIdField";

const tabList = ({state, dispatch, control, errors, setValue, permissions}) => {
    return (
        [
            {
                name: 'adventures.tabList.clients',
                child: <>
                    <ProductOrdersList state={state} dispatch={dispatch} disabled={!permissions.edit}
                                       product_type={state.is_school ? 'practices' : 'arrivals'}/>
                    <CancelledAndMovedOrdersList
                        name={"arrival_orders-moved-and-cancelled-list"}
                        fields={state.is_school ? [ ...getPracticeSet(), ...TinkoffIdField() ] : [ ...getArrivalSet(), ...TinkoffIdField() ]}
                        entity={state}
                        entityOrderName={'arrival_id'}
                        reload={state.reload}
                    />
                </>,
                isDisplayed: state.id,
                url: 'orders'
            },
            {
                name: "adventures.tabList.aboutArrival",
                child: <About dispatch={dispatch} state={state} control={control} errors={errors} setValue={setValue}/>,
                isDisplayed: true,
                url: 'about'
            },
            {
                name: "adventures.tabList.globalSales",
                child: <GlobalSales entityName='arrivals' state={state} />,
                isDisplayed: true,
                url: 'globalsales'
            },
            {
                name: "adventures.tabList.promocodes",
                child: <ArrivalPromocodes state={state} dispatch={dispatch}/>,
                isDisplayed: true,
                url: 'promocodes'
            },

        ]
    )
}

export default tabList;