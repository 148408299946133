import React from 'react';
import PropTypes from 'prop-types';

const ConditionalField = ({condition, children}) =>
    condition
        ? <>{children}</>
        : <></>


ConditionalField.propTypes = {
    condition: PropTypes.bool.isRequired,
}

export default ConditionalField;

