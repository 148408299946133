import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Label, Input} from "reactstrap";
import {DataTable, field} from "../../../generic/tables";
import dayjs from "dayjs";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {FaCheck} from "react-icons/fa";
import {ModalConfirmation} from "../../../generic/modals";
import {RangedDatePicker} from "../../../generic/fields";
import {t} from "i18next";
import { getConfirmationText } from './helpers';

const MovePaymentModalArrivals = ({destinationArrival, sourceArrivalId, setDestinationArrival,
                                  isModalOpen, triggerModal, clientId, moveOrder, presetFilters = {}}) => {
    const {findAndCountEntries} = useDefaultEndpoints('arrivals');
    const getAdventures = useDefaultEndpoints('adventures').getEntries;

    const [selectedAdventure, setSelectedAdventure] = useState(presetFilters.adventure_id)
    const [adventures, setAdventures] = useState([]);
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const [daysFilter, setDaysFilter] = useState({
        from: dayjs().startOf('day').toDate(),
        to: dayjs().startOf('day').add(1, 'M').toDate()
    })

    const isNotToday = arrival => dayjs(arrival.start_date).isAfter(dayjs())
        || dayjs(arrival.start_date).isSame(dayjs().startOf("day"))

    const practiceFilter = adventure => presetFilters.type === 'practices'
        ? adventure.is_school === true
        : adventure.is_school === false

    const openMoveOrderModal = (res) => {
        setDestinationArrival(() => res);

        triggerConfirmationModal()
    }

    const triggerConfirmationModal = () => {
        toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen)
    }

    useEffect(() => {
        getAdventures({_sort:'name:asc', _limit:10000, is_archived: false})
            .then(res =>
                setAdventures(() => res.filter(adventure =>
                    adventure.arrivals_ids?.some(arrival => !arrival.is_archived && isNotToday(arrival))
                        && practiceFilter(adventure)
                ))
            )
    }, [])

    const filters = {
        name_ncontains: 'delete',
        adventure_id: selectedAdventure,
        start_date_gte: daysFilter.from.toISOString(),
        end_date_lte: daysFilter.to.toISOString(),
        is_archived: false
    };

    return (
        <Modal isOpen={isModalOpen} toggle={triggerModal} size={'lg'}>
            <ModalConfirmation onClose={triggerConfirmationModal} isOpen={isModalConfirmationOpen}
                               onConfirm={moveOrder}
                               confirmButtonText={t('common.forms.movePaymentModal.yes')}
                               text={destinationArrival && getConfirmationText(clientId, destinationArrival)}
                               title={t('common.forms.movePaymentModal.clientMoving')}
                               timer={3}
            />
            <ModalHeader>{t('common.forms.movePaymentModal.move')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('common.forms.movePaymentModal.pickTravel')}
                            </Label>
                            <Input type={'select'} name={'adventure_id'} value={selectedAdventure}
                                   onChange={(e) => setSelectedAdventure(() => e.target.value)}>
                                {adventures.map(adventure => <option key={adventure.id} value={adventure.id}>{adventure.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>
                                {t('common.forms.movePaymentModal.pickDates')}
                            </Label>
                            <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            findAndCount={findAndCountEntries}
                            filters={filters}
                            fields={[
                                field(t('common.forms.movePaymentModal.name'), true, "name", res => <>
                                    <h5>{res.name} </h5> <br/>
                                    {!!res.comment && <><h6>{res.comment} </h6> <br/></>}
                                    <div style={{color: 'green'}}>{res.url}</div>
                                </>),
                                field(t('common.forms.movePaymentModal.startDate'), true, "start_date", res => <>
                                    {dayjs(res.start_date).format('DD.MM.YYYY')}</>),
                                field(t('common.forms.movePaymentModal.endDate'), true, "end_date", res => <>
                                    {dayjs(res.end_date).format('DD.MM.YYYY ')}</>),
                                field(t('common.forms.movePaymentModal.bookedToTotal'), false, "booked_to_total", res => <>
                                    {(res.booked_sits) + ' / ' + res.total_sits}
                                </>),
                                field(t('common.forms.movePaymentModal.pick'), false, "id", res =>
                                    <Button color={sourceArrivalId !== res.id ? 'success' : 'secondary'}
                                            onClick={() => openMoveOrderModal(res)} disabled={sourceArrivalId === res.id}>
                                        <FaCheck/>
                                    </Button>
                                ),
                            ]}
                            sortField="start_date"
                            sortDir="asc"
                            name="arrivals-list"
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

MovePaymentModalArrivals.propTypes = {
    sourceArrivalId: PropTypes.number.isRequired,
    setDestinationArrival: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    clientId: PropTypes.number,
    presetFilters: PropTypes.object
};

export {
    MovePaymentModalArrivals
};