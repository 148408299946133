import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {t} from "i18next";

const Phone = ({ form, state, setState, setValue, isRequired = true, name = 'phone', disabled = false,
               country = 'ru', label = t('common.forms.phone.label') }) => {
    const {control, errors} = form;
    const [minPhoneLength, setMinPhoneLength] = useState(11);
    const rules = {minLength: {value: minPhoneLength, message: t("common.forms.phone.minMessage")}}
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={state[name] || ''}
            rules={isRequired ? {required: t('inputs.validation.required'), ...rules} : rules}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors} label={label} name={name} isRequired={isRequired}
                                     type="text" onBlur={onBlur} value={value} placeholder="+X (XXX) XXX-XX-XX"
                                     onChange={e => {
                                         setState && setState(e)
                                         onChange(e.target.value)
                                     }}
                >
                    <PhoneInput
                        containerClass={invalid ? 'is-invalid': ''}
                        onChange={(value, country, e) => {
                            onChange(value);
                            setState && setState(e);
                            setValue && setValue({target: {value, name}});
                            if (country.countryCode === 'ru')
                                setMinPhoneLength(11)
                            else
                                setMinPhoneLength(null)
                        }}
                        value={value}
                        country={country}
                        inputProps={{name}}
                        isValid={!invalid}
                        disabled={disabled}
                    />
                </FieldWithValidation>
            }
        />
    );
}

Phone.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func,
    isRequired: PropTypes.bool,
};
export default Phone;