import React, {useEffect, useReducer, useState} from 'react'
import {TableCard} from "../../generic/cards";
import {useHistory, useLocation, useParams} from "react-router-dom"
import {initialState, reducer} from "./reducer";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import tabList from "./tabList";
import {Loader} from '../../generic/loader';
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import qs from 'qs';
import {useForm} from "react-hook-form";
import {TextEditRHF} from "../../common/reactHookFormsFields";
import {API_URL} from "../../../lib/api/useApi";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import {useRuleAccess} from "../../../lib/utils/frontRules";

const BaseInterface = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory()
    let {url} = useParams();
    const {pathname, search} = useLocation();
    const rolePermissions = useRoleSystem(ENTITIES.BASES);
    const { check } = useRuleAccess();
    const {edit, view} = rolePermissions;

    const [state, dispatch] = useReducer(reducer, initialState);
    const {getEntries, createEntry, editEntry} = useDefaultEndpoints('bases', true)
    const [activeTab, setActiveTab] = useState('about');
    const form = useForm({reValidateMode: 'onBlur',});
    const {handleSubmit} = form
    const toggleTab = tab => {
        history.replace(`${pathname}?t=${tab.url}`)
        if (activeTab !== tab.url) setActiveTab(tab.url)
    };

    useEffect(() => {
        if (url)
            getEntries({url: url}).then(res => {
                dispatch({type: 'setBase', payload: {...res[0], files: res[0].img ? {...res[0].img, preview: API_URL + res[0].img.url} : {}}})
                const mapper = {
                    url: {ru: res[0].name || '', en: url},
                };
                createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })        // eslint-disable-next-line
        else {
            dispatch({type: 'setLoading', payload: false});
            createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle({}, {})
        }
        if (!search) history.replace(`${pathname}?t=${activeTab}`);


        let {t} = qs.parse(history.location?.search.slice(1))
        if (t)
            setActiveTab(t)
        // eslint-disable-next-line
    }, [history.location.pathname, url])

    const createOrUpdateBase = () => {
        const {active_promocodes_ids, ...rest} = state;
        return (state.id
            ? editEntry(state.id, rest).then(res => (res.url !== url) && history.push(res.url))
            : createEntry(state).then(() => history.push('/bases/' + state.url)))
            .then(() => toast.success(t('bases.base.reloadSuccess')))
            .catch(() => toast.error(t('bases.base.somethingWentWrong')))
    }

    const onSubmit = handleSubmit(() => {
            createOrUpdateBase()
        },
        () => {
            toast.error(t('bases.base.requiredError'))
        })

    return state.loading
        ? <Loader/>
        : <TableCard>
            <Row className={'mb-3 justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <TextEditRHF state={state} name={'name'} form={form} isRequired={true} placeholder={t('bases.base.baseName')}
                                 setState={val => dispatch({type: 'setBase', payload: {name: val}})}/>
                </Col>
                <Col className={'col-auto'}>
                    <ElementWithPermissions hidden={!view} disabled={!edit}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('bases.base.save')}
                        </Button>
                    </ElementWithPermissions>

                </Col>
                <Col className={'col-auto'}>
                    <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                            onClick={() => history.push('/bases')}>
                        {t('bases.base.cancel')}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabList({state, dispatch, rolePermissions, check})
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(12 / tabList({state, dispatch, rolePermissions, check}).length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {t(tab.name)}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {
                            tabList({state, dispatch, form, rolePermissions, check})
                                .filter(tab => tab.isDisplayed !== false).map(tab => (
                                    <TabPane tabId={tab.url} key={tab.name}>
                                        {tab.child}
                                    </TabPane>
                            ))
                        }
                    </TabContent>
                </Col>
            </Row>

        </TableCard>
}

export default BaseInterface;