import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "../../../generic/loader";
import {TableCard} from "../../../generic/cards";
import {Col, CustomInput, FormGroup, Row} from "reactstrap";
import {FaPencilAlt} from 'react-icons/fa'
import {MainInfo, SlotsTable} from "./ExistingEventBlocks";
import {Promocodes} from "./NewEventBlocks";
import {useEventsApi} from "../../../../lib/api/events";
import {t} from "i18next";
import ExportByDirectionButton from '../../../common/formElements/ExportByDirectionButton';
import { COMMON_PRODUCT_DIRECTIONS } from '../../../../lib/utils/constants';
import {RuleAccessProvider} from "../../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

const Event = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory();
    let {url, event_url} = useParams();
    const [state, setState] = useState({});
    const {getEntries, setPromocodesVisible} = useEventsApi();

    const changePromocodesVisibility = () => setPromocodesVisible(state.id, !state.show_promocodes)
        .then(res => setState(() => res))

    useEffect(() => {
        getEntries({url: event_url}).then(res => {
            setState(() => res[0]);
            const mapper = {
                url: {ru: res[0].base_id.name || '', en: url},
                event_url: {ru: res[0].name || '', en: event_url},
            }
            createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
        })// eslint-disable-next-line

    }, [history.location.pathname, event_url, url])


    return !state.id
        ? <Loader/>
        : <TableCard>

            <RuleAccessProvider accessKey={frk["EVENT-ITEM-EDIT"]}>
                <Row className={'mb-5 justify-content-between align-items-center'}>
                    <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                        <div className={'display-4'}>
                            {state.name} <FaPencilAlt style={{cursor: 'pointer'}}
                                                      onClick={() => history.push(`/bases/${url}/event/${event_url}/edit`, {id: state.id})}/>
                        </div>
                    </Col>
                </Row>
            </RuleAccessProvider>

            <Row>
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12}>
                            <MainInfo state={state}/>
                        </Col>
                    </Row>
                    <RuleAccessProvider accessKey={frk["EVENT-ITEM-EXPORT"]}>
                        <Col xs={12}>
                            <ExportByDirectionButton direction={COMMON_PRODUCT_DIRECTIONS.EVENTS} directionId={state.id} />
                        </Col>
                    </RuleAccessProvider>
                    <Row>
                        <Col xs={12} className={'mt-3'}>
                            <FormGroup className={'mb-1'}>
                                <CustomInput type="checkbox" className={'custom-switch'} id="showPromo"
                                             name="showPromo"
                                             label={t('bases.base.showPromo')}
                                             checked={state.show_promocodes || false}
                                             onChange={changePromocodesVisibility}
                                />
                            </FormGroup>
                        </Col>
                        {state.show_promocodes === true &&
                        <Col>
                            <Promocodes state={state} productType={'events'}/>
                        </Col>
                        }
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <SlotsTable state={state}/>
                </Col>
            </Row>
        </TableCard>
}


export default Event;