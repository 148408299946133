import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {TableCard} from "../../../generic/cards";
import {Discounts, Settings} from "../../events/event/NewEventBlocks";
import {useParams} from 'react-router-dom';
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import { Loader } from '../../../generic/loader';
import {UrlRHF} from "../../../common/reactHookFormsFields";
import {CopyLink} from "../../../generic/buttons";
import {BOOKING_URL} from "../../../../lib/api/useApi";
import {TextControllerRHF} from "../../../generic/fields";
import {useSlotsApi} from "../../../../lib/api/slots";
import {t} from "i18next";

const Slot = ({state, dispatch, form}) => {

    const {findByUrl} = useSlotsApi();
    const getBase = useDefaultEndpoints('bases').getEntry;
    const [slotUrl, setSlotUrl] = useState('')

    let {slot_url} = useParams();

    const fetchSlotAndBase = async () => {
        const slot = await findByUrl(slot_url);
        const base = await getBase(slot.event_id.base_id);
        dispatch({type: 'setSlotData', payload: {...slot, base}});
        setSlotUrl(() => `${base.booking_url || BOOKING_URL}/sport/${base.url}/${slot.url}`)
    }

    useEffect(() => {
        if (!state.id)
            fetchSlotAndBase().catch(e => console.error(e))
        else
            getBase(state.event_id.base_id).then(base => {
                dispatch({type: 'setSlotData', payload: { base}});
                setSlotUrl(() => `${base.booking_url || BOOKING_URL}/sport/${base.url}/${state.url}`)
            })
        // eslint-disable-next-line
    }, [])

    return !state.id ?
        <Loader />
        :(
        <>
            <TableCard isInline>
                <Row>
                    <Col xs={12} md={6}>
                        <Settings state={state} dispatch={dispatch} isSlot={true} errors={form.errors}
                                  control={form.control}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Discounts dispatch={dispatch} state={state}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={8}>
                                <FormGroup>
                                    <Label for="name">{t('bases.base.slotComment')}</Label>
                                    <Input type={'text'} name={'comment'} value={state.comment || ''}
                                           onChange={e => dispatch({type: 'setEventInputData', payload: e})}/>
                                    <Label for={'name'} className={'text-secondary'}>{t('bases.base.eventCommentHint')}</Label>
                                </FormGroup>
                            </Col>
                            <Col xs={11} md={7}>
                                <FormGroup>
                                    <UrlRHF state={state} setState={e => dispatch({type: 'setEventInputData', payload: e})}
                                            form={form} label={t('bases.base.eventUrl')}
                                            hint={t('bases.base.eventUrlHint')}/>
                                </FormGroup>
                            </Col>
                            <Col xs={1}>
                                <FormGroup check row>
                                    <Label>
                                    {t('bases.base.link')}
                                    </Label>
                                    <CopyLink href={slotUrl} showLink={false}/>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={8}>
                                <FormGroup>
                                    <TextControllerRHF defaultValue={state.explanation || ''} inputName={"explanation"}
                                                       control={form.control} setValue={e =>  dispatch({type: 'setFormData', payload: e})}
                                                       label={t('bases.base.decs')} rules={{maxLength: 250}} hint={t('bases.base.decsHint')}
                                                       errors={form.errors} placeholder={t('bases.base.decsPlaceholder')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={8}>
                                <FormGroup>
                                    <Label for="name">{t('bases.base.slotStatus')}</Label>
                                    <Input type={'select'} name={'status'} value={state.status}
                                           onChange={e => dispatch({type: 'setEventInputData', payload: e})}>
                                        <option value={'coming'}>{t('bases.base.coming')}</option>
                                        <option value={'postponed'}>{t('bases.base.postponed')}</option>
                                        <option value={'canceled'}>{t('bases.base.canceled')}</option>
                                        <option value={'finished'}>{t('bases.base.finished')}</option>
                                        <option value={'other'}>{t('bases.base.other')}</option>

                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={8}>
                                <FormGroup>
                                    <Label for="name">{t('bases.base.slotPrice')}</Label>
                                    <Input type={'text'} name={'price_value'} value={state.price_value} placeholder={'1000'}
                                           onChange={e => dispatch({type: 'setEventInputData', payload: e})}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableCard>
        </>
    )
}

export default Slot;