import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FaFileImport} from "react-icons/fa";
import {t} from "i18next";
import { previewImage } from '../preview';


const Dropzone = ({extFile, callback, name, accept, onError, maxSize, maxFiles, ...rest}) => {

  const [file, setFile] = useState(extFile);
  const {getRootProps, getInputProps} = useDropzone({
    maxSize,
    maxFiles: maxFiles || 1,
    accept: accept || 'image/*',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (file.file.type.includes('image')) {
            acceptedFiles.push(file.file);
          } else {
            if (err.code === "file-too-large") {
              onError(t('generic.dropzone.formatTooLarge'));
            }

            if (err.code === "file-invalid-type") {
              onError(t('generic.dropzone.invalidType'));
            }
          }
        });
      });

      const file = acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file), fieldName: name ? name: 'file'}))[0]

      if (file) {
        setFile(() => file);
        if (callback) {
          callback(file);
        }
      }
    },
  });

  const onDelete = () => {
    setFile(null)
    callback({fieldName: name, preview: null, file});
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  useEffect(() => {
    setFile(() => extFile)
  }, [extFile]);

  return (
    <div className={"dropify-wrapper" + (file ? " has-preview" : "")} {...getRootProps()} {...rest} >
      <input className="dropify" {...getInputProps()} />
      <button type="button" className="dropify-clear" onClick={onDelete}>{t('generic.dropzone.delete')}</button>
      <div className="dropify-message">
        <div>
          <FaFileImport size={'2em'}/>
          <p>{t('generic.dropzone.uploadFile')}</p>
        </div>
      </div>
      {
        file && <div className={'dropify-preview'} style={{display: file ? 'block' : 'none'}}>
          <span className={'dropify-render'}>
            {previewImage({ ext: file.ext, src: file.preview})}
          </span>
          <div className="dropify-infos">
            <div className="dropify-infos-inner">
              <p className="dropify-filename">
                <span className="file-icon"></span>
                <span className="dropify-filename-inner">{file.name}</span>
              </p>
              <p className="dropify-infos-message">{t('generic.dropzone.dropFile')}</p></div>
          </div>
        </div>
      }
    </div>

  );
}

export default Dropzone;