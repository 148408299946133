import React, {useCallback, useEffect, useState} from 'react';
import {TableCard} from '../../generic/cards'
import {useDefaultEndpoints} from '../../../lib/api/useDefaultEndpoints'
import {DataTable, field} from '../../generic/tables'
import {FaCheck, FaPlus, FaTimes} from 'react-icons/fa';
import {Col, CustomInput, FormGroup, Input, Label} from 'reactstrap'
import {useHistory} from 'react-router-dom';
import {datetime} from '../../../lib/utils/format'
import {RangedDatePicker, TextSearch} from "../../generic/fields";
import PropTypes from 'prop-types';
import {openInNewTab} from "../../../lib/utils/helper";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {StringParam, useQueryParam} from "use-query-params";
import {ExportFunButton} from "../../generic/buttons";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {RuleAccessProvider} from "../../../lib/utils/frontRules";
import { frk } from "sv-common/constants/frontRoles";
import {t} from "i18next";

const PromocodesList = withBreadcrumbs(({isInline = false, isView = false, onClick}) => {
    const {findAndCountEntries, exportEntries} = useDefaultEndpoints('promocodes');
    const [isArchived, setIsArchived] = useState(false);
    const permissions = useRoleSystem(ENTITIES.PROMOCODES);
    let history = useHistory();
    const filters = {
        is_archived: isArchived
    }

    const [daysFilter, setDaysFilter] = useState({
        from: undefined,
        to: undefined
    })

    const [nameFilter, _setNameFilter] = useQueryParam('name', StringParam)
    const setNameFilter = (v) => _setNameFilter(v, 'replaceIn');

    if (daysFilter.from)
        filters.created_at_gte = daysFilter.from

    if (daysFilter.to)
        filters.created_at_lte = daysFilter.to

    if (nameFilter)
        filters.name_contains = nameFilter

    const onMiddleClick = (res) => openInNewTab('/administration/promocodes/edit/' + res.id)

    const exportButton = useCallback((dataParameters) => {
        return <ExportFunButton dataParameters={{...dataParameters}} exportFun={exportEntries}/>
    }, [])

    return <>
        <TableCard isInline={isInline}>
            <div className={'row justify-content-between mb-0 pr-0 mr-0'}>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <TextSearch placeholder={'QWER-1234-ASDF-ZXCV'} value={nameFilter || ''}
                                    onSearch={e => setNameFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label className={'mb-0'}>
                            {t('management.promoCodes.showArchive')}
                        </Label>
                        <CustomInput type='checkbox' className={'custom-switch'} id='is_waiting'
                                     name='is_waiting' onChange={e => setIsArchived(() => e.target.checked)}
                        />
                    </FormGroup>
                </Col>
              <RuleAccessProvider accessKey={frk["PROMOCODES-ITEM-EDIT"]}>
                {!isView && <div className={'col-auto'}>
                  <ElementWithPermissions disabled={!permissions.edit}>
                    <button className={'btn btn-sm btn-primary btn-rounded'}
                            onClick={() => history.push('/administration/promocodes/edit/')}>
                      <FaPlus/>
                      &nbsp; {t('management.promoCodes.create')}
                    </button>
                  </ElementWithPermissions>
                </div>}
              </RuleAccessProvider>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.promoCodes.promoCodeName'), true, 'name'),
                           field(t('management.promoCodes.startDate'), true, 'available_from', res => datetime(res.available_from)),
                           field(t('management.promoCodes.endDate'), true, 'available_to', res => res.is_open_ended
                               ? t('management.promoCodes.indefinite')
                               : datetime(res.available_to)
                           ),
                           field(t('management.promoCodes.usingMax'), true, 'uses_max'),
                           field(t('management.promoCodes.totalUsages'), true, 'used_total'),
                           field(t('management.promoCodes.repaid'), true, "is_extinguished",
                               res => res.is_extinguished ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>),
                           field(t('management.promoCodes.archived'), true, "is_archived",
                               res => res.is_archived ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>),
                           field(t('management.promoCodes.creator'), false, "creator.email"),
                       ]}
                       sortField='created_at'
                       sortDir='desc'
                       name='promocodes-list'
                       onClick={promocode => onClick
                           ? onClick(promocode)
                           : history.push('/administration/promocodes/edit/' + promocode.id)
                       }
                       onMiddleClick={!onClick && onMiddleClick}
                       tableButtons={exportButton}
            />
        </TableCard>
    </>
})

export default PromocodesList;

PromocodesList.propTypes = {
    isInline: PropTypes.bool,
    isView: PropTypes.bool,
    onClick: PropTypes.func
}