import React from "react";
import { Dropzone } from '../../generic/fields';
import {Controller} from "react-hook-form";
import {Col, FormGroup, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {t} from "i18next";
import {API_URL} from "../../../lib/api/useApi";
import { withBreadcrumbs } from "../../common/hoc/Breadcrumbs";
import CarouselComponent from "../../common/formElements/Carousel";
import {useFilesApi} from "../../../lib/api/files";

const EditModalChildren = withBreadcrumbs(({entity, setEntity, control, errors, handleInput}) => {
    const { upload } = useFilesApi();

    return (
        <Row>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.areas.name')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.areas.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col md={6} xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="description"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('constructor.areas.description')}
                                                 name={name} isRequired={true} type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('constructor.areas.descriptionPlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="img"
                        defaultValue={entity.img || {}}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.imgSize')}
                                                 name={name} isRequired={true}
                                                 hint={t('adventures.edit.fileSize')}
                            >
                                <div style={{border: '0'}}>
                                    <Dropzone
                                      callback={img => {
                                          if (img) {
                                              upload(img).then(res => {
                                                  setEntity(value => ({ ...value, img_urls: [...(value.img_urls || []), res.url] }))
                                              });
                                          }
                                    }}/>
                                    {<CarouselComponent
                                        items={(entity.img_urls || []).map(i => ({ src: API_URL+i }))}
                                        onDelete={(_, index) => {
                                            setEntity(value => ({
                                                ...value,
                                                img_urls: [
                                                    ...value.img_urls.slice(0, index), ...value.img_urls.slice(index + 1),
                                                ]
                                            }))
                                        }}
                                    />}
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
        </Row>)
})

export default EditModalChildren;
