import {useState} from "react";
import './style.scss'
import {useSlotsApi} from "../../../../../../lib/api/slots";
import useEditTable from "../../../../../../lib/utils/hooks/useEditTable";
import CommonToggle from '../../../../../common/formElements/Toggle';
const SlotClosed = ({value, id}) => {
    const [isClosed, setIsClosed] = useState(value);
    const [serverIsClosed, setServerIsClosed] = useState(value);
    const {editEntry} = useSlotsApi();

    const debounceCallback = (is_closed) => editEntry(id, {is_closed});
    const debounceErrorCallback = () => setIsClosed(serverIsClosed);

    const debounceSuccessCallback = (data) => setServerIsClosed(data.is_closed);

    const {loading} = useEditTable({
        debounceCallback,
        debounceErrorCallback,
        debounceSuccessCallback,
        payload: isClosed,
        deps: [isClosed],
        callbackDeps: [serverIsClosed],
    })

  return <CommonToggle disabled={loading} isActive={isClosed} onChange={() => setIsClosed(!isClosed)} />
}

export default SlotClosed;