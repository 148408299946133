import React, {useState} from 'react';
import {Button} from 'reactstrap';
import {FaTrash} from "react-icons/fa";
import {useOrdersApi} from "../../../../lib/api/orders";
import {ModalConfirmation} from "../../../generic/modals";
import {t} from "i18next";

const DeleteButton = ({isClient, isNew, data, index, orderId, item, setState, onAnnulationCb, disabled}) => {
    const {deleteMember} = useOrdersApi('orders');
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const triggerModalConfirmation = () => {
        toggleModalConfirmation(isModalOpen => !isModalOpen)
    }

    if (isClient && isNew)
        return <Button title={t('management.orders.deleteButton.delete')} className={'p-1 m-0'} color={'danger'} disabled={disabled}
                       onClick={() => setState(state => (
                               {...state, paid_for_ids: data.filter((c, id) => id !== index)}
                           )
                       )}
        >
            <FaTrash/>
        </Button>
    if (isClient && !isNew)
        return <>
            {isModalConfirmationOpen && !disabled &&
            <ModalConfirmation onClose={triggerModalConfirmation} isOpen={isModalConfirmationOpen}
                               onConfirm={() => deleteMember(orderId, item.id)
                                   .then(res => {
                                       setState(state => (
                                           {
                                               ...state,
                                               paid_for_ids: data.filter((c, id) => id !== index)
                                           }))
                                       onAnnulationCb && onAnnulationCb(res)
                                       triggerModalConfirmation()
                                   })
                                   .catch(e => console.debug(e))
                               }
                               confirmButtonText={t('management.orders.deleteButton.confirmButtonText')}
                               text={t('management.orders.deleteButton.confirmText')}
                               title={t('management.orders.deleteButton.confirmTitle')}
            />}
            <Button title={t('management.orders.deleteButton.deleteFromOrder')} className={'p-1 m-0'} color={'danger'}
                    onClick={triggerModalConfirmation} disabled={disabled}
            >
                <FaTrash/>
            </Button>
        </>
    return ''
}

export default DeleteButton;

