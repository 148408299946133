import React, {useState, useEffect} from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
const ServiceWorkerContext = React.createContext();

export const ServiceWorkerProvider = ({ children }) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        serviceWorkerRegistration.register({
            askUserToUpdate: registration => {
                setWaitingServiceWorker(registration);
                setUpdateAvailable(true);
            },
        });
    }, []);

    const value = React.useMemo(() => ({
        isUpdateAvailable,
        updateAssets: () => {
            if (waitingServiceWorker) {
                // вешаем обработчик изменения состояния
                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    window.location.reload();
                });

                // пропускаем ожидание
                if (waitingServiceWorker && waitingServiceWorker.waiting) {
                    waitingServiceWorker.waiting.postMessage({ type: 'SKIP_WAITING' });
                }

            }
        }
    }), [isUpdateAvailable, waitingServiceWorker]);

    return (
        <ServiceWorkerContext.Provider value={value}>
            {children}
        </ServiceWorkerContext.Provider>
    );
}

export const useServiceWorker = () => {
    return React.useContext(ServiceWorkerContext);
}