import React from 'react';
import {Col, Row} from "reactstrap";
import MainInfo from "./MainInfo";
import LessonsTable from "./LessonsTable";
import {Promocodes} from "../../../../../../bases/events/event/NewEventBlocks";
import {ENTITIES, useRoleSystem} from "../../../../../../../lib/utils/hooks/useRoleSystem";
import ExportByDirectionButton from '../../../../../../common/formElements/ExportByDirectionButton';
import { COMMON_PRODUCT_DIRECTIONS } from '../../../../../../../lib/utils/constants';

const GroupView = ({state}) => {
    const permissions = useRoleSystem(state.type === 'theory' ? ENTITIES.GROUPS_THEORY: ENTITIES.GROUPS_LECTURES)
    return (
        <Row>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <MainInfo state={state}/>
                    </Col>
                    <Col xs={12}>
                      <ExportByDirectionButton direction={COMMON_PRODUCT_DIRECTIONS.GROUPS} directionId={state.id} />
                    </Col>
                    <Col xs={12}>
                        <Promocodes productType={'groups'} state={state} disabled={!permissions.edit}/>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6}>
                <LessonsTable state={state}/>
            </Col>

        </Row>
    )
}

export default GroupView;