import React from 'react';
import {Col, Row} from "reactstrap";
import {DataList} from "../generic/tables";
import AdventureCard from "./AdventureCard";
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import { onNewPage } from './adventure/helpers';

const CustomAdventuresList = ({findAndCountEntries, type, deleteAdventure, editEntry, cloneEntry, filters = {}, name = 'adventures', sortField = 'created_at', sortDir= 'ASC', permissions, typeForRule}) => {
    const {is_school, is_camp, is_charter} = filters;
    let history = useHistory();
    const handleMouseUp = (e, res) => {
      if ( e.button === 1 || ((e.ctrlKey ||  e.metaKey) && e.button === 0)) {
        onNewPage(res.url, is_school, is_camp, is_charter, false, history);
      }
    }

    const handleMouseClick = (e, res) => {
      if (e.metaKey || e.ctrlKey) return;
      onNewPage(res.url, is_school, is_camp, is_charter, true, history);
    }
    return <>
        <Row>
            <Col>
                <DataList findAndCount={findAndCountEntries} sortField={sortField} sortDir={sortDir} filters={filters}
                          wrapperClassname={'row'} name={name}
                          renderer={
                              adventure =>
                                  <Col xs={12} sm={6} lg={4} xl={3} key={adventure.id} className={'mb-4'}>
                                      <AdventureCard adventure={adventure} cloneEntry={cloneEntry} editEntry={editEntry}
                                           type={type} onMouseUp={e => handleMouseUp(e, adventure)}
                                           onClick={(e) => handleMouseClick(e, adventure)} permissions={permissions}
                                           deleteEntry={deleteAdventure} hasOverlay typeForRule={typeForRule}/>
                                  </Col>
                          }
                />
            </Col>
        </Row>
    </>
}

CustomAdventuresList.propTypes = {
    findAndCountEntries: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    deleteAdventure: PropTypes.func.isRequired,
    editEntry: PropTypes.func.isRequired,
    cloneEntry: PropTypes.func.isRequired,
    filters: PropTypes.object,
    name: PropTypes.string,
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    productType: PropTypes.string,
}

export default CustomAdventuresList;

