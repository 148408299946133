import React from 'react';
import {API_URL, useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useInstructorsApi = (isFormData = false) => {
    const defaultApi = useDefaultEndpoints('instructors', isFormData);
    const {request} = useApi();

    const checkIfInstructorAvailable = async (slot, instructorId, baseId) =>
        request('post', '/instructors/available/', {slot, instructorId, baseId});

    const attachInstructor = async (slot, instructorId) =>
        request('post', '/instructors/attach/', {slot, instructorId});
    const detachInstructor = async (slot, instructorId, updatedOrders) =>
        request('post', '/instructors/detach/', {slot, instructorId, updatedOrders});
    const getFreeInstructorsForSlot = async (slotId = '', instructorId) =>
        request('get', '/instructors/free/' + slotId, {instructorId});

    const getAvailableInstructorsForSlot = async (startTime, endTime, startDate, eventId, search) =>
        request('post', '/instructors/getAvailable/', {startTime, endTime, startDate, eventId, search});

    const getInstructor = async (id) => {
        const entry = await defaultApi.getEntry(id)
        return {...entry, files: entry.avatar ? {...entry.avatar, preview: API_URL + entry.avatar.url} : {}}
    }

    return {
        ...defaultApi,
        checkIfInstructorAvailable, detachInstructor, attachInstructor, getFreeInstructorsForSlot,
        getAvailableInstructorsForSlot, getInstructor
    }
};