import React, {useEffect, useMemo, useReducer, useState} from 'react'
import {TableCard} from "../../generic/cards";
import {useHistory, useLocation, useParams} from "react-router-dom"
import {initialState, reducer} from "./reducer";
import EasyEdit from 'react-easy-edit'
import {FaCheck, FaTimes} from "react-icons/fa";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import tabList from "./tabList";
import {Loader} from '../../generic/loader';
import qs from 'qs';
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {daysAbbr} from "../../../lib/utils/format";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {API_URL, useApi} from "../../../lib/api/useApi";
import {AiOutlineSync} from "react-icons/ai";
import {useArrivalsApi} from "../../../lib/api/arrivals";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

dayjs.extend(customParseFormat)

const Arrival = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory()
    const {pathname, search} = useLocation();
    let {arrival_url} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {findByUrl, editEntry} = useArrivalsApi(true)
    const {request} = useApi();
    let entity = useMemo(() => {
        if (state.adventure_id?.is_school) return ENTITIES.GROUPS_PRACTICE;
        if (state.adventure_id?.type === 'world') return ENTITIES.ADVENTURES_WORLD;
        return ENTITIES.ADVENTURES_RUSSIA;
    }, [state.adventure_id?.is_school, state.adventure_id?.type]);

    const permissions = useRoleSystem(entity);
    const [activeTab, setActiveTab] = useState('orders');
    const {handleSubmit, control, errors, setValue} = useForm({reValidateMode: 'onBlur',});

    const toggleTab = tab => {
        history.replace(`${pathname}?t=${tab.url}`)
        if (activeTab !== tab.url) setActiveTab(tab.url)
    };

    const createLinked = async () => {
        const {orders_ids, ...filteredState} = state;
        request('POST', '/arrivals/linked', filteredState)
            .then(res => history.push(`${res.url}`))
    }

    useEffect(() => {
        if (arrival_url)
            findByUrl(arrival_url).then(res => {
                const mapper = {
                    arrival_url: {ru: res.name, en: arrival_url},
                    url: {ru: res.adventure_id.name, en: res.adventure_id.url},
                    type: {ru: res.adventure_id.type === 'world' ? 'Мир' : 'Россия', en: res.adventure_id.type}
                }
                createTranslatedBreadcrumbsAndTitle
                && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
                dispatch({type: 'setForm', payload: {...res, files: {...res.img, preview: API_URL + res?.img?.url}, reload: false}});
            })
        else {
            dispatch({type: 'setLoading', payload: false})
        }
        if (!search) history.replace(`${pathname}?t=${activeTab}`);

        let {t} = qs.parse(history.location?.search.slice(1))
        if (t)
            setActiveTab(t)
        // eslint-disable-next-line
    }, [pathname, arrival_url, state.reload])

    const editArrival = () => {
        const convertValueToObject = v => v.split?.('\n') || v;
        const {
            adventure_id, active_promocodes_ids, orders_ids,  booked_to_total, booked_sits, available_sits,
            start_date, end_date, reserved_sits, available_reserved_sits, options_included_ids,
            options_not_included_ids, global_event_sale, ...rest
        } = state;
       const startDate = dayjs(start_date).format('YYYY-MM-DD');
       const endDate = dayjs(end_date).format('YYYY-MM-DD');

        if (!rest.check_id) rest.check_id = null;

        return editEntry(state.id, {
            ...rest, start_date: startDate, end_date: endDate,
            options_included_ids: convertValueToObject(options_included_ids),
            options_not_included_ids: convertValueToObject(options_not_included_ids)
        })
            .then(() => toast.success(t('adventures.arrival.updateSuccess')))
    }
    const onSubmit = handleSubmit(() => editArrival())

    const syncLoad = async () => {
        dispatch({type: 'setForm', payload: {reload: !state.reload}})
        toast.success(t('adventures.arrival.dataWasSync'))
    }

    return state.loading
        ? <Loader/>
        : <TableCard>
            <Row className={'justify-content-between align-items-center'}>
                <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                    <div className={'display-3'}>
                        <Row>
                            <div className={'col-'}>
                                {t('adventures.arrival.title')}
                            </div>
                            <Col>
                                <EasyEdit
                                    type="text"
                                    name="name"
                                    onSave={(val) => {
                                        dispatch({type: 'setForm', payload: {name: val}})
                                    }}
                                    value={state.name}
                                    placeholder={t('adventures.arrival.arrivalName')}
                                    saveButtonLabel={<FaCheck fill={'success'} stroke={'success'}/>}
                                    cancelButtonLabel={<FaTimes color={'secondary'}/>}
                                    viewAttributes={{style: {color: 'grey'}}}
                                />
                            </Col>
                        </Row>
                    </div>

                </Col>
                <Col className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('adventures.arrival.save')}
                        </Button>
                    </ElementWithPermissions>
                </Col>
                {!state.linked_arrival_id?.id && permissions.edit && <Col className={'col-auto'}>
                    <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                            onClick={createLinked}>
                        {t('adventures.arrival.addRelated')}
                    </Button>
                </Col>}
            </Row>
            <Row className={'mb-3'}>
                <div className={'col-'}>
                    {t('adventures.arrival.relatedArrival')}
                </div>
                <div className={'col-'}>
                    <span style={{cursor: 'pointer', textDecoration: 'underline'}}
                          onClick={() => history.push(`${state.linked_arrival_id?.url}`)}>
                        {state.linked_arrival_id?.name}
                    </span>
                </div>
            </Row>
            <Row className={'mb-5'}>
                <Col xs={3}>
                    <div className={'badge badge-outline-dark badge-pill'}>
                        {state.start_date ? dayjs(state.start_date).format('DD.MM.YYYY') : t('adventures.arrival.no')}
                    </div>
                    <span style={{color: 'gray', fontSize: 10}}>
                        {state.end_date
                            ? <> - {dayjs(state.start_date).format('DD.MM.YYYY')} {dayjs(state.end_date).format('DD.MM.YYYY')},
                            {daysAbbr[dayjs(state.start_date).isoWeekday()]} - {daysAbbr[dayjs(state.end_date).isoWeekday()]}</>
                            : ''
                        }
                    </span>
                </Col>
                <Col xs={3}>
                    {state.id && <div className={'m-0 mr-1 p-0 btn btn-light btn-rounded btn-sm '}>
                        <AiOutlineSync
                            className={'boats-amount-icon'}
                            size={20}
                            onClick={syncLoad}
                        />
                    </div>}
                    <div className={'badge badge-outline-dark badge-pill'}>
                      {(state.booked_sits || 0) + ` (${state.total_sits - state.booked_sits - state.available_reserved_sits})` + ' / ' + (state.total_sits || 0)}
                    </div>
                    <span style={{
                        color: 'gray',
                        fontSize: 10
                    }}> - {state.booked_to_total || 0}% {t('adventures.arrival.load')} {state.available_sits || 0} {t('adventures.arrival.left')} {state.total_sits - state.booked_sits - state.available_reserved_sits} {t('adventures.arrival.reserved')}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabList({state, dispatch, permissions})
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(9 / tabList({state, dispatch, permissions}).length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {t(tab.name)}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {tabList({state, dispatch, control, errors, setValue, permissions})
                            .filter(tab => tab.isDisplayed !== false).map(tab => (
                                <TabPane tabId={tab.url} key={tab.name}>
                                    {tab.child}
                                </TabPane>))
                        }
                    </TabContent>
                </Col>
            </Row>
            <Row className={'mb-5 justify-content-end align-items-center'}>
                <Col className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                            {t('adventures.arrival.save')}
                        </Button>
                    </ElementWithPermissions>
                </Col>
            </Row>
        </TableCard>
}

export default Arrival;