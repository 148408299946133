import React, {useEffect, useState} from 'react'
import {Col, Form, FormGroup, Row} from "reactstrap";
import Select from "react-select";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {

    const [userFields, setUserFields] = useState([])
    const {getEntries} = useDefaultEndpoints('user-fields')


    useEffect(() => {
        getEntries({}).then(res => setUserFields(() => res
            .filter(userField => !(userField.is_required && (!userField.is_optional)))
            .map(userField => ({value: userField.key, label: userField.value}))))
    }, [])


    return <Form>
        <Row>
            <Col lg={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.userFieldsTemplates.name')}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.userFieldsTemplates.name')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="fields"
                        defaultValue={entity.fields || []}
                        rules={{validate: val => !(!val || val?.length === 0) || t('inputs.validation.required'),}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.userFieldsTemplates.includedFields')}
                            >
                                <Select
                                    isMulti
                                    name={name}
                                    options={userFields}
                                    className={"basic-multi-select " + (invalid ? 'is-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={{control: (provided) => ({...provided, borderColor: invalid ? '#dc3545' : '#ccc'})}}
                                    onBlur={onBlur}
                                    value={userFields.filter(userField => (value?.includes(userField.value)))}
                                    onChange={(res) => {
                                        const val = res?.map(record => record.value)
                                        setEntity(entity => ({
                                            ...entity,
                                            fields: val
                                        }))
                                        onChange(val)
                                    }}
                                    placeholder={t('directories.userFieldsTemplates.includedFieldsPlaceholder')}
                                />
                            </FieldWithValidation>
                        }
                    />

                </FormGroup>
            </Col>
        </Row>
    </Form>
}


export default EditModalChildren;
