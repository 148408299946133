import { t } from 'i18next';

const changesObjectTranslates = {
  assignee_comment: t('management.certificates.history.changes.assignee_comment'),
  assignee_email: t('management.certificates.history.changes.assignee_email'),
  assignee_full_name_search: t('management.certificates.history.changes.assignee_full_name_search'),
  assignee_name: t('management.certificates.history.changes.assignee_name'),
  assignee_phone: t('management.certificates.history.changes.assignee_phone'),
  assignee_surname: t('management.certificates.history.changes.assignee_surname'),
  available_from: t('management.certificates.history.changes.available_from'),
  available_to: t('management.certificates.history.changes.available_to'),
  country: t('management.certificates.history.changes.country'),
  is_active: t('management.certificates.history.changes.is_active'),
  is_created_manually: t('management.certificates.history.changes.is_created_manually'),
  name: t('management.certificates.history.changes.name'),
  price_currency: t('management.certificates.history.changes.price_currency'),
  product_type: t('management.certificates.history.changes.product_type'),
  queue_action_date: t('management.certificates.history.changes.queue_action_date'),
  queue_action_email: t('management.certificates.history.changes.queue_action_email'),
  queue_error_message: t('management.certificates.history.changes.queue_error_message'),
  queue_status: t('management.certificates.history.changes.queue_status'),
  send_if_paid: t('management.certificates.history.changes.send_if_paid'),
  senders_email: t('management.certificates.history.changes.senders_email'),
  size: t('management.certificates.history.changes.size'),
  size_left: t('management.certificates.history.changes.size_left'),
  type: t('management.certificates.history.changes.type'),
}

export default changesObjectTranslates;