import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../../directories/DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";



const BaseAddresses = (props) => {
    return <DirectoryTemplate
        fields={[
            field(t('constructor.baseAddresses.name'), true, "name"),
            field(t('constructor.baseAddresses.city'), true, "city"),
            field(t('constructor.baseAddresses.address'), true, "address"),
            field(t('constructor.baseAddresses.building'), true, "building"),
            field(t('constructor.baseAddresses.getting_there_link'), true, "getting_there_link"),
        ]}
        editable
        deletable
        entityName={'base-addresses'}
        EditModalChildren={EditModalChildren}
        permissions={getPermissions(ENTITIES.BASE_ADDRESSES)}
        isNameFieldSearch
        searchPlaceholder={t('constructor.directoryTemplate.search.base_addresses')}
        {...props}
    />
}

export default BaseAddresses