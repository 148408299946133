import React from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const ExtraMaterials = ({onClick = undefined, isInline, deletable = true, ...rest}) => <DirectoryTemplate
    fields={[
        field(t('directories.extraMaterials.link'), true, "link"),
        field(t('directories.extraMaterials.name'), true, "name")
    ]}
    entityName={'extra-materials'}
    EditModalChildren={EditModalChildren}
    editable
    deletable={deletable}
    customRowClick={onClick}
    isInline={isInline}
    permissions={getPermissions(ENTITIES.EXTRA_MATERIALS)}
    {...rest}
/>


export default ExtraMaterials;