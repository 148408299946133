import computeSits from "./computeSits";

const initialState = (state) => ({
    id: state?.id || undefined,
    // relations
    event_id: state?.event_id || '',
    orders_ids: state?.orders_ids || [],
    instructor_id: state?.instructor_id || null,

    // technical
    booked_to_total: state?.booked_to_total ||0,
    total_sits: state?.total_sits || 0,

    // data
    status: state?.status || 'coming',
    start_date: state?.start_date || '',
    end_date: state?.end_date || '',
    start_time: state?.start_time || '',
    end_time: state?.end_time || '',
    repeat_type: state?.repeat_type || '',
    available_boats: state?.available_boats || 1,


    // inherited data
    price_value: state?.price_value || '',
    is_coach_required: state?.is_coach_required || false,
    is_coach_selectable: state?.is_coach_selectable || false,
    is_full_book_available:  state?.is_full_book_available || false,
    is_private: state?.is_private || false,
    comment: state?.comment || '',
    calendar_color: state?.calendar_color || '',
    url: state?.url || '',
    full_book_discount_type: state?.full_book_discount_type || '',
    full_book_discount_size: state?.full_book_discount_size || 0.0,
    full_book_discount_info: state?.full_book_discount_info || '',
    coachless_discount_type: state?.coachless_discount_type || '',
    coachless_discount_size: state?.coachless_discount_size || 0.0,
    coachless_discount_info: state?.coachless_discount_info || '',
    are_promocodes_active: state?.are_promocodes_active || false,
    active_promocodes_ids: state?.active_promocodes_ids || [],
    name: state?.name || '',
    paid_locally: state?.paid_locally || false,

    reload: false
});

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value};
    }

    switch (action.type) {
        // Assistance block

        // Data editing block
        case 'setForm':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload,
            }

        case 'setFormData':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        case 'setSlotData':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload
            }

        case 'setEventInputData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }
        case 'setSlotInstructor':
            // value: instructor
            return {
                ...state,
                instructor_id: action.payload
            }
        case 'changeBoatsAmount':
            // value: instructor
            return {
                ...state,
                ...computeSits({
                    val: action.payload,
                    totalSits: state.total_sits,
                    availableBoats: state.available_boats,
                    availableSits: state.available_sits
                })
            }

        case 'clearDiscountData':
            // value: 'example'
            const discount = {}
            discount[action.payload + '_discount_type'] = 'summ'
            discount[action.payload + '_discount_size'] = 0.0
            discount[action.payload + '_discount_info'] = ''
            return {
                ...state,
                ...discount
            }

        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};