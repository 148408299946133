const ruTitleMapper = (path) => {
    return urls[path];
};

const templates = 'routes.templates';
const pages = 'routes.pages';
const letters = 'routes.letters';
const edit = 'routes.edit';
const create = 'routes.create';
const directory = 'routes.constructorDirectory';

const urls = {
    '/constructor/projects': [
        {ru: 'routes.projects'},
    ],
    '/constructor/templates/edit/:id': [
        {ru: templates},
        {ru: edit},
    ],
    '/constructor/templates/edit/': [
        {ru: templates},
        {ru: create},
    ],
    '/constructor/templates': [
        {ru: 'routes.templates'},
    ],
    '/constructor/pages/edit/:id': [
        {ru: pages},
        {ru: edit},
    ],
    '/constructor/pages/edit/': [
        {ru: pages},
        {ru: create},
    ],
    '/constructor/pages': [
        {ru: pages},
    ],
    '/constructor/letters/edit/:id': [
        {ru: letters},
        {ru: edit},
    ],
    '/constructor/letters/edit/': [
        {ru: letters},
        {ru: create},
    ],
    '/constructor/letters': [
        {ru: letters},
    ],
    '/constructor/files': [
        {ru: 'routes.files'},
    ],
    '/constructor/directory': [
        {ru: directory},
    ],
    '/constructor/directory/edit/': [
        {ru: directory},
        {ru: create},
    ],
    '/constructor/directory/edit/:id': [
        {ru: directory},
        {ru: edit},
    ],
};

export default ruTitleMapper;