import {t} from "i18next";

export const onlyNumberRequired = {
    required: 'Поле обязательно',
    pattern: {value:/^\d+$/, message: 'Только цифры'
    }
}

export const emailRule = {
  pattern: { value: /^\S+@\S+\.\S+$/, message: t('inputs.email.pattern') }
}

export const requiredRule = {required: t('inputs.validation.required')}
export const maxLength =  length => ({maxLength: length})