import React from "react";
import PropTypes from 'prop-types';
import {CardBody, Card} from "reactstrap";

const AnalyticGeneralCard = ({pic, data, title, className}) =>
    <Card className={className}>
        <CardBody>
            <div className="d-flex justify-content-around align-items-center">
                <div className="icon-rounded-primary">
                    <i>{pic}</i>
                </div>
                <div>
                    <p>{title}</p>
                    <h3 className="mb-0">{data}</h3>
                </div>
            </div>
        </CardBody>
    </Card>

AnalyticGeneralCard.propTypes = {
    pic: PropTypes.object,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    className: PropTypes.string
};

AnalyticGeneralCard.defaultProps = {
    className: ""
};

export default AnalyticGeneralCard;