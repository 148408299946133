import React, {useEffect} from 'react';
import {TableCard} from "../../../generic/cards";
import {Promocodes} from "../../events/event/NewEventBlocks";
import {useParams} from 'react-router-dom';
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {Loader} from '../../../generic/loader';
import {useSlotsApi} from "../../../../lib/api/slots";

const Slot = ({state, dispatch, disabled}) => {

    const {findByUrl} = useSlotsApi();
    let {slot_url} = useParams();

    useEffect(() => {
        if (!state.id)
            findByUrl(slot_url).then(res => dispatch({type: 'setSlotData', payload: res}))
    }, [])

    return !state.id ?
        <Loader/>
        : (
            <>
                <TableCard isInline>
                    <Promocodes dispatch={dispatch} state={state} productType={'slots'} disabled={disabled}/>
                </TableCard>
            </>
        )
}

export default Slot;