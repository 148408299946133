import React from "react";

const ruBreadcrumbsMapper = (path) => {
    return urls[path];
}

const urls = {
    '': [],
    '/': [],

}

export default ruBreadcrumbsMapper;