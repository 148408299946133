import React, {createContext} from 'react'
import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {FaCheck, FaTimes} from "react-icons/fa";
import {getProductOptions} from "../../administration/certificates/certificate/tabs/General/Parameters";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import i18n, {t} from "i18next";

export const IsFormValueState = createContext(false);

const LegalEntities = (props) => {
    const {language} = i18n

    const translatedTypes = getProductOptions(language).map(({props}) => ({key: props.value, value: props.children}))

    const renderTranslatedTypes = ({certificate_types}) => certificate_types?.map(cT => {
        const translatedType = translatedTypes.find(t => t.key === cT)?.value
        return translatedType ? translatedType + ', ' : '';
    }).join('').slice(0, -2);

    return <IsFormValueState.Provider value={true}>
        <DirectoryTemplate
            fields={[
                field(t('directories.legalEntities.name'), true, "name"),
                field(t('directories.legalEntities.shopId'), true, "shop_id"),
                field(t('directories.legalEntities.apiKey'), true, "api_key",
                    res =>  res.api_key.length > 50 ? res.api_key.slice(0, 50) + '...' : res.api_key
                ),
                field(t('directories.legalEntities.subscriptionAvailable'), true, "subscription_available",
                    res => res.subscription_available ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>),
                field(t('directories.legalEntities.certificateType'), false, "certificate_types",
                    renderTranslatedTypes),
                field(t('directories.legalEntities.paymentSystem'), true, 'type')
            ]}
            entityName={'legal-entities'}
            EditModalChildren={EditModalChildren}
            editable
            deletable
            permissions={getPermissions(ENTITIES.LEGAL_ENTITIES)}
            {...props}
        />
    </IsFormValueState.Provider>
}


export default LegalEntities;