import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {ExtraMaterials} from "../../../directories";
import {t} from "i18next";


const ExtraMaterialsModal = ({toggle, state, setState}) => {
    return (
        <Modal isOpen={true} toggle={toggle} className={'edit-modal'} size={'lg'}>
            <ModalHeader>{t('common.extraMaterialsModal.pickExtraMaterials')}</ModalHeader>
            <ModalBody>
                <ExtraMaterials onClick={(index) => {
                    toggle()
                    setState({
                        ...state,
                        extra_materials: [
                            ...state.extra_materials ?? [],
                            index,
                        ]
                    })
                }} isInline deletable={false}/>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Row>
                        <Col>
                            <Button block color="primary" outline onClick={toggle}>
                                {t('common.extraMaterialsModal.close')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ExtraMaterialsModal;