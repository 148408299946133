import React from 'react';
import "./LoaderOverlay.css";
import PropTypes from 'prop-types';
import Loader from './Loader';

const LoaderOverlay = (props) =>
    <div className={"loader-wrap " + props.className}>
        <div className={`loader-overlay ${props.isVisible ? 'visible' : ''}`}>
            <Loader/>
        </div>
        {props.children}
    </div>

LoaderOverlay.propTypes = {
    isVisible: PropTypes.bool,
    className: PropTypes.string
};

export default LoaderOverlay;

