import React from "react";
import {Col, CustomInput, FormGroup, Label} from "reactstrap";
import {t} from "i18next";
import ControllerRHF from "../../../../generic/fields/ControllerRHF";
import {TextControllerRHF} from "../../../../generic/fields";

const InformationBlocksSection = ({form, control, errors, requiredRule}) => (
    <>
        <Col lg={4} md={6} xs={12} className={'mb-1'}>
            <FormGroup>
                <ControllerRHF
                    name={'show_block_information'}
                    form={form}
                    render={({value, name, onChange}) => (
                        <CustomInput
                            type="checkbox"
                            className={'custom-switch'}
                            id={name}
                            name={name}
                            label={t("constructor.templates.show_block_information")}
                            checked={value || false}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    )}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup required>
                <Label>{t('constructor.pages.event_format')}</Label>
                <TextControllerRHF
                    type='textarea'
                    inputName='event_format'
                    placeholder={t('constructor.pages.eventFormatPlaceholder')}
                    control={control}
                    errors={errors}
                    isRequired
                    rules={requiredRule}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup>
                <Label>{t('constructor.pages.description')}</Label>
                <TextControllerRHF
                    type='textarea'
                    inputName='description'
                    placeholder={t('constructor.pages.descriptionPlaceholder')}
                    control={control}
                    errors={errors}
                    isRequired
                    rules={requiredRule}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup required>
                <Label>{t('constructor.pages.duration')}</Label>
                <TextControllerRHF
                    type='textarea'
                    inputName='duration'
                    isRequired
                    placeholder={t('constructor.pages.durationPlaceholder')}
                    control={control}
                    errors={errors}
                    rules={requiredRule}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup required>
                <Label>{t('constructor.pages.timing')}</Label>
                <TextControllerRHF
                    type='textarea'
                    inputName='timing'
                    isRequired
                    placeholder={t('constructor.pages.timingPlaceholder')}
                    control={control}
                    errors={errors}
                    rules={requiredRule}
                />
            </FormGroup>
        </Col>
        <Col lg={4} md={6} xs={12}>
            <FormGroup>
                <Label>{t('constructor.pages.yaMapsWidget')}</Label>
                <TextControllerRHF
                    type='textarea'
                    inputName='ya_maps_widget'
                    placeholder={t('constructor.pages.yaMapsWidgetPlaceholder')}
                    control={control}
                    errors={errors}
                />
            </FormGroup>
        </Col>
    </>
);

export default InformationBlocksSection;
