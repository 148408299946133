import PropTypes from 'prop-types';
import {MovePaymentModalSlot} from "./MovePaymentModalSlots";
import {MovePaymentModalGroups} from "./MovePaymentModalGroups";
import {MovePaymentModalArrivals} from "./MovePaymentModalArrivals";
import {toast} from "react-toastify";
import {useOrdersApi} from "../../../../lib/api/orders";
import {useMemo, useState} from "react";
import {t} from "i18next";

const MovePaymentModal = ({productType, selectedProductId, isModalOpen, triggerModal, orderId, clickCb, clientId, presetFilters = {}}) => {
    const {movePayment} = useOrdersApi();

    const [destinationProduct, setDestinationProductId] = useState()

    const actuallyProductType = useMemo(() => productType === 'practices' ? 'arrivals' : productType, [productType])

    const confirmMove = () => {
        return movePayment(
            orderId,
            actuallyProductType,
            selectedProductId,
            actuallyProductType,
            destinationProduct.id,
            clientId
        )
            .then((res) => clickCb ? clickCb(res) : true)
            .then(() => setDestinationProductId(() => undefined))
            .then(() => toast.success(t('common.forms.movePaymentModal.successUpdate')))
            .then(() => triggerModal());
    }

    return (
        <>
        {actuallyProductType === 'slots'
        && <MovePaymentModalSlot sourceSlotId={selectedProductId}
                                 destinationSlot={destinationProduct} setDestinationSlot={setDestinationProductId}
                                 isModalOpen={isModalOpen} triggerModal={triggerModal}
                                 orderId={orderId} clientId={clientId} moveOrder={confirmMove}
                                 presetFilters={presetFilters} />}
        {actuallyProductType === 'arrivals'
        && <MovePaymentModalArrivals sourceArrivalId={selectedProductId}
                                     destinationArrival={destinationProduct} setDestinationArrival={setDestinationProductId}
                                     isModalOpen={isModalOpen} triggerModal={triggerModal}
                                     orderId={orderId} clientId={clientId} moveOrder={confirmMove}
                                     presetFilters={presetFilters} />}
        {actuallyProductType === 'groups'
        && <MovePaymentModalGroups sourceGroupId={selectedProductId} setDestinationArrival={setDestinationProductId}
                                   isModalOpen={isModalOpen} triggerModal={triggerModal} clientId={clientId}
                                   moveOrder={confirmMove} presetFilters={presetFilters} destinationGroup={destinationProduct}/>}
       </>
    );
};

MovePaymentModal.propTypes = {
    productType: PropTypes.oneOf(['slots', 'arrivals', 'groups', 'practices']).isRequired,
    selectedProductId: PropTypes.number.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    clickCb: PropTypes.func,
    clientId: PropTypes.number,
    presetFilters: PropTypes.object
};

export default MovePaymentModal;