import React, {useState} from 'react';
import {Button, Col, CustomInput, FormGroup, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../../generic/tables";
import {EditModal, ModalConfirmation} from "../../../../generic/modals";
import EditSlotModalChildren from "../EditSlotModalChildren";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import {useHistory, useParams} from 'react-router-dom';
import {getStartDayAndMonth, getStartWeekDay} from "../../../../../lib/utils/format";
import {LoadBadge} from "../../../../generic/badges";
import {GiWhistle} from "react-icons/gi";
import {FaCheck, FaExclamation} from "react-icons/all";
import {BOOKING_URL} from "../../../../../lib/api/useApi";
import {CopyLink} from "../../../../generic/buttons";
import {ENTITIES, useRoleSystem} from "../../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../../common/formElements";
import i18next from "i18next";
import {RuleAccessProvider, useRuleAccess} from "../../../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

dayjs.extend(customParseFormat)
dayjs.extend(isoWeek)

const SlotsTable = ({state}) => {
    const getSlotUrl = slot => `${state.base_id?.booking_url || BOOKING_URL}/sport/${state.base_id.url}/${slot.url}`
    const [reload, setReload] = useState(false);
    const [ejectedSlot, setEjectedSlot] = useState('');
    const [isModalOpen, toggleModal] = useState(false);
    const [showCurrent, setShowCurrent] = useState(true);
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    let history = useHistory();
    let {url, event_url} = useParams();
    const { check } = useRuleAccess();

    const {findAndCountEntries, deleteEntry, createEntry} = useDefaultEndpoints('slots');
    const permissions = useRoleSystem(ENTITIES.SLOTS);
    const triggerModal = () => toggleModal(isModalOpen => !isModalOpen);
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen);

    const {t, language} = i18next;

    const deleteSlot = (slot) => {
        setEjectedSlot(() => slot);
        triggerModalConfirmation()
    }
    const filters = {
        event_id: state.id,
        ...(showCurrent ? {start_date_gte: dayjs().startOf('day').format('YYYY-MM-DD')} : {}),
        is_archived_ne: true,
    }

    const onMiddleClick = (res) => {
        const slotUrl = new URL(`/bases/${url}/event/${event_url}/slot/${res.url}`, window.location.href)
        const newWindow = window.open(slotUrl.href, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return <>
        {isModalOpen &&
        <EditModal entityName={'slots'} triggerModal={triggerModal} isOpened={isModalOpen}
                   entry={{event_id: state.id}} edit={permissions.edit}
                   EditForm={EditSlotModalChildren}
                   requiredFields={['start_date', 'end_date', 'start_time', 'end_time', 'repeat_type']} size={'sm'}
                   onSuccess={(entry) => createEntry(entry).then(() => {
                       setReload(reload => !reload)
                       triggerModal();
                   })}
        />
        }
        {isModalConfirmationOpen && <ModalConfirmation onClose={triggerModalConfirmation} onConfirm={() => {
            deleteEntry(ejectedSlot.id).then(() => {
                setReload(reload => !reload);
                triggerModalConfirmation();
            })

        }} isOpen={isModalConfirmationOpen} hasCancel title={t('bases.base.slotDeleting')}
                                                       text={t('bases.base.areYouSureSlot')}
                                                       cancelButtonText={t('bases.base.no')}
                                                       confirmButtonText={t('bases.base.yes')}
        />}
        <Col xs={12}>
            <Row className={'mb-3'}>
                <Col md={3}>
                    <div className={'display-4'}>
                        {t('bases.base.slots')}
                    </div>
                </Col>
                <RuleAccessProvider accessKey={frk["EVENT-ITEM-SLOTS-CREATION"]}>
                    <Col>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button color={'dark'} className={'btn-rounded'} onClick={triggerModal}> {t('bases.base.add')} </Button>
                        </ElementWithPermissions>
                    </Col>
                </RuleAccessProvider>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="showCurrent" name="showCurrent"
                                     label={t("bases.base.hideExpired")} checked={showCurrent || false}
                                     onChange={() => setShowCurrent(cur => !cur)}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <DataTable
                        filters={filters}
                        findAndCount={findAndCountEntries}
                        onClick={res => history.push('/bases/' + url + '/event/' + event_url + '/slot/' + res.url, res)}
                        onMiddleClick={onMiddleClick}
                        fields={[
                            field(t("bases.base.date"), true, "start_datetime", res => <>
                                {getStartDayAndMonth(res.start_date, language)} | {getStartWeekDay(res.start_date, language)}
                            </>),
                            field(t("bases.base.slots"), false, "time", res =>
                                res.start_time ? dayjs(res.start_time, 'HH:mm:ss').format('HH:mm') + ' - ' +
                                    dayjs(res.end_time, 'HH:mm:ss').format('HH:mm') : ''),
                            field(t("bases.base.orders"), false, "booked_to_total", res => <>
                                {(res.booked_sits) + ` (${res.reserved_sits - res.booked_sits}) ` + ' / ' + res.total_sits}
                                <LoadBadge value={res.booked_to_total || 0} colorBreakpoint={67}/>
                            </>),
                            field(t("bases.base.link"), false, "url", res => <CopyLink showLink={false} href={getSlotUrl(res)}/>),
                            field(<GiWhistle/>, false, "instructors_ids", res => {
                                return res.instructors_ids.length
                                    ? <FaCheck color={'green'}/>
                                    : <FaExclamation color={'#ffb548'} title={t('bases.base.haveNoTrainer')}/>
                            }),
                        ]}
                        sortField="start_datetime"
                        sortDir="ASC"
                        name="slots-list"
                        onDelete={permissions.edit ? check(frk["EVENT-ITEM-SLOTS-LIST-ROW-REMOVE"]) && deleteSlot : null}
                        reload={reload}
                    />
                </Col>
            </Row>
        </Col>
    </>
}

export default SlotsTable;

SlotsTable.propTypes = {
    state: PropTypes.object.isRequired,
}