import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, FormGroup, Label, Row} from "reactstrap";

import Parameters from "./Parameters";
import PaymentInfo from "./PaymentInfo";
import {CopyLink} from "../../../../../generic/buttons";
import {BOOKING_URL} from "../../../../../../lib/api/useApi";
import {t} from "i18next";
import getCountryValue from '../../../../../../lib/utils/getCountryValue';

const generateUrl = state => {
    let baseUrl = `${BOOKING_URL}/${getCountryValue({ ru: 'certificate', en: 'giftcard' })}?`
    if (state.size) baseUrl += `certificateSum=${state.size}&`
    if (state.assignee_name) baseUrl += `name=${state.assignee_name}&`
    if (state.assignee_surname) baseUrl += `surname=${state.assignee_surname}&`
    if (state.assignee_phone) baseUrl += `phone=${state.assignee_phone}&`
    if (state.assignee_email) baseUrl += `email=${state.assignee_email}&`
    if (state.senders_email) baseUrl += `senderEmail=${state.senders_email}&`
    if (state.price_currency) baseUrl += `currency=${state.price_currency}&`
    if (state.product_type) baseUrl += `productTypes=${state.product_type}&`
    if (state.assignee_comment) baseUrl += `comment=${state.assignee_comment}&`
    if (state.country) baseUrl += `country=${state.country}`
    return baseUrl;
}

const General = ({state, dispatch, form}) => {
    const CERTIFICATE_URL = generateUrl(state);
    return (
        <Form>
            <Row>
                <Col xs={12} md={5}>
                    <Label className={'display-4'}>{t('management.certificates.tabsGeneralIndex.certificateParameters')}</Label>
                    <Parameters dispatch={dispatch} form={form} state={state}/>
                </Col>
                <Col xs={0} md={1}/>
                <Col xs={12} md={5}>
                    <Label className={'display-4'}>{t('management.certificates.tabsGeneralIndex.paymentParameters')}</Label>
                    <PaymentInfo dispatch={dispatch} form={form} state={state}/>
                </Col>
            </Row>
            {!!state.id && <>
                <hr/>
                <Row className={'justify-content-center'}>
                    <Col xs={'auto'}>
                        <div className={'display-3'}>{state.name}</div>
                    </Col>
                </Row>
                <Row className={'justify-content-center'}>
                    <Col xs={'auto'}>
                        <FormGroup check row>
                            <Label>{t('management.certificates.tabsGeneralIndex.copyCode')}</Label>
                            <CopyLink href={state.name || ''} showLink={false} message={t('management.certificates.tabsGeneralIndex.copyCode')}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'justify-content-center'}>
                    <Col xs={'auto'}>
                        <FormGroup check row>
                            <CopyLink href={CERTIFICATE_URL} text={t('management.certificates.tabsGeneralIndex.purchasePage')}/>
                        </FormGroup>
                    </Col>
                </Row>
            </>}
        </Form>
    );
};

General.propTypes = {
    state: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default General;