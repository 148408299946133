import React, {useState} from 'react';
import {Input} from "reactstrap";
import {useApi} from "../../../lib/api/useApi";
import {handleInputChange} from "../../../lib/utils/handleInputChange";
import svLogo from '../../../styles/assets/images/sv/sv_logo-black.svg';
import wrLogo from '../../../styles/assets/images/sv/WRLogoBlack.svg'
import {COUNTRY} from "../../../lib/utils/constants";
import {t} from "i18next";

const Login = () => {
    const {authorize} = useApi();
    const [form, setForm] = useState({email: '', password: ''});
    const logo = COUNTRY === 'RU' ? svLogo : wrLogo
    const onFieldChange = e => {
        const {name, value} = handleInputChange(e);
        setForm(form => ({...form, [name]: value}));
    }

    return(
        <div className="content-wrapper d-flex align-items-center auth px-0 login-page">
            <div className="row w-100 mx-0">
                <div className="col-12">
                    <div className="container">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src={logo} alt="Sila_Vetra" style={{width: '100%'}}/>
                                </div>
                                <form className="pt-3">
                                    <div className="form-group">
                                        <Input type="email" name='email' className="form-control form-control-lg" role="loginInput"
                                            value={form.email} onChange={onFieldChange} placeholder={t('layout.login')}/>
                                    </div>
                                    <div className="form-group">
                                        <Input type="password" name='password' className="form-control form-control-lg" role="loginInput"
                                            value={form.password} onChange={onFieldChange} placeholder={t('layout.password')}/>
                                    </div>
                                    <div className="mt-3">
                                        {/*eslint-disable-next-line*/}
                                        <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                        href='#' onClick={() => authorize(form.email?.toLowerCase(), form.password)} role="submitButton">
                                            {t('layout.enter')}</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Login;