import React from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import PropTypes from "prop-types";
import {handleInputChange} from "../../../../../lib/utils/handleInputChange";
import {useHistory} from 'react-router-dom';
import qs from 'qs';
import {TextControllerRHF} from "../../../../generic/fields";
import {t as tr} from "i18next";

const CreateNew = (
    {state, setState, form, ...rest}
) => {
    let history = useHistory();
    const {t} = qs.parse(history.location?.search.slice(1))
    const {control, errors} = form;
    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setState(state => ({...state, [name]: value}))
    };

    return (
        <Form>
            <Row>
                <Col xs={6}>
                    <FormGroup>
                        <TextControllerRHF inputName={'email'} control={control} rules={t === 'new' ? {required: tr('management.instructors.editModal.requiredField')}: {}}
                                           setValue={handleInput} errors={errors} placeholder={'example@bask.ws'}
                                           defaultValue={state.client_id?.email || ''}
                                           label={tr('management.instructors.editModal.instructorMail')} isRequired={t === 'new'}
                        />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <Label>
                            {tr('management.instructors.editModal.patronymic')}
                        </Label>
                        <Input placeholder={tr('management.instructors.editModal.patronymicPlaceholder')} value={state.surname || ''} name={'surname'}
                               onChange={handleInput}/>
                    </FormGroup>
                </Col>
            </Row>
        </Form>

    )
}

CreateNew.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
}

export default CreateNew;

