import React, {useState} from 'react';
import {TableCard} from '../../generic/cards'
import {useDefaultEndpoints} from '../../../lib/api/useDefaultEndpoints'
import {DataTable, field} from '../../generic/tables'
import {FaCheck, FaInfoCircle, FaPlus, FaRegCommentAlt, FaTimes} from 'react-icons/fa';
import {Col, FormGroup, Input, Label, PopoverBody, UncontrolledPopover} from 'reactstrap'
import {useHistory} from 'react-router-dom';
import {TextSearch} from "../../generic/fields";
import PropTypes from 'prop-types';
import {ModalConfirmation} from "../../generic/modals";
import {openInNewTab} from "../../../lib/utils/helper";
import {Functions} from "sv-common";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {extractNumbers} from "sv-common/functions/formatters";
import {CERTIFICATE_TYPES_LANG_ADAPTER, PRODUCT_TYPES_LANG_ADAPTER} from "sv-common/constants/certificates";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import i18n from "../../../lib/utils/i18n";
import { RuleAccessProvider } from '../../../lib/utils/frontRules';
import { frk } from 'sv-common/constants/frontRoles';

const {isCertificateAvailable} = Functions.CERTIFICATES;

const translateProductType = (language) => ({product_type}) => {
    return PRODUCT_TYPES_LANG_ADAPTER[language][product_type] || product_type
}

function getStatusValue(status) {
    if (status === '') return undefined
    else if (status === 'active') return true
    else if (status === 'inactive') return false

}

const CertificatesList = withBreadcrumbs(({isInline = false, isView = false, onClick}) => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('certificates');
    let history = useHistory();
    const { language } = i18n;

    const [isModalOpen, setModalOpen] = useState();
    const [reload, setReload] = useState(true);
    const [nameFilter, setNameFilter] = useState(undefined);
    const [statusFilter, setStatusFilter] = useState('active');
    const [senderEmailFilter, setSenderEmailFilter] = useState(undefined);
    const [productTypeFilter, setProductTypeFilter] = useState(undefined);
    const [typeFilter, setTypeFilter] = useState(undefined);
    const [selectedCertificate, setSelectedCertificate] = useState(undefined);
    const [assigneeFilter, setAssigneeFilter] = useState(undefined);
    const permissions = useRoleSystem(ENTITIES.CERTIFICATES);

    const toggleModal = () => setModalOpen(isOpen => !isOpen);

    const commonFilters = (filters) => [
        ...(nameFilter ? [{name_contains: nameFilter, name_ncontains: 'deleted'}] : [{ name_ncontains: 'deleted' }]),
        ...(productTypeFilter ? [{product_type: productTypeFilter}] :[]),
        ...(typeFilter ? [{type: typeFilter}] :[]),
        {is_active: getStatusValue(statusFilter)},
        ...(senderEmailFilter ? [{senders_email_contains: senderEmailFilter}] : []),
        ...filters
    ]

    const filters = {
        _where: {
            _or: [
                [...commonFilters([{'assignee_email_contains': assigneeFilter}])],
                [...commonFilters([{'assignee_name_contains': assigneeFilter}, ])],
                [...commonFilters([{'assignee_surname_contains': assigneeFilter}])],
                [...commonFilters([{'assignee_full_name_search_contains': assigneeFilter}])],
                ...(extractNumbers(assigneeFilter)
                    ? [{...commonFilters([{'assignee_phone_contains': extractNumbers(assigneeFilter)}]),}]
                    : []),
            ],
        },
    };


    const confirmArchiveCertificate = () => deleteEntry(selectedCertificate.id).then(() => {
        setReload(reload => !reload)
        toggleModal()
    })
    const onDelete = () => permissions.edit
        ? res => {
            setSelectedCertificate(() => res);
            toggleModal()
        }
        : null
    const onTableClick = res => onClick
        ? onClick(res)
        : history.push('/administration/certificates/edit/' + res.id)

    const onMiddleClick = (res) => openInNewTab('/administration/certificates/edit/' + res.id)

    return <>
        <ModalConfirmation onClose={toggleModal} onConfirm={confirmArchiveCertificate} isOpen={isModalOpen}
                           title={t('management.certificates.list.modalTitle')}
                           text={t('management.certificates.list.deleteText')}
                           confirmButtonText={t('management.certificates.list.confirmButtonText')}/>
        <TableCard isInline={isInline}>
            <div className={'row justify-content-between mb-0 pr-0 mr-0 align-items-end'}>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.certificates.list.titleInputLabel')}</Label>
                        <TextSearch placeholder={'QWER-1234-ASDF-ZXCV'} value={nameFilter || ''}
                                    onSearch={e => setNameFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>
                            {t('management.certificates.list.ownerInputLabel')}
                            <FaInfoCircle id={"ownerInput"} className="ml-1"/>
                            <UncontrolledPopover placement="right" target={"ownerInput"} trigger={'hover'}>
                                <PopoverBody>
                                    {t('management.certificates.list.ownerInputPhoneHint')}<br/>
                                    {t('management.certificates.list.ownerInputNameHint')}
                                </PopoverBody>
                            </UncontrolledPopover>
                        </Label>
                        <TextSearch placeholder={t('management.certificates.list.ownerInputPlaceholder')} value={assigneeFilter || ''}
                                    onSearch={e => setAssigneeFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.certificates.list.senderInputLabel')}</Label>
                        <TextSearch placeholder={'ivanov@silavetra.ru'} value={senderEmailFilter || ''}
                                    onSearch={e => setSenderEmailFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.certificates.list.productTypeInputLabel')}</Label>
                        <Input type={'select'} value={productTypeFilter || ''}
                               onChange={e => setProductTypeFilter(() => e.target.value)}>
                            <option value={''}>{t('management.certificates.list.all')}</option>
                            {
                                Object.keys(PRODUCT_TYPES_LANG_ADAPTER[language]).map((pK, i) =>
                                    <option value={pK} key={i}>{PRODUCT_TYPES_LANG_ADAPTER[language][pK]}</option>)
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.certificates.tabsGeneralParameters.certificateType')}</Label>
                        <Input type={'select'} value={typeFilter || ''}
                               onChange={e => setTypeFilter(() => e.target.value)}>
                            <option value={''}>{t('management.certificates.list.all')}</option>
                            {
                                Object.keys(CERTIFICATE_TYPES_LANG_ADAPTER[language]).map((pK, i) =>
                                    <option value={pK} key={i}>{CERTIFICATE_TYPES_LANG_ADAPTER[language][pK]}</option>)
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.certificates.list.stateInputLabel')}</Label>
                        <Input type={'select'} value={statusFilter} onChange={e => setStatusFilter(() => e.target.value)}>
                            <option value={''}>{t('management.certificates.list.all')}</option>
                            <option value={'active'}>{t('management.certificates.list.active')}</option>
                            <option value={'inactive'}>{t('management.certificates.list.inactive')}</option>
                        </Input>
                    </FormGroup>
                </Col>
            </div>
            <RuleAccessProvider accessKey={frk["CERTIFICATES-ITEM-EDIT"]}>
            <div className={'row justify-content-end mb-0 pr-0 mr-0'}>
                {!isView && <Col xs={12} md={'auto'}>
                    <FormGroup>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <button className={'btn btn-sm btn-primary btn-rounded btn-block'}
                                    onClick={() => history.push('/administration/certificates/edit/')}>
                                <FaPlus/>
                                &nbsp; {t('management.certificates.list.create')}
                            </button>
                        </ElementWithPermissions>
                    </FormGroup>
                </Col>}
            </div>
            </RuleAccessProvider>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.certificates.list.ownerName'), true, 'assignee_surname', res => res.assignee_surname + ' ' + res.assignee_name),
                           field(t('management.certificates.list.title'), true, 'name'),
                           field(t('management.certificates.list.product'), true, 'product_type', translateProductType(language)),
                           field(t('management.certificates.list.remains'), true, 'size_left'),
                           field(t('management.certificates.list.sum'), true, 'size'),
                           field(t('management.certificates.list.ownerPhone'), true, 'assignee_phone'),
                           field(t('management.certificates.list.ownerMail'), true, 'assignee_email'),
                           field(t('management.certificates.list.senderMail'), true, 'sender_email', res =>
                               <>{res.senders_email}&nbsp;{res.is_created_manually
                                   ?<>
                                       <FaRegCommentAlt id={"sender_email-" + res.id}/>
                                       <UncontrolledPopover placement="left" target={"sender_email-" + res.id}
                                                            trigger={'hover'}>
                                           <PopoverBody>{t('management.certificates.list.manager')}</PopoverBody>
                                       </UncontrolledPopover></>
                                   : ''}
                               </>
                           ),

                           field(t('management.certificates.list.isActive'), true, 'is_active',
                               res => isCertificateAvailable(res)
                                   ? <FaCheck color={'green'}/>
                                   : <FaTimes color={'red'}/>
                           ),
                       ]}
                       sortField='created_at'
                       sortDir='desc'
                       name='certificates-list'
                       onClick={onTableClick}
                       onMiddleClick={res => !onClick && onMiddleClick(res)}
                       onDelete={onDelete()}
                       reload={reload}
            />
        </TableCard>
    </>
});

CertificatesList.propTypes = {
    isInline: PropTypes.bool,
    isView: PropTypes.bool,
    onClick: PropTypes.func
}

export default CertificatesList;

