import React from 'react';
import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {AiOutlineWallet} from 'react-icons/ai'
import {t} from "i18next";

const SubscriptionApplied = ({res}) => <>
    <AiOutlineWallet id={"comment-" + res.id} size={'20px'} color={'green'}/>
    <UncontrolledPopover placement="left" target={"comment-" + res.id}
                         trigger={'hover'}>
        <PopoverBody>
            <b className={'popover-text'}>{res.subscription_applied.name} ({t('common.productOrderList.discount')}:
                {t('common.productOrderList.common')}
                - {res.subscription_applied.keel_all_luch_group_discount_size}%,
                {t('common.productOrderList.individual')}
                - {res.subscription_applied.luch_individual_discount_size}%)</b>&nbsp;
            {res.deposit_sum} {res.payment_currency}; {res.deposit_discount}%
        </PopoverBody>
    </UncontrolledPopover>
    &nbsp;
</>

export default SubscriptionApplied;