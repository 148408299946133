import {FcEmptyTrash} from "react-icons/fc";
import React from "react";
import {t} from "i18next";

const ExtraMaterialsTable = ({state, setState}) => {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th><b>{t('common.extraMaterialsTable.name')}</b></th>
                    <th><b>{t('common.extraMaterialsTable.link')}</b></th>
                    <th><b>{t('common.extraMaterialsTable.delete')}</b></th>
                </tr>
                </thead>
                <tbody>
                {state.extra_materials?.map((material, index) => (
                    <tr key={index}>
                        <td>{material.name}</td>
                        <td>
                            {material.link}
                        </td>
                        <td>
                            <FcEmptyTrash size={20} onClick={() => {
                                setState({
                                    ...state,
                                    extra_materials: [
                                        ...state.extra_materials.slice(0, index),
                                        ...state.extra_materials.slice(~~index + 1),
                                    ]
                                })
                            }}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default ExtraMaterialsTable;