import React from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../../../generic/tables";
import {datetime} from "../../../../../lib/utils/format";
import {t} from "i18next";

const ClientInsurances = ({state}) => {
    const {findAndCountEntries} = useDefaultEndpoints('orders-insurances')
    return <>
        <DataTable findAndCount={findAndCountEntries}
                   filters={{client_id: state.id}}
                   fields={[
                       field(t('management.users.insuranceId'), true, "insurance_id"),
                       field(t('management.users.action'), true, "action", () => t('management.users.insurance')),
                       field(t('management.users.time'), true, "created_at", res =>
                           <>{datetime(res.created_at)} {res.order_id?.created_at ? <small>{t('management.users.insured')}: {datetime(res.order_id?.created_at)}</small> : ''}</>
                       ),
                   ]}
                   sortField="created_at"
                   sortDir="desc"
                   name="client-insurance"
        />
    </>
}

export default ClientInsurances;

ClientInsurances.propTypes = {
    state: PropTypes.object.isRequired,
}
