import React from 'react';
import PropTypes from 'prop-types';
import {TextControllerRHF} from "../../../generic/fields";
import {t} from "i18next";

const HideUntilField = ({control, inputName = 'hide_until_time', defaultValue, isRequired, setValue, errors}) =>
    <>
        <TextControllerRHF control={control} inputName={inputName} defaultValue={defaultValue}
                           isRequired={isRequired} setValue={setValue} errors={errors} label={t('common.forms.hideUntil.label')}
                           placeholder={'24'} rules={{valueAsNumber: true,}} type={'number'}/>
    </>


HideUntilField.propTypes = {
    control: PropTypes.any.isRequired,
    defaultValue: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    inputName: PropTypes.string,
    errors: PropTypes.object.isRequired
};

export default HideUntilField;