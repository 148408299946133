import React, {useEffect, useState} from "react";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {Card, CardBody} from "reactstrap";
import {useGlobalConfigApi} from "../../../lib/api/globalConfig";
import SwitchExtraButton from "./SwitchExtraButton";
import {t} from "i18next";
import ElementWithAccessInCountries from "../../../lib/utils/hocs/ElementWithAccessInCountries";

const Separate = withBreadcrumbs((props) => {
    const {getGlobalSettings, saveGlobalSettings} = useGlobalConfigApi('global-settings');
    const [globalSettings, setGlobalSettings] = useState({});

    useEffect(() => {
        getGlobalSettings().then((data) => {
            setGlobalSettings(data.config);
        })
    }, [])

    const onChange = (key, value) => {
        const valueObj = { ...globalSettings };

        if (key === 'partial_payment_available' || key === 'installment_available') {
            valueObj.partial_payment_available = false;
            valueObj.installment_available = false;
        }

        valueObj[key] = value;

        saveGlobalSettings(valueObj).then(() => {
            setGlobalSettings(valueObj);
        })
    }

    return (
        <Card body className='mb-2'>
            <CardBody>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                    <SwitchExtraButton
                        value={globalSettings.installment_available}
                        setValue={value => onChange('installment_available', value)}
                        id='installment_available'
                        label={t('globalSettings.separateFunctionality.enableInstallment')}
                    />
                </ElementWithAccessInCountries>
                <SwitchExtraButton
                    value={globalSettings.insurance_available}
                    setValue={value => onChange('insurance_available', value)}
                    id='insurance_available'
                    label={t('globalSettings.separateFunctionality.enableCustomerInsurance')}
                />
                <SwitchExtraButton
                    value={globalSettings.promo_subscription_available}
                    setValue={value => onChange('promo_subscription_available', value)}
                    id='promo_subscription_available'
                    label={t('management.promoCodes.useForAbonements')}
                />
                <SwitchExtraButton
                    value={globalSettings.partial_payment_available}
                    setValue={value => onChange('partial_payment_available', value)}
                    id='partial_payment_available'
                    label={t('globalSettings.separateFunctionality.enablePartialPayment')}
                />
            </CardBody>
        </Card>

    )
})

export default Separate;