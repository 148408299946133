import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Input} from "reactstrap";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {requiredRule} from "../rules";
import {t} from "i18next";

PublicContracts.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    withAlter: PropTypes.bool,
    onlyAlter: PropTypes.bool,
};

const PublicContractsItem = ({ control, state, isRequired, errors, publicContracts, withAlter, setState, info }) => {
    const prefix = withAlter ? 'alter_' : '';

    return (
        <Controller
            control={control}
            name={`${prefix}public_contract_url`}
            defaultValue={state[`${prefix}public_contract_url`] || ''}
            rules={isRequired ? requiredRule : {}}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation info={info} invalid={invalid} errors={errors} label={t(`common.forms.${prefix}publicContracts.label`)}
                                     name={name} isRequired={true}
                >
                    <Input  type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                            style={{border: invalid ? '1px solid #dc3545' : '0'}}
                            value={value}
                            onChange={e => {
                                setState(e)
                                onChange(e.target.value)
                            }}>
                        <option value={t(`common.forms.${prefix}publicContracts.pickContract`)} disabled></option>
                        {publicContracts.map(publicContract => <option value={publicContract.link} key={publicContract.id}>
                            {publicContract.tag}
                        </option>)}
                    </Input>
                </FieldWithValidation>
            }
        />
    )
}

function PublicContracts({form, state, setState, isRequired = true, withAlter, onlyAlter}) {
    const {control, errors} = form;
    const getPublicContracts = useDefaultEndpoints('public-contracts').getEntries;
    const [publicContracts, setPublicContracts] = useState([]);
    useEffect(() => {
        getPublicContracts({}).then(res => setPublicContracts(() => res));
    }, [])

    const props = { control, state, isRequired, errors, publicContracts, setState };

    return (
        <>
            {!onlyAlter && <PublicContractsItem {...props} />}
            {(withAlter || onlyAlter) && <div className={!onlyAlter && 'mt-4' || ''}>
                <PublicContractsItem {...props} withAlter info={t('common.forms.alter_publicContracts.tooltip')} />
            </div>}
        </>
    );
}

export default PublicContracts;