import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import React, {useEffect, useState} from 'react';
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {Button} from "reactstrap";
import {EditModal, ModalConfirmation} from "../../generic/modals";
import EditModalChildren from "./EditModalChildren";
import dayjs from "dayjs";

const Projects = withBreadcrumbs(() => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('projects');

    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [ejectedProject, setEjectedProject] = useState();
    const [projectToCopy, setProjectToCopy] = useState();
    const [reload, setReload] = useState(false);

    const toggleModalConfirmation = () =>
        setIsModalConfirmationOpen(!isModalConfirmationOpen);
    const toggleEditModal = () => {
        if (isEditModalOpen) {
            setProjectToCopy(undefined)
        }
        setIsEditModalOpen(!isEditModalOpen);
    }

    const onConfirmModal = () => deleteEntry(ejectedProject.id).then(toggleModalConfirmation);

    const onDeleteProject = (project) => {
        setEjectedProject(() => project);
        toggleModalConfirmation();
    }
    useEffect(() => {
        if (projectToCopy?.id) setIsEditModalOpen(!isEditModalOpen);
    }, [projectToCopy?.id])

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
            title={t('constructor.projects.deleting')} text={t('constructor.projects.areYouSure')}
            cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
            onConfirm={() => { onConfirmModal().then(() => setReload(!reload)) }}
        />

        <EditModal title={t('constructor.projects.newProject')} triggerModal={toggleEditModal}
            entityName={'projects'} isOpened={isEditModalOpen}
            EditForm={EditModalChildren} size={'sm'} edit={true} entry={{...projectToCopy}}
            onSuccessCb={() => setReload(!reload)}
        />

        <TableCard>
            <DataTable
                findAndCount={findAndCountEntries}
                fields={[
                  field(t('constructor.label'), true, 'label'),
                  field(t('constructor.key'), true, 'key'),
                  field(t('constructor.createdAt'), true, 'created_at',
                          res => dayjs(res.created_at).format('DD.MM.YYYY HH:MM')),
                  field(t('constructor.updatedAt'), true, 'updated_at',
                          res => dayjs(res.updated_at).format('DD.MM.YYYY HH:mm')),
                ]}
                sortField='created_at'
                sortDir='desc'
                name='projects-list'
                reload={reload}
                onDelete={onDeleteProject}
                onClick={(res) => {
                    setProjectToCopy(res)
                }}
            />
            <Button color={'primary'} outline className={'btn-rounded'} onClick={ () => toggleEditModal()}>
                {t('constructor.projects.createProject')}
            </Button>
        </TableCard>

    </>
})

export default Projects