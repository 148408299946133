import React from 'react'
import GroupView from "./tabs/GroupView";
import {ProductOrdersList} from "../../../../common/formElements";
import {t} from "i18next";
import CancelledAndMovedOrdersList from "../../../../common/formElements/CancelledAndMovedOrdersList";
import {getSlotSet} from "../../../../common/formElements/ProductOrdersList/fieldSets";
import GlobalSales from '../../../../common/formElements/GlobalSales';
import TinkoffIdField from "../../../../common/formElements/TinkoffIdField";

const tabList = ({state, dispatch, permissions}) => {
    return (
        [
            {
                name: t('school.theory.tabList.aboutArrival'),
                child: <GroupView state={state} dispatch={dispatch}/>,
                isDisplayed: true,
                url: 'about'
            },
            {
              name: t("school.theory.tabList.globalSales"),
              child: <GlobalSales entityName='groups' state={state} />,
              isDisplayed: true,
              url: 'globalsales'
            },
            {
                name: t('school.theory.tabList.clients'),
                child: (
                    <>
                        <ProductOrdersList state={state} product_type={'groups'} dispatch={dispatch}
                                           disabled={!permissions.edit}/>
                        <CancelledAndMovedOrdersList
                            name={"groups_orders-moved-and-cancelled-list"}
                            fields={[ ...getSlotSet(), ...TinkoffIdField() ]}
                            entity={state}
                            entityOrderName={'group_id'}
                            reload={state.reload}
                        />
                    </>
                    ),
                isDisplayed: !!state.id,
                url: 'instructors'
            },
        ]
    )
}

export default tabList;