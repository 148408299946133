import {useDefaultEndpoints} from "./useDefaultEndpoints";
import {useApi} from "./useApi";


export const useAdventuresApi = () => {
    const {request} = useApi();

    const archiveAdventure = async (id) => request('put', `/adventures/archive/${id}`)

    return {...useDefaultEndpoints('adventures'), archiveAdventure}
};