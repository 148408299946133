import React, {Fragment} from 'react';
import {
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledPopover, PopoverBody
} from 'reactstrap';
import PropTypes from "prop-types";
import {ModalConfirmation} from "../modals";
import {DOWNLOAD_LINK} from "../../../lib/api/useApi";
import {Loader} from "../loader";
import {toast} from "react-toastify";
import {exportStatuses} from "../../../lib/utils/helper";
import {t} from "i18next";
import { FaInfoCircle } from 'react-icons/fa';
const showDangerToast = e => toast(e, {type: 'error'});

class ExportButton extends React.Component {
    constructor(props) {
        super(props);

        const state = {
          showModal: false,
          showLoader: false,
          buttonTitle: 'generic.exportButton.export',
          mods: [{ type: ExportButton.All, label: 'generic.exportButton.allTable' }, { type: ExportButton.Page, label: 'generic.exportButton.currentPage' }],
        };

        if (props.mods) {
          state.mods = props.mods;
        }

        if (props.buttonTitle) {
          state.buttonTitle = props.buttonTitle;
        }

        if (props.tooltipDesc) {
          state.tooltipDesc = props.tooltipDesc;
        }

        this.onSelect = this.onSelect.bind(this);
        this.state = state;
    }

    onSelect(mode) {
        this.setState({showLoader: true});
        this.props.exportFun(mode).then(result => {
            this.setState({exportData: result, showModal: true, showLoader: false})
        }).catch((e) => {
            this.setState({showLoader: false});
            showDangerToast(e);
        });
    }

    render() {
        const {exportData, showModal, showLoader} = this.state;
        const {status, description} = exportData || {};

        const isModsExist = !!this.state.mods.length;

        return <Fragment>
            <UncontrolledButtonDropdown direction="up">
                <DropdownToggle
                  color={'outline-secondary'}
                  className="btn-sm "
                  caret={isModsExist}
                  onClick={() => !isModsExist && this.onSelect()}
                >
                    {t(this.state.buttonTitle)}
                </DropdownToggle>
                {isModsExist && <DropdownMenu>
                  {this.state.mods.map(i => (
                    <DropdownItem onClick={() => this.onSelect(i.type)}>{t(i.label)}</DropdownItem>
                  ))}
                </DropdownMenu>}
            </UncontrolledButtonDropdown>
            {this.state.tooltipDesc && <>
              <FaInfoCircle id={"export_button_info"} className="ml-1"/>
                <UncontrolledPopover placement="right" target={"export_button_info"} trigger={'hover'}>
                <PopoverBody>
                  {t(this.state.tooltipDesc)}
                </PopoverBody>
                </UncontrolledPopover>
              </>
            }
            <ModalConfirmation onClose={() => this.setState({showModal: false})}
                               onConfirm={() => this.setState({showModal: false})}
                               isOpen={showLoader || showModal}
                               hasCancel={false}
                               title={t('generic.exportButton.discharge')}
                               confirmButtonText={showLoader ? t('generic.exportButton.loading') : t('generic.exportButton.ok')}
                               color={"primary"}
                               text={
                                   showLoader ?
                                       <Loader/> :
                                       <Fragment>
                                           {status === exportStatuses.Error && description}
                                           {status === exportStatuses.Processing && t('generic.exportButton.downloadLater')}
                                           {status === exportStatuses.Ready &&
                                           <p>
                                               <a href={DOWNLOAD_LINK(exportData)} rel="noopener noreferrer" target="_blank"
                                                  download>{t('generic.exportButton.uploadFile')}: <strong>{exportData.filename}</strong></a>
                                               <br/>
                                               {t('generic.exportButton.downloadLaterShort')}
                                           </p>
                                           }
                                       </Fragment>
                               }/>
        </Fragment>
    }
}

ExportButton.propTypes = {
    /**
     * function(mode: string): Promise<{id: string, status: string, filename: string}>
     */
    exportFun: PropTypes.func.isRequired,
};

ExportButton.All = "all";
ExportButton.Page = "page";

export default ExportButton;