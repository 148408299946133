import React from 'react';
import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {FaTimes} from "react-icons/fa";

const IsPartialCanceled = ({res}) =>
    <>
        <FaTimes id={"is_cancelled-" + res.id} size={'20px'} color={'red'}/>
        <UncontrolledPopover placement="top" target={"is_cancelled-" + res.id}
                             trigger={'hover'}>
            <PopoverBody>
                Заказ просрочен
            </PopoverBody>
        </UncontrolledPopover>
        &nbsp;
    </>

export default IsPartialCanceled;