import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Col, CustomInput, FormGroup, Label} from "reactstrap";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {t} from "i18next";

const Params = ({control, entity, errors, setEntity, handleInput}) => {
    return (
        <Fragment>
            <Col xs={12} lg={4}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="category"
                        defaultValue={entity.category || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired
                                                 label={t('school.theory.editModalChildren.product')}
                            >
                                <div className={(invalid ? 'is-invalid' : '')}>
                                    <ButtonGroup>
                                        <Button color={invalid ? 'danger' : 'primary'} outline
                                                className={'btn-rounded '} size={'sm'}
                                                onClick={() => {
                                                    onChange('active')
                                                    setEntity(entity => ({
                                                        ...entity,
                                                        category: 'school'
                                                    }))
                                                }}
                                                active={entity.category === 'school'} onBlur={onBlur}
                                        >
                                            {t('school.theory.editModalChildren.school')}
                                        </Button>
                                        <Button color={invalid ? 'danger' : 'primary'} outline
                                                className={'btn-rounded'} size={'sm'}
                                                onClick={() => {
                                                    onChange('active')
                                                    setEntity(entity => ({...entity, category: 'gims'}))
                                                }}
                                                active={entity.category === 'gims'} onBlur={onBlur}
                                        >
                                            {t('school.theory.editModalChildren.gims')}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('school.theory.editModalChildren.intensive')}</Label>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_intensive" name="is_intensive"
                                 checked={entity.is_intensive || false}
                                 onChange={e => handleInput(e, setEntity).value}

                    />
                </FormGroup>
            </Col>
        </Fragment>
    );
};

Params.propTypes = {
    control: PropTypes.any.isRequired,
    entity: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setEntity: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired
};

export default Params;