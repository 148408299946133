import React from 'react';
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../../../generic/tables";
import {useHistory} from 'react-router-dom';
import {datetime} from "../../../../../lib/utils/format";
import {t} from "i18next";

const actionToLabel = ({action}) => {
    switch (action){
        case 'booking':
            return t('management.users.booking');
        case 'paymentApproved':
            return t('management.users.paymentApproved');
        case 'refund':
            return t('management.users.refund');
        case 'paymentDeclined':
            return t('management.users.paymentDeclined');
        case 'postpone':
            return t('management.users.postpone');
        case 'moved':
            return t('management.users.moved');
        case 'removedFromPayment':
            return t('management.users.removedFromPayment')
        default:
            return action
    }
}

const ClientHistory = ({state}) => {
    let history = useHistory()
    const {findAndCountEntries} = useDefaultEndpoints('client-histories')
    return <>
        <DataTable findAndCount={findAndCountEntries}
                   filters={{client_id: state.id}}
                   fields={[
                       field(t('management.users.action'), true, "action", actionToLabel),
                       field(t('management.users.reason'), true, "reason"),
                       field(t('management.users.time'), true, "created_at", res =>
                           <>{datetime(res.created_at)} {res.order_id?.created_at ? <small>{t('management.users.purchase')}: {datetime(res.order_id?.created_at)}</small> : ''}</>
                       ),
                   ]}
                   sortField="created_at"
                   sortDir="desc"
                   name="client-history-list"
                   onClick={record => history.push('/administration/orders/edit/' + record.order_id?.id)}
        />
    </>
}

export default ClientHistory;

ClientHistory.propTypes = {
    state: PropTypes.object.isRequired,
}
