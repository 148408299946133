import React, { useEffect, useState } from 'react';
import {TableCard} from '../../generic/cards'
import {useDefaultEndpoints} from '../../../lib/api/useDefaultEndpoints'
import {DataTable, field} from '../../generic/tables'
import {FaTimes, FaCheck, FaPlus} from 'react-icons/fa';
import {Input, Col, FormGroup, UncontrolledPopover, PopoverBody, CustomInput} from 'reactstrap'
import {useHistory} from 'react-router-dom';
import {datetime} from '../../../lib/utils/format'
import {ConditionalField, RangedDatePicker, TextSearch} from "../../generic/fields";
import {TiTicket} from "react-icons/ti";
import {GiSailboat} from 'react-icons/gi';
import {BsPersonDashFill} from 'react-icons/bs';
import {FaExclamation} from 'react-icons/fa';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import SlotHrefAdmin from "../../common/hrefGenerators/SlotHrefAdmin";
import ArrivalHrefAdmin from "../../common/hrefGenerators/ArrivalHrefAdmin";
import { AiOutlineWallet } from "react-icons/ai";
import PayCertificatePopover from "../../common/popovers/PayCertificatePopover";
import {openInNewTab} from "../../../lib/utils/helper";
import GenericHrefGenerator from "../../common/hrefGenerators/GenericHrefGenerator";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import IsPartialCanceled from "../../common/formElements/ProductOrdersList/tableFieldElements/IsPartialCanceled";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import CertificateHrefAdmin from '../../common/hrefGenerators/CertificateHrefAdmin';
import { useOrdersApi } from '../../../lib/api/orders';
import moment from 'dayjs';
import ExportByDirectionButton from '../../common/formElements/ExportByDirectionButton';

const showSuccessToast = e => toast(e, {type: 'success'});

const renderPaymentField = res => {
    if (!res.has_paid && !res.paid_value && !res.fraud) return <small>{t('management.orders.orderList.noPayment')}</small>
    let renderedMessage;

    renderedMessage = res.fraud;

    if (res.paid_with === 'card') {
        if (res.acquirer?.toLowerCase() === 'tinkoff' || (res.paid_value && !res.acquirer)) renderedMessage = t('management.orders.orderList.tinkoff')
        if (res.acquirer?.toLowerCase() === 'cloudpayments' || (res.paid_value && !res.acquirer)) renderedMessage = t('management.orders.orderList.cloudPayments')
        if (res.acquirer?.toLowerCase() === 'alpha' || (res.paid_value && !res.acquirer)) renderedMessage = t('management.orders.orderList.alpha')
        if (res.acquirer?.toLowerCase() === 'stripe' || (res.paid_value && !res.acquirer)) renderedMessage = t('management.orders.orderList.stripe')
    }

    if (res.paid_with === 'promocode') {
        if (res.acquirer?.toLowerCase() === 'promocode' || (!res.paid_value && res.promocode_applied) || res.paid_with === 'promocode') renderedMessage = t('management.orders.orderList.promoCode')
        if (res.acquirer?.toLowerCase() === 'free' || res.total_value === 0) renderedMessage = t('management.orders.orderList.free')
    }
    if (res.paid_with === 'subscription') {
        renderedMessage = t('management.orders.orderList.subscription')
    }
    if (res.paid_with === 'certificate') {
        renderedMessage = t('management.orders.orderList.certificate')
    }
    if (res.paid_with === 'cash') {
        renderedMessage = t('management.orders.orderList.paidLocally')
    }

    renderedMessage = (renderedMessage || t('management.orders.orderList.manual')) + ' ' + res.paid_to_total + '%'

    return  <div>
        {res.has_paid ? <FaCheck color={'green'} title={t('management.orders.orderList.paidFor')}/> : <FaTimes color={'red'} title={t('management.orders.orderList.notPaid')}/>} &nbsp;
        <small>{renderedMessage}</small> &nbsp;
        {res.promocode_applied &&  <>
            <CopyToClipboard text={res.promocode_applied.name} onCopy={() => showSuccessToast(t('management.orders.orderList.promoCodeCopied'))}>
                <TiTicket id={"comment-" + res.id} size={'20px'} color={'green'}/>
            </CopyToClipboard>

            <UncontrolledPopover placement="left" target={"comment-" + res.id} trigger={'hover'}>
                <PopoverBody>
                    <b className={'popover-text'}>{res.promocode_applied.name}</b> {res.promocode_applied.discount_size} {
                    res.promocode_applied.discount_type === 'summ' ? res.promocode_applied.discount_currency : '%'}
                </PopoverBody>
            </UncontrolledPopover>
        </>} &nbsp;
        {res.subscription_applied &&  <>
            <CopyToClipboard text={res.subscription_applied.name} onCopy={() => showSuccessToast(t('management.orders.orderList.subscriptionCopied'))}>
                <AiOutlineWallet id={"comment-" + res.id} size={'20px'} color={'green'}/>
            </CopyToClipboard>

            <UncontrolledPopover placement="left" target={"comment-" + res.id} trigger={'hover'}>
                <PopoverBody>
                    <b className={'popover-text'}>
                        {res.subscription_applied.name} ({t('management.orders.orderList.discountGeneral')} - {res.subscription_applied.keel_all_luch_group_discount_size}%, {t('management.orders.orderList.individual')} - {res.subscription_applied.luch_individual_discount_size}%)
                    </b>&nbsp;
                    {res.deposit_sum} {res.payment_currency}; {res.deposit_discount}%
                </PopoverBody>
            </UncontrolledPopover>
        </>} &nbsp;
        {res?.certificate_applied &&  <>
            <PayCertificatePopover orderId={res.id} certificateName={res.certificate_applied.name}
                                   certificateSize={res.certificate_applied.size_left}
                                   certificatePriceCurrency={res.certificate_applied.price_currency}
                                   certificateSum={res.certificate_sum}
            />
        </>} &nbsp;
        {res.fraud &&  <>
            <FaExclamation id={"is-fraud-" + res.id} size={'20px'} color={'red'}/>

            <UncontrolledPopover placement="left" target={"is-fraud-" + res.id} trigger={'hover'}>
                <PopoverBody>
                    {t('management.orders.orderList.probablyFraudster')}
                </PopoverBody>
            </UncontrolledPopover>
        </>} &nbsp;
        <ConditionalField condition={res.is_partial_cancelled}>
            <IsPartialCanceled res={res}/>
        </ConditionalField>
        {res.coachless_discount_applied && <BsPersonDashFill size={'20px'} color={'#f5cd02'} title={t('management.orders.orderList.soloDiscount')}/>}
        {res.full_book_discount_applied && <GiSailboat size={'20px'} color={'#f5cd02'} title={t('management.orders.orderList.fullBoardDiscount')}/>}

    </div>
}

const OrdersList = withBreadcrumbs(({client_id, isInline, user_id}) => {
    const {findAndCountEntries} = useDefaultEndpoints('orders');
    const {findAndCountEntries: findAndCountTransactionStatus} = useDefaultEndpoints('transaction-statuses');
    const { getOrdersStatistic } = useOrdersApi();

    const [onlyMasterOrder, setOnlyMasterOrder] = useState(true);
    const [transactionStatuses, setTransactionStatuses] = useState([]);
    const [ordersStatistic, setOrdersStatistic] = useState({});

    let history = useHistory();
    const filters = {}
    if (client_id) {
        filters.client_id = client_id;
        filters.master_order_gte = 1;
    } else {
        if (onlyMasterOrder) filters.master_order_null = true;
    }

    const [daysFilter, setDaysFilter] = useState({
      from: undefined,
      to: undefined
    })

    useEffect(() => {
      findAndCountTransactionStatus().then(res => {
        setTransactionStatuses(res.data);
      });
    }, []);

    const [emailFilter, setEmailFilter] = useState(undefined);
    const [currencyFilter, setCurrencyFilter] = useState(undefined);
    const [promoNameFilter, setPromoNameFilter] = useState(undefined);
    const [paymentTypeFilter, setPaymentTypeFilter] = useState(undefined);
    const [transactionStatusFilter, setTransactionStatusFilter] = useState(undefined);
    const permissions = useRoleSystem(ENTITIES.ORDERS);

    useEffect(() => {
      user_id && getOrdersStatistic(
        user_id,
        daysFilter.from && moment(daysFilter.from).format('YYYY-MM-DD'),
        daysFilter.to && moment(daysFilter.to).format('YYYY-MM-DD'),
        transactionStatusFilter,
      ).then(setOrdersStatistic);
    }, [daysFilter.from, daysFilter.to, transactionStatusFilter]);

    if (daysFilter.from) filters.created_at_gte = daysFilter.from;

    if (daysFilter.to) filters.created_at_lte = daysFilter.to;

    if (emailFilter) filters['client_id.email_contains'] = emailFilter;

    if (promoNameFilter) filters['promocode_applied.name'] = promoNameFilter;

    if (setPaymentTypeFilter) filters.paid_with = paymentTypeFilter;

    if (currencyFilter) filters.payment_currency_contains = currencyFilter;

    if (transactionStatusFilter) filters.transaction_status = transactionStatusFilter;

    const onClick = order => {
      order.master_order ? history.push('/administration/orders/edit/' + order.master_order.id)
        : history.push('/administration/orders/edit/' + order.id)
    }
    const onMiddleClick = (res) => openInNewTab(
        res.master_order
            ?'/administration/orders/edit/' + res.master_order.id
            : '/administration/orders/edit/' + res.id
    )

    return <>
        <TableCard isInline={isInline}>
            <div className={'row justify-content-between mb-0 pr-0 mr-0'}>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <RangedDatePicker daysFilter={daysFilter} setDaysFilter={setDaysFilter}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={1}>
                    <FormGroup>
                        <TextSearch placeholder={'RUB'} value={currencyFilter || ''} onSearch={ e => setCurrencyFilter(() => e.toUpperCase())}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <TextSearch placeholder={'email@example.ru'} value={emailFilter || ''} onSearch={ e => setEmailFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={1}>
                    <FormGroup>
                        <Input type={'select'} value={paymentTypeFilter} onChange={e => setPaymentTypeFilter(() => e.target.value || undefined)}>
                            <option value={''}>{t('management.orders.orderList.any')}</option>
                            <option value={'free'}>{t('management.orders.orderList.free')}</option>
                            <option value={'card'}>{t('management.orders.orderList.card')}</option>
                            <option value={'promocode'}>{t('management.orders.orderList.byPromoCode')}</option>
                            <option value={'subscription'}>{t('management.orders.orderList.bySubscription')}</option>
                            <option value={'cash'}>{t('management.orders.orderList.byCash')}</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <TextSearch placeholder={t('management.orders.orderList.promoCode')} value={promoNameFilter || ''} onSearch={ e => setPromoNameFilter(() => e)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <button className={'btn btn-sm btn-primary btn-rounded'} onClick={() => history.push('/administration/orders/edit/')}>
                            <FaPlus/>
                            &nbsp; {t('management.orders.orderList.create')}
                        </button>
                    </ElementWithPermissions>
                </Col>
                {!client_id && <Col xs={12}>
                    <FormGroup>
                        <CustomInput type='checkbox' className={'custom-switch position-static'} id='only_master_order'
                                     name='only_master_order'
                                     label={t('management.users.onlyMasterOrders')}
                            checked={onlyMasterOrder} onChange={() => setOnlyMasterOrder(!onlyMasterOrder)}
                        />
                    </FormGroup>
                </Col>}
                <Col xs={12} md={2}>
                  <FormGroup>
                    <Input type={'select'} value={transactionStatusFilter} onChange={e => setTransactionStatusFilter(() => e.target.value || undefined)}>
                      <option value={''}>{t('management.orders.orderList.transactionStatus')}</option>
                      {transactionStatuses.map(i => <option value={i.id}>{i.title}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field('id', true, 'id', (res) => res.master_order?.id || res.id),
                           field(t('management.orders.orderList.name'), true, 'client_id.first_name'),
                           field(t('management.orders.orderList.mail'), true, 'client_id.email'),
                           field(t('management.orders.orderList.transactionStatus'), true, 'transaction_status', res => {
                            return <small>{res.transaction_status?.title ? res.transaction_status.title : t('management.orders.orderList.no')}</small>
                           }),
                           field(t('management.orders.orderList.paidFor'), true, 'has_paid', renderPaymentField),
                           field(t('management.orders.orderList.currency'), true, 'payment_currency'),
                           field(t('management.orders.orderList.paymentType'), true, 'created_at', res => datetime(res.created_at)),
                           field(t('management.orders.orderList.productType'), false, 'product_type', res => {
                               switch (res.product_type) {
                                   case "slots": return <small>{t('management.orders.orderList.slot')}</small>;
                                   case "arrivals": return <small>{t('management.orders.orderList.arrival')}</small>;
                                   case "groups": return  <small> {res.group_id.type === 'theory' ? t('management.orders.orderList.theory') : t('management.orders.orderList.lecture')}</small>;
                                   case "subscriptions": return <small>{t('management.orders.orderList.subscription')}: <b>{res.subscription_id?.nominal_value}</b></small>;
                                   case "certificates": return <small>{t('management.orders.orderList.certificate')}: <b>{res.certificate_id?.size}</b></small>;
                               }
                           }),
                           field(t('management.orders.orderList.product'), false, 'product_name', (res) => {
                               if (res.slot_id) {
                                   return <SlotHrefAdmin
                                       product={res.slot_id}
                                       linkName={res.slot_id.name}
                                   />;
                               }
                               if (res.arrival_id) {
                                   return <ArrivalHrefAdmin
                                       product={res.arrival_id}
                                       linkName={res.arrival_id.name}
                                   />;
                               }
                               if (res.group_id) {
                                   return <GenericHrefGenerator
                                       order={res}
                                       productUrl={res.group_id.url}
                                   />;
                               }
                               if (res.certificate_id) {
                                  return <CertificateHrefAdmin {...res.certificate_id} />
                               }

                               return res.subscription_id?.name;
                           }),
                       ]}
                       sortField='created_at'
                       sortDir='desc'
                       name='orders-list'
                       onClick={onClick}
                       onMiddleClick={onMiddleClick}
            />
          {user_id && <>
            <ExportByDirectionButton userId={user_id} />
            <Col xs={12}>
              <div className="field">
                <p>{t('management.orders.statistic.slots')}: {ordersStatistic.slots_count}</p>
                <ElementWithPermissions disabled={['EN']}>
                  <p>{t('management.orders.statistic.arrivals')}: {ordersStatistic.arrivals_count}</p>
                </ElementWithPermissions>
                <p>{t('management.orders.statistic.groups')}: {ordersStatistic.groups_count}</p>
                <p>{t('management.orders.statistic.moved')}: {ordersStatistic.moved_count}</p>
                <p>{t('management.orders.statistic.canceledRefunded')}: {ordersStatistic.canceled_count}</p>
              </div>
            </Col>
          </>}
        </TableCard>
    </>
})

export default OrdersList;