import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import React, {useState} from 'react';
import {t} from "i18next";
import {TableCard} from "../../generic/cards";
import {DataTable, field} from "../../generic/tables";
import useFilesApi from "./useFilesApi";
import {EditModal, ModalConfirmation} from "../../generic/modals";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import dayjs from "dayjs";
import {API_URL} from "../../../lib/api/useApi";
import {CopyLink} from "../../generic/buttons";
import {FaPlus} from "react-icons/fa";
import EditModalChildren from "./EditModalChildren";

const getFileSize = res => {
    if(isNaN(res.size)) return "";
    let label = "";
    if(res.size >= 100) label = (Math.round(res.size / 100) / 10) + ' MB';
    else label = Math.round(res.size) + ' KB';
    if(res.width && res.height) label += ` ${res.width}x${res.height}px`
    return label;
}
const getDownloadLink = url => API_URL + url;

const Files = withBreadcrumbs(({ entity }) => {
    const {findAndCount} = useFilesApi();
    const {deleteEntry} = useDefaultEndpoints('public-files');

    const [reload, setReload] = useState(false);
    const [ejectedFile, setEjectedFile] = useState();
    const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const toggleModalConfirmation = () =>
        setIsModalConfirmationOpen(!isModalConfirmationOpen);

    const onConfirmModal = () => deleteEntry(ejectedFile.id).then(toggleModalConfirmation);
    const onDeleteFile = (file) => {
        setEjectedFile(file);
        toggleModalConfirmation();
    };

    return <>
        <ModalConfirmation onClose={toggleModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('constructor.files.deleting')} text={t('constructor.files.areYouSure')}
                           cancelButtonText={t('common.cancel')} confirmButtonText={t('common.yesSure')}
                           onConfirm={() => { onConfirmModal().then(() => setReload(!reload)) }}
        />
        <EditModal entry={{ entity }} title={t('constructor.files.upload')} triggerModal={() => setIsEditModalOpen(!isEditModalOpen)}
                   entityName={'public-files'} isOpened={isEditModalOpen} EditForm={EditModalChildren} size={'sm'}
                   edit={true} onSuccessCb={() => setReload(!reload)} isFormData
        />
        <div className="justify-content-between mb-4">
            <button className={'btn btn-sm btn-primary btn-rounded'} onClick={() => setIsEditModalOpen(true)}>
                <FaPlus/>
                &nbsp; {t('constructor.files.upload')}
            </button>
        </div>
        <TableCard>
            <DataTable
                findAndCount={findAndCount}
                filters={{ entity }}
                fields={[
                    field(t('constructor.label'), true, 'name', res =>
                        <CopyLink href={getDownloadLink(res.url)} text={res.name} />
                    ),
                    field(t('constructor.files.size'), true, 'size', getFileSize),
                    field(t('constructor.files.image'), false, null, res => {
                        return res.url &&
                            <a target="_blank" href={getDownloadLink(res.url)}>
                                <img
                                  src={getDownloadLink(res.url)}
                                  alt="preview"
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = '/images/common/file.png';
                                  }}
                                />
                            </a>
                    }),
                    field(t('common.comment'), false, 'comment', res => <span>{res.provider_metadata?.comment}</span>),
                    field(t('constructor.createdAt'), true, 'updated_at',
                        res => dayjs(res.updated_at).format('DD.MM.YYYY HH:mm')),
                ]}
                name='files-list'
                reload={reload}
                sortField='created_at'
                sortDir='desc'
                onDelete={onDeleteFile}
            />
        </TableCard>
    </>
})

export default Files