import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useBasesApi = (isFormData = false) => {
  const defaultEventsApi = useDefaultEndpoints('bases', isFormData);
  const setInMenuVisibility = async (eventId, value) => defaultEventsApi.editEntry(eventId, {hide_in_menu: value});

    return {
        ...defaultEventsApi,
      setInMenuVisibility,
    }
};