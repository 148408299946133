import React from "react";
import {Col, FormGroup} from "reactstrap";
import {TextControllerRHF} from "../../../../generic/fields";
import {t} from "i18next";

const CardTemplateSection = ({control, errors, requiredRule}) => (
    <Col md={6} xs={12}>
        <FormGroup>
            <TextControllerRHF
                inputName={'card_template'} control={control} errors={errors}
                label={t('constructor.pages.cardTemplate')}
                isRequired defaultValue=''
                rules={{
                    ...requiredRule,
                    maxLength: {value: 200, message: t('common.forms.maxLength', {count: 200})}
                }}
                placeholder={t('constructor.pages.cardTemplatePlaceholder')}
            />
        </FormGroup>
    </Col>
);

export default CardTemplateSection;