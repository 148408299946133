import './style.scss';
import { generalHrefHoc } from './GeneralHrefComponent';
import ProductHrefAdmin from './ProductHrefAdmin';

const SlotHrefAdmin = ({ product, getParentEntry, ...rest }) => {
  const linkFun = async (preparedProductFunc) => {
    let preparedProduct = await preparedProductFunc();

    const event = await getParentEntry(product ? product.event_id : preparedProduct?.event_id?.id);
    return `/bases/${event?.base_id?.url}/event/${event.url}/slot/${preparedProduct?.url}`;
  }

  return <ProductHrefAdmin {...rest} linkFun={linkFun} product={product} />
}

const slotHrefHoc = (Component) => (props) => <Component
  {...props}
  name={props.linkName || 'слот'}
  parentEndpoint='events'
  endpoint='slots'
/>

export default slotHrefHoc(generalHrefHoc(SlotHrefAdmin));