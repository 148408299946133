import React from 'react';
import PropTypes from 'prop-types';
import {Controller, useForm} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import EasyEdit from "react-easy-edit";
import {FaCheck, FaTimes} from "react-icons/fa";


const Name = ({form, name, state, setState, isRequired = true, placeholder}) => {
    const {control, errors} = form;
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={state[name] || ''}
            rules={isRequired ? {required: 'Поле обязательно'} : {}}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid, isTouched, isDirty}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors}
                                     name={name} isRequired={isRequired}
                >
                    <div className={'display-3' + (invalid ? ' is-invalid ' : '')}
                    >
                        <EasyEdit
                            type="text"
                            onSave={(val) => {
                                onChange(val);
                                setState(val);
                            }}
                            value={value || ''}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            saveButtonLabel={<FaCheck fill={'success'} stroke={'success'}/>}
                            cancelButtonLabel={<FaTimes color={'secondary'}/>}
                        />
                    </div>
                </FieldWithValidation>
            }
        />
    );
};

Name.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string
};
export default Name;