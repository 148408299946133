import React from "react";
import {Col, FormGroup} from "reactstrap";
import {TimePickerRHF} from "../../../../common/reactHookFormsFields";
import { DatePickerRHF } from "../../../../generic/fields";

import {t} from "i18next";

const DateAndTimeSection = ({control, errors, requiredRule, setValue}) => (
    <>
        <Col md={6} xs={12}>
            <FormGroup>
                <DatePickerRHF
                    isRequired control={control} errors={errors} inputName={'start_date'}
                    label={t('constructor.templates.start_date')} rules={requiredRule} defaultValue=''
                />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup>
                <TimePickerRHF
                    control={control} errors={errors} isRequired defaultValue='' name="start_time"
                    label={t('constructor.templates.start_time')} setEntity={(value) => setValue('start_time', value)}
                />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup>
                <TimePickerRHF
                    control={control} errors={errors} isRequired defaultValue='' name="end_time"
                    label={t('constructor.templates.end_time')} setEntity={(value) => setValue('end_time', value)}
                />
            </FormGroup>
        </Col>
    </>
);

export default DateAndTimeSection;