import React from 'react'
import PropTypes from "prop-types";
import CommonData from "../EditModal/CommonData";

const InstructorData = ({entity, bases, form}) =>
    <CommonData entity={entity} form={form} bases={bases}/>


InstructorData.propTypes = {
    entity: PropTypes.object.isRequired,
    bases: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
}

export default InstructorData;

