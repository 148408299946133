import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import { ImCross, FaDownload } from 'react-icons/all';
import { previewImage } from '../../../generic/preview';

const settings = {
  dots: true,
  infinite: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true
};

const CarouselComponent = ({ items, onDelete, onDownload }) => {
  return (
    <Slider {...settings}>
      {items.map((i, index) => {
        return (
          <div className='slider-item' key={index}>
            <button type='button' className='deleteEntity' onClick={() => onDelete(i, index)}>
              <ImCross className='deleteEntity_svg' />
            </button>
            <a
              href={i.preview}
              target='_blank'
              className='downloadEntity'
              onClick={e => {
                if (onDownload) {
                  e.preventDefault();
                  onDownload(i);
                }
              }}
            >
              <FaDownload className='downloadEntity_svg' />
            </a>
            {previewImage({ ...i, style: { width: 100, height: 100, className: 'slider-image' }, className: 'slider-image' })}
          </div>
        )
      })}
    </Slider>
  );
}

export default CarouselComponent;