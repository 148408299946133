import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-month-picker'

import 'react-day-picker/lib/style.css';
import 'react-month-picker/css/month-picker.css';
import {Input} from "reactstrap";

const months = ['Jan', 'Feb', 'Mar', 'Spr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const years = () => {
    let years = [];
    for (let i = 2019; i <= new Date().getFullYear(); i++) years.push(i)
    return years;
}


const makeText = (year, month) => (year && month) ? (year + '.' + ("0" + month).slice(-2)) : '';

export default class MonthPicker extends React.Component {

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.showMonthPicker = this.showMonthPicker.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.pickerRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDay !== prevProps.selectedDay)
            this.setState({
                selectedDay: this.props.selectedDay
            });
    }

    handleDayClick(date) {
        this.setState({
            selectedDay: date
        });
        if (this.props.onDateChange) this.props.onDateChange(date);
    }

    onTextChange(text) {
        const splitted = text.split(".");
        if(splitted.length !== 2) return;
        const year = parseInt(splitted[0]);
        const month = parseInt(splitted[1]);
        if(month > 0 && month <= 12 && year > 1900 && year < 2090)
            this.props.onMonthChange(year, month);
    }

    showMonthPicker() {
        this.pickerRef.current.show();
    }

    render() {

        const {year, month} = this.props;

        return (
            <Picker
                lang={months}
                ref={this.pickerRef}
                value={{year, month}}
                onChange={this.props.onMonthChange}
                years={years()}
            >
                <Input
                    value={makeText(year, month)}
                    onChange={(e) => this.onTextChange(e.target.value)}
                    onKeyDown={this.showMonthPicker}
                    onFocus={this.showMonthPicker}
                    placeholder="Choose Month ..."
                />
            </Picker>
        );
    }
}

MonthPicker.propTypes = {
    onMonthChange: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired
};