import React, {useEffect} from 'react';
import {Controller} from "react-hook-form";
import FieldWithValidation from "./FieldWithValidation";
import PropTypes from 'prop-types';

const TextControllerRHF = ({
                                 inputName,
                                 control,
                                 defaultValue = '',
                                 rules,
                                 errors,
                                 label,
                                 setValue,
                                 placeholder,
                                 isRequired,
                                setValueRHF,
                                type='text',
                                isDeleteSpaces,
                                 ...rest
                             }) => {

    useEffect(() => {
        if (setValueRHF)
            setValueRHF(inputName, defaultValue)
    }, [defaultValue])

    return (
        <Controller
            control={control}
            name={inputName}
            defaultValue={defaultValue}
            rules={rules}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors} label={label}
                                            name={name} isRequired={isRequired}
                                            type={type} onBlur={onBlur} value={value} placeholder={placeholder}
                                            onChange={e => {
                                                if (isDeleteSpaces) {
                                                    e.target.value = e.target.value.replace(" ", "")
                                                }
                                                onChange(e.target.value);
                                                setValue && setValue(e);
                                            }}
                                            {...rest}
                />
            }
        />
    )
}

TextControllerRHF.propTypes = {
    inputName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any.isRequired,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    errors:  PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    label: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    isRequired: PropTypes.bool,
}

export default TextControllerRHF;
