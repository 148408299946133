import React from 'react';
import './statusIndicator.scss'
import {t} from "i18next";

function LegendIndicator() {
    return (
        <div className={'wrapper-legend'}>
            <div className={'legend-title'}>
                <p>{t('adventures.statusIndicator.title')}</p>
            </div>
            <div className={'legend-paragraph'}>
                <span className={'indicator indicator-red legend-span'}></span>
                <p>– {t('adventures.statusIndicator.red')}</p>
            </div>
            <div className={'legend-paragraph'}>
                <span className={'indicator indicator-yellow legend-span'}></span>
                <p>– {t('adventures.statusIndicator.yellow')}</p>
            </div>
            <div className={'legend-paragraph'}>
                <span className={'indicator indicator-green legend-span'}></span>
                <p>– {t('adventures.statusIndicator.green')}</p>
            </div>
        </div>
    )
}

export default LegendIndicator;