import React, {useState, useEffect} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {InstructorsList} from "../../../administration";
import InstructorAttachModal from "./InstructorAttachModal";
import {useInstructorsApi} from "../../../../lib/api/instructors";
import {t} from "i18next";

const ViewModal = ({state, isOpened, triggerModal, onClick}) => {
    const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState(undefined);
    const [instructorState, setInstructorState] = useState(undefined);
    const triggerConfirmModal = () => toggleConfirmModal(isModalOpen => !isModalOpen);
    const {checkIfInstructorAvailable} = useInstructorsApi();
    const attachInstructor = () => onClick(selectedInstructor)
    useEffect(() => {
        if (selectedInstructor?.id !== state)
        if (selectedInstructor?.id) {
            checkIfInstructorAvailable(state, selectedInstructor.id, state.event_id.base_id).then(res => {
                if (res.status === 'free') onClick(selectedInstructor); // Просто прикрепить
                if (res.status === 'activeInSlot') {
                    setInstructorState(res.slot)
                    triggerConfirmModal()
                    return;
                }
                setInstructorState(res.status)
                triggerConfirmModal()
            })
        }
    } , [selectedInstructor?.id])

    return (
        <Modal isOpen={isOpened} toggle={triggerModal} className={'edit-modal'} size={'lg'}>
            {isConfirmModalOpen && instructorState && <InstructorAttachModal instructorState={instructorState} onConfirm={attachInstructor} triggerModal={triggerModal} isOpened={isConfirmModalOpen}/>}

            <ModalHeader>{t('bases.base.pickInstructor')}</ModalHeader>
            <ModalBody>
               <InstructorsList baseFilter={state.event_id.base_id} isInline onClick={i => setSelectedInstructor(() => i)} boatFilter={state.event_id?.boat_class}/>
            </ModalBody>
            <ModalFooter>
                <div>
                    <Row>
                        <Col>
                            <Button block color="primary" outline onClick={triggerModal}>
                                {t('bases.base.close')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ViewModal;

ViewModal.propTypes = {
    state: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
}
