import React, {useState} from 'react';
import {TableCard} from "../../generic/cards";
import {useDefaultEndpoints} from "../../../lib/api/useDefaultEndpoints";
import {DataTable, field} from "../../generic/tables";
import {Col, FormGroup, Input, Label} from "reactstrap";
import {useHistory} from 'react-router-dom';
import {TextSearch} from "../../generic/fields";
import {datetime} from "../../../lib/utils/format";
import {openInNewTab} from "../../../lib/utils/helper";
import {PRODUCT_TYPES, PRODUCT_TYPES_LANG_ADAPTER} from "sv-common/constants/certificates";
import {FaPlus} from "react-icons/fa";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../common/formElements";
import i18n from "../../../lib/utils/i18n";
import {t} from "i18next";

const ViolationsList = withBreadcrumbs(({}) => {
    const {findAndCountEntries} = useDefaultEndpoints('violations');
    const [productFilter, setProductFilter] = useState('');
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [surname, setSurname] = useState(undefined);
    const permissions = useRoleSystem(ENTITIES.BLACKLIST);
    let history = useHistory();
    const { language } = i18n;

    const filters = {
        'client_id.first_name_contains': firstName,
        'client_id.last_name_contains': lastName,
        'client_id.surname_contains': surname,
        product_type: productFilter
    }

    const onMiddleClick = (res) => openInNewTab('/administration/violations/edit/' + res.id)

    return <>
        <TableCard>
            <div className={'row justify-content-between mb-0 pr-0 mr-0 align-items-end'}>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>{t('management.violations.surname')}</Label>
                        <TextSearch placeholder={t('management.violations.surnamePlaceholder')} value={lastName || ''}
                                    onSearch={v => setLastName(() => v)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>{t('management.violations.name')}</Label>
                        <TextSearch placeholder={t('management.violations.namePlaceholder')} value={firstName || ''}
                                    onSearch={v => setFirstName(() => v)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                    <FormGroup>
                        <Label>{t('management.violations.patronymic')}</Label>
                        <TextSearch placeholder={t('management.violations.patronymicPlaceholder')} value={surname || ''}
                                    onSearch={v => setSurname(() => v)}/>
                    </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <Label>{t('management.violations.productType')}</Label>
                        <Input type={'select'} className="form-control" value={productFilter || ''} bsSize={'sm'}
                               onChange={(e) => setProductFilter(() => e.target.value)}>
                            <option value=''>{t('management.violations.chooseProduct')}</option>
                            {Object.values(PRODUCT_TYPES).filter(v => !!PRODUCT_TYPES_LANG_ADAPTER[language][v])
                                .map(type => <option key={type} value={type}>{PRODUCT_TYPES_LANG_ADAPTER[language][type]}</option>)}
                        </Input>
                    </FormGroup>
                </Col>

            </div>
            <div className={'row justify-content-end mb-0 pr-0 mr-0'}>
                <FormGroup>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <button className={'btn btn-sm btn-primary btn-rounded btn-block'}
                                onClick={() => history.push('/administration/violations/edit/')}>
                            <FaPlus/>
                            &nbsp; {t('management.violations.createButton')}
                        </button>
                    </ElementWithPermissions>
                </FormGroup>
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.violations.violation'), true, "violation"),
                           field(t('management.violations.name'), false, "client_id.first_name",
                                   res => res.client_id?.first_name || ''),
                           field(t('management.violations.surname'), false, "client_id.last_name",
                               res => res.client_id?.last_name || ''),
                           field(t('management.violations.patronymic'), false, "client_id.surname",
                               res => res.client_id?.surname || '' ),
                           field(t('management.violations.manager'), true, "admin_id.email"),
                           field(t('management.violations.product'), false, "product_name",
                               res => PRODUCT_TYPES_LANG_ADAPTER[language][res.product_type]),
                           field(t('management.violations.date'), true, "created_at", res => datetime(res.created_at)),
                       ]}
                       sortField="created_at"
                       sortDir="desc"
                       name="violations-list"
                       onClick={violation =>  history.push('/administration/violations/edit/' + violation.id)}
                       onMiddleClick={violation => onMiddleClick(violation)}
            />
        </TableCard>
    </>
})

ViolationsList.propTypes = {}

export default ViolationsList;

