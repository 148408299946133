import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";
import qs from 'qs';

export const useEventsApi = () => {
    const {request} = useApi();
    const defaultEventsApi = useDefaultEndpoints('events');

    const setPromocodesVisible = async (eventId, value) => defaultEventsApi.editEntry(eventId, {show_promocodes: value});
    const findAndCountWithSlotFiltration = async (params) => {
        const query = qs.stringify(params);
        return request('get', `/events/findAndCountWithSlotFiltration?${query}`)
    };
    const setAdminVisibility = async (eventId, value) => defaultEventsApi.editEntry(eventId, {show_in_admin: value});


    return {...defaultEventsApi, setPromocodesVisible, findAndCountWithSlotFiltration, setAdminVisibility}
};