import { Col, Form, FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { t } from 'i18next';
import FieldWithValidation from '../../generic/fields/FieldWithValidation';

const Modal = ({entity, setEntity, control, errors, handleInput}) => {
  return (
    <Form>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="title"
            defaultValue={entity.title || ''}
            rules={{required: t('inputs.validation.required')}}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) =>
              <FieldWithValidation
                invalid={invalid}
                errors={errors}
                label={t('directories.transactionStatus.title')}
                name={name} isRequired={true}
                type="text" onBlur={onBlur} value={value}
                onChange={e => onChange(handleInput(e, setEntity).value)}
              />
            }
          />
        </FormGroup>
      </Col>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="type"
            defaultValue={entity.type || ''}
            rules={{required: t('inputs.validation.required')}}
            render={(
              {value, name},
            ) =>
              <FieldWithValidation
                disabled={true}
                label={t('directories.transactionStatus.title')}
                name={name}
                type="text"
                value={value}
                errors={errors}
              />
            }
          />
        </FormGroup>
      </Col>
    </Form>
  )
}

export default Modal;