import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useBoatsClassesApi = () => {
    const {request} = useApi();
    const defaultClassesPlaces = useDefaultEndpoints('boats-classes');

    const getBoatsPlaces = async (boatClass) => request('get', '/boats-classes/places/' + boatClass);

    return {...defaultClassesPlaces, getBoatsPlaces}
};