import DirectoryTemplate from '../../directories/DirectoryTemplate';
import { ENTITIES, getPermissions } from '../../../lib/utils/hooks/useRoleSystem';
import { field } from '../../generic/tables';
import { t } from 'i18next';
import { Badge } from 'reactstrap';
import EditModalChildren from './EditModalChildren';
import { CopyLink } from '../../generic/buttons';
import { BOOKING_URL } from '../../../lib/api/useApi';

const CorpClients = (props) => <DirectoryTemplate
  fields={[
    field(t('constructor.corpClients.title'), true, "title"),
    field(t('constructor.corpClients.slug'), true, "slug"),
    field(t('constructor.corpClients.events'), true, "events", (row) => {
      return row.events?.map(i => (
        <Badge
          className={'mr-2'}
          key={i.id}
          color={"success"}
        >
          {i.name}
        </Badge> || null)
      )
    }),
    field(t('constructor.extraMaterials.link'), false, "url", (row) => <CopyLink showLink={false} href={`${BOOKING_URL}/sport-corp/${row.slug}`}/>),
  ]}
  entityName='corp-clients'
  EditModalChildren={EditModalChildren}
  editable
  deletable
  permissions={getPermissions(ENTITIES.CORP_CLIENTS)}
  {...props}
/>

export default CorpClients;