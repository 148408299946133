import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from '../../../lib/utils/helper';
import { useDefaultEndpoints } from '../../../lib/api/useDefaultEndpoints';

const NewTabHref = ({ link, name }) => {
  let history = useHistory();

  const handleLink = (callback) => {
      if (typeof link === 'function') {
          link().then(callback);
      } else {
          callback(link);
      }
  }

  const onClick = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();
    e.stopPropagation();
    handleLink(history.push);
  }

  const onMouseUp = (e) => {
    if ( e.button === 1 || ((e.ctrlKey ||  e.metaKey) && e.button === 0))  {
      e.preventDefault();
      e.stopPropagation();
      handleLink(openInNewTab);
    }
  }

  return <span className={'link'} onClick={onClick} onMouseUp={onMouseUp} onMouseDown={e => e.preventDefault()}>
    {name}
  </span>
}

export const generalHrefHoc = (Component) => {
  return ({ endpoint, parentEndpoint, ...rest }) => {
    const {getEntry, getEntries} = endpoint && useDefaultEndpoints(endpoint) || {};
    const getParentEntry = parentEndpoint && useDefaultEndpoints(parentEndpoint).getEntry;

    return <Component
      {...rest}
      getParentEntry={getParentEntry}
      getEntry={getEntry}
      getEntries={getEntries}
    />
  }
}

export default NewTabHref;