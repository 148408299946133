import React from 'react';
import {Button, Card, CardBody, CardImg, CardImgOverlay, CardText, CardTitle} from "reactstrap";
import {FaFile} from "react-icons/fa";
import {BsGear} from "react-icons/bs";
import {FcEmptyTrash} from "react-icons/fc";
import {API_URL} from "../../../lib/api/useApi";
import PropTypes from 'prop-types';
import './style.scss'
import {LoadBadge} from "../../generic/badges";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import {ElementWithPermissions} from "../../common/formElements";
import {t} from "i18next";
import {AdventureRemoveRuleProvider} from "../RuleProvider";

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    months: [
        'adventures.adventureCard.january', 'adventures.adventureCard.february', 'adventures.adventureCard.march',
        'adventures.adventureCard.april', 'adventures.adventureCard.may', 'adventures.adventureCard.june',
        'adventures.adventureCard.july', 'adventures.adventureCard.august', 'adventures.adventureCard.september',
        'adventures.adventureCard.october', 'adventures.adventureCard.november', 'adventures.adventureCard.december'
    ]
})
const mockFunc = () => {
};

const generateCardBody = (type, adventure) => {
    if (type === 'arrivalFree')
        return ''
    if (type === 'archived' || type === 'past')
        return <>
            {adventure.last_arrival
                ? t(dayjs(adventure.last_arrival.start_date, 'YYYY-MM-DD').format('MMMM'))
                : ''} - {
            adventure.last_arrival
                ? t(dayjs(adventure.last_arrival.end_date, 'YYYY-MM-DD').format('MMMM'))
                : ''
            }
            <LoadBadge value={adventure.average_load}/>
        </>
    if (type === 'current')
        return <>
            {t(dayjs(adventure.next_arrival?.start_date, 'YYYY-MM-DD').format('MMMM'))} - {t(dayjs(adventure.next_arrival?.end_date, 'YYYY-MM-DD').format('MMMM'))}
            <LoadBadge value={adventure.current_load}/>
            <div>{adventure.booked_arrivals_sits && adventure.total_arrivals_sits ? adventure.booked_arrivals_sits + "/" + adventure.total_arrivals_sits : "-"}</div>
        </>
    return <>
        Октябрь - ноябрь
        <LoadBadge value={100}/>
    </>
};

const AdventureCard = ({
                           adventure, type, cloneEntry = mockFunc, deleteEntry = mockFunc, editEntry = mockFunc, height,
                           onClick = undefined, onMouseUp = undefined, children, hasOverlay,
                           permissions, typeForRule
                       }) => {
    return (
        <Card style={{height: height || '340px'}} className={'adventure_card ' + (onClick ? 'clickable' : '')}
              onClick={e => e.target.localName === 'div' && onClick && onClick(e)}
              onMouseUp={e => e.target.localName === 'div' && onMouseUp && onMouseUp(e)}>
            <CardImg top width="100%" style={{maxHeight: '200px'}}
                     src={API_URL + adventure?.img?.url || "https://via.placeholder.com/200x100"}
                     alt="Card image cap"
            />
            {hasOverlay && <CardImgOverlay name={'adventure_card-overlay'}>
                <div className="badge badge-primary badge-pill">
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button title={t('adventures.adventureCard.copyAdventure')} className={'p-0 m-0'} color={'primary'}
                                onClick={() => cloneEntry(adventure)}>
                            <FaFile/>
                        </Button>
                    </ElementWithPermissions>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button title={t('adventures.adventureCard.editAdventure')} className={'p-0 m-0'}
                                color={'primary'} onClick={() => editEntry(adventure)} name={'test'}>
                            <BsGear/>
                        </Button>
                    </ElementWithPermissions>
                    <AdventureRemoveRuleProvider typeForRule={typeForRule}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button title={t('adventures.adventureCard.deleteAdventure')} className={'p-0 m-0'}
                                    color={'primary'} onClick={() => {
                                deleteEntry(adventure)
                            }}>
                                <FcEmptyTrash/>
                            </Button>
                        </ElementWithPermissions>
                    </AdventureRemoveRuleProvider>
                </div>
            </CardImgOverlay>}
            <CardBody>
                {children ? children : <>
                    <CardTitle tag="h5" style={{height: '40px'}}>{adventure.name}</CardTitle>
                    <CardText>
                        {generateCardBody(type, adventure)}
                    </CardText>
                </>}
            </CardBody>
        </Card>
    )
}


export default AdventureCard;

AdventureCard.propTypes = {
    adventure: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['current', 'past', 'archived', 'arrivalFree']),
    cloneEntry: PropTypes.func,
    deleteEntry: PropTypes.func,
    editEntry: PropTypes.func,
    hasOverlay: PropTypes.bool,
    productType: PropTypes.string,
};
