import dayjs from "dayjs";
import {Col, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import {BiCake} from "react-icons/bi";
import React from "react";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import {FaCopy} from "react-icons/all";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {withLinkPhone} from "../../../../../lib/utils/helpers/interfaceFeatures";
import {t} from "i18next";

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const showSuccessToast = e => toast(e, {type: 'success'});

export const showOrderDynamicRow = (fieldKey, order, isShowBirthDate) => {
    const { paid_for_ids, id } = order;

    const {start_date, end_date} = order?.arrival_id || order?.slot_id || order?.group_id;

    let startDate;
    let endDate;

    const isUserBirthday = (userBirthDate) => {
        if (!userBirthDate) return false;
        startDate = dayjs(start_date)
        endDate = dayjs(end_date)
        userBirthDate = dayjs(userBirthDate)

        return (
            (dayjs(userBirthDate.set('y', startDate.get('y'))) .isBetween(startDate, endDate, 'day', '[]')
                || dayjs(userBirthDate.set('y', endDate.get('y'))).isBetween(startDate, endDate, 'day', '[]'))
        )
    }

    return <>
        {paid_for_ids.map((i, index) => {
            return (
                <>
                    <Row>
                        <Col xs={12}>
                            <div className="d-flex align-items-center">
                                {withLinkPhone(i[fieldKey], fieldKey)}
                                {isShowBirthDate && isUserBirthday(i?.birthdate) && <>
                                    <BiCake className="ml-2" color="#eb3464"
                                            id={`popover-target-${i?.id }-${id ? `${id}` : ''}`}
                                    />
                                    <UncontrolledPopover placement="bottom" trigger={'hover'}
                                                         target={`popover-target-${i?.id }-${id ? `${id}` : ''}`}
                                    >
                                        <PopoverBody>{i?.birthdate}</PopoverBody>
                                    </UncontrolledPopover>
                                </>}
                            </div>
                        </Col>
                    </Row>
                    {paid_for_ids[index+1] && <hr className={'mt-2 mb-2'}/>}
                </>
            )
        })}
    </>
}


export const showWithCopy = (text) => {
    return <>
        {text}
        <CopyToClipboard text={text}
                         onCopy={() => showSuccessToast(t('common.textCopied'))}>
            <button className="btn btn-link p-0 mb-1 ml-1" onSubmit={e => e?.preventDefault()}
                    onClick={e => e?.preventDefault()}><FaCopy/></button>
        </CopyToClipboard>
    </>
}