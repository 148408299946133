import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import {FaPlus} from "react-icons/fa";
import ExtraMaterialsModal from "../../../../common/formElements/AdditionalExtraMaterials/ExtraMaterialsModal";
import ExtraMaterialsTable from "../../../../common/formElements/AdditionalExtraMaterials/ExtraMaterialsTable";
import {t} from "i18next";


const AdditionalInfo = ({state, setState}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {setIsOpen(isOpen => !isOpen);};

    return (
        <Row>
            <Col xs={12}>
                <div className="table-responsive overflow-hidden">
                    <Row>
                        <Col xs={8}>
                            <label>{t('school.theory.editModalChildren.additionalInfo')}</label>
                        </Col>
                        <Col>
                         <span className={'btn btn-primary btn-rounded btn-sm'}
                           onClick={() => toggle()}><FaPlus size={'1em'}/></span>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            {isOpen && <ExtraMaterialsModal state={state} setState={setState} toggle={toggle}/>}
                            <ExtraMaterialsTable state={state} setState={setState} />
                        </Col>
                    </Row>

                </div>
            </Col>
        </Row>
    )
}

export default AdditionalInfo;