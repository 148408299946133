import RolesSelect from './RolesSelect';
import { useDefaultEndpoints } from '../../../lib/api/useDefaultEndpoints';
import { useState } from 'react';

const RolesSelectField = ({ id, roles, allRoles }) => {
  const [rolesState, setRolesState] = useState(roles || []);
  const { editEntry } = useDefaultEndpoints('frontend-roles');

  const onChange = (v) => {
    editEntry(id, { sub_roles: v }).then((s) => {
      setRolesState(s.sub_roles);
    });
  }

  return (
    <div onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }}>
      <RolesSelect
        allRoles={allRoles}
        onChange={onChange}
        value={rolesState}
      />
    </div>
  )
}

export default RolesSelectField;