import React,  {useState} from "react";
import BoatsCounter from "../../../../slot/BoatsCounter";
import {useSlotsApi} from "../../../../../../lib/api/slots";
import {LoadBadge} from "../../../../../generic/badges";
import useEditTable from "../../../../../../lib/utils/hooks/useEditTable";
import computeSits from "../../../../slot/computeSits";

const BoatsLimitCounter = ({id, totalSits,  availableSits, boatsAmount, boatClass, bookedToTotal, bookedSits, isAddedNewOrder, reservedSits}) => {
    const [amount, setAmount] = useState(boatsAmount)
    const [boatsTotalSits, setBoatsTotalSits] = useState(totalSits)
    const [serverAmount, setServerAmount] = useState(boatsAmount);
    const [serverBoatsTotalSits, setServerBoatsTotalSits] = useState(totalSits);

    const {editEntry} = useSlotsApi()

    const beforeDebounceCallback = ({amount}) => {
        const data = computeSits({
            val: amount,
            totalSits: totalSits,
            availableBoats: boatsAmount,
            availableSits: availableSits
        })
        setBoatsTotalSits(data.total_sits)

        return data
    }

    const debounceCallback = (data) => editEntry(id, data)

    const debounceErrorCallback = () => {
        setBoatsTotalSits(serverBoatsTotalSits);
        setAmount(serverAmount);
    }

    const debounceSuccessCallback = (data) => {
      setServerAmount(data.available_boats);
      setServerBoatsTotalSits(data.total_sits);
    }

    const {loading} = useEditTable({
        beforeDebounceCallback,
        debounceCallback,
        debounceErrorCallback,
        debounceSuccessCallback,
        payload: {amount, boatsTotalSits},
        deps: [amount],
        callbackDeps: [serverAmount, serverBoatsTotalSits],
    })

    const actualReserved = reservedSits - bookedSits;

    return <div className={'d-flex align-items-center justify-content-end'}
                style={{position: 'relative', opacity: loading ? 0.5 : 1}}>
        <div className={'fa'} style={{display: loading ? 'block' : 'none',
            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}} ></div>
        <div className={`mr-3 d-flex align-items-center`}>
            <div className={isAddedNewOrder ? 'is-added-new-order' : ''}>
                {`${bookedSits} ${Boolean(actualReserved) ? `(${actualReserved})` : ''} / ${boatsTotalSits}`}
            </div>
            <LoadBadge value={bookedToTotal || 0} colorBreakpoint={67}/>
        </div>
        <BoatsCounter onClickPlus={() => setAmount(amount + 1)}
                      onClickMinus={() => setAmount(amount - 1 || 1)}
                      boatsAmount={amount}
                      boatClass={boatClass}
                      setValue={setAmount}
                      loading={loading} />
    </div>
}

export default BoatsLimitCounter;