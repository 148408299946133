import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {t} from "i18next";

const City = ({form, state, setState, isRequired = true}) => {
    const {control, errors} = form;
    return (
            <Controller
                control={control}
                name="city"
                defaultValue={state.city || ''}
                rules={isRequired ? {required: t('inputs.validation.required')} : {}}
                render={(
                    {value, onBlur, name, ref, onChange},
                    {invalid}
                ) =>
                    <FieldWithValidation invalid={invalid} errors={errors} label={t('common.forms.city.label')}
                                         name={name} isRequired={isRequired} type="text" onBlur={onBlur} value={value}
                                         placeholder={t('common.forms.city.placeholder')}
                                         onChange={e => {
                                             setState(e)
                                             onChange(e.target.value)
                                         }}
                    />
                }
            />
    );
}

City.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};
export default City;