import { FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { t } from 'i18next';
import FieldWithValidation from '../../generic/fields/FieldWithValidation';
import React from 'react';

const TextAreaJsonBlock = ({ control, dispatch, defaultValue, required, name, label, placeholder, errors }) => {
  const setData = (e) => dispatch({type: 'setFormData', payload: e})

  const valueConverter = value => {
    if (typeof value === 'object')
      return value.join('\n');
    else
      return value
  }

  return (
    <FormGroup>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={required && {required: t('inputs.validation.required')}}
        render={(
          {value, onBlur, name, ref, onChange},
          {invalid}
        ) =>
          <FieldWithValidation invalid={invalid} errors={errors} label={label}
                               name={name} isRequired={true} type="textarea" onBlur={onBlur}
                               placeholder={placeholder}
                               style={{height: '10em'}}
                               onChange={e => {
                                 setData(e);
                                 onChange(e.target.value);
                               }}
                               value={valueConverter(value)}
          />


        }
      />
    </FormGroup>
  )
}

export default TextAreaJsonBlock;