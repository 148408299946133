import React from 'react';
import {Col, FormGroup, Row} from 'reactstrap';
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../../generic/fields/FieldWithValidation";

import {
    LegalEntitiesRHF,
    PriceCurrencyRHF,
    PublicContractsRHF,
    UrlRHF
} from "../../../../../common/reactHookFormsFields";
import {TextControllerRHF} from "../../../../../generic/fields";
import {getNonEmpty} from "sv-common/functions/formatters";
import {t} from "i18next";
import CheckSelector from '../../../../../generic/fields/CheckSelector';
import { CHECK_TYPES } from 'sv-common/constants/checks';

const TechInfo = ({state, dispatch, control, errors}) => {
    const setFormData = (e) => dispatch({type: 'setFormData', payload: e})
    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                          <TextControllerRHF
                            inputName={'total_sits'}
                            setValue={setFormData}
                            label={t('school.theory.techInfo.placeAmount')}
                            control={control}
                            errors={errors}
                            defaultValue={state.total_sits || ''}
                            placeholder={'10'}
                            isRequired={true}
                            rules={{required: t('inputs.validation.required')}}
                            info={t('common.totalSitsDecrease')}
                          />
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <Controller
                                control={control}
                                name="price_value"
                                defaultValue={getNonEmpty(state?.price_value)}
                                rules={{required: t('inputs.validation.required')}}
                                render={(
                                    {value, onBlur, name, ref, onChange},
                                    {invalid}
                                ) =>
                                    <FieldWithValidation invalid={invalid} errors={errors} label={t('school.theory.techInfo.price')}
                                                         name={name} isRequired
                                                         type="text" onBlur={onBlur} value={value} placeholder="1000"
                                                         onChange={e => {
                                                             setFormData(e);
                                                             onChange(e.target.value)
                                                         }}
                                    />
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={4}>
                        <FormGroup>
                            <PriceCurrencyRHF form={{control, errors}} setState={setFormData} state={state} isRequired/>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <LegalEntitiesRHF form={{errors, control}} setState={setFormData} state={state} isRequired/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <UrlRHF state={state} setState={setFormData} form={{errors, control}}
                            label={t('school.theory.techInfo.groupURL')}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <PublicContractsRHF form={{errors, control}} setState={setFormData} state={state}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'tg_link'}
                                       setValue={setFormData}
                                       label={t('school.theory.techInfo.telegramLink')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.tg_link || ''}
                                       placeholder={'https://t.me/...'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'platform_url'}
                                       setValue={setFormData}
                                       label={t('school.theory.techInfo.platformLink')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.platform_url || ''}
                                       placeholder={'https://...'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'platform_auth_code'}
                                       setValue={setFormData}
                                       label={t('school.theory.techInfo.platformCode')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.platform_auth_code || ''}
                                       placeholder={'1234'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'quiz_url'}
                                       setValue={setFormData}
                                       label={t('school.theory.techInfo.quizLink')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.quiz_url || ''}
                                       placeholder={'https://...'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'url_calendar'}
                                       setValue={setFormData}
                                       label={t('school.theory.techInfo.calendarLink')}
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.url_calendar || ''}
                                       placeholder={'https://...'}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
              <CheckSelector
                control={control}
                dispatch={dispatch}
                defaultValue={state.check_id}
                productName={state.name}
                errors={errors}
                checkTypes={CHECK_TYPES}
                deps={[state.name]}
              />
            </Col>
        </Row>
    )
}

export default TechInfo;