import React, {useEffect, useState} from 'react';
import {TableCard} from '../../generic/cards'
import {useDefaultEndpoints} from '../../../lib/api/useDefaultEndpoints'
import {DataTable, field} from '../../generic/tables'
import {FaPlus} from 'react-icons/fa';
import {Button, Col, FormGroup} from 'reactstrap'
import {useHistory} from 'react-router-dom';
import {TextSearch} from "../../generic/fields";
import PropTypes from 'prop-types';
import {ModalConfirmation} from "../../generic/modals";
import {openInNewTab} from "../../../lib/utils/helper";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {GiMagnifyingGlass} from "react-icons/gi";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const PromocodePackagesList = withBreadcrumbs(({
                                                   isInline = false, isView = false,
                                                   onClick
                                               }) => {
    const {findAndCountEntries, deleteEntry} = useDefaultEndpoints('promocodes-packages');
    const [reload, setReload] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const permissions = useRoleSystem(ENTITIES.PROMOCODE_PACKAGES);
    const toggleModal = () => setModalOpen(isOpen => !isOpen);
    let history = useHistory();
    const filters = {}
    const [nameFilter, setNameFilter] = useState(undefined)

    if (nameFilter)
        filters.name_contains = nameFilter;

    const onMiddleClick = (res) => openInNewTab('/administration/promocodes-packages/edit/' + res.id)

    return <>
        <TableCard isInline={isInline}>
            {isModalOpen && <ModalConfirmation onClose={toggleModal} isOpen={isModalOpen}
                                               onConfirm={() => deleteEntry(selectedPackage.id).then(res => {
                                                   toggleModal();
                                                   setReload(reload => !reload);
                                               })}
                                               confirmButtonText={t('management.promoCodePackages.confirmDelete')}
                                               text={t('management.promoCodePackages.deleteAnswer')}
                                               title={t('management.promoCodePackages.deleteTitle')}
            />}
            <div className={'row justify-content-between mb-0 pr-0 mr-0'}>
                <Col xs={12} md={2}>
                    <FormGroup>
                        <TextSearch placeholder={'MEGAFON'} value={nameFilter || ''}
                                    onSearch={e => setNameFilter(() => e)}/>
                    </FormGroup>
                </Col>
                {!isView && <div className={'col-auto'}>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <button className={'btn btn-sm btn-primary btn-rounded'}
                                onClick={() => history.push('/administration/promocodes-packages/edit/')}>
                            <FaPlus/>
                            &nbsp; {t('management.promoCodePackages.create')}
                        </button>
                    </ElementWithPermissions>
                </div>}
            </div>
            <DataTable findAndCount={findAndCountEntries}
                       filters={filters}
                       fields={[
                           field(t('management.promoCodePackages.packageName'), true, 'prefix'),
                           field(t('management.promoCodePackages.packageSize'), true, 'size'),
                           field(t('management.promoCodePackages.creator'), false, "creator.email"),
                       ]}
                       sortField='created_at'
                       sortDir='desc'
                       name='promocodes-list'
                       onClick={res => onClick
                           ? onClick(res)
                           : history.push('/administration/promocodes-packages/edit/' + res.id)
                       }
                       onMiddleClick={res => !onClick && onMiddleClick(res)}
                       reload={reload}
                       additionalButtons={res =>
                           <Button title={t('management.promoCodePackages.searchPromoCodes')} color="primary" size={'sm'}
                                   onClick={() => history.push('/administration/promocodes/?name=' + res.prefix)}><GiMagnifyingGlass/></Button>
                       }
                       onDelete={permissions.edit
                           ? res => {
                               setSelectedPackage(() => res);
                               toggleModal();
                           }
                           : null}
            />
        </TableCard>
    </>
})

export default PromocodePackagesList;

PromocodePackagesList.propTypes = {
    isInline: PropTypes.bool,
    isView: PropTypes.bool,
    onClick: PropTypes.func
}