import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {UrlRHF} from "../../../../common/reactHookFormsFields";
import {BOOKING_URL} from "../../../../../lib/api/useApi";
import {CopyLink} from "../../../../generic/buttons";
import {ModalConfirmation} from "../../../../generic/modals";
import {useHistory} from "react-router-dom";
import {TextControllerRHF} from "../../../../generic/fields";
import {CHECK_TYPES} from "sv-common/constants/checks";
import CheckSelector from "../../../../generic/fields/CheckSelector";
import {ENTITIES, useRoleSystem} from "../../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../../common/formElements";
import {t} from "i18next";
import ManagerComments from '../../../../common/formElements/ManagerComments';

const ExtraInfo = ({state, dispatch, control, errors}) => {
    const {getEntries} = useDefaultEndpoints('public-contracts');
    const [publicContracts, setPublicContracts] = useState([]);
    const EVENT_URL = `${state.base_id?.booking_url || BOOKING_URL}/sport/${state.url}`
    useEffect(() => {
        getEntries({}).then(res => setPublicContracts(() => res))
    }, [])
    let history = useHistory();
    const {deleteEntry} = useDefaultEndpoints('events');
    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false)
    const triggerModalConfirmation = () => toggleModalConfirmation(isModalConfirmationOpen => !isModalConfirmationOpen)
    const permissions = useRoleSystem(ENTITIES.EVENTS);

    const setComments = (value) => dispatch({ type: 'setForm', payload: { comments_for_manager: value } });

    return <>
        <ModalConfirmation onClose={triggerModalConfirmation} isOpen={isModalConfirmationOpen} hasCancel
                           title={t('bases.base.eventDeleting')} text={t('bases.base.areYouSureEvent')}
                           cancelButtonText={t('bases.base.cancel')} confirmButtonText={t('bases.base.yes')}
                           onConfirm={() => deleteEntry(state.id).then(() => history.push(`/bases/${state.base_id.url}/events`))}
        />
        <Row className={'mb-3 mt-3'}>
            <Col xs={12} md={8}>
                <h5 className={'display-4'}>{t('bases.base.infoAndLinks')}</h5>
            </Col>
        </Row>
        <Row>
            {state?.id && <Col xs={12} className='mb-4'>
                <ManagerComments entity='events' id={state.id} data={state.comments_for_manager} setData={setComments} />
            </Col>}
            <Col xs={state.id ? 11 : 12} md={state.id ? 7 : 8}>
                <FormGroup>
                    <UrlRHF state={state} setState={e => dispatch({type: 'setFormData', payload: e})}
                            form={{control, errors}} label={t('bases.base.eventUrl')}
                            hint={t('bases.base.eventUrlHint')} isTrimOnChange={true} />
                </FormGroup>
            </Col>
            {state.id && <Col xs={1}>
                <FormGroup check row>
                    <Label>
                        {t('bases.base.link')}
                    </Label>
                    <CopyLink href={EVENT_URL} showLink={false}/>
                </FormGroup>
            </Col>}
            <Col xs={12} md={8}>
                <FormGroup>
                    <TextControllerRHF defaultValue={state.explanation || ''} inputName={"explanation"}
                                       control={control} setValue={e =>  dispatch({type: 'setFormData', payload: e})}
                                       label={t('bases.base.decs')} rules={{maxLength: 250}} hint={t('bases.base.decsHint')}
                                       errors={errors} placeholder={t('bases.base.decsPlaceholder')}
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={8}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="bring_guide_url"
                        defaultValue={state.bring_guide_url || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors}
                                                 label={t('bases.base.bringGuide')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder="http://example.com/chto_vzyat"
                                                 onChange={e => {
                                                     dispatch({type: 'setFormData', payload: e});
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={8}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="public_contract_url"
                        defaultValue={state.public_contract_url}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.publicContract')}
                                                 name={name} isRequired={true}
                            >
                                <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                       style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                       value={value}
                                       onChange={e => {
                                           dispatch({type: 'setFormData', payload: e});
                                           onChange(e.target.value)
                                       }}>
                                    <option value={''} disabled>{t('bases.base.pickContract')}</option>
                                    {publicContracts.map(publicContract => <option value={publicContract.link}
                                                                                   key={publicContract.id}>{publicContract.tag}</option>)}
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={8}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="return_policy_url"
                        defaultValue={state.return_policy_url || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('bases.base.returnPolicy')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder="http://example.com/vozvrat"
                                                 onChange={e => {
                                                     dispatch({type: 'setFormData', payload: e});
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={8}>
                <CheckSelector control={control} dispatch={dispatch} defaultValue={state.check_id?.id}
                               productName={state.name} errors={errors} checkTypes={CHECK_TYPES} deps={[state.name]} />
            </Col>
        </Row>
        {state.id && <>
            <Row className={'pb-2'}>
                <Col>
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button size={'lg'} color={'danger btn-rounded'} onClick={triggerModalConfirmation} outline>
                            {t('bases.base.deleteEvent')}
                        </Button>
                    </ElementWithPermissions>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Label className={'text-secondary'}>{t('bases.base.deleteEventInfo')}</Label>
                        <br/>
                        <Label className={'text-secondary'}>{t('bases.base.cantCancel')}</Label>
                    </FormGroup>
                </Col>
            </Row>
        </>}
    </>
}

export default ExtraInfo;

ExtraInfo.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}