import { useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { useManagerCommentsApi } from '../../../../lib/api/managerComments';
import React from 'react';
import { TextControllerRHF } from '../../../generic/fields';
import { requiredRule } from '../../reactHookFormsFields/rules';
import { t } from 'i18next';

const ManagerCommentsForm = ({ id, entity, onAdd }) => {
  const api = useManagerCommentsApi({ id, entity });

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { comment: '' },
  });

  const onSubmit = handleSubmit(({ comment }) => api(comment).then(onAdd).then(reset));

  return (
    <Form>
      <Row>
        <Col xs={4}>
          <FormGroup className='m-0'>
            <TextControllerRHF
              placeholder={t('managerComments.placeholder')}
              type='textarea'
              inputName='comment'
              control={control}
              errors={errors}
              rules={requiredRule}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup className='d-flex align-items-center h-100'>
            <Button color="primary" size={'sm'} className={'btn-rounded'} onClick={onSubmit}>
              {t('common.add')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default ManagerCommentsForm;