import React from 'react';
import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useClientsApi = () => {
    const defaultClientsApi = useDefaultEndpoints('clients');
    const {request} = useApi();

    const validatePhoneExists = async (phone, id) => request('get', '/clients/validatePhone/', {phone, id});

    return {...defaultClientsApi, validatePhoneExists}
};