import React, {useMemo} from "react";
import {DataTable, field} from "../../generic/tables";
import {useLinksApi} from "../../../lib/api/links";
import {StringParam, useQueryParam} from "use-query-params";
import {t} from "i18next";

const LinksHistory = ({reload}) => {
    const {findAndCountEntries} = useLinksApi()
    const [activeTab] = useQueryParam("activeTab", StringParam);
    const filters = useMemo(() => {
        const filters = {_limit: 20};

        switch (activeTab) {
          case 'sport': filters.productType = 'sport';
          break;
          case 'theory': filters.productType = 'theory';
          break;
          case 'sport-events': filters.productType = 'sport-events';
          break;
          default: filters.productType = 'sport';
        }

        return filters
    }, [activeTab])

    return <>
        <div className="display-4 mt-3 mb-3">
            {t('management.linksGenerator.linksHistory')}
        </div>
        <DataTable
            findAndCount={findAndCountEntries}
            fields={[
                field(t('management.linksGenerator.link'), true, "url"),
            ]}
            sortField="created_at"
            sortDir="desc"
            name="links"
            reload={reload}
            filters={filters}
        />
    </>
}

export default LinksHistory