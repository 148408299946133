import useEditTable from '../../../../../lib/utils/hooks/useEditTable';
import { useOrdersApi } from '../../../../../lib/api/orders';
import { useState } from 'react';

const IsAdded = ({value, id}) => {
  const [isAdded, setIsAdded] = useState(value);
  const [serverIsAdded, setIsServerAdded] = useState(value);
  const {editEntry} = useOrdersApi();

  const debounceCallback = (added_to_manager_table) => editEntry(id, {added_to_manager_table});
  const debounceErrorCallback = () => setIsAdded(serverIsAdded);

  const debounceSuccessCallback = (data) => {
    setIsServerAdded(data.added_to_manager_table);
  }

  const {loading} = useEditTable({
    debounceCallback,
    debounceErrorCallback,
    debounceSuccessCallback,
    payload: isAdded,
    deps: [isAdded],
    callbackDeps: [serverIsAdded],
  });

  return (
    <input
      disabled={loading}
      type="checkbox"
      checked={isAdded}
      onClick={() => setIsAdded(!isAdded)}
    />
  )
}

export default IsAdded;