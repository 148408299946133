import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from "reactstrap";
import CertificateChangeModal from "./CertificateChangeModal";
import {t} from "i18next";
import { ENTITIES, useRoleSystem } from '../../../../../../lib/utils/hooks/useRoleSystem';
import { ElementWithPermissions } from '../../../../../common/formElements';
import { isRuleAccess } from '../../../../../../lib/utils/frontRules';
import { frk } from 'sv-common/constants/frontRoles';

const CertificateStatus = ({state, dispatch, form}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const triggerModal = () => setModalOpen(isOpen => !isOpen);
    const permissions = useRoleSystem(ENTITIES.CERTIFICATES);

    return <Row>
        <CertificateChangeModal triggerModal={triggerModal} isOpened={isModalOpen} dispatch={dispatch} state={state}/>
        <div className={'badge badge-outline-dark mr-1 mb-3'} style={{textAlign: 'start', borderRadius: '10px', width: '100%'}}>
            <Col xs={12}>
                <Row style={{justifyContent: 'space-evenly'}}>
                    <Col xs={'auto'}>
                        <h1 className="display-4 mb-0 text-dark">{t('management.certificates.certificateStatus.size')}:</h1>
                    </Col>
                    <Col className={'pr-0 flex-grow-0'}>
                        <h1 className="display-4 mb-0 text-dark">
                            <b>{state.size || 0} {state.price_currency || ''}</b>
                        </h1>
                    </Col>
                </Row>
                <Row  style={{justifyContent: 'space-evenly'}}>
                    <Col xs={'auto'}>
                        <h1 className="display-4 mb-0 text-dark">{t('management.certificates.certificateStatus.sizeLeft')}:</h1>
                    </Col>
                    <Col className={'pr-0 flex-grow-0'}>
                        <h1 className="display-4 mb-0 text-dark">
                            <b>{state.size_left || 0} {state.price_currency || ''}</b></h1>
                    </Col>
                </Row>
            </Col>
        </div>
        <Col xs={12}>
          <ElementWithPermissions disabled={!isRuleAccess(frk["CERTIFICATES-ITEM-EDIT"])() || !permissions.edit}>
            <Button block color={'inverse-warning'} onClick={triggerModal} className={'btn-rounded'}>
              {t('management.certificates.certificateStatus.changeState')}
            </Button>
          </ElementWithPermissions>
        </Col>

    </Row>

};

CertificateStatus.propTypes = {
    state: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default CertificateStatus;