import React from 'react'
import General from "./tabs/General";
import History from "./tabs/History";
import QueueStatus from "./tabs/QueueStatus";
import {t} from "i18next";
import { isRuleAccess } from '../../../../lib/utils/frontRules';
import { frk } from 'sv-common/constants/frontRoles';

const tabList = ({state, dispatch, form = {}}) => {
    return (
        [
            {
                name: t('management.certificates.tabList.generalData'),
                child: <General state={state} dispatch={dispatch} form={form}/>,
                isDisplayed: true,
                url: 'general'
            },
            {
                name: t('management.certificates.tabList.historyOfChanges'),
                child: <History state={state} dispatch={dispatch} form={form}/>,
                isDisplayed: !!state.id && isRuleAccess(frk['CERTIFICATES-ITEM-TABS-HISTORY'])(),
                url: 'history'
            },
            {
                name: t('management.certificates.tabList.queueHistory'),
                child: <QueueStatus state={state}/>,
                isDisplayed: !!state.id,
                url: 'queue_status'
            },

        ]
    )
}

export default tabList;