import React from 'react';
import {Col, CustomInput, FormGroup, Input, Label, Row} from "reactstrap";
import PropTypes from 'prop-types';
import {ConditionalField, TextControllerRHF} from "../../../../generic/fields";
import {useHistory} from "react-router-dom";
import {t} from "i18next";
import TelegramOptions from '../../../../common/formElements/TelegramOptions';
import ElementWithAccessInCountries from "../../../../../lib/utils/hocs/ElementWithAccessInCountries";

const SwitchBlock = ({state, dispatch, currencies, control, errors}) => {
    const setAdventureData = (e) => dispatch({type: 'setFormData', payload: e})
    let history = useHistory();
    const is_school = history.location.pathname.includes('school') || !!state.is_school;

    const clearDiscountData = () => dispatch({type: 'clearDiscountData', payload: 'team_book'})
    return (<Row>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_open" name="is_open"
                                 label={t('adventures.edit.open')} checked={state.is_open || false}
                                 onChange={setAdventureData}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_private" name="is_private"
                                 label={t('adventures.edit.private')} checked={state.is_private || false}
                                 onChange={setAdventureData}
                    />
                </FormGroup>
            </Col>
            <ConditionalField condition={is_school}>
                <Col xs={12}>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="check_age_required" name="check_age_required"
                                     label={t('adventures.edit.checkAge')} checked={state.check_age_required || false}
                                     onChange={setAdventureData}
                        />
                    </FormGroup>
                </Col>
                {state.check_age_required && <>
                    <Col sm={6}>
                        <FormGroup>
                            <TextControllerRHF
                                errors={errors}
                                isRequired={true}
                                control={control}
                                label={t('adventures.edit.age')}
                                placeholder={'16'}
                                setValue={setAdventureData}
                                inputName={'check_age_value'}
                                rules={{required: t('inputs.validation.required')}}
                                defaultValue={state.check_age_value || ''}
                            />
                        </FormGroup>
                    </Col>
                </>}
            </ConditionalField>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="currency_only" name="currency_only"
                                 label={t('adventures.edit.currencyOnly')} checked={state.currency_only || false}
                                 onChange={setAdventureData}
                    />
                </FormGroup>
            </Col>
            <ConditionalField condition={!is_school}>
                 <Col xs={12}>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="has_loyal_discount"
                                     name="has_loyal_discount" label={t('adventures.edit.loyalDiscount')}
                                     checked={state.has_loyal_discount || false} onChange={setAdventureData}
                        />
                    </FormGroup>
                </Col>
            </ConditionalField>
            <ConditionalField condition={!is_school}>
                <Col xs={12}>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="has_transfer" name="has_transfer"
                                     label={t('adventures.edit.hasTransfer')} checked={state.has_transfer || false}
                                     onChange={setAdventureData}
                        />
                    </FormGroup>
                </Col>
            </ConditionalField>
            {state.has_transfer && <>
                <Col>
                    <FormGroup>
                        <Label for="transfer_price">{t('adventures.edit.price')}</Label>
                        <Input type="text" name="transfer_price" id="transfer_price" placeholder="50"
                               value={state.transfer_price || ''} onChange={setAdventureData}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="transfer_currency">{t('adventures.edit.currency')}</Label>
                        <Input type={'select'} value={state.transfer_currency || ''} name={'transfer_currency'}
                               onChange={setAdventureData}>
                            <option disabled value={''}>{t('adventures.edit.pickPrice')}</option>
                            {currencies.map(currency => <option key={currency.sign}
                                                                value={currency.key}>{currency.sign}</option>)}
                        </Input>
                    </FormGroup>
                </Col>
            </>}
            <ConditionalField condition={!is_school}>
                <Col xs={12}>
                    <FormGroup>
                        <CustomInput type="checkbox" className={'custom-switch'} id="team_book_discount"
                                     name="team_book_discount"
                                     label={t('adventures.edit.teamBookDiscount')} checked={state.team_book_discount_size}
                                     onChange={() => {
                                         clearDiscountData()
                                         dispatch({
                                             type: 'setForm',
                                             payload: {
                                                 team_book_discount_size: state.team_book_discount_size ? 0.0 : 1.0
                                             }
                                         })
                                     }}
                        />
                    </FormGroup>
                </Col>
            </ConditionalField>
            <Col xs={12}>
                <Row>
                    {!!state.team_book_discount_size && <>
                        <Col xs={6} md={6}>
                            <Label>{t('adventures.edit.type')}</Label>
                            <Input bsSize={'sm'} type={'select'} name={'team_book_discount_type'}
                                   value={state.team_book_discount_type}
                                   onChange={e => dispatch({type: 'setFormData', payload: e})}
                            >
                                <option value={'summ'}>{t('adventures.edit.sum')}</option>
                                <option value={'percent'}>{t('adventures.edit.percent')}</option>
                            </Input>
                        </Col>
                        <Col xs={6} md={6}>
                            <Label>{t('adventures.edit.sum')}</Label>
                            <Input bsSize={'sm'} type={'text'} name={'team_book_discount_size'}
                                   value={state.team_book_discount_size}
                                   onChange={e => dispatch({type: 'setFormData', payload: e})}
                                   placeholder={'1000'}
                            />
                        </Col>
                        <Col xs={12} md={12} className={'form-group'}>
                            <Label>{t('adventures.edit.peopleCount')}</Label>
                            <Input type={'text'} name={'team_book_discount_condition'}
                                   value={state.team_book_discount_condition}
                                   onChange={e => dispatch({type: 'setFormData', payload: e})}
                                   placeholder={'5'}
                            />
                        </Col>
                    </>}
                </Row>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Col xs={12}>
                    <FormGroup>
                        <CustomInput type='checkbox' className={'custom-switch'} id='visible_installment'
                                     name='visible_installment' label={t('adventures.edit.isInstallment')}
                                     checked={state.visible_installment || false}
                                     disabled={state.partial_payment_option_20 || state.partial_payment_option_50}
                                     onChange={e => dispatch({type: 'setFormData', payload: e})}
                        />
                    </FormGroup>
                </Col>
            </ElementWithAccessInCountries>
            <Col xs={12}>
              <FormGroup>
                <Label>{t('adventures.edit.tg_options')}</Label>
                <TelegramOptions tg_options={state.tg_options} onChange={setAdventureData} />
              </FormGroup>
            </Col>
        <ConditionalField condition={!is_school}>
            <Col xs={12}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="check_age_required" name="check_age_required"
                                 label={t('adventures.edit.checkAge')} checked={state.check_age_required || false}
                                 onChange={setAdventureData}
                    />
                </FormGroup>
            </Col>
            {state.check_age_required && <>
                <Col sm={6}>
                    <FormGroup>
                        <TextControllerRHF
                            errors={errors}
                            isRequired={true}
                            control={control}
                            label={t('adventures.edit.age')}
                            placeholder={'16'}
                            setValue={setAdventureData}
                            inputName={'check_age_value'}
                            rules={{required: t('inputs.validation.required')}}
                            defaultValue={state.check_age_value || ''}
                        />
                    </FormGroup>
                </Col>
            </>}
        </ConditionalField>
        </Row>
    )
}

export default SwitchBlock;

SwitchBlock.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,

}