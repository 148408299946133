import {field} from "../../generic/tables";
import EditModalChildren from "./EditModalChildren";
import DirectoryTemplate from "../DirectoryTemplate";
import {RU_EVENT_TYPES, EN_EVENT_TYPES} from "sv-common/constants/checks";
import {ENTITIES, getPermissions} from "../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";
import getLangValue from '../../../lib/utils/getLangValue';

const Checks = (props) => {
    return <DirectoryTemplate
        fields={[
            field(t('directories.checks.name'), true, "name"),
            field(t('directories.checks.type'), true, "product_type", (res) => getLangValue({ ru: RU_EVENT_TYPES[res.product_type], en: EN_EVENT_TYPES[res.product_type] })),
        ]}
        editable
        entityName={'checks'}
        EditModalChildren={EditModalChildren}
        permissions={getPermissions(ENTITIES.CHECKS)}
        {...props}
    />
}

export default Checks