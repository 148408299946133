import { Col, Form, FormGroup, Label } from 'reactstrap';
import {Controller} from "react-hook-form";
import { t } from 'i18next';
import FieldWithValidation from '../../generic/fields/FieldWithValidation';
import AsyncEventsPaginateComponent from '../../administration/linksGenerator/EventsLinksGenerator/AsyncPaginate';
import { withBreadcrumbs } from '../../common/hoc/Breadcrumbs';

const EditModalChildren = withBreadcrumbs(({entity, setEntity, control, errors, handleInput}) => {
  return (
    <Form>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="title"
            defaultValue={entity.title || ''}
            rules={{required: t('inputs.validation.required')}}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) =>
              <FieldWithValidation
                invalid={invalid}
                errors={errors}
                label={t('constructor.corpClients.title')}
                name={name} isRequired={true}
                type="text" onBlur={onBlur} value={value}
                onChange={e => onChange(handleInput(e, setEntity).value)}
              />
            }
          />
        </FormGroup>
      </Col>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="slug"
            defaultValue={entity.slug || ''}
            rules={{required: t('inputs.validation.required')}}
            render={(
              {value, onBlur, name, ref, onChange},
              {invalid}
            ) =>
              <FieldWithValidation
                invalid={invalid}
                errors={errors}
                label={t('constructor.corpClients.slug')}
                name={name} isRequired={true}
                type="text" onBlur={onBlur} value={value}
                onChange={e => onChange(handleInput(e, setEntity).value)}
              />
            }
          />
        </FormGroup>
      </Col>
      <Col lg={12}>
        <FormGroup>
          <Controller
            control={control}
            name="events"
            defaultValue={entity?.events}
            render={(
              {value, onChange},
            ) => {
              return (
                <>
                  <Label>{t('constructor.corpClients.events')}</Label>
                  <AsyncEventsPaginateComponent
                    events={value || []}
                    onChangeEvents={(events) => onChange(handleInput({ target: { name: 'events', value: events } }, setEntity).value)}
                    additionReqOpts={{ 'corp_client_null': true }}
                    valueProp='id'
                  />
                </>
              )
            }}
          />
        </FormGroup>
      </Col>
    </Form>
  )
})

export default EditModalChildren;