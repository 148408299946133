import React from 'react';
import GroupsList from "../theory/GroupsList";

const LecturesList = ({createTranslatedBreadcrumbsAndTitle}) => {
    return <GroupsList
        type={'lecture'}
        createTranslatedBreadcrumbsAndTitle={createTranslatedBreadcrumbsAndTitle}
    />
}

export default LecturesList;