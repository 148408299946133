import React from 'react'
import SlotGeneral from "./SlotGeneral";
import SlotInstructors from "./SlotInstructors";
import SlotPromocodes from "./SlotPromocodes";
import SlotOrders from "./SlotOrders";
import { IS_RUSSIA } from '../../../lib/utils/constants';
import {frk} from "sv-common/constants/frontRoles";

const tabList = ({state, dispatch, form = {}, edit, check}) => {
    return (
        [
            {
                name: "bases.base.users",
                child: <SlotOrders dispatch={dispatch} state={state} edit={edit} />,
                isDisplayed: !!state.id,
                url: 'users'
            },
            {
                name: "bases.base.general",
                child: <SlotGeneral state={state} dispatch={dispatch} form={form}/>,
                isDisplayed: check(frk["SLOT-ITEM-TABS-GENERAL"]),
                url: 'general'
            },
            {
                name: "bases.base.instructors",
                child: <SlotInstructors state={state} dispatch={dispatch} disabled={!edit} />,
                isDisplayed: (IS_RUSSIA && check(frk["SLOT-ITEM-TABS-INSTRUCTORS"]) || check(frk["SLOT-ITEM-TABS-INSTRUCTORS"])),
                url: 'instructors'
            },
            {
                name: "bases.base.promocodes",
                child: <SlotPromocodes state={state} dispatch={dispatch} disabled={!edit}/>,
                isDisplayed: check(frk["SLOT-ITEM-TABS-PROMOS"]),
                url: 'promocodes'
            }
        ]
    )
}

export default tabList;