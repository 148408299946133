import JSZip from 'jszip';
import saveAs from 'file-saver';
import { API_URL } from '../../../../../lib/api/useApi';
import { getCookie } from '../../../../../lib/utils/cookies';

const makePassportsZip = async (zipName, passports) => {
  const zip = new JSZip();

  const token = getCookie('userAccessToken');

  for (const passport of passports) {
    const name = `passport_${passport.owner.first_name}_${passport.owner.last_name}.png`;
    const buffer = await fetch(API_URL+passport.url, { headers: { Authorization: `Bearer ${token}` } }).then(res => res.arrayBuffer());
    zip.file(name, buffer, { binary: true });
  }

  await zip.generateAsync({type:"blob"}).then((content) => {
    saveAs(content, `${zipName}.zip`);
  });
}

export default makePassportsZip;