import React, {useEffect, useState} from "react";
import "./SortableColumn.css"
import PropTypes from "prop-types";
import {FaArrowDown, FaArrowUp} from 'react-icons/fa'

const SortableColumn = (props) => {
    const [state, setState] = useState({sort: props.sort})

    /*
        componentWillReceiveProps(nextProps) {
             this.setState({sort: nextProps.sort});
         }
    попробую заменить useEffect c [], если не выйдет, надо будет использовать usePrevious, но это очень странный юзкейс
     */
    useEffect(() => {
        setState(state => ({...state, sort: props.sort}));
    }, [props.sort])

    const handleSort = () => {
        let newSort;
        switch (state.sort) {
            case "asc":
                newSort = "desc";
                break;
            case "desc":
                newSort = undefined;
                break;
            default:
                newSort = "asc";
        }
        setState(state => ({...state, sort: newSort}));
        if (props.handleSort) props.handleSort(newSort);
    }
    return (
        <th className={`${props.className} sortable-column ` + (state.sort ? `sortable-column-${state.sort}` : "")}
            onClick={handleSort}>
            {props.children}
            <FaArrowDown size={'11px'} className={'arrow-down'}/>
            <FaArrowUp size={'11px'} className={'arrow-up'}/>
        </th>
    );
}

SortableColumn.propTypes = {
    handleSort: PropTypes.func.isRequired,
    sort: PropTypes.oneOf(['asc', 'desc'])
};

export default SortableColumn;