import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom"
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import tabList from "./tabList";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import InstructorsData from "./InstructorsData";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {useInstructorsApi} from "../../../../lib/api/instructors";
import {Loader} from "../../../generic/loader";
import {TableCard} from "../../../generic/cards";
import {useQueryParam} from "use-query-params";
import AsyncButton from "../../../generic/buttons/AsyncButton";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {t} from "i18next";

const showDangerToast = e => toast(e, {type: 'error'});
const showSuccessToast = e => toast(e, {type: 'success'});

const Instructor = withBreadcrumbs(() => {
    const {id} = useParams();
    const {getInstructor, editEntry} = useInstructorsApi(true);
    const basesEndpoints = useDefaultEndpoints('bases');
    const [instructor, setInstructor] = useState();
    const [bases, setBases] = useState([]);
    const [activeTab, setActiveTab] = useQueryParam('t');
    const permissions = useRoleSystem(ENTITIES.INSTRUCTORS);
    useEffect(() => {
        getInstructor(id).then(res => setInstructor(res));
        basesEndpoints.getEntries({is_archived_ne: true}).then(res => setBases(() => [...res]))
        if (!activeTab) setActiveTab('existing', 'replaceIn')
    }, [])

    const form = useForm({reValidateMode: 'onBlur',});

    const {handleSubmit} = form;


    const toggleTab = tab => {
        setActiveTab(tab.url, 'replaceIn')
    };

    const onSubmit = () => {
        return handleSubmit(async (data) => {
            await editEntry(instructor.id, data)
                .then(() => {
                    showSuccessToast(t('management.instructors.instructor.success'));
                })
                .catch(showDangerToast);
        })()
    }

    const tabs = instructor ? tabList({state: instructor, dispatch: setInstructor, form}) : [];

    return !instructor
        ? <Loader/>
        : <TableCard>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabs
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(12 / tabs.length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row className={'mb-3'}>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {
                            tabs
                                .filter(tab => tab.isDisplayed !== false).map(tab => (
                                <TabPane tabId={tab.url} key={tab.name}>
                                    {tab.child}
                                </TabPane>
                            ))
                        }
                    </TabContent>
                </Col>
            </Row>

            {
                activeTab !== 'schedule' &&
                <>
                    <InstructorsData entity={instructor} bases={bases} form={form}/>
                    <div>
                        <Row>
                            <Col>
                                <ElementWithPermissions disabled={!permissions.edit}>
                                    <AsyncButton color="primary" onClick={onSubmit}>
                                        {t('management.instructors.instructor.ok')}
                                    </AsyncButton>
                                </ElementWithPermissions>
                            </Col>
                        </Row>
                    </div>
                </>
            }
        </TableCard>
});

export default Instructor;

Instructor.propTypes = {
    requiredFields: PropTypes.array,
    triggerModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
}