import {useApi} from "./useApi";
import {useDefaultEndpoints} from "./useDefaultEndpoints";

export const useArrivalsApi = (isFormData = false) => {
    const {request} = useApi();

    const archiveArrival = async (id) => {
        return request('put', `/arrivals/archive/${id}`)
    };

    const syncData = async (id) => request('get', '/arrivals/sync', {id});

    const findByUrl = async (url) => request('get', '/arrivals/findByUrl/' + url);

    return {
        ...useDefaultEndpoints('arrivals', isFormData), archiveArrival, syncData, findByUrl
    }
};