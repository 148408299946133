import _ from 'lodash';
import {handleInputWithInstallmentBackward} from "../helpers";

const initialState = {
    name: '',
    country: '',
    city: '',
    coordinates: '',
    price_currency: 'RUB',
    partial_payment_option_20: false,
    partial_payment_option_50: false,
    partial_payment_option_100: true,
    check_age_16: true,
    public_contract_url: '',
    req_fields: {},
    difficulty_level_id: {},
    extra_materials: [],
    files: undefined,
    loading: true
};

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        return handleInputWithInstallmentBackward(name, value);
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'General'
            return {
                ...state,
                activeTab: action.payload
            }
        case 'setLoading':
            // value: false'
            return {
                ...state,
                loading: action.payload
            }

        // Data editing block
        case 'setForm':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload,
                loading: false
            }

        case 'setFormData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        case 'setImg':
            // value: File
            return {
                ...state,
                files:
                    action.payload
            }

        case 'clearDiscountData':
            // value: 'example'
            const discount = {}
            discount[action.payload + '_discount_type'] = 'summ'
            discount[action.payload + '_discount_size'] = 0.0
            return {
                ...state,
                ...discount
            }

        case 'addExtraMaterial':
            // value: {}
            return {
                ...state,
                extra_materials: [
                    ...state.extra_materials,
                    action.payload
                ]
            }
        case 'removeExtraMaterial':
            // value: {}
            return {
                ...state,
                extra_materials: [
                    ...state.extra_materials.slice(0, action.payload),
                    ...state.extra_materials.slice(~~action.payload + 1),
                ]
            }
        case 'setAdventureData':
            // value: SyntheticEvent
            return {
                ...state,
                ...handleInputChange(action.payload)
            }

        case 'setDifficulty':
            // value: SyntheticEvent
            return {
                ...state,
                difficulty_level_id: {
                    ...action.payload
                }
            }
        case 'setReqField': {
            // value: e
            const [e, reqField] = action.payload;
            const {[reqField.key]: val} = handleInputChange(e);
            const reqFields = _.cloneDeep(state.req_fields)
            if (val)
                reqFields[reqField.key] = {label: reqField.value, required: reqField.is_required}
            else
                delete reqFields[reqField.key]
            return {
                ...state,
                req_fields: {
                    ...reqFields
                }
            }
        }

        case 'setReqFields':
            // value: []
            const reqFields = {};
            action.payload.forEach(field => reqFields[field.key] = {
                label: field.value, required: field.is_required})
            return {
                ...state,
                req_fields: {...reqFields}
            }

        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};