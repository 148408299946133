import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Row} from "reactstrap";
import {Dropzone} from "../../../../generic/fields";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {Controller} from "react-hook-form";
import FieldWithValidation from "../../../../generic/fields/FieldWithValidation";
import {t} from "i18next";

const AdventureCard = ({state, dispatch, control, errors, isArrival}) => {
    const setAdventureData = (e) => dispatch({type: 'setFormData', payload: e})
    const [difficultyLevels, setDifficultyLevels] = useState([]);

    const {getEntries} = useDefaultEndpoints('difficulty-levels');
    useEffect(() => {
        getEntries({}).then(res => setDifficultyLevels(() => res));
        // eslint-disable-next-line
    }, [])

    return (
        <Row>
            <Col xs={12}>
                <h4 className={'display-4'}>{isArrival ? t('adventures.edit.arrivalCard') : t('adventures.edit.travelCard')}</h4>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="img"
                        defaultValue={state.files}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.imgSize')}
                                                 name={name} isRequired={true}
                                                 hint={t('adventures.edit.fileSize')}
                            >
                                <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                     className={invalid ? 'is-invalid' : ''}>
                                    <Dropzone name={'img'} extFile={state.files} onBlur={onBlur} callback={img => {
                                        dispatch({type: 'setImg', payload: img});
                                        onChange('test');
                                    }}/>
                                </div>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                {!isArrival && <FormGroup>
                    <Controller
                        control={control}
                        name="difficulty_level_id"
                        defaultValue={state.difficulty_level_id?.id || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.difficulty')}
                                                 name={name} isRequired={true}
                            >
                                <Input type="select" id={name} invalid={invalid} name={name} onBlur={onBlur}
                                       style={{border: invalid ? '1px solid #dc3545' : '0'}}
                                       value={value}
                                       onChange={(e) => {
                                           dispatch({type: 'setDifficulty', payload: difficultyLevels
                                                   .filter(level => level.id === ~~e.target.value)[0]
                                           })
                                           onChange(e.target.value)
                                       }}

                                >
                                    <option value={''} disabled>{t('adventures.edit.pickDifficulty')}</option>
                                    {difficultyLevels
                                        .sort((a, b) => a.level - b.level)
                                        .map(difficultyLevel => <option key={difficultyLevel.id} value={difficultyLevel.id}>
                                            {difficultyLevel.level}/{difficultyLevel.max_level} - {difficultyLevel.title}
                                        </option>)}
                                </Input>
                            </FieldWithValidation>
                        }
                    />
                </FormGroup>}
            </Col>
            <div className="mb-4 w-100 d-flex">
                <Col xs={6}>
                    <Controller
                        control={control}
                        name="hours_sailing"
                        defaultValue={state.hours_sailing || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.timeOnWaterFrom')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="1"
                                                 hint={t('adventures.edit.averageTime')}
                                                 onChange={e => {
                                                     setAdventureData(e)
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </Col>

                <Col xs={6}>
                    <Controller
                        control={control}
                        name="hours_sailing_limit"
                        defaultValue={state.hours_sailing_limit || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.timeOnWaterTo')}
                                                 name={name} isRequired={true}
                                                 type="text" onBlur={onBlur} value={value} placeholder="1"
                                                 hint={t('adventures.edit.averageTime')}
                                                 onChange={e => {
                                                     setAdventureData(e)
                                                     onChange(e.target.value)
                                                 }}
                            />
                        }
                    />
                </Col>
            </div>

            <Col xs={6}>
                <Controller
                    control={control}
                    name="air_temperature"
                    defaultValue={state.air_temperature || ''}
                    rules={{required: t('inputs.validation.required')}}
                    render={(
                        {value, onBlur, name, ref, onChange},
                        {invalid}
                    ) =>
                        <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.airTemperature')}
                                             name={name} isRequired={true}
                                             type="text" onBlur={onBlur} value={value} placeholder="30"
                                             onChange={e => {
                                                 setAdventureData(e)
                                                 onChange(e.target.value)
                                             }}
                        />
                    }
                />
            </Col>
            <Col xs={6}>
                <Controller
                    control={control}
                    name="water_temperature"
                    defaultValue={state.water_temperature || ''}
                    rules={{required: t('inputs.validation.required')}}
                    render={(
                        {value, onBlur, name, ref, onChange},
                        {invalid}
                    ) =>
                        <FieldWithValidation invalid={invalid} errors={errors} label={t('adventures.edit.waterTemperature')}
                                             name={name} isRequired={true}
                                             type="text" onBlur={onBlur} value={value} placeholder="20"
                                             onChange={e => {
                                                 setAdventureData(e)
                                                 onChange(e.target.value)
                                             }}
                        />
                    }
                />
            </Col>
        </Row>

    )
}

export default AdventureCard;