import React, {useEffect, useState} from 'react';
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {useParams} from 'react-router-dom';
import EventsTable from "./EventsTable";
import {BooleanParam, ObjectParam, StringParam, useQueryParam} from "use-query-params";
import SlotsTable from "./SlotsTable";
import {Button, Col, CustomInput, FormGroup, Label, Row} from "reactstrap";
import {TableCard} from "../../../generic/cards";
import {RangedDatePicker, TextSearch} from "../../../generic/fields";
import dayjs from "dayjs";
import './style.scss';
import {t} from "i18next";
import ExportButton from '../../../generic/buttons/ExportButton';
import { useOrdersApi } from '../../../../lib/api/orders';
import {frk} from "sv-common/constants/frontRoles";
import {RuleAccessProvider} from "../../../../lib/utils/frontRules";

const EventsList = ({createTranslatedBreadcrumbsAndTitle}) => {
  const { exportForBase } = useOrdersApi();

    const {url} = useParams();
    const defaultDaysFilter = {
        from: dayjs().format("DD.MM.YYYY"),
        to: dayjs().add(1, 'd').format("DD.MM.YYYY")
    }

    const getBases = useDefaultEndpoints('bases').getEntries;
    const [tableShown, setTableShown] = useQueryParam('table', StringParam);
    const [name, setName] = useQueryParam('name', StringParam);
    const [showHidden, setShowHidden] = useQueryParam('hidden', BooleanParam);
    const toggleHidden = () => setShowHidden(!showHidden, 'replaceIn');
    const [showDate, setShowDate] = useQueryParam('date_hidden', BooleanParam);
    const toggleDate = () => setShowDate(!showDate, 'replaceIn');
    const [daysFilter, setDaysFilter] = useQueryParam('days', ObjectParam)
    const setParsedDaysFilter = generateUpdateFilter => {
        let newFilter = generateUpdateFilter(daysFilter)
        if (newFilter.from !== daysFilter.from)
            setDaysFilter({...daysFilter, from: dayjs(newFilter.from).format("DD.MM.YYYY"),})
        if (newFilter.to !== daysFilter.to)
            setDaysFilter({...daysFilter, to: dayjs(newFilter.to).format("DD.MM.YYYY"),})
    }
    const [base, setBase] = useState({});

    const commonFilters = {
        name_contains: name || undefined,
        start_date_gte: daysFilter && showDate ? dayjs(daysFilter.from, 'DD.MM.YYYY').format('YYYY-MM-DD') : undefined,
        end_date_lte: daysFilter && showDate ? dayjs(daysFilter.to, 'DD.MM.YYYY').format('YYYY-MM-DD') : undefined,
        show_in_admin: !!showHidden ? undefined : true,
        is_archived_ne: true,
    }

    // Sets the defaults for params and checks if there are no preset (or absent) values
    useEffect(() => {
        if (tableShown === undefined)
            setTableShown('events', 'replaceIn')
        if (daysFilter === undefined)
            setDaysFilter(defaultDaysFilter, 'replaceIn')
        if (showHidden === undefined)
            setShowHidden(false, 'replaceIn')
        if (showDate === undefined)
            setShowDate(false, 'replaceIn')
        if (name === undefined)
            setName('', "replaceIn")
    }, [tableShown, daysFilter?.from, daysFilter?.to, showDate, showHidden, name])

    // resets filters for new url (in case :url is only part of url that is changed)
    useEffect(() => {
        if ((url !== base.url) && base.url) {
            setTableShown('events', 'replaceIn')
            setDaysFilter(defaultDaysFilter, 'replaceIn')
            setShowHidden(false, 'replaceIn')
            setShowDate(false, 'replaceIn')
            setName('', "replaceIn")
        }

        getBases({url}).then(res => {
            const e = res[0]
            setBase(e);
            const mapper = {
                url: {ru: e.name || '', en: url},
            };
            createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
        })
    }, [url])


    const openEventsTable = () => {
        setTableShown('events', 'replaceIn');
        setShowDate(false, 'replaceIn')
    };

    const openSlotsTable = () => {
        setTableShown('slots', 'replaceIn');
        setShowDate(true, 'replaceIn')
    };

    const onExport = (isMaster) => {
      return exportForBase(isMaster, base.id);
    }

    return (
        <TableCard>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'events'}
                                onClick={openEventsTable}
                                active={tableShown === 'events'}>
                            {t('bases.base.events')}
                        </Button>
                        <Button size={'sm'} outline color="primary" className={'btn-rounded m-1'} name={'slots'}
                                onClick={openSlotsTable}
                                active={tableShown === 'slots'}>
                            {t('bases.base.slots')}
                        </Button>
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <CustomInput type='checkbox' className={'custom-switch'} id='hasPaid'
                                     name='hasPaid' label={t('bases.base.showHidden')} checked={showHidden || false}
                                     onChange={toggleHidden}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <CustomInput type='checkbox' className={'custom-switch'} id='showDate'
                                     name='showDate' label={t('bases.base.dateFilter')} checked={showDate}
                                     onChange={toggleDate}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div className={'row mb-0 pr-0 mr-0 align-items-end'}>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>{t('bases.base.name')}</Label>
                        <TextSearch onSearch={v => setName(v, 'replaceIn')} value={name || ''}
                                    placeholder={t('bases.base.practiceSv20')}
                        />
                    </FormGroup>
                </Col>
                {showDate && <Col xs={12} md={6}>
                    <FormGroup>
                        <Label>{t('bases.base.date')}</Label>
                        <RangedDatePicker size={'md'} daysFilter={daysFilter || defaultDaysFilter}
                                          setDaysFilter={setParsedDaysFilter} dateFormat={"DD.MM.YYYY"}/>
                    </FormGroup>
                </Col>}
            </div>
            {
                tableShown === 'slots'
                    ? <SlotsTable filters={{...commonFilters, 'event_id.base_id': base.id}}/>
                    : <EventsTable filters={{...commonFilters, 'base_id.url': url}}/>
            }
            <RuleAccessProvider accessKey={frk["BASE-EVENTS-EXPORT"]}>
                <ExportButton
                    exportFun={onExport}
                    mods={[
                        { type: true, label: 'generic.exportButton.exportMaster' },
                        { type: false, label: 'generic.exportButton.exportWithCompanion' },
                    ]}
                    buttonTitle='generic.exportButton.exportByBase'
                    tooltipDesc='generic.exportButton.exportByBaseTooltip'
                />
            </RuleAccessProvider>
        </TableCard>
    )
};

export default EventsList;