import React from 'react'
import {Col, Form, FormGroup, Row, CustomInput} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {TextControllerRHF} from "../../generic/fields";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
    return <Form>
        <Row>
            <Col lg={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="class"
                        defaultValue={entity.class || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors}
                                                 label={t('directories.boatsClasses.boatClass')}
                                                 name={name} isRequired={true} disabled={!!entity.id}
                                                 type="text" onBlur={onBlur} value={value} placeholder="SV-5"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={12}>
                <FormGroup>
                    <TextControllerRHF defaultValue={entity.label || ''} setValue={e => handleInput(e, setEntity)}
                                       control={control} errors={errors} inputName={'label'}
                                       label={t('directories.boatsClasses.name')}/>
                </FormGroup>
            </Col>
            <Col lg={12}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="places"
                        defaultValue={entity.places || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.boatsClasses.boatPlaces')}
                                                 type="text" onBlur={onBlur} value={value} placeholder="5"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={12} md={6}>
                <FormGroup>
                    <CustomInput type="checkbox" className={'custom-switch'} id="is_active" name="is_active"
                                 label={t('directories.boatsClasses.isActive')} checked={entity.is_active || false}
                                 onChange={e => handleInput(e, setEntity).value}
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
