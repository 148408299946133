import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import PropTypes from "prop-types";
import {useDefaultEndpoints} from "../../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {AiOutlineProfile} from 'react-icons/ai'
import {useHistory} from 'react-router-dom';
import {ClientsList} from "../../../users";
import {t} from "i18next";

const showSuccessToast = e => toast(e, {type: 'success'});

const ChooseClient = (
    {state, setState, form, canCreate = true, role = t('management.instructors.editModal.client'), showSurname, ...rest}
) => {
    let history = useHistory();
    const getClient = useDefaultEndpoints('clients').getEntry;

    const [client, setClient] = useState({});

    useEffect(() => {
        state.client_id && getClient(state.client_id).then(res => {
            setClient(() => res)
        })
        // eslint-disable-next-line
    }, [state.client_id])

    const handleTableInput = client => {
        setState(user => ({
            ...user,
            client_id: client.id,
            first_name: client?.first_name,
            last_name: client?.last_name
        }))
        showSuccessToast(t('management.instructors.editModal.clientSelected'));
    }

    return (
        <Form>
            {state.id && <Row>
                <Col md={11}>
                    <FormGroup>
                        <Label>
                            {t('management.instructors.editModal.instructor')}
                        </Label>
                        <Input type="text" id={'client_id'} name={'client_id'} disabled={true}
                               value={client.first_name + ' ' + client.last_name + ' - ' + client.role_name}/>
                    </FormGroup>
                </Col>
                <Col>
                    <AiOutlineProfile size={'100%'} title={t('management.instructors.editModal.goToClientRecord')}
                                      onClick={() => history.push(`/${history.location.pathname.split('/')[1]}/users/edit/` + state.client_id)}/>
                </Col>
            </Row>}
            {!state.id && <ClientsList isInline onClick={handleTableInput} role={role} canCreate={canCreate} showSurname={showSurname}/>}
        </Form>

    )
}

ChooseClient.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    canCreate: PropTypes.bool
}

export default ChooseClient;
