import {ProductOrdersList} from "../../common/formElements";
import {getSlotSet} from "../../common/formElements/ProductOrdersList/fieldSets";
import CancelledAndMovedOrdersList from "../../common/formElements/CancelledAndMovedOrdersList";
import TinkoffIdField from "../../common/formElements/TinkoffIdField";

const SlotOrders = ({dispatch, state, edit}) => {
    return (
        <>
            <ProductOrdersList dispatch={dispatch} state={state} product_type={'slots'} showExp disabled={!edit}/>
            <CancelledAndMovedOrdersList
                name={"slots_orders-moved-and-cancelled-list"}
                fields={[
                    ...getSlotSet(),
                    ...TinkoffIdField(),
                ]}
                entity={state}
                entityOrderName={'slot_id'}
                reload={state.reload} />
        </>
    )
}

export default SlotOrders