import React, {useEffect, useState} from 'react';
import "../loader/Loader.css";
import PropTypes from "prop-types";

const Pagination = (props) => {
    const [state, setState] = useState({current: props.current});
    const buttonsVisible = 9;
    const onButtonClick = (e, page) => {
        e.preventDefault();
        setState(state => ({...state, current: page}));
        props.onPageChange(page);
    };

    useEffect(() => {
        if (state.current !== props.current) {
            setState((state) => ({...state, current: props.current}));
        }
    }, [ state.current, props.current])


    const renderButton = page =>
        <li key={"page-" + page} className={`page-item ${state.current === page ? "active" : ""}`}>
            <button className="page-link" onClick={e => onButtonClick(e, page)}>{page}</button>
        </li>;

    const renderBlank = (number) =>
        <li key={"page-blank-" + number} className="page-item">
            <span className="page-link">..</span>
        </li>;

    const renderButtonAt = i => {
        const delta = Math.floor(buttonsVisible / 2);

        if (i === 1 || props.total <= buttonsVisible) return renderButton(i);

        if (i === 2) {
            if (state.current - delta > 2) return renderBlank(i);
            else return renderButton(i);
        }

        if (i > 2 && i < buttonsVisible - 1) {
            return renderButton(
                Math.min(
                    Math.max(state.current + i - delta - 1, i),
                    props.total - (buttonsVisible - i)
                )
            );
        }

        if (i === (buttonsVisible - 1)) {
            if (state.current + delta <= props.total) return renderBlank(i);
            else return renderButton(props.total - 1);
        }

        if (i === buttonsVisible) return renderButton(props.total);
    };
    return <nav>
        <ul className="pagination flex-wrap pagination-separated">
            {[...Array(Math.min(buttonsVisible, props.total)).keys()].map(i => renderButtonAt(i + 1))}
        </ul>
    </nav>
}

Pagination.propTypes = {
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
    current: 1
};

export default Pagination;

