import React, {useEffect, useReducer} from 'react'
import {TableCard} from "../../generic/cards";
import {useHistory, useParams} from "react-router-dom"
import {initialState, reducer} from "./reducer";
import {Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import tabList from "./tabList";
import {Loader} from '../../generic/loader';
import dayjs from "dayjs";
import {fullTimeToShort, getStartDayAndMonth, getStartWeekDay} from "../../../lib/utils/format";
import {AiOutlineSync} from "react-icons/ai";
import {useForm} from "react-hook-form";
import {useSlotsApi} from "../../../lib/api/slots";
import {toast} from "react-toastify";
import {StringParam, useQueryParam} from "use-query-params";
import {ElementWithPermissions} from "../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../lib/utils/hooks/useRoleSystem";
import i18next from "i18next";
import BoatsCounter from "./BoatsCounter";
import ManagerComments from '../../common/formElements/ManagerComments';
import {RuleAccessProvider, useRuleAccess} from "../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";

const SlotInterface = ({createTranslatedBreadcrumbsAndTitle}) => {
    let history = useHistory();
    let {slot_url, url, event_url} = useParams();
    const {editEntry, findByUrl, syncData} = useSlotsApi();
    const [state, dispatch] = useReducer(reducer, initialState());
    const [activeTab, setActiveTab] = useQueryParam('t',  StringParam);
    const { check } = useRuleAccess();

    const {t, language} = i18next;

    useEffect(() => {
        const mapper = {
            slot_url: {ru: `${t('bases.base.slotLowercase')} ${fullTimeToShort(state.start_time)} - ${fullTimeToShort(state.end_time)}` || '', en: slot_url},
            url: {ru: state.base?.name || '', en: url},
            event_url: {ru: state.event_id?.name || '', en: event_url}
        }
        createTranslatedBreadcrumbsAndTitle
        && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
    }, [slot_url, url, event_url, state.base?.id, language])

    const form = useForm({reValidateMode: 'onBlur',});
    const {handleSubmit} = form;

    const editSlot = () => {
        const {
            instructor_id, event_id, active_promocodes_ids, repeat_type, orders_ids, booked_to_total, booked_sits,
            available_sits, reserved_sits, available_reserved_sits, ...sanitizedData
        } = state;
        editEntry(state.id, {...sanitizedData})
            .then(() => history.goBack());
    }

    const submitSlotEdit = handleSubmit(() => {
        editSlot()
    })

    const toggleTab = tab => {
        setActiveTab(tab.url, 'replaceIn')
    };

    useEffect(() => {
        findByUrl(slot_url).then(res => dispatch({type: 'setSlotData', payload: {...res, reload: false}}))
        if (!activeTab) setActiveTab('users', 'replaceIn');

        if (activeTab)
            setActiveTab(activeTab, 'replaceIn')
        // eslint-disable-next-line
    }, [history.location.pathname, url, state.reload])

    const permissions = useRoleSystem(ENTITIES.SLOTS);
    const tabs = tabList({state, dispatch, form, edit: permissions.edit, check});

    const syncLoad = async () => {
        const slot = syncData(state.id);
        dispatch({type: 'setSlotData', payload: {...slot, reload: !state.reload}})
        toast.success(t('bases.base.syncData'))
    }

    const setComments = (value) => dispatch({ type: 'setSlotData', payload: { comments_for_manager: value } });

    return state.loading
        ? <Loader/>
        : <TableCard>
            <Row className={'mb-5'}>
                <Col>
                    <div className={'display-4'}>
                        {t('bases.base.slot')} <span style={{color: 'gray'}}>{state.name}</span>
                    </div>
                </Col>
            </Row>
            <Row className={'mb-5'}>
              <Col>
                <ManagerComments entity='slots' id={state.id} data={state.comments_for_manager} setData={setComments} />
              </Col>
            </Row>
            <Row className={'mb-5 justify-content-between'}>
                <Col xs='auto' className="mb-3">
                    <div className={'badge badge-outline-dark badge-pill'}>
                        {state.start_time ? dayjs(state.start_time, 'HH:mm:ss').format('HH:mm') : 'Нет'}
                    </div>
                    <span style={{color: 'gray', fontSize: 10}}>
                        {state.end_time
                            ? <> - {dayjs(state.end_time, 'HH:mm:ss').format('HH:mm')} | {getStartDayAndMonth(state.start_date, language)} | {getStartWeekDay(state.start_date, language)}</>
                            : ''
                        }
                    </span>
                </Col>
                <Col className="mb-3" xs='auto'>
                    {state.id && <div className={'m-0 mr-1 p-0 btn btn-light btn-rounded btn-sm '}>
                        <AiOutlineSync
                            className={'boats-amount-icon'}
                            size={20}
                            onClick={syncLoad}
                        />
                    </div>}
                    <div className={'badge badge-outline-dark badge-pill'}>
                        {(state.booked_sits || 0) + ` (${state.reserved_sits - state.booked_sits})` + ' / ' + (state.total_sits || 0)}
                    </div>
                    <span style={{
                        color: 'gray',
                        fontSize: 10
                    }}> - {state.booked_to_total || 0}% {t('bases.base.loading')} {state.available_sits} {t('bases.base.remained')} {state.reserved_sits - state.booked_sits} {t('bases.base.reserved')}</span>
                </Col>
                <RuleAccessProvider accessKey={frk["SLOT-ITEM-BOATS"]}>
                    <Col className="mb-3" xs='auto'>
                        <BoatsCounter
                            boatClass={state.event_id?.boat_class || ''}
                            boatsAmount={state.available_boats}
                            onClickMinus={() => dispatch({
                                type: 'changeBoatsAmount',
                                payload: state.available_boats - 1
                            })}
                            setValue={(num) => dispatch({
                                type: 'changeBoatsAmount',
                                payload: num
                            })}
                            onClickPlus={() => dispatch({
                                type: 'changeBoatsAmount',
                                payload: state.available_boats + 1
                            })} />
                    </Col>
                </RuleAccessProvider>
                <Col xs={12} lg='auto'>
                    <Row className="justify-content-end">
                        <Col className="mb-3" xs={'auto'}>
                            <ElementWithPermissions disabled={!permissions.edit}>
                                <Button className={'btn-rounded btn-dark'} size={'sm'} onClick={submitSlotEdit}>
                                    {t('bases.base.save')}
                                </Button>
                            </ElementWithPermissions>

                        </Col>
                        <Col className="mb-3" xs='auto'>
                            <div className={'btn btn-sm btn-rounded btn-outline-dark ml-1'}
                                 onClick={() => history.push('/bases/' + url + '/event/' + event_url)}>
                                {t('bases.base.cancel')}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabs
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(12 / tabs.length)}`}
                                    key={`field-tabs-${t(tab.name)}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {t(tab.name)}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {
                            tabs
                                .filter(tab => tab.isDisplayed !== false).map(tab => (
                                <TabPane tabId={tab.url} key={tab.name}>
                                    {tab.child}
                                </TabPane>
                            ))
                        }
                    </TabContent>
                </Col>
            </Row>

        </TableCard>
}

export default SlotInterface;