import React, {useEffect, useState} from 'react';
import {Input} from "reactstrap";
import PropTypes from "prop-types";
import DayPicker, {DateUtils} from "react-day-picker";
import "./RangedSelectionDatePicker.scss"
import dayjs from "dayjs";
import {t} from "i18next";
import 'react-day-picker/lib/style.css';
import i18n from "i18next";
import useIsMobile from '../../../lib/utils/hooks/useIsMobile';
import { localazeCalendar } from '../../../lib/utils/helpers/localizeFeatures';

const {language} = i18n;

const RangedSelectionDatePicker = ({daysFilter, setDaysFilter, numberOfMonths= 1, dateFormat='YYYY.MM.DD', disabledDays}) => {
    const [selectedCount, setSelectedCount] = useState(0);
    const [showDatePicker, setShowDatePicker] = useState(false)
    const isMobile = useIsMobile();

    const handleDayClick = (day) => {
        setSelectedCount(selectedCount + 1)

        setDaysFilter(
            DateUtils.addDayToRange(day, daysFilter)
        )
    }

    const inputValue = daysFilter.from && daysFilter.to
        ? dayjs(daysFilter.from).format(dateFormat) + " - " + dayjs(daysFilter.to).format(dateFormat)
        : ''

    const modifiers = { start: daysFilter.from, end: daysFilter.to };

    useEffect(() => {
        const isValidRange = daysFilter.from && daysFilter.to
        if (!isValidRange) return

        if (selectedCount > 1) {
            setShowDatePicker(false)
            setSelectedCount(0)
        }
    }, [daysFilter?.from, daysFilter?.to])

    return (
        <div>
            <Input placeholder={t('generic.rangedSelectionDatePicker.placeholder')}
                   value={inputValue} onClick={() => setShowDatePicker(showDatePicker => !showDatePicker)} onChange={() => {}} />
            {showDatePicker && <DayPicker
                firstDayOfWeek={language === 'ru' ? 1 : 0}
                className="Selectable"
                numberOfMonths={isMobile ? 1 : 2}
                selectedDays={[daysFilter.from, { from: daysFilter.from, to: daysFilter.to }]}
                modifiers={modifiers}
                onDayClick={handleDayClick}
                disabledDays={disabledDays}
                locale={language}
                {...localazeCalendar(language)}
            />}
        </div>
    )
}

RangedSelectionDatePicker.propTypes = {
    daysFilter: PropTypes.object.isRequired,
    setDaysFilter: PropTypes.func.isRequired
}

export default RangedSelectionDatePicker;