import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DirectoriesCard from "./cards/DirectoriesCard";
import {Nav, NavLink} from "reactstrap";
import AdministrationCard from "./cards/AdministrationCard";
import SchoolCard from "./cards/SchoolCard";
import TravelCard from "./cards/TravelCard";
import BasesCard from "./cards/BasesCard";
import DataLensCard from "./cards/DataLensCard";
import {Link} from "react-router-dom";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../lib/utils/hocs/ElementWithAccessInCountries';
import {COUNTRY} from "../../lib/utils/constants";
import CampsCard from './cards/CampsCard';
import {useRuleAccess} from "../../lib/utils/frontRules";


export const GenerateLink = (subRoute, index, userRole) => {
    const { check } = useRuleAccess();
    let permitted;
    if (subRoute.access && !subRoute.hideInCountries?.includes(COUNTRY)) {
        permitted = !!(subRoute.access.find(role => role === userRole));
    }

    const isRouteShowed = (r) => {
        const basicFilter = (!r.access || r.access.includes(userRole)) && !r.hideInCountries?.includes(COUNTRY);

        return basicFilter && (r.accessKey ? check(r.accessKey) : true);
    }
    return permitted && isRouteShowed(subRoute)
        ? <div>
            <NavLink href={subRoute.to} key={subRoute.to + index}>
                <span style={{color: 'black', textDecoration: 'underline'}}>{t(subRoute.title)}</span>
                {subRoute.extraLinks && subRoute.extraLinks.map((extraLink, index) =>
                    <span key={extraLink.to + index}>
                        <Link
                            to={extraLink.to}>
                            {extraLink.link}
                        </Link>
                     </span>)}
            </NavLink>
        </div>
        : <></>
}
export const generateLinks = (route, userRole) => {
    let permitted;
    if (route.access) permitted = !!(route.access.find(role => role === userRole));
    return permitted
        ? <div>
            <Nav vertical>
                {route.children.map((subRoute, index) => GenerateLink(subRoute, index, userRole))}
            </Nav>
        </div>
        : <></>
}


const Dashboard = ({createTranslatedBreadcrumbsAndTitle}) => {
    useEffect(() => {
        createTranslatedBreadcrumbsAndTitle && createTranslatedBreadcrumbsAndTitle({}, {})
    }, [])
    return (<>
            <div className="row">
                <DirectoriesCard/>
                <AdministrationCard/>
                <div className="col-xs-3 col-md-6 col-lg-6 col-xl-3  grid-margin stretch-card">
                    <div className="row flex-column w-100 justify-content-between no-gutters">
                        <SchoolCard/>
                        <ElementWithAccessInCountries hideInCountries={['EN']}>
                          <div>
                            <CampsCard/>
                            <TravelCard/>
                          </div>
                        </ElementWithAccessInCountries>
                    </div>
                </div>
                <BasesCard/>
            </div>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <div className={'row'}>
                    <DataLensCard />
                </div>
            </ElementWithAccessInCountries>
        </>
    );
};

Dashboard.propTypes = {
    createTranslatedBreadcrumbsAndTitle: PropTypes.any
};

export default Dashboard;