import {PopoverBody, UncontrolledPopover} from "reactstrap";
import {AiOutlineBook} from "react-icons/ai";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {t} from "i18next";

const showSuccessToast = e => toast(e, {type: 'success'});

const PayCertificatePopover = ({orderId, certificateName, certificateSize, certificatePriceCurrency, certificateSum}) => (
    <>
        <CopyToClipboard text={certificateName} onCopy={() => showSuccessToast(t('common.popovers.subscriptionNameCopied'))}>
            <AiOutlineBook id={"certificate-" + orderId} size={'20px'} color={'green'}/>
        </CopyToClipboard>

        <UncontrolledPopover placement="left" target={"certificate-" + orderId} trigger={'hover'}>
            <PopoverBody>
                <b className={'popover-text'}>{`${certificateName} `}</b>
                {!!certificateSize
                && <div>
                    {t('common.popovers.remainder')}
                    <b>{` ${certificateSize} ${certificatePriceCurrency}`}</b>
                </div>
                }
                {!!certificateSum &&
                <div>
                    {t('common.popovers.writeOffAmount')} <b>{`${certificateSum} ${certificatePriceCurrency}`}</b>
                </div>
                }
            </PopoverBody>
        </UncontrolledPopover>
    </>
)

export default PayCertificatePopover