import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {Col, Row} from 'reactstrap';

const SubMenuLink = ({to, children, exact, location, extraLinks}) => {
    const isActive = exact ? location.pathname === to : location.pathname.startsWith(to);
    return (
        <>
            <li className={`nav-item ${isActive ? "active" : ""}`}>
                <Row className={`no-gutters justify-content-space-between`}>
                    <Col className={`col-9`}>
                        <Link
                            className={`nav-link ${isActive ? "active" : ""}`}
                            to={to}>
                            {children}
                        </Link>
                    </Col>
                    {extraLinks && extraLinks.map((extraLink, index) => <Col key={extraLink.to + index} className={`col-3`}>
                        <Link
                            className={`nav-link ${isActive ? "active" : ""}`}
                            to={extraLink.to}>
                            {extraLink.link}
                        </Link>
                    </Col>)
                    }
                </Row>

            </li>
        </>
    );
}

SubMenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool
};

export default SubMenuLink;