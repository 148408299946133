import React, {useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from "react-router-dom"
import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'
import tabList from "./tabList";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import CommonData from "./CommonData";
import {t} from "i18next";

const showDangerToast = e => toast(e, {type: 'error'});
const showSuccessToast = e => toast(e, {type: 'success'});

const EditModal = ({triggerModal, isOpened, bases, ...rest}) => {
    let history = useHistory()
    let {url} = useParams();
    const {pathname, search} = useLocation();
    const [entity, setEntity] = useState({
        id:  '',
        photo:  '',
        first_name:  '',
        last_name:  '',
        surname: '',
        status: '',
        boats_classes:  [],
        base_id: undefined,
        client_id: '',
        state: '',
    })
    const [isDisabled, disableButton] = useState(false);
    const {createEntry, editEntry} = useDefaultEndpoints('instructors');

    const [activeTab, setActiveTab] = useState('new');
    const form = useForm({reValidateMode: 'onBlur',});

    const {handleSubmit} = form;


    const toggleTab = tab => {
        history.replace(`${pathname}?t=${tab.url}`)
        if (activeTab !== tab.url) setActiveTab(tab.url)
    };
    useEffect(() => {
        if (!search) history.replace(`${pathname}?t=${activeTab}`);
        else setActiveTab(() => 'existing')
        // eslint-disable-next-line
    }, [history.location.pathname, url])

    const createOrUpdate = (data) => {
        if (entity.id) editEntry(entity.id, data)
            .then(() => {
                showSuccessToast(t('management.instructors.editModal.success'));
                triggerModal()
            })
            .catch(showDangerToast)
        else createEntry({client_id: entity.client_id, ...data, type: activeTab}).then(() => {
            showSuccessToast(t('management.instructors.editModal.success'))
            triggerModal()
        })
            .catch(showDangerToast)
    }


    const rhfSubmit = handleSubmit((data) => {
        createOrUpdate(data)
    }, err => disableButton(() => false))

    const onSubmit = () => {
        disableButton(() => true)
        return rhfSubmit()
    }

    const tabs = tabList({state: entity, dispatch: setEntity, form});

    return <Modal isOpen={isOpened} toggle={triggerModal} className={'edit-modal'} size={'lg'}>
        <ModalHeader>{t('management.instructors.editModal.createInstructor')}</ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <Nav tabs fill className={'align-items-end no-gutters'}>
                        {tabs
                            .filter(tab => tab.isDisplayed !== false)
                            .map(tab =>
                                <NavItem
                                    className={`col-sm-12 col-md-${Math.ceil(12 / tabs.length)}`}
                                    key={`field-tabs-${tab.name}`}>
                                    <NavLink className={activeTab === tab.url && "active" ? "active" : ""}
                                             onClick={() => toggleTab(tab)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>
                            )}
                    </Nav>
                </Col>
            </Row>

            <Row className={'mb-3'}>
                <Col>
                    <TabContent activeTab={activeTab}>
                        {
                            tabs
                                .filter(tab => tab.isDisplayed !== false).map(tab => (
                                <TabPane tabId={tab.url} key={tab.name}>
                                    {tab.child}
                                </TabPane>
                            ))
                        }
                    </TabContent>
                </Col>
            </Row>
            <CommonData setEntity={setEntity} entity={entity} bases={bases} form={form}/>

        </ModalBody>
        <ModalFooter>
            <div>
                <Row>
                    <Col>
                        <Button color="primary" onClick={onSubmit} disabled={isDisabled}>
                            {t('management.instructors.editModal.ok')}
                        </Button></Col>
                    <Col>
                        <Button color="secondary" outline onClick={triggerModal}>
                            {t('management.instructors.editModal.cancel')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </ModalFooter>
    </Modal>
}

export default EditModal;

EditModal.propTypes = {
    requiredFields: PropTypes.array,
    triggerModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
}