import React from "react";
import PropTypes from 'prop-types';
import ControllerRHF from "./ControllerRHF";
import {Dropzone} from "./index";

const FileControllerRHF = ({
    form,
    name,
    fileName,
    label,
    defaultValue,
    rules,
    hint,
    isRequired = false
}) =>
    <ControllerRHF
        name={name}
        form={form}
        label={label}
        defaultValue={defaultValue}
        rules={rules}
        hint={hint}
        isRequired={isRequired}
        render={(
            {value, onBlur, name, ref, onChange},
            {invalid, isTouched, isDirty}
        ) =>
            <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                 className={invalid ? 'is-invalid' : ''}>
                <Dropzone name={fileName} extFile={value} onBlur={onBlur} callback={onChange}/>
            </div>
        }
    />

FileControllerRHF.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any.isRequired,
    rules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
}


export default FileControllerRHF;