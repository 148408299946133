import {useCallback, useEffect, useState} from "react";
import _ from "lodash"
const useEditTable = ({beforeDebounceCallback, debounceCallback, debounceErrorCallback, debounceSuccessCallback, timeout = 1000, payload, deps = [], callbackDeps = []}) => {
    const [initialized, setInitialized] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedChangeHandler = useCallback(
        _.debounce((params) => {
            setLoading(true)
            debounceCallback(params)
                .then((data) => {
                  debounceSuccessCallback(data);
                })
                .catch(() => {
                    setError(true)
                    debounceErrorCallback()
                })
                .finally(() => setLoading(false))
        }, timeout)
        , callbackDeps)

    useEffect(() => {
        setInitialized(true)
    }, [])

    useEffect(() => {
        if (initialized && !error) {
            let data = payload
            if (beforeDebounceCallback) data = beforeDebounceCallback(payload)
            debouncedChangeHandler(data)
        }

        if (error) setError(false)
    }, deps)


    return {loading}
}

export default useEditTable