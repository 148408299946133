import React, {useCallback, useEffect, useMemo, useState} from "react";
import {NumberParam, StringParam, useQueryParam} from 'use-query-params';
import SortableTable, {HeaderConfig} from "./SortableTable";
import LoaderOverlay from "../loader/LoaderOverlay";
import {Pager} from "../pager";
import PropTypes from "prop-types";
import {getCookie, setCookie} from "../../../lib/utils/cookies";
import {Button} from 'reactstrap';
import "./DataTable.css";
import ButtonWithConfirm from "../buttons/ButtonWithConfirm";
import {toast} from "react-toastify";
import {FaMarker, FaTrash} from "react-icons/fa";
import _ from 'lodash';
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {useRuleAccess} from "../../../lib/utils/frontRules";

const showDangerToast = e => toast(e, {type: 'error'});

function DataTable(props) {
    const prefix = props.name ? props.name + '_' : '';
    const [start, setStart] = useQueryParamWithDefault(prefix + 'start', NumberParam, 0);
    const [limit, setLimit] = useQueryParamWithDefault(prefix + 'limit', NumberParam, 20);
    const [sortField, setSortField] = useQueryParam(prefix + 'sortField', StringParam);
    const [sortDir, setSortDir] = useQueryParam(prefix + 'sortDir', StringParam);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [totalRow, setTotalRow] = useState();
    const [multipleSelectedIds, setMultipleSelectedIds] = useState([]);
    const [multipleSelectAll, setMultipleSelectAll] = useState(false);
    const { check } = useRuleAccess();

    const multipleSelectedItems = useMemo(() =>
        (data || []).filter(x => x[props.rowKey] && multipleSelectedIds && multipleSelectedIds.includes(x[props.rowKey])), [data, multipleSelectedIds]);

    const {emptyContent, fields, onClick, onChange, onDelete, additionalButtons, tableButtons, onMultipleDelete, multipleSelection, onMultipleSelect, onMultipleAllSelect, buttonsProps} = props;

    const hasButtons = onChange || onDelete || additionalButtons;
    const hasMultipleSelection = !!onMultipleSelect || multipleSelection || onMultipleDelete;
    const hasMultipleDelete = onMultipleDelete && multipleSelectedIds && multipleSelectedIds.length > 0;
    const filteredFields = fields.filter(i => i.frontendRule?.hide ? check(i.frontendRule.key) : true);

    const dataParameters = {
        sortField: sortField || props.sortField, sortDir: sortDir || props.sortDir, start: start || 0,
        limit: limit || 10, filters: {...props.filters}};

    function filterDataIds(ids, data) {
        return (ids || []).filter(id => (data || []).find(x => x[props.rowKey] === id));
    }

    function filterData(ids) {
        return (data || []).filter(x => {
          return x[props.rowKey] && ids.includes(x[props.rowKey])
        });
    }

    function onMultipleSelectionClick(id, item, checked) {
        let newArray = filterDataIds(multipleSelectedIds, data).filter(x => x !== id);

        if(checked) newArray = newArray.concat(id);

        setMultipleSelectedIds(newArray);

      const newData = filterData(newArray);

        if(onMultipleSelect) onMultipleSelect(newArray, newData);
    }

    function onMultipleSelectAll(checked) {
        let newArray = [];

        if(checked) newArray = (data || []).map(x => x[props.rowKey]);

        setMultipleSelectAll(checked);
        setMultipleSelectedIds(newArray);

        if (onMultipleAllSelect) {
          if (checked) {
            onMultipleAllSelect(newArray.filter(i => !!i), (data || []).filter(i => !!i[props.rowKey]));
          } else {
            onMultipleAllSelect([], []);
          }
        }
    }

    useEffect(() => {
        setLoading(true);

        const {start, limit, sortField = 'created_at', sortDir, filters} = dataParameters;

        const fetchedLimit = limit || +getCookie(prefix + 'limit') || 10;

        const params = _.pickBy({_start: start, _limit: limit, _sort: sortField + ':' + sortDir, ...filters}, (value) => !(value === undefined || value === null || value === ''))

        props.findAndCount(params)
            .then(({data, total, totalRow}) => {
                setData(data);
                setTotal(total);
                setTotalRow(totalRow);
                setMultipleSelectedIds(filterDataIds(multipleSelectedIds, data));
                setMultipleSelectAll(multipleSelectedIds && multipleSelectedIds.length > 0 && (data || []).every(x => multipleSelectedIds.includes(x[props.rowKey])));

                if(start >= total) setStart((total - total % fetchedLimit) || 0, 'replaceIn');
            }).catch(e => {
            showDangerToast(e);
        }).finally(() => {
            setLoading(false);
        });

    }, [props.reload, JSON.stringify(dataParameters)]);

    useEffect(() => {
        setCookie(prefix + 'limit', limit);
    }, [limit]);

    const handleSort = useCallback(function (sortField, sortDir) {
        setSortField(sortField);
        setSortDir(sortDir);
    }, []);

    const handleMouseUp = useCallback((e, row) => {
        if (props.onCellClick) return;
        const isMiddleClick = e.button === 1 || ((e.ctrlKey ||  e.metaKey) && e.button === 0)
        if (props.onMiddleClick && isMiddleClick) {
            e.preventDefault();
            // prevents triggering of onClick when clicking buttons
            const className = e.target.className;
            if (!className.includes || className.includes('btn') || className.includes('fa') || className.includes('popover')) return;
            props.onMiddleClick(row);
        }
    }, [props.onCellClick, props.onMiddleClick])

    const handleRowClick = useCallback(function (e, row) {
        if (e.metaKey || e.ctrlKey) return;
        if (props.onCellClick) return;
        if (props.onClick) {
            e.preventDefault();
            // prevents triggering of onClick when clicking buttons
            const className = e.target.className;

            if (e.target.closest('.cancel-click')) {
              return;
            }

            if (!className.includes || className.includes('btn') || className.includes('fa') || className.includes('popover')) return;
            props.onClick(row);
        }
    }, [props.onCellClick, props.onClick]);

    const handleCellClick = useCallback(function (e, row, field) {
        if (!props.onCellClick) return;
        e.preventDefault();
        props.onCellClick(row, field);
    }, [props.onCellClick]);

    const totalRowRender = props.totalRowRender || (totalRow => <tr className="totalRow">
        {hasMultipleSelection && <th/>}
        {filteredFields.map(field =>
            <th key={`total-row-cell-${field.key}`}>
                {field.render(totalRow)}
            </th>
        )}
        {hasButtons && <th/>}
    </tr>);

    return <LoaderOverlay isVisible={loading} className="data-table-content">
        {((!data || data.length === 0) && emptyContent) ? emptyContent :
            <div className="table-responsive data-table-content">
                <SortableTable handleSort={handleSort}
                               fields={filteredFields.map(x => x.headerConfig).concat(hasButtons ? [new HeaderConfig("", false, "action-column")] : [])}
                               sortField={sortField}
                               sortDir={sortDir}
                               hover={!!onClick}
                               hasMultipleSelection={hasMultipleSelection}
                               onMultipleSelectAll={onMultipleSelectAll}
                               multipleSelectAll={multipleSelectAll}
                               style={props.tableStyle}
                >
                    {totalRow && totalRowRender(totalRow)}
                    {data && data.map((res, i) => {

                        return props.customRowRender
                                ? props.customRowRender (res, i)
                                : (
                            <tr className={props.rowClass && props.rowClass(res)}
                                key={`row-${props.name}-${res[props.rowKey]}-${i}`}
                                onClick={e => handleRowClick(e, res)}
                                onMouseUp={e => handleMouseUp(e, res)}
                            >
                                {hasMultipleSelection &&
                                <td style={{width:'1px'}}>
                                    {res[props.rowKey] && <input
                                        onChange={event => onMultipleSelectionClick(res[props.rowKey], res, event.target.checked)}
                                        checked={multipleSelectedIds && res && multipleSelectedIds.includes(res[props.rowKey]) ? 'checked' : ''}
                                        className="checkbox"
                                        type="checkbox"
                                    />}
                                </td>
                                }
                                {
                                    filteredFields.map(field =>
                                        <td key={`row-${res[props.rowKey]}-cell-${field.key}`}
                                            onClick={e => handleCellClick(e, res, field)}
                                            className={field.getClassName(res, field)}>
                                            {typeof field.render(res) === 'boolean' ? field.render(res).toString() : field.render(res)}
                                        </td>
                                    )
                                }
                                {
                                    hasButtons && (
                                        <td className="small-padding small-width text-center" style={buttonsProps}>
                                            <div className="btn-group">
                                                {additionalButtons && additionalButtons(res)}
                                                {onChange &&
                                                <Button color="success" size="xs" onClick={() => onChange(res)}><FaMarker/>
                                                </Button>}
                                                {onDelete && (props.useSimpleButton
                                                        ? <Button color="danger" size="xs" onClick={() => onDelete(res)}><FaTrash/></Button>
                                                        : <ButtonWithConfirm onClick={() => onDelete(res)}
                                                                             className="btn-xs"
                                                                             modalButtonText="Удалить"
                                                                             color="danger"
                                                                             modalText="Вы действительно хотите удалить?">
                                                            <FaTrash/>)
                                                        </ButtonWithConfirm>
                                                )}
                                            </div>
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    })}
                </SortableTable>
            </div>
        }
        <div className={"mt-2"}>
            <Pager
                start={start}
                total={total}
                limit={limit}
                onPageChange={x => setStart(x, 'replaceIn')}
                onLimitChange={x => {
                    setStart(0, 'replaceIn');
                    setLimit(x, 'replaceIn');
                }}
                canChangeLimit={!!props.name}
            />

            {tableButtons && tableButtons(dataParameters, multipleSelectedIds, multipleSelectedItems)}
            {hasMultipleDelete && <ButtonWithConfirm onClick={() => onMultipleDelete(multipleSelectedIds, multipleSelectedItems)}
                                                     className="btn-xs"
                                                     modalButtonText="Удалить"
                                                     color="danger"
                                                     modalText={`Вы хотите удалить ${multipleSelectedIds.length} элемента?`}>
                Удалить {multipleSelectedIds.length} запись(ей)
            </ButtonWithConfirm>}
        </div>
    </LoaderOverlay>;
}

export class Field {
    constructor(label, isSortable, key, render, className, headerClassName, frontendRule) {
        this.getClassName = this.getClassName.bind(this);
        this.label = label;
        this.isSortable = isSortable || false;
        this.key = key || label;
        this.render = render || (x => Field.defaultRender(Field.resolveNested(this.key, x)));
        this.className = className;
        this.headerClassName = headerClassName;
        this.frontendRule = frontendRule;
    }

    static resolveNested(path, item) {
        var properties = Array.isArray(path) ? path : path.split(".");
        return properties.reduce((prev, curr) => prev && prev[curr], item)
    }

    get headerConfig() {
        return new HeaderConfig(this.label, this.isSortable, this.key, this.headerClassName)
    }

    static defaultRender(value) {
        //Display decimal with two places
        if (Number(value) === value && value % 1 !== 0) {
            return parseFloat(Math.round(value * 100) / 100).toFixed(2);
        } else return value;
    }

    getClassName(res, field) {
        if (!this.className) return undefined;
        if (typeof this.className === "function") return this.className(res, field);
        return this.className;
    }
}

/**
 * Конфигурация поля таблицы
 * @param label - Название поля. Может быть тегом <i>курсив</i>
 * @param isSortable - Флаг сортировки. По умолчанию выключена
 * @param key - ключ поля (название поля в таблице). По умолчанию равен label
 * @param render - функция отображения поля. По умолчанию res => res[key] - значение по ключу поля
 * @param {string|function} className - класс ячейки td
 * @param headerClassName
 * @param frontendRule
 * @returns {Field}
 */
export const field = (label, isSortable, key, render, className, headerClassName, frontendRule) => new Field(label, isSortable, key, render, className, headerClassName, frontendRule);

DataTable.propTypes = {
    filters: PropTypes.object,
    findAndCount: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.instanceOf(Field)),
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    rowKey: PropTypes.string,
    onClick: PropTypes.func,
    onMiddleClick: PropTypes.func,
    onCellClick: PropTypes.func,
    name: PropTypes.string,
    reload: PropTypes.any,
    rowClass: PropTypes.func,
    emptyContent: PropTypes.node,
    limit: PropTypes.number,
    totalRowRender: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    /**
     * function(row: object): JSX
     */
    additionalButtons: PropTypes.func,
    /**
     * function(parameters: {sortField, sortDir, start, limit, filters}, ids: any[], items: object[]): JSX
     */
    tableButtons: PropTypes.func,
    /**
     * function(ids: any[], items: object[])
     */
    onMultipleSelect: PropTypes.func,
    multipleSelection: PropTypes.bool,
    /**
     * function(ids: any[], items: object[])
     */
    onMultipleDelete: PropTypes.func,
    useSimpleButton: PropTypes.bool,
    onMultipleAllSelect: PropTypes.func,
    tableStyle: PropTypes.object,
};

DataTable.defaultProps = {
    filters: {},
    sortDir: "desc",
    rowKey: "id",
    name: "",
    multipleSelection: false,
    useSimpleButton: true
};

export default DataTable;